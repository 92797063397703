import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    psdDropDown:[],
    scatDropDown:[],
    listDropDown:[],
    summaryFloorDropDown:[],
    scheduleDatesDropdown:[],
    baListDropdown:[],
    VOANNDRRVUDropdown:[],
    VTODropdown:[],
    localVTODropdown:[],
    Settlement2023:[],
    Settlement2017:[],
    Settlement2010:[],
    Settlement2005:[],
    Settlement2000:[],
    Settlement1995:[],
    Settlement1990:[],

  };
  const preloadedSlice = createSlice({
    name: 'preloadedData',
    initialState,
    reducers: {
      setPSDDropDown(state,action){
        state.psdDropDown = action.payload;
      },

      setSCATDropDown(state,action){

        state.scatDropDown = action.payload;
      },  
      
      setSummaryFloorDropDown(state,action){

        state.summaryFloorDropDown = action.payload;
      },      
      setListDropDown(state,action){

        state.listDropDown = action.payload;
      },      
      setScheduleDatesDropDown(state,action){

        state.scheduleDatesDropdown = action.payload;
      },
      setBAListDropdown(state,action){
        state.baListDropdown = action.payload;
      },
      setVOANNDRRVUDropdown(state,action){
        state.VOANNDRRVUDropdown = action.payload;
      },
      setVTODropdown(state,action){
        state.VTODropdown = action.payload;
      },
      setLocalVTODropdown(state,action){
        state.localVTODropdown = action.payload;
      },
      setSettlement2023(state,action){
        state.Settlement2023 = action.payload;
      },
      setSettlement2017(state,action){
        state.Settlement2017 = action.payload;
      },
      setSettlement2010(state,action){
        state.Settlement2010 = action.payload;
      },
      setSettlement2005(state,action){
        state.Settlement2005 = action.payload;
      },
      setSettlement2000(state,action){
        state.Settlement2000 = action.payload;
      },
      setSettlement1995(state,action){
        state.Settlement1995 = action.payload;
      },
      setSettlement1990(state,action){
        state.Settlement1990 = action.payload;
      },
      resetPreload(state){
          return initialState
          
      }
    },
});
export const {resetPreload, setPSDDropDown, setSCATDropDown,setListDropDown,setScheduleDatesDropDown,setBAListDropdown,setVOANNDRRVUDropdown,
  setVTODropdown,setSummaryFloorDropDown, setLocalVTODropdown,setSettlement2023,setSettlement2017,setSettlement2010,setSettlement2005,setSettlement2000,setSettlement1995,setSettlement1990
} = preloadedSlice.actions;
export default preloadedSlice.reducer;
export const selectPSDDropDown = state => state.preloadedData.psdDropDown;

export const selectScheduleDatesDropDown= state => state.preloadedData.scheduleDatesDropdown;
export const selectSCATDropDown =state => state.preloadedData.scatDropDown;

export const selectListDropDown =state => state.preloadedData.listDropDown;
export const selectBAListDropdown =state => state.preloadedData.baListDropdown;
export const selectVOANNDRRVUDropdown =state => state.preloadedData.VOANNDRRVUDropdown;
export const selectSummaryFloorDropDown =state => state.preloadedData.summaryFloorDropDown;
export const selectVTODropdown =state => state.preloadedData.VTODropdown;
export const selectLocalVTODropdown =state => state.preloadedData.localVTODropdown;
export const selectSettlement2023 =state => state.preloadedData.Settlement2023;
export const selectSettlement2017 =state => state.preloadedData.Settlement2017;
export const selectSettlement2010 =state => state.preloadedData.Settlement2010;
export const selectSettlement2005 =state => state.preloadedData.Settlement2005;
export const selectSettlement2000 =state => state.preloadedData.Settlement2000;
export const selectSettlement1995 =state => state.preloadedData.Settlement1995;
export const selectSettlement1990 =state => state.preloadedData.Settlement1990;
  