import { useEffect, useState } from "react";
import IntegratedSettlement from "../../Models/IntegratedSettlement";
import { useSelector } from "react-redux";
import { selectListDropDown, selectSettlement1990, selectSettlement1995, selectSettlement2000, selectSettlement2005, selectSettlement2010, selectSettlement2017, selectSettlement2023 } from "../../Redux/PreloadedSlicer";
import store from "../../Redux/ConfigureStore";
import { cleanup } from "@testing-library/react";
import { selectIntegratedListSettlement, setIntegratedSettlementSearch } from "../../Redux/SearchSlicer";
import BasicCodeModel from "../../Models/BasicCodeModel";
import { Checkbox } from "@mui/material";
import { ConstructionOutlined } from "@mui/icons-material";

export default function IntegratedSettlementSearchComponent(props) {
    const { searchCount } = props;
    const listDropDown = useSelector(selectListDropDown);
    const [selectedSettlementList, setSelectedSettlementList] = useState<Array<BasicCodeModel>>([]);
    const settlement2023Codes = useSelector(selectSettlement2023);
    const settlement2017Codes = useSelector(selectSettlement2017);
    const settlement2010Codes = useSelector(selectSettlement2010);
    const settlement2005Codes = useSelector(selectSettlement2005);
    const settlement2000Codes = useSelector(selectSettlement2000);
    const settlement1995Codes = useSelector(selectSettlement1995);
    const settlement1990Codes = useSelector(selectSettlement1990);
    const data = useSelector(selectIntegratedListSettlement)
    useEffect(() => {
        setSelectedSettlementList(settlement2023Codes)
        return () => {
            cleanup();
        };
    }, [settlement2023Codes]);
    
    const handleChange = async (e) => {
        const { name, value } = e.target;
        if(data ==undefined || data == null){return;}
        let updatedSearchData = { ...data, [name]: Number(value) } ;
        //setSearchData(new IntegratedSettlement(updatedSearchData));
        if(name == 'listYear'){
            switch (Number(value)){
                case 2023:{
                    updatedSearchData = { ...updatedSearchData, settlementType:[]};
                    setSelectedSettlementList(settlement2023Codes);
                    store.dispatch(setIntegratedSettlementSearch(new IntegratedSettlement(updatedSearchData).serialize()));
                    break;
                }
                case 2017:{
                    updatedSearchData = { ...updatedSearchData, settlementType:[]};
                    store.dispatch(setIntegratedSettlementSearch(new IntegratedSettlement(updatedSearchData).serialize()));
                    setSelectedSettlementList(settlement2017Codes);
                    break;
                }
                case 2010:{
                    updatedSearchData = { ...updatedSearchData, settlementType:[]};
                    store.dispatch(setIntegratedSettlementSearch(new IntegratedSettlement(updatedSearchData).serialize()));
                    setSelectedSettlementList(settlement2010Codes);
                    break;
                }
                case 2005:{
                    updatedSearchData = { ...updatedSearchData, settlementType:[]};
                    store.dispatch(setIntegratedSettlementSearch(new IntegratedSettlement(updatedSearchData).serialize()));
                    setSelectedSettlementList(settlement2005Codes);
                    break;
                }
                case 2000:{
                    updatedSearchData = { ...updatedSearchData, settlementType:[]};
                    store.dispatch(setIntegratedSettlementSearch(new IntegratedSettlement(updatedSearchData).serialize()));
                    setSelectedSettlementList(settlement2000Codes);
                    break;
                }                
                case 1995:{
                    updatedSearchData = { ...updatedSearchData, settlementType:[]};
                    store.dispatch(setIntegratedSettlementSearch(new IntegratedSettlement(updatedSearchData).serialize()));
                    setSelectedSettlementList(settlement1995Codes);
                    break;
                }
                case 1990:{
                    updatedSearchData = { ...updatedSearchData, settlementType:[]};
                    store.dispatch(setIntegratedSettlementSearch(new IntegratedSettlement(updatedSearchData).serialize()));
                    setSelectedSettlementList(settlement1990Codes);
                    break;
                }
            }
        }
    };
    const handleCheckBoxChange = (e) => {
        const { checked, name } = e.target;
        const updatedSearchData = { ...data, [name]: checked };
        store.dispatch(setIntegratedSettlementSearch(new IntegratedSettlement(updatedSearchData).serialize()));
    }
    const handleListCheckBoxChange =async  (e) => {
        const { checked, value,name } = e.target;

        let updatedBasicCodeSearch;
        if (!data) return;
        if (checked) {
          
            updatedBasicCodeSearch = await new IntegratedSettlement({
              ...data,
              settlementType: [...data.settlementType, value]
            });
            await store.dispatch(setIntegratedSettlementSearch(updatedBasicCodeSearch.serialize()));

        } else {
            updatedBasicCodeSearch = await new IntegratedSettlement({
              ...data,
              settlementType: data.settlementType.filter(item => item !== value)
            });
            await store.dispatch(setIntegratedSettlementSearch(updatedBasicCodeSearch.serialize()));

        }
      
    }

    return(
        <>{data&&
        <div className="searchcomponentcategory">
                <div className="miniheader">
                <div className="layoutrow">
                    <Checkbox name='searchSettlement' checked={data.searchSettlement}  color="secondary" sx={{color: '#009FE3',p:0}} onChange={handleCheckBoxChange} /> 
                    <div className="integratedlistlabel">Active</div>
                </div>
                    <div className="trendminicontent">
                        <div className="surveylineselectcontainer">
                            <select id="dropdown" className='settlementselect' name='listYear' disabled={!data?.searchSettlement} value={data?.listYear} onChange={handleChange}>

                                {listDropDown.length!=0 && listDropDown.map((value) => (
                                    <option key={value} value={value}>
                                        {value} 
                                    </option>
                                ))}
                            </select>
                            <div className="settlementselecttext">List, where settlement types are.. </div>
                        </div>

                    </div>
                </div>
                <div className="settlementcomponent layoutcolumn">
                {selectedSettlementList.map((item, index) => (
                    <div className='basiccodecomponentrow' key={index}>
                    <Checkbox  name="scat"  sx={{color: '#009FE3',p:0}} color="secondary" checked={data?.settlementType.includes(item.value)} value={item.value} onChange={handleListCheckBoxChange} disabled={!data?.searchSettlement}/>
                    <label className="checkboxlabel" htmlFor={`checkbox-${index}`}>{item.value} = {item.label}</label>
                    </div>
                ))}
        </div>
            </div>}
        </>
    )
}