import React, { useEffect, useRef, useState } from 'react';
import { DataGridPro, GridRowsProp, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import {selectListYear} from '../../Redux/SearchSlicer';

import SurveyCarPark from '../../Models/SearchApiModels/SurveyCarPark';
import { useSelector } from 'react-redux';
import { FormatNumberWithCommas } from '../../Services/FormatService';




export default function SurveyPark(props) {
  const {uarn,data} = props


 
  const [selectedUARN,setSelectedUARN]=useState<string>()


  useEffect(()=>{
    setSelectedUARN(selectedUARN)
  },[selectedUARN])

  

  const rows = data;

  return (
     
    <>
    {rows.length !=0 ? (
   <div style={{ padding: '1px' }}>


<table className="bordered-table">
        <thead>
            <tr style={{color: '#0173C7'}}>
            <th >NO. OF CAR PARK SPACES</th>
         <th>SPACED VALUE(£)</th>
         <th>CAR PARK AREA (m²)</th>
         <th >AREA VALUE(£)</th>
         <th >CAR PARK TOTAL VALUE(£)</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{data?.cP_Spaces}</td>
                <td>{data?.cP_SPACED_VALUE}</td>
                <td>{data?.cP_AREA}</td>
                <td>{FormatNumberWithCommas(data?.cP_AREA_VALUE!)}</td>
                <td>{FormatNumberWithCommas(data?.cP_TOTAL!)}</td>
            </tr>
           
        </tbody>
    </table>


 </div>
     ):
     (<>
      {<p style={{marginLeft: '30px', marginTop: '30px'}}>No data available</p>}
    </>)
    
    }
    </>
    
   );




 
 
}