import { useEffect, useState } from "react";
import AuthorityScheduleAdv2 from "../../Models/AuthorityScheduleAdv2";
import { cleanup } from "@testing-library/react";
import store from "../../Redux/ConfigureStore";
import { setAuthorityScheduleAdv2Search } from "../../Redux/SearchSlicer";
import { SearchLogic } from "../../Models/Enums/SearchLogic";
import { DateSearchLogic } from "../../Models/Enums/DateSearchLogic";
import { DateRange, DesktopDatePicker, DesktopDateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import AdapterDayjs from '@mui/x-date-pickers/AdapterDayjs';
import { Checkbox, ClickAwayListener, FormControlLabel, Radio, TextField } from "@mui/material";


export default function AuthorityScheduleAdv2Component(props) {
    const [searchData, setSearchData] = useState<AuthorityScheduleAdv2 | null>(null);
    const { searchCount } = props;
    const [edDateRange, setEDDateRange] = useState<DateRange<Dayjs>>([null, null]);

    const [ladDateRange, setLADDateRange] = useState<DateRange<Dayjs>>([null, null]);
    const [isEDOpen, setIsEDOpen] = useState(false);

    const [isLADOpen, setIsLADOpen] = useState(false);
    useEffect(() => {
        var search = store.getState().search.authorityScheduleAdv2
        setSearchData(new AuthorityScheduleAdv2(search));
        var startEd = search.edValue ? dayjs(search.edValue) : null;
        var endEd = search.edValueLessThan ? dayjs(search.edValueLessThan) : null;
        var startLad = search.ladValue ? dayjs(search.ladValue) : null;
        var endLad = search.ladValueLessThan ? dayjs(search.ladValueLessThan) : null;
        setEDDateRange([startEd, endEd])
        setLADDateRange([startLad, endLad])
        return () => {
            cleanup();
        };
    }, []);
    useEffect(() => {
        if (searchCount <1) {
            setSearchData(new AuthorityScheduleAdv2(store.getState().search.authorityScheduleAdv2));
        }
        return () => {
            cleanup();
        };
    }, [searchCount]);
    const handleBlur= (e) => {
        const {value,name} = e.target;
        if (value.trim() === ''){
            const updatedSearchData = { ...searchData, [name]: 0 };
            setSearchData(new AuthorityScheduleAdv2(updatedSearchData));
        }
    }
    const handleDateRangeChange = (value, name) => {
        if (searchData) {
            const updatedSearchData = searchData;
            if (value[0]) {
                updatedSearchData[name + 'Value'] = value[0].toJSON()
            }
            if (value[1]) {
                updatedSearchData[name + 'ValueLessThan'] = value[1].toJSON()
            }
            setSearchData(new AuthorityScheduleAdv2(updatedSearchData));
            if (name == 'ed') {
                setEDDateRange(value)
            }
            if (name == 'lad') {
                setLADDateRange(value)

            }
        }

    }
    const handleDateChange = (value, name) => {
        if (searchData) {
            const updatedSearchData = searchData;
            if (value) {
                updatedSearchData[name + 'Value'] = value.toJSON()
            }
            setSearchData(new AuthorityScheduleAdv2(updatedSearchData));
            if (name == 'ed') {
                var newDateRange: DateRange<Dayjs> = [value, null]
                setIsEDOpen(false)

                setEDDateRange(newDateRange)
            }
            if (name == 'lad') {
                var newDateRange: DateRange<Dayjs> = [value, null]
                setIsLADOpen(false)

                setLADDateRange(newDateRange)
            }
        }

    }
    const handleChange = async (e) => {
        const { name, value } = e.target;
        const updatedSearchData = { ...searchData, [name]: value };
        setSearchData(new AuthorityScheduleAdv2(updatedSearchData));

    };
    const handleCheckBoxChange = (e) => {
        const { checked, name } = e.target;
        const updatedSearchData = { ...searchData, [name]: checked };
        setSearchData(new AuthorityScheduleAdv2(updatedSearchData));
    }
    const handleRadioChange = (e) => {
        const { name, value } = e.target;

        const radiovalue = value == 'true' ? true : false

        const updatedSearchData = { ...searchData, [name]: radiovalue };
        setSearchData(new AuthorityScheduleAdv2(updatedSearchData));
    }

    useEffect(() => {
        sendSearchData();
        setDates();
    }, [searchData]);
    const setDates = () => {
    }
    const sendSearchData = () => {
        if (searchData) {
            store.dispatch(setAuthorityScheduleAdv2Search(searchData.serialize()));

        }
    }
    return (
        <>
            {searchData &&
                <div className="searchcomponentcategory">
                    <div className="trendminicomponent">
                    <div className="trendminiheader">
                        <Checkbox color="secondary" sx={{ color: '#009FE3', p: 0 }} name='rvSearch' checked={searchData.rvSearch} onChange={handleCheckBoxChange} /> Rateable Value
                        <div className="trendminicontent">
                            <div className="surveylineselectcontainer">
                                <div className="advancedscheduletext">RV = </div>
                                <select id="dropdown" className='advancedscheduleselect' name='rvLogic' disabled={!searchData.rvSearch} value={searchData.rvLogic} onChange={handleChange}>
                                    {Object.values(SearchLogic).filter(key => isNaN(Number(SearchLogic[key]))).map((value) => (
                                        <option key={value} value={value}>
                                            {SearchLogic[value]}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>
                        <div className="surveylinevalues">
                            <input type='number' onBlur={handleBlur} className={searchData.rvLogic == 3 ? "authadvnumberinput shrink" : "authadvnumberinput"} name='rvValue' value={searchData.rvValue} disabled={!searchData.rvSearch} step='100' onChange={handleChange} />
                            {searchData.rvLogic == 3 &&
                                <>
                                    <label htmlFor="rvValueLessThan" className="intadvlessthanlabel">and</label>
                                    <input type='number'onBlur={handleBlur} className="authadvnumberinput lessthan" name='rvValueLessThan' value={searchData.rvValueLessThan} step='100' disabled={!searchData.rvSearch}  onChange={handleChange} />
                                </>
                            }
                        </div>

                    </div>
                    </div>
                    <div className="trendminicomponent">
                    <div className="trendminiheader">
                        <Checkbox color="secondary" sx={{ color: '#009FE3', p: 0 }} name='edSearch' checked={searchData.edSearch} onChange={handleCheckBoxChange} /> Effective Date
                        <div className="trendminicontent">
                            <div className="surveylineselectcontainer">
                                <div className="advancedscheduletext">Eff Date = </div>
                                <select id="dropdown" className='advancedscheduleselect' name='edLogic' disabled={!searchData.edSearch} value={searchData.edLogic} onChange={handleChange}>
                                    {Object.values(DateSearchLogic).filter(key => isNaN(Number(DateSearchLogic[key]))).map((value) => (
                                        <option key={value} value={value}>
                                            {DateSearchLogic[value]}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>
                        <div className="surveylinevalues">
                            <div className="adv2datepickers">

                                {searchData.edLogic == 4 &&
                                    <>
                                        <DesktopDateRangePicker
                                            value={edDateRange}
                                            onChange={(value) => handleDateRangeChange(value, 'ed')}
                                            calendars={1}
                                            disabled={!searchData.edSearch}

                                            slotProps={{
                                                field: { dateSeparator: 'and',sx:{fontSize:'12px'} },
                                                textField: {
                                                    label:false,
                                                    sx: {
                                                        '& fieldset': {
                                                            padding: 0,
                                                            height: 25,
                                                            borderColor:'#009FE3',

                                                        },
                                                        '& .MuiInputBase-root': {
                                                            padding: 0, 
                                                            borderColor:'#009FE3',
                                                            borderRadius:'0px',
                                                            '& .MuiInputBase-input': {
                                                                width: 300,
                                                                fontSize: 12,
                                                                height: 15,
                                                                borderRadius:'0px',
                                                                lineHeight:'20px',
                                                                padding: '5px',
                                                            },
                                                        },
                                                    },
                                                    style: {
                                                        padding:'0px',
                                                        height: '30px',
                                                        borderRadius:0,
                                                        lineHeight:'20px'
                                                    },
                                                    className: "hide-adornment ",
                                                },

                                            }}
                                        />
                                    </>
                                }
                                {searchData.edLogic != 4 &&
                                    <ClickAwayListener onClickAway={() => (console.log('clicked away'),setIsEDOpen(false))}>
                                        <DesktopDatePicker
                                            value={edDateRange[0]}
                                            onChange={(value) => handleDateChange(value, "ed")}
                                            open={isEDOpen}
                                            disabled={!searchData.edSearch}
                                            key='ed'
                                            slotProps={{
                                                textField: {
                                                    sx: {
                                                        '& fieldset': {
                                                            padding: 0,
                                                            height: 25,
                                                            borderColor:'#009FE3',

                                                        },
                                                        '& .MuiInputBase-root': {
                                                            padding: 0, 
                                                            borderColor:'#009FE3',
                                                            borderRadius:'0px',
                                                            '& .MuiInputBase-input': {
                                                                width: 300,
                                                                fontSize: 12,
                                                                height: 15,
                                                                borderRadius:'0px',
                                                                lineHeight:'20px',
                                                                padding: '5px',
                                                            },
                                                        },
                                                    },
                                                    style: {
                                                        padding:'0px',
                                                        height: '30px',
                                                        borderRadius:0,
                                                        lineHeight:'20px'
                                                    },
                                                    className: "hide-adornment ",
                                                    onClick: () => setIsEDOpen(true)
                                                },
                                                
                                            }}
                                        />
                                    </ClickAwayListener>

                                }
                            </div>
                        </div>

                    </div>
                    </div>
                    <div className="trendminicomponent">
                    <div className="trendminiheader">
                        <Checkbox color="secondary" sx={{ color: '#009FE3', p: 0 }} name='ladSearch' checked={searchData.ladSearch} onChange={handleCheckBoxChange} /> List Alteration Date
                        <div className="trendminicontent">
                            <div className="surveylineselectcontainer">
                                <div className="advancedscheduletext">Alt Date = </div>
                                <select id="dropdown" className='advancedscheduleselect' name='ladLogic' disabled={!searchData.ladSearch} value={searchData.ladLogic} onChange={handleChange}>
                                    {Object.values(DateSearchLogic).filter(key => isNaN(Number(DateSearchLogic[key]))).map((value) => (
                                        <option key={value} value={value}>
                                            {DateSearchLogic[value]}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>
                        <div className="surveylinevalues">
                            <div className="adv2datepickers">

                                {searchData.ladLogic == 4 &&
                                    <>
                                        <DesktopDateRangePicker
                                            disabled={!searchData.ladSearch}
                                            value={ladDateRange}
                                            onChange={(value) => handleDateRangeChange(value, 'lad')}
                                            calendars={1}

                                            slotProps={{
                                                field: { dateSeparator: 'and',sx:{fontSize:'12px'} },
                                                textField: {
                                                    label:false,
                                                    sx: {
                                                        '& fieldset': {
                                                            padding: 0,
                                                            height: 25,
                                                            borderColor:'#009FE3',

                                                        },
                                                        '& .MuiInputBase-root': {
                                                            padding: 0, 
                                                            borderColor:'#009FE3',
                                                            borderRadius:'0px',
                                                            '& .MuiInputBase-input': {
                                                                width: 300,
                                                                fontSize: 12,
                                                                height: 15,
                                                                borderRadius:'0px',
                                                                lineHeight:'20px',
                                                                padding: '5px',
                                                            },
                                                        },
                                                    },
                                                    style: {
                                                        padding:'0px',
                                                        height: '30px',
                                                        borderRadius:0,
                                                        lineHeight:'20px'
                                                    },
                                                    className: "hide-adornment ",
                                                },

                                            }}
                                        />
                                    </>
                                }
                                {searchData.ladLogic != 4 &&
                                    <ClickAwayListener onClickAway={() => setIsLADOpen(false)}>
                                        <DesktopDatePicker
                                            value={ladDateRange[0]}
                                            onChange={(value) => handleDateChange(value, 'lad')}
                                            open={isLADOpen}
                                            disabled={!searchData.ladSearch}
                                            key='lad'

                                            slotProps={{
                                                textField: {
                                                    sx: {
                                                        '& fieldset': {
                                                            padding: 0,
                                                            height: 25,
                                                            borderColor:'#009FE3',

                                                        },
                                                        '& .MuiInputBase-root': {
                                                            padding: 0, 
                                                            borderColor:'#009FE3',
                                                            borderRadius:'0px',
                                                            '& .MuiInputBase-input': {
                                                                width: 300,
                                                                fontSize: 12,
                                                                height: 15,
                                                                borderRadius:'0px',
                                                                lineHeight:'20px',
                                                                padding: '5px',
                                                            },
                                                        },
                                                    },
                                                    style: {
                                                        padding:'0px',
                                                        height: '30px',
                                                        borderRadius:0,
                                                        lineHeight:'20px'
                                                    },
                                                    className: "hide-adornment ",
                                                    onClick: () => setIsLADOpen(true)
                                                },

                                            }}
                                        />
                                    </ClickAwayListener>

                                }
                            </div>
                        </div>

                    </div>
                    </div>
                    <div className="trendminiheader">
                        <div className="partdomesticsection">
                            <div className="partdomesticlabel">
                                Part Domestic
                            </div>

                            <Checkbox color="secondary" sx={{ color: '#009FE3', p: 0 }} name='partDomesticLogic' checked={searchData.partDomesticLogic} onChange={handleCheckBoxChange} />  Active
                            <div className="radiogroup">

                                <FormControlLabel value="best" control={
                                    <Radio
                                        checked={searchData.partDomestic === false}
                                        onChange={handleRadioChange}
                                        value={false}
                                        name="partDomestic"
                                        size="small"
                                        disabled={!searchData.partDomesticLogic}
                                        inputProps={{ 'aria-label': 'No' }}
                                    />}
                                    label="No" />

                                <FormControlLabel value="best" control={
                                    <Radio
                                        checked={searchData.partDomestic === true}
                                        onChange={handleRadioChange}
                                        value={true}
                                        name="partDomestic"
                                        size="small"
                                        disabled={!searchData.partDomesticLogic}
                                        inputProps={{ 'aria-label': 'Yes' }}
                                    />}
                                    label="Yes" />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}