import { Password } from "@mui/icons-material";
import { authenticated, login, loginBypass } from "../Redux/AuthorisationSlicer";
import store from "../Redux/ConfigureStore";
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import * as ApiHelper from '../API/APIHelper';
import { Button, Checkbox } from "@mui/material";


export default function ForgotPasswordComponent() {
    const [username, setUsername] = useState('');
    const [invalidUsername, setInvalidUsername] = useState(false);
    const isAuthenticated = useSelector(authenticated);
    const [requestSent,setRequestSent] = useState(false);
    const [sending,setSending] = useState(false);
    const navigate = useNavigate();
    
    const handleReset = async () => {
        var response;
        setSending(true);
        if(username !=''){
            try {
                response = await ApiHelper.InitPasswordReset(username)
            }
            catch (e) {
                
            }
            finally{
                setRequestSent(true);
            }
        }
        else{
            if(username ==''){
                setInvalidUsername(true)
            }
        }
    }

    useEffect(()=>{
        setInvalidUsername(false)
    },[username])

    useEffect(()=>{

        if (isAuthenticated) {
            return navigate("/") ;
        }
    },[isAuthenticated])

    return (
        <>
            <div className='logincomponent'>
                <div className="analyselogo">
                </div>
                <div className="logininputdiv layoutcolumn">
                    <label htmlFor="username" className="loginlabel"> Email</label>
                    <input type='text' name="username" id="username" className="loginfield" value={username} onChange={(e) => setUsername(e.target.value)} placeholder="Email Address" />
                    {invalidUsername&&<div className="loginerrormessage">Please enter an Email Address.</div>}
                </div>
                <Button variant="contained" disabled={sending} sx={{ marginTop:'40px', bgcolor: 'lightblue.main',fontSize:'18px'}} className="loginbutton" onClick={() => handleReset()}>Request Reset</Button>
                {requestSent&&<div className="emailsentmessage">Email sent, Check your inbox for reset email</div>}
            </div>
        </>
    )
}