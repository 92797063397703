import { ContactPageSharp } from '@mui/icons-material';
import ExcelJS from 'exceljs';
import store from '../Redux/ConfigureStore';
import { setLoading } from '../Redux/SearchSlicer';
export async function dataToFile(data, columnGroupingModel, columns) {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet1');

  worksheet.columns = columns.map(col => ({ header: col.header, key: col.key, width: col.width ,style: col.style? col.style:null }));

  const greyBorderStyle = {
    top: { style: 'thin' as ExcelJS.BorderStyle, color: { argb: 'FFD5D5D5' } },
    left: { style: 'thin' as ExcelJS.BorderStyle, color: { argb: 'FFD5D5D5' } },
    bottom: { style: 'thin' as ExcelJS.BorderStyle, color: { argb: 'FFD5D5D5' } },
    right: { style: 'thin' as ExcelJS.BorderStyle, color: { argb: 'FFD5D5D5' } },
  };

  let startCol = 1;
  columnGroupingModel.forEach(group => {
    const groupSize = group.children.length;
    worksheet.mergeCells(1, startCol, 1, startCol + groupSize - 1);

    const cell = worksheet.getCell(1, startCol);
    cell.value = group.groupId;
    cell.font = { size: 9, color: { argb: 'FF0173C7' } };
    cell.alignment = { horizontal: 'center',vertical:'top',wrapText: true };
    cell.border = greyBorderStyle;

    for (let i = startCol; i < startCol + groupSize; i++) {
      worksheet.getCell(1, i).border = greyBorderStyle;
    }

    startCol += groupSize;
  });

  const secondHeaderRow = worksheet.addRow(columns.flatMap(group => group.header));
  secondHeaderRow.eachCell(cell => {
    cell.font = { size: 9,color: { argb: 'FF0173C7' } };
    cell.alignment = { horizontal: 'center', vertical: 'top',wrapText: true };
    cell.border = greyBorderStyle;
  });
  const maxColumns = columnGroupingModel.reduce((sum, group) => sum + group.children.length, 0);

  data.forEach((item, index) => {
    const rowData = columns.map(col => item[col.key]);
    const dataRow = worksheet.addRow(rowData);

    
    for (let i = 1; i <= maxColumns; i++) {
      const cell = dataRow.getCell(i);
      if (cell.value == '<E>') {
        cell.value = null; 
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'f949494' }, 
        };
      } else {
        
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: index % 2 === 0 ? 'FFFFFFFF' : 'FFEEEEEE' },
        };
      }
      cell.alignment = { vertical: 'top', horizontal: 'left', wrapText: true };
      cell.border = greyBorderStyle;
      cell.font = {size: 9}
    };
  });
  worksheet.eachRow((row, rowNumber) => {
    row.height = 36; 
  });
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'dataExport.xlsx';
  link.click();
  
  store.dispatch(setLoading(false))
};
