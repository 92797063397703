import React, { useState, useEffect, useRef } from 'react';
import { DataGridPro, GridRowsProp, GridColDef, useGridApiRef, GridRowSelectionModel, GridColumnGroup } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import { cleanup } from '@testing-library/react';
import SummaryValuationResults from '../../Models/SummaryValuationResults';
import SummaryValuationSearch, { validateSummaryValuationSearch } from '../../Models/SearchApiModels/SummaryValuationSearch';
import { formatCurrency, formatDateToString, FormatNumberWithCommas, stringifyList } from '../../Services/FormatService';
import store from '../../Redux/ConfigureStore';
import BasicSearchApiModel from '../../Models/SearchApiModels/BasicSearchApiModel';
import SurveyLineSearchApiModel from '../../Models/SearchApiModels/SurveyLineSearchApiModel';
import { SurveyAdvancedApiModel } from '../../Models/SearchApiModels/SurveyAdvancedApiModel';
import { useSelector } from 'react-redux';
import { addUARNs, selectAddSelection, setDispatchAddSelection } from '../../Redux/ListBuilderSlicer';
import TableRightClickMenu from './TableContextMenus/TableRightClickMenu';
import { selectTriggerShowScheme, selectListYear, selectSVIsLive, selectSelectedUARN, selectTriggerGOVUK, selectTriggerGetTotal, selectTriggerGetCount, selectTriggerGoogleMaps, selectTriggerPrint, setHeaderBarContext, setSelectedUARN, setTriggerGOVUK, setTotal, setTriggerGetTotal, setCount, setTriggerGetCount, setTriggerGoogleMaps, setTriggerPrint, setTriggerShowScheme, setLoading, setDisplayDetails, selectTriggerExportExcel, setTriggerExportExcel } from '../../Redux/SearchSlicer';
import BasicSearch from '../../Models/BasicSearch';
import { VTSReportModel } from '../../Models/VTSReportModel';
import { ReportModel } from '../../Models/ReportModel';
import { Button, ClickAwayListener } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GetScheme } from '../../API/AnalyseAPIHelper';
import ReportWindow from '../SubComponents/ReportWindow';
import { GridInitialStatePro } from '@mui/x-data-grid-pro/models/gridStatePro';
import HomeScreenComponent from '../SubComponents/HomeScreenComponent';
import { dataToFile } from '../../Services/ExcelExportService';





export default function SummaryValuationTable(props) {
  const [summaryValuationData, setSummaryValuationData] = useState<Array<SummaryValuationResults>>([])
  const [warning, setWarning] = useState(false);
  const apiRef = useGridApiRef();
  var pageNo = useRef(0);
  const [contextMenuPosition, setContextMenuPosition] = useState<{ mouseX: number | null; mouseY: number | null }>({ mouseX: null, mouseY: null });
  const [selectedRow, setSelectedRow] = useState<SummaryValuationResults | undefined>();
  const { searchCount, handleUARNChange } = props;
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [uarnSelectionModel, setUARNSelectionModel] = useState<string[]>([]);
  const triggerAddSelection = useSelector(selectAddSelection);
  const triggerGoogleMaps = useSelector(selectTriggerGoogleMaps);
  const triggerGOVUK = useSelector(selectTriggerGOVUK);
  const [storedQuery, setStoredQuery] = useState<SummaryValuationSearch>();
  const triggerSearchButton = useRef(false);
  const keepSelection = useRef(false);
  const selectedUARN = useSelector(selectSelectedUARN);
  const [selectedKeyField,setSelectedKeyField] = useState<number>();
  const triggerPrint = useSelector(selectTriggerPrint);
  const [report, setReport] = useState();
  const triggerGetTotal = useSelector(selectTriggerGetTotal);
  const triggerGetCount = useSelector(selectTriggerGetCount);
  const summaryListYear = useSelector(selectListYear);
  const isLive = useSelector(selectSVIsLive);
  const [showNoScheme, setShowNoScheme] = useState(false);
  const showScheme = useSelector(selectTriggerShowScheme);
  const [searchEmpty, setSearchEmpty] = useState(false);
  const [schemeData, setSchemeData] = useState<Array<SummaryValuationResults>>([]);
  const [showSchemeTable,setShowSchemeTable] = useState(false);
  const [savedState,setSavedState] = useState<GridInitialStatePro>();
  var pageNoStored = useRef(0);
  const triggerExport = useSelector(selectTriggerExportExcel);
  const canTableBeEmptyTable = useRef(false)
  const [columnWidths, setColumnWidths] = useState({});

    useEffect(() => {
    if(!showSchemeTable && summaryValuationData.length>0){
      restoreTableState();
      var selectedUarnExists=summaryValuationData.find((elem)=>elem.uarn = selectedUARN)
      if (selectedUarnExists && selectedRow){
        setRowSelectionModel([selectedRow.keY_FIELD!]);
      }
      if(!selectedUarnExists){
        setSelectedKeyField(summaryValuationData[0].keY_FIELD!)
        setRowSelectionModel([summaryValuationData[0].keY_FIELD!]);
        store.dispatch(setSelectedUARN([summaryValuationData[0].uarn!]));
      }
    }

  },[showSchemeTable])

    
  const handleColumnResize = (params) => {
    setColumnWidths((prevWidths) => ({
      ...prevWidths,
      [params.colDef.field]: params.width,
    }));
  };
  
  useEffect(() => {
    if (triggerExport > 0) {
      handleExportExcel(triggerExport)
      store.dispatch(setTriggerExportExcel(0))
    }
  }, [triggerExport])

  const handleExportExcel = async (options) => {
    var excelData
    store.dispatch(setLoading(true))
    try {
      switch (options) {
        case 1:
          //if(isLive){
            var query = new SummaryValuationSearch();
            var basic = new BasicSearch();
            query.BasicSearch = new BasicSearchApiModel(basic);
            query.BasicSearch.uarn = stringifyList(uarnSelectionModel);
            query.BasicSearch.region = undefined;
            query.report = true;
            query.Archive = isLive?false:true;
            query.ListYear = summaryListYear;
            excelData = await ApiHelper.GetSummaryValuationExcel(query);

          // }
          // else{

          // }
          break;
        case 2:
          var query = storedQuery!;
          query.report = true;
          excelData = await ApiHelper.GetSummaryValuationExcel(query);
          break;
      }
    }
    catch (err) {
      console.error(err)
    }
    finally {
      storedQuery!.report = false;
      if (excelData.length) {
        excelData.forEach(item => {
          item.froM_DATE = (item.froM_DATE)? formatDateToString(item.froM_DATE):null;
          item.tO_DATE =(item.tO_DATE)?  formatDateToString(item.tO_DATE):null;
          item.lisT_ALTERATION_DATE = (item.lisT_ALTERATION_DATE)? formatDateToString(item.lisT_ALTERATION_DATE):null;
          
        });
        dataToFile(excelData, ExcelGroupingModel, ExcelColumnModel)
      }
    }
  }

  const handleStoreTableState=()=>{
    if(apiRef.current && apiRef){
      setSavedState(apiRef.current.exportState());
      apiRef?.current.setSortModel([]);
    }
    pageNoStored.current = pageNo.current
    pageNo.current = 0
  }
  const restoreTableState=()=>{
    if(savedState){
      apiRef.current.restoreState(savedState);
      pageNo.current = pageNoStored.current
    }
  }
  const getSchemeData=async()=>{
    try{
      if (apiRef.current.state) {
        var sortcolumn;
        var sortorder;
        const { sortModel } = apiRef.current.state.sorting;
        if (sortModel.length != 0) {
          sortcolumn = sortModel[0]['field'];
          sortorder = sortModel[0]['sort'] ? sortModel[0]['sort'] : 'asc';
        }
      }
      store.dispatch(setLoading(true))
      var schemeData = await ApiHelper.GetSummaryValuationByScheme(selectedRow?.schemE_REFERENCE,summaryListYear,pageNo.current,sortcolumn,sortorder)
      var schemeDataArray = schemeData ? schemeData : [];
      if (schemeDataArray.length>0){
        if(pageNo.current===0){
        setSchemeData(schemeDataArray);
        store.dispatch(setSelectedUARN(schemeDataArray[0].uarn))
        setRowSelectionModel([schemeDataArray[0].keY_FIELD! ])
        setShowSchemeTable(true);
        if(searchCount==1){
          store.dispatch(setDisplayDetails(true))
        }
      }
        else {
          setSchemeData(prevData => [...prevData, ...schemeDataArray]);
          
        }
      }else{
      if (pageNo.current == 0) {
      if(canTableBeEmptyTable.current){
        store.dispatch(setSelectedUARN(undefined))
        setRowSelectionModel([])
        setSchemeData(schemeDataArray);
      }
      }}
    }
    catch(err){
      console.error(err)
    }
    finally{
      store.dispatch(setLoading(false))
    }
  }

  useEffect(() => {
    pageNo.current = 0;
    var query = GetSummarySearch();
    fetchData(query);
    canTableBeEmptyTable.current =false
    triggerSearchButton.current = true;
    store.dispatch(setSelectedUARN(''))
    setSelectedRow(undefined)
    setShowSchemeTable(false);
  }, [searchCount]);

  // useEffect(() => {
  //   if (summaryValuationData.length > 0 && searchCount > 0) {
  //     if (summaryValuationData[0].uarn && summaryValuationData[0].keY_FIELD) {

  //     }
  //   }
  // }, [summaryValuationData]);
  const onClipboardCopy = (data, event, { api }) => {
    const visibleColumns = apiRef.current.getVisibleColumns();
    
    const columnsAsString = visibleColumns.filter(col => col.field !== '__check__').map(col => col.headerName).join('\t');
    navigator.clipboard.writeText(columnsAsString + '\n' + data);
  }

  useEffect(() => {
    if (triggerGetCount == true) {
      handleGetCount();
    }
  }, [triggerGetCount]);

  useEffect(() => {
    if (triggerGetTotal == true) {
      handleGetTotal();
    }
  }, [triggerGetTotal]);

  const handleGetTotal = async () => {
    if (storedQuery) {
      store.dispatch(setLoading(true))
      try {
        var totalQuery = storedQuery;
        totalQuery.totals = true;
        var data = await ApiHelper.GetSummaryValuationSum(totalQuery);

        totalQuery.totals = false;
        if (data) {
          store.dispatch(setTotal(data[0].rateablE_VALUE))

        }
        // if (store.getState().search.sqlDump) {
        //   var totalQuery = storedQuery
        //   storedQuery.sqldump = true;
        //   totalQuery.totals = true;
        //   var dump = await ApiHelper.GetSummaryValuationSum(totalQuery);
        //   storedQuery.totals = false;
        //   storedQuery.sqldump = false;
        //   console.log(dump)
        // }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        store.dispatch(setLoading(false))
      }
    }
  }

  const handleGetCount = async () => {
    if (storedQuery) {
      store.dispatch(setLoading(true))
      try {
        var totalQuery = storedQuery;
        totalQuery.totals = true;
        var data = await ApiHelper.GetSummaryValuationCount(totalQuery);

        totalQuery.totals = false;
        if (data) {
          store.dispatch(setCount(data[0].keY_FIELD))

        }
        // if (store.getState().search.sqlDump) {
        //   var totalQuery = storedQuery
        //   storedQuery.sqldump = true;
        //   totalQuery.totals = true;
        //   var dump = await ApiHelper.GetSummaryValuationCount(totalQuery);
        //   storedQuery.totals = false;
        //   storedQuery.sqldump = false;
        //   console.log(dump)
        // }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        store.dispatch(setLoading(false))
      }
    }
  }


  useEffect(() => {
    var query = storedQuery;
    canTableBeEmptyTable.current = true;
    if(!showSchemeTable){
      if (query && query.BasicSearch) {
        keepSelection.current=false;
        query.ListYear = summaryListYear;
        fetchData(query);
      }
    }
    else{
      pageNo.current=0;
      getSchemeData();
    }
  }, [summaryListYear])
  useEffect(() => {
    var query = storedQuery;
    canTableBeEmptyTable.current = true;
    setShowSchemeTable(false);
    if (query && query.BasicSearch) {
      keepSelection.current=false;
      query.Archive = !isLive;
      fetchData(query);
    }
  }, [isLive])
  useEffect(() => {
    if (triggerPrint[0]) {
      handlePrint(triggerPrint)

      store.dispatch(setTriggerPrint([0, triggerPrint[1]]))
    }
  }, [triggerPrint]);

  const handlePrint = async (props) => {
    store.dispatch(setLoading(true))
    var newreport
    try {
      switch (props[0]) {
        case 1:
          var query1 = new ReportModel({ list: summaryListYear, uarn: stringifyList(uarnSelectionModel) });
          newreport = await ApiHelper.GetSMVReport(query1);
          break;
        case 3:
          var query2 = new SummaryValuationSearch();
          var basic = new BasicSearch();
          query2.BasicSearch = new BasicSearchApiModel(basic);
          query2.BasicSearch.uarn = stringifyList(uarnSelectionModel);
          query2.ListYear = summaryListYear;
          query2.BasicSearch.region = undefined;
          newreport = await ApiHelper.GetValuationListReportSelected(query2);
          break;
        case 4:
          newreport = await ApiHelper.GetValuationListReport(storedQuery!);
          break
      }
    }
    catch (err) {
      console.error(err)
    }
    finally {
      store.dispatch(setLoading(false))
    }
    setReport(newreport)
  }

  useEffect(() => {
    const selectedUARNs = rowSelectionModel.map(id => {
      return summaryValuationData.find(row => row.keY_FIELD === id)?.uarn;
    }).filter(Boolean) as string[];
    setUARNSelectionModel(selectedUARNs);
  }, [rowSelectionModel]);

  useEffect(() => {
    if (triggerAddSelection == true) {
      handleAddToListBuilder();
      store.dispatch(setDispatchAddSelection(false));
    }
  }, [triggerAddSelection]);

  const handleAddToListBuilder = () => {    
    try{
      ApiHelper.postLogThis("Adding to List: "+ stringifyList(uarnSelectionModel))
    }catch(error){
      console.error(error)
    }
    finally{
      store.dispatch(addUARNs(uarnSelectionModel));
    }
  }

  useEffect(() => {
    var key ;
    if(isLive == true){
      key=null
    }
    else{
      key = selectedKeyField

    }

    handleUARNChange(selectedUARN,key)
  }, [selectedUARN])

  const handleContextMenu = (event, row) => {
    event.preventDefault();

    if (event.shiftKey) {
      console.log('Shift key is held down.');
    }
    else {
      if (event.ctrlKey) {
        setRowSelectionModel([...rowSelectionModel,row.keY_FIELD!]);
      }
      else {
        if (rowSelectionModel.findIndex(elem => row.keY_FIELD == elem) > -1) {
        }
        else {
          
          setRowSelectionModel([row.keY_FIELD]);
        }

      }
      if (row.uarn !== selectedUARN) {
        store.dispatch(setSelectedUARN(row.uarn));
        setSelectedKeyField(row.keY_FIELD!)
      }
      if(!showSchemeTable){
        setSelectedRow(row)
      }
    }
      

    if (contextMenuPosition.mouseX !== null && contextMenuPosition.mouseY !== null) {
      handleCloseContextMenu(() => {
        setContextMenuPosition({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
      });
    } else {
      setContextMenuPosition({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
    }
  };
  const handleCloseContextMenu = (callback) => {
    setContextMenuPosition({ mouseX: null, mouseY: null });
    if (callback) {
      setTimeout(callback, 0);
    }
  };

  useEffect(() => {
    if (showScheme) {
      handleScheme()
    }
  }, [showScheme]);

  const handleMenuItemClick = async(item,param='') => {
    switch (item) {
      case "Add to 'ListBuilder'":
        handleAddToListBuilder();
        break;

      case "Show Scheme":
        handleScheme();
        break;
      case "Show Entry on GOV.UK":
        store.dispatch(setTriggerGOVUK(true));
        break;
      case "Print Quick Valuation Report":
        handlePrint([1, 0])
        break;
      case "Show All Valuations based upon this Scheme":
        await handleStoreTableState()
        getSchemeData();
        break;
      case "Return To Search Results":
        setShowSchemeTable(false);
        break;
      case "Show in Google Maps":
        handleGoogleMapSearch(param);
        break;
      case "Search in Google":
        handleGoogleSearch();
        break;
      default:
        break;
    }
    handleCloseContextMenu(null);
  };
  const handleScheme = async () => {
    store.dispatch(setLoading(true))
    try {
      var response = await GetScheme(summaryListYear, selectedUARN)
      if (response && response.assessmenT_REFERENCE && response.schemE_REFERENCE && selectedUARN) {
        openExternalLink(`https://www.tax.service.gov.uk/business-rates-find/valuations/${response.assessmenT_REFERENCE}/schemes/${response.schemE_REFERENCE}?uarn=${selectedUARN}`);
      }
      else {
        setShowNoScheme(true);
      }
    }
    catch {
      setShowNoScheme(true);
    } finally {
      store.dispatch(setTriggerShowScheme(false))

      store.dispatch(setLoading(false))
    }
  }
  const openExternalLink = (url) => {
    try{
      ApiHelper.postLogThis("sending user to url: "+ url)
    }catch(error){
      console.error(error)
    }
    finally{
      window.open(url, '_blank', 'noopener,noreferrer');

    }
  };
  useEffect(() => {
    if (triggerGoogleMaps == true && selectedUARN) {
      handleGoogleMapSearch()

    }
    store.dispatch(setTriggerGoogleMaps(false));
  }, [triggerGoogleMaps]);

  const handleGoogleSearch = ()=>{
    var row = summaryValuationData.find(item =>
      item.uarn == selectedUARN
    )
    var mapquery = ''
    mapquery += row?.address ? row?.address + ',' : ''
    mapquery += row?.postcode;
    openExternalLink('https://www.google.co.uk/search?q=' + mapquery);
  }

  const handleGoogleMapSearch=(param='')=>{
    var row  = summaryValuationData.find(item=>
      item.uarn == selectedUARN
    )
    var mapquery = ''
    switch(param){
      case"address":
        mapquery += row?.address;
        break;
      case"postcode":
        mapquery += row?.postcode;
        break;
      
      case"addressandpostcode":
        mapquery +=row?.address? row?.address+',':''
        mapquery += row?.postcode;
        break;
      
      default:
        mapquery += row?.postcode;
        break;
    }
    openExternalLink('https://www.google.co.uk/maps?q='+mapquery);
  
}

  useEffect(() => {
    if (triggerGOVUK == true && selectedUARN) {
      openExternalLink('https://www.tax.service.gov.uk/business-rates-find/valuations/' + selectedUARN)
    }
    store.dispatch(setTriggerGOVUK(false));
  }, [triggerGOVUK]);

  async function fetchData(query) {
    query.sortcolumn = "l.keY_FIELD";
    query.sortorder = 'asc';
    if (apiRef.current.state) {
      const { sortModel } = apiRef.current.state.sorting;
      if (sortModel.length != 0) {
        query.sortcolumn = sortModel[0]['field'];
        query.sortorder = sortModel[0]['sort'] ? sortModel[0]['sort'] : 'asc';
      }
    }
    query.pageno = pageNo.current
    var errors = validateSummaryValuationSearch(query);
    var data;

    if (errors.length == 0) {
      try {

        store.dispatch(setLoading(true))
        data = await ApiHelper.GetSummaryValuation(query);
        var dataarray = data ? data : [];

        if (dataarray.length > 0) {
          if (pageNo.current === 0) {
            setSummaryValuationData(dataarray);
            store.dispatch(setTriggerGetTotal(false))
            store.dispatch(setTriggerGetCount(false))
            setStoredQuery(query)
            if (triggerSearchButton.current) {
              store.dispatch(setHeaderBarContext(1))
            }
            if (!keepSelection.current) {
              store.dispatch(setSelectedUARN(dataarray[0].uarn))
              setRowSelectionModel([dataarray[0].keY_FIELD!])
              setSelectedRow(dataarray[0])
            }
          } else {
            setSummaryValuationData(prevData => [...prevData, ...dataarray]);
          }
        }
        else {
          if (query.pageno == 0) {
          if(canTableBeEmptyTable.current){
            store.dispatch(setSelectedUARN(undefined))
            setRowSelectionModel([])
            setSelectedRow(undefined)
            setSummaryValuationData(dataarray);
            setStoredQuery(query)
          }
          else{
            setSearchEmpty(true);
          }
          }
        }
        // if (store.getState().search.sqlDump) {
        //   query.sqldump = true;
        //   var dump = await ApiHelper.GetSummaryValuationDump(query);
        //   console.log(dump)
        // }
      }
      catch (ex) {
        console.log(ex)
        setWarning(true)

      }
      finally {

        store.dispatch(setLoading(false))
      }
    }
    triggerSearchButton.current = false;
    keepSelection.current = false;
  }
  const GetSummarySearch = () => {
    var storeQueryData = store.getState().search;
    var query = new SummaryValuationSearch();
    query.BasicSearch = new BasicSearchApiModel(storeQueryData.basicSearch);
    var basicCodes = storeQueryData.basicCodeSearch;
    if (basicCodes.PSD?.length != 0 || basicCodes.SCAT?.length != 0 || basicCodes.codeSearch != '') {
      if (basicCodes.codeSearch) {
        if (basicCodes.codeContext == 0) {
          query.BasicSearch.PSD = [basicCodes.codeSearch]
        }
        else {
          query.BasicSearch.SCAT = [basicCodes.codeSearch]

        }
      }
      else {
        if (basicCodes.PSD?.length == 0) {
          query.BasicSearch.SCAT = basicCodes.SCAT;
        }
        if (basicCodes.SCAT?.length == 0) {
          query.BasicSearch.PSD = basicCodes.PSD;
        }
        if (basicCodes.PSD?.length != 0 && basicCodes.SCAT?.length != 0) {
          if (basicCodes.codeContext == 1) {
            query.BasicSearch.SCAT = basicCodes.SCAT;
          } else {
            query.BasicSearch.PSD = basicCodes.PSD;
          }
        }
      }

    }
    query.Archive = !storeQueryData.svIsLive;
    query.ListYear = storeQueryData.summaryListYear;
    var surveylines = storeQueryData.surveyLinesSurveySearch;
    var surveyAdvanced = storeQueryData.advancedSurveySearch;
    var areaSearch = storeQueryData.areaSearch;
    if (areaSearch.BACODE != '' && areaSearch.BACODE) {
      query.BasicSearch.BACODE = areaSearch.BACODE
    }
    if (areaSearch.TOFFICE != '' && areaSearch.TOFFICE) {
      query.BasicSearch.TOFFICE = areaSearch.TOFFICE
    }
    if (areaSearch.VOANNDRRVU != '' && areaSearch.VOANNDRRVU) {
      query.BasicSearch.VOANNDRRVU = areaSearch.VOANNDRRVU
    }
    if (areaSearch.VOOFFICELOCAL != '' && areaSearch.VOOFFICELOCAL) {
      query.BasicSearch.VOOFFICELOCAL = areaSearch.VOOFFICELOCAL
    }
    query.BasicSearch.region = Number(areaSearch.region)
    if (surveylines.floorSearch || surveylines.surveyLineSearch || surveylines.adjustmentsSearch || surveylines.additionalItemSearch || Number(surveylines.measurementBasis)!=0) {
      query.SurveyLinesSurvey = new SurveyLineSearchApiModel(surveylines);

    }
    if (surveyAdvanced.hasCarpark || surveyAdvanced.hasPlantMachinery || surveyAdvanced.totalAreaSearch || surveyAdvanced.ibrSearch
      || (surveyAdvanced.schemeReference != '' && surveyAdvanced.schemeReference) || surveyAdvanced.uarTrendSearch
      || (Number(surveyAdvanced.uarVsIBRSearch) != 0 || surveyAdvanced.uarVsIBRSearch == undefined) || surveyAdvanced.uarSearch || surveyAdvanced.rvSearch) {
      query.SurveyAdvanced = new SurveyAdvancedApiModel(surveyAdvanced);
    }
    return query;
  }
  const handleRowOrderChange = (model) => {
    pageNo.current = 0
    if(!showSchemeTable){
      fetchData(storedQuery);
    }
    else{
      getSchemeData()
    }
    apiRef.current.scroll({ top: 0 });
  }
  const handleRowsScrollEnd = () => {
    keepSelection.current = true;
    if(!showSchemeTable){
      if(Math.floor(summaryValuationData.length/100) >pageNo.current){
        pageNo.current += 1;
        fetchData(storedQuery);
      }
    }
    else{
      if(Math.floor(schemeData.length%100) >pageNo.current){
        pageNo.current += 1;
        getSchemeData()
      }
    }
  }
  const handleRowClick = (e) => {
    const { row } = e;
    if (row.uarn !== selectedUARN) {
      setSelectedKeyField(row.keY_FIELD)
      store.dispatch(setSelectedUARN(row.uarn));

    }
  }

  const ExcelColumnModel = [
    { key: 'firm_name', header: 'FIRM NAME', width: 12 },
    { key: 'address', header: 'ADDRESS', width: 48 },
    { key: 'postcode', header: 'POSTCODE', width: 12 },
    { key: 'schemE_REFERENCE', header: 'SCHEME REFERENCE', width: 12 },
    { key: 'bA_REFERENCE_NUMBER', header: 'BA REFERENCE NUMBER', width: 12 },
    { key: 'description', header: 'DESCRIPTION', width: 20 },
    { key: 'psD_CODE', header: 'PSD CODE', width: 8 },
    { key: 'scat', header: 'SCAT', width: 8 },
    { key: 'measuremenT_BASIS', header: 'MEASUREMENT BASIS', width: 12 },
    { key: 'totaL_AREA', header: 'TOTAL AREA', width: 12 },
    { key: 'suB_TOTAL', header: 'SUB TOTAL', width: 12, style: { numFmt: '#,##0' } },
    { key: 'totaL_VALUE', header: 'TOTAL VALUE', width: 12, style: { numFmt: '#,##0' } },
    { key: 'rateablE_VALUE', header: 'RATEABLE VALUE', width: 12, style: { numFmt: '#,##0' } },
    { key: 'froM_DATE', header: 'EFFECTIVE DATE', width: 12},
    { key: 'tO_DATE', header: 'EFFECTIVE TO', width: 12},
    { key: 'lisT_ALTERATION_DATE', header: 'LIST ALTERATION DATE', width: 18 },
    { key: 'compileD_RATEABLE_VALUE', header: 'COMPILED RATEABLE VALUE', width: 18, style: { numFmt: '#,##0' } },
    { key: 'rV_PERCENTAGE', header: 'RV PERCENTAGE', width: 12 },
    { key: 'uaR_A', header: 'UAR Current', width: 12 },
    { key: 'uaR_B', header: 'UAR Historic', width: 12 },
    { key: 'uaR_PERCENTAGE', header: 'UAR PERCENTAGE', width: 12 },
    { key: 'uaR_IBR_PERCENTAGE', header: 'UAR IBR PERCENTAGE', width: 12 },
    { key: 'ibR_A', header: 'IBR Current', width: 12 },
    { key: 'ibR_B', header: 'IBR Historic', width: 12 },
    { key: 'ibR_DESCRIPTION', header: 'IBR DESCRIPTION', width: 12 },
    { key: 'ibR_PERCENTAGE', header: 'IBR PERCENTAGE', width: 12 }
  ];
  
  const ExcelGroupingModel = [
    {
      groupId: 'ADDRESS',
      children: [{ field: 'firm_name' }, { field: 'address' }, { field: 'postcode' }]
    },
    {
      groupId: 'HEREDITAMENT INFORMATION',
      children: [{ field: 'schemE_REFERENCE' }, { field: 'description' }, { field: 'psD_CODE' }, { field: 'scat' }, { field: 'bA_REFERENCE_NUMBER' }, { field: 'measuremenT_BASIS' }]
    },
    {
      groupId: 'SUMMARY VALUATION',
      children: [{ field: 'totaL_AREA' }, { field: 'suB_TOTAL' }, { field: 'totaL_VALUE' }, { field: 'rateablE_VALUE' }, { field: 'froM_DATE' }, { field: 'tO_DATE' }, { field: 'lisT_ALTERATION_DATE' }, { field: 'compileD_RATEABLE_VALUE' }, { field: 'rV_PERCENTAGE' }, { field: 'uaR_A' }, { field: 'uaR_B' }, { field: 'ibR_A' }, { field: 'ibR_B' }, { field: 'uaR_PERCENTAGE' }, { field: 'uaR_IBR_PERCENTAGE' }, { field: 'ibR_DESCRIPTION' }, { field: 'ibR_PERCENTAGE' }]
    }
  ];


  const columns: GridColDef[] = [

    {
      field: 'firm_name', headerName: 'FIRM NAME', width: columnWidths['firm_name'] || 78, minWidth: 78, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>

          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row) =>{
        return value? value:'\u200B';
      }
    },
    {
      field: 'address', headerName: 'ADDRESS', width: columnWidths['address'] || 458, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>

          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'postcode', headerName: 'POSTCODE', width: columnWidths['postcode'] || 78, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>

          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'schemE_REFERENCE', headerName: 'SCHEME REFERENCE', type: 'number', width: columnWidths['schemE_REFERENCE'] || 80, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>

          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'bA_REFERENCE_NUMBER', headerName: 'BA REFERENCE NUMBER', width: columnWidths['bA_REFERENCE_NUMBER'] || 98, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>

          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    // { field: 'assessmenT_REFERENCE', headerName: 'Assessment Reference', width: 88, headerAlign:  'left',align:'center',
    //   renderCell: (params: any) => (
    //     <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
    //       
    //      {params.value!=null ||params.value !=undefined?params.value : <span>&nbsp;</span>}
    //     </div>
    //   ), },
    {
      field: 'description', headerName: 'DESCRIPTION', width: columnWidths['description'] || 175, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'psD_CODE', headerName: 'PSD CODE', width: columnWidths['psD_CODE'] || 40, minWidth: 40, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'scat', headerName: 'SCAT', width: columnWidths['scat'] || 40, minWidth: 40, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'measuremenT_BASIS', headerName: 'MEASUREMENT BASIS', width: columnWidths['measuremenT_BASIS'] || 40, minWidth: 40, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'totaL_AREA',
      headerName: 'TOTAL AREA',
      type: 'number', width: columnWidths['totaL_AREA'] || 100, align: 'right', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? `${params.value} m²` : <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row) => {
        return value ? `${value} m²` : '';
      },
    },
    {
      field: 'suB_TOTAL', headerName: 'SUB TOTAL', type: 'number', width: columnWidths['suB_TOTAL'] || 78, align: 'right', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row) => {
        return FormatNumberWithCommas(value)
      },
    },
    {
      field: 'totaL_VALUE', headerName: 'TOTAL VALUE', type: 'number', width: columnWidths['totaL_VALUE'] || 78, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row) => {
        return FormatNumberWithCommas(value)
      },
    },
    {
      field: 'rateablE_VALUE', headerName: 'RATEABLE VALUE', type: 'number', width: columnWidths['rateablE_VALUE'] || 78, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row) => {
        return FormatNumberWithCommas(value)
      },
    },
    {
      field: 'froM_DATE',
      headerName: 'EFFECTIVE DATE',
      width: columnWidths['froM_DATE'] || 78, align: 'center', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row) => {
        return formatDateToString(value)
      },
    },
    {
      field: 'tO_DATE',
      headerName: 'EFFECTIVE TO',
      width: columnWidths['tO_DATE'] || 100, align: 'center', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row) => {
        return formatDateToString(value)
      },
    },
    {
      field: 'lisT_ALTERATION_DATE',
      headerName: 'LIST ALTERATION DATE',
      width: columnWidths['lisT_ALTERATION_DATE'] || 100, align: 'center', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row) => {
        return formatDateToString(value)
      },
    },
    {
      field: 'compileD_RATEABLE_VALUE', headerName: 'COMPILED RATEABLE VALUE', type: 'number', width: columnWidths['compileD_RATEABLE_VALUE'] || 80, align: 'right', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row) => {
        return FormatNumberWithCommas(value)
      },
    },
    {
      field: 'rV_PERCENTAGE', headerName: 'RV PERCENTAGE', type: 'number', width: columnWidths['rV_PERCENTAGE'] || 80, align: 'right', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'uaR_A', headerName: 'UAR Current', width: columnWidths['uaR_A'] || 80, align: 'right', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatCurrency(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row) => {
        return formatCurrency(value)
      },
    },
    {
      field: 'uaR_B', headerName: 'UAR Historic', width: columnWidths['uaR_B'] || 80, align: 'right', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatCurrency(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row) => {
        return formatCurrency(value)
      },
    },
    {
      field: 'uaR_PERCENTAGE', headerName: 'UAR PERCENTAGE', type: 'number', width: columnWidths['uaR_PERCENTAGE'] || 80, align: 'right', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'uaR_IBR_PERCENTAGE', headerName: 'UAR IBR PERCENTAGE', type: 'number', width: columnWidths['uaR_IBR_PERCENTAGE'] || 80, align: 'right', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'ibR_A', headerName: 'IBR Current', width: columnWidths['ibR_A'] || 80, align: 'right', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatCurrency(params.value) || <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'ibR_B', headerName: 'IBR Historic', width: columnWidths['ibR_B'] || 80, align: 'right', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatCurrency(params.value) || <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'ibR_DESCRIPTION', headerName: 'IBR DESCRIPTION', width: columnWidths['ibR_DESCRIPTION'] || 80, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'ibR_PERCENTAGE', headerName: 'IBR PERCENTAGE', type: 'number', width: columnWidths['ibR_PERCENTAGE'] || 80, align: 'right', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
  ];
  const columnGroupingModel: GridColumnGroup[] = [
    {
      groupId: 'ADDRESS',
      children: [{ field: 'firm_name' }, { field: 'address' }, { field: 'postcode' }],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: 'HEREDITAMENT INFORMATION',
      children: [{ field: 'schemE_REFERENCE' }, { field: 'description' }, { field: 'psD_CODE' }, { field: 'scat' }, { field: 'bA_REFERENCE_NUMBER' }, { field: 'measuremenT_BASIS' }]
      , headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: 'SUMMARY VALUATION',
      children: [{ field: "totaL_AREA" }, { field: "suB_TOTAL" }, { field: "totaL_VALUE" }, { field: "rateablE_VALUE" }, { field: "froM_DATE" }, { field: "tO_DATE" }, { field: "lisT_ALTERATION_DATE" }, { field: "compileD_RATEABLE_VALUE" }, { field: "rV_PERCENTAGE" }, { field: "uaR_A" }, { field: "uaR_B" }, { field: "ibR_A" }, { field: "ibR_B" }, { field: "uaR_PERCENTAGE" }, { field: "uaR_IBR_PERCENTAGE" }, { field: "ibR_DESCRIPTION" }, { field: "ibR_PERCENTAGE" },]
      , headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    }
  ]

  const rows = showSchemeTable? schemeData :summaryValuationData;
  return (

    <div style={{ height: '100%', width: '100%' }}>
      {/* {warning &&
        <div onClick={() => setWarning(false)}> refine search</div>
      } */}
      {(summaryValuationData.length > 0 || canTableBeEmptyTable.current) &&
        <>
          <DataGridPro
            rows={rows}
            columns={columns}
            rowHeight={22}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            getRowId={(row: SummaryValuationResults) => row.keY_FIELD!}
            sortingMode="server"
            apiRef={apiRef}
            onRowsScrollEnd={handleRowsScrollEnd}
            onSortModelChange={(model) => handleRowOrderChange(model)}
            columnHeaderHeight={22}
            onClipboardCopy={onClipboardCopy}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            columnGroupingModel={columnGroupingModel}
            rowSelectionModel={rowSelectionModel}
            autosizeOptions={{
              includeOutliers: false,
              includeHeaders: false,
            }}
            onColumnWidthChange={handleColumnResize}
            onRowClick={handleRowClick}
            hideFooter
          />
          <TableRightClickMenu
            contextMenuPosition={contextMenuPosition}
            handleClose={handleCloseContextMenu}
            handleMenuItemClick={handleMenuItemClick}
            table={1}
            subtable={showSchemeTable}
            archive={isLive? false:true}
          />
        </>
      }
          {report && 
          <ReportWindow reportString = {report} onClose={()=>setReport(undefined)}></ReportWindow>
          }
      {showNoScheme &&
        <ClickAwayListener onClickAway={(event) => (console.log(event), setShowNoScheme(false))}>
          <div className='noschemepopup'>
            <div className='closeme' onClick={() => setShowNoScheme(false)}>
              <CloseIcon />
            </div>
            <div className='popupheader'>Analyse</div>
            <div className='popupbody'>Valuation Scheme Not Available</div>
            <Button variant='outlined' className='popupbutton' onClick={() => setShowNoScheme(false)}>OK</Button>
          </div>
        </ClickAwayListener>
      }
      {
        searchEmpty &&
        <ClickAwayListener onClickAway={(event) => (setSearchEmpty(false))}>
          <div className='searchemptypopup'>
            <div className='closeme' onClick={() => setSearchEmpty(false)}>
              <CloseIcon />
            </div>
            <div className='popupheader'>Analyse</div>
            <div className='popupbody'>No Assessments found - Please refine your search</div>
            <Button variant='outlined' className='popupbutton' onClick={() => setSearchEmpty(false)}>OK</Button>
          </div>
        </ClickAwayListener>
      }
      {rows.length ==0 &&
        <HomeScreenComponent/>        
      }
    </div>

  );
}