export default function HomeScreenComponent(){
    return(
        
    <div className="homescreen">
        <div className="homescreenbackground">

        </div>
    </div>
    
    )
}