import { Regions } from "./Enums/Regions";
import {StartsWithContains} from "./Enums/StartsWithContains"
import {StartsWithEquals} from "./Enums/StartsWithEquals"


export default class BasicSearch {
    LIST?: number = 2023;
    firmname?: string='';
    firmnamelogic?: StartsWithContains;
    buildingName?: string='';
    buildingNamelogic?: StartsWithContains;
    street?: string='';
    streetlogic?: StartsWithContains;
    postdistrict?: string='';
    postdistrictlogic?: StartsWithContains;
    town?: string='';
    townlogic?: StartsWithEquals;
    postcode?: string='';
    postcodelogic?: StartsWithEquals;
    description?: string='';
    descriptionlogic?: StartsWithContains;
    billingAuthorityReference?: string='';
    live2023AssessmentReference?: string='';
    BACODE?: string;
    VOANNDRRVU?: string;
    VOOFFICELOCAL?: string;
    TOFFICE?: string;
    uarn?: string;
    wherefrom?: string;
    region?:Regions;
    constructor(data?: Partial<BasicSearch>) {
        Object.assign(this, {
            LIST: 2023,
            firmname: '',
            firmnamelogic: 0,
            buildingName: '',
            buildingNamelogic: 0,
            street: '',
            streetlogic: 0,
            postdistrict: '',
            postdistrictlogic: 0,
            town: '',
            townlogic: 0,
            postcode: '',
            postcodelogic: 0,
            description: '',
            descriptionlogic: 0,
            billingAuthorityReference: '',
            live2023AssessmentReference: '',
            BACODE: '',
            VOANNDRRVU: '',
            VOOFFICELOCAL: '',
            TOFFICE: '',

            uarn: '',
            wherefrom: '',
            region:0,
            ...data,
        });
    }

    serialize(): Partial<BasicSearch> {
        return {
            LIST: this.LIST,
            firmname: this.firmname,
            firmnamelogic: this.firmnamelogic,
            buildingName: this.buildingName,
            buildingNamelogic: this.buildingNamelogic,
            street: this.street,
            streetlogic: this.streetlogic,
            postdistrict: this.postdistrict,
            postdistrictlogic: this.postdistrictlogic,
            town: this.town,
            townlogic: this.townlogic,
            postcode: this.postcode,
            postcodelogic: this.postcodelogic,
            description: this.description,
            descriptionlogic: this.descriptionlogic,
            billingAuthorityReference: this.billingAuthorityReference,
            live2023AssessmentReference: this.live2023AssessmentReference,
            BACODE: this.BACODE,
            VOANNDRRVU: this.VOANNDRRVU,
            VOOFFICELOCAL: this.VOOFFICELOCAL,
            TOFFICE: this.TOFFICE,
            uarn: this.uarn,
            wherefrom: this.wherefrom,
            region: this.region
        };
    }
}