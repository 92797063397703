import { DateSearchLogic } from "./Enums/DateSearchLogic";
import {SearchLogic} from "./Enums/SearchLogic"
export default class IntegratedListAdvancedSearch {
    rvSearch?: boolean;
    rvList?: number;
    rvLogic?: SearchLogic;
    rvValue?: number;
    rvValueLessThan?: number;

    edSearch?: boolean;
    edList?: number;
    edLogic?: DateSearchLogic;
    edValue?: Date;
    edValueLessThan?: Date;

    ladSearch?: boolean;
    ladList?: number;
    ladLogic?: DateSearchLogic;
    ladValue?: Date;
    ladValueLessThan?: Date;
    
    partDomestic?: boolean;
    partDomesticSearch?:boolean;
    partDomesticList?:string;
    listStatus?:string;
    listStatusSearch?:boolean;
    listStatusList?:string;


    
    constructor(data?: Partial<IntegratedListAdvancedSearch>) {
        Object.assign(this, {
            rvSearch: false,
            rvLogic: 0,
            rvValue: 0,
            rvValueLessThan: 0,
            rvList:2023,
            edSearch: false,
            edLogic: 0,
            edValue: undefined,
            edValueLessThan: undefined,
            edList:2023,
            ladSearch: false,
            ladLogic: 0,
            ladValue: undefined,
            ladValueLessThan: undefined,
            ladList:2023,
            partDomestic: true,
            partDomesticSearch:false,
            partDomesticList:'2023',
            listStatus:'true',
            listStatusSearch:false,
            listStatusList:'2023',
            ...data,
        });
    }

    serialize(): Partial<IntegratedListAdvancedSearch> {
        return {
            rvSearch: this.rvSearch,
            rvLogic: this.rvLogic,
            rvValue: this.rvValue,
            rvValueLessThan: this.rvValueLessThan,
            rvList:this.rvList,
            edSearch: this.edSearch,
            edLogic: this.edLogic,
            edList:this.edList,
            edValue: this.edValue,
            edValueLessThan: this.edValueLessThan,
            ladSearch: this.ladSearch,
            ladLogic: this.ladLogic,
            ladValue: this.ladValue,
            ladList:this.ladList,
            ladValueLessThan: this.ladValueLessThan,
            partDomestic: this.partDomestic,
            partDomesticSearch:this.partDomesticSearch,
            partDomesticList:this.partDomesticList,
            listStatus:this.listStatus,
            listStatusSearch:this.listStatusSearch,
            listStatusList:this.listStatusList,
        };
    }
}