import { UserTokens } from "../Models/UserTokens";

export function getCookie(name) {
    const nameEQ = `${name}=`;
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.indexOf(nameEQ) === 0) {
        return cookie.substring(nameEQ.length, cookie.length);
      }
    }
    return null;
  }
  
  export function getAuthenticationCookies(){
    var userTokens = new UserTokens();
    const token = getCookie('token');
    const refreshToken = getCookie('refreshToken');
    const expiration = getCookie('tokenExpires');

    if (token ==null || refreshToken==null || expiration==null) {
        throw new Error('kick');
    }

    userTokens.token = token;
    userTokens.refreshToken = refreshToken;
    userTokens.expiration = new Date(expiration).toISOString();

    return userTokens;
  }
  export function setLoginCookies(model: UserTokens){
    var token = model.token;
    var refreshToken = model.refreshToken;
    const customDate = new Date();
    customDate.setDate(customDate.getDate() + 7);
    const customDateUTC = customDate.toUTCString();
    document.cookie = `refreshToken=${refreshToken};expires=${customDateUTC};path=/;`;
    document.cookie = `token=${token};expires=${customDateUTC};path=/;`;
    document.cookie = `tokenExpires=${customDateUTC};expires=${customDateUTC};path=/;`;
  }
  export function setAuthCookies(model: UserTokens){
    var date = getCookie('tokenExpires');
    if (date) {
      document.cookie = `refreshToken=${model.refreshToken};expires=${date};path=/;SameSite=lax;`;
      document.cookie = `token=${model.token};expires=${date};path=/;SameSite=lax;`;
    }
    else{
      throw new Error('Required authentication cookies are missing.');

    }
  }