import React, { useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js/auto'
import { Chart, Line, Pie } from 'react-chartjs-2'

// import 'chart.js/auto';


// import { DataGridPro, GridRowsProp, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../API/AnalyseAPIHelper';
import RvHistory from '../Models/SearchApiModels/RvHistoryModel';
import RvHistoryGraph from '../Models/RvHistoryGraph';
import { Height } from '@mui/icons-material';
import store from '../Redux/ConfigureStore';
import { setLoading } from '../Redux/SearchSlicer';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)


function LineChart(props) { 
    const {uarn,dataChanged, Height, Width} = props
    const [rvHistoryData, setRVHistoryData] = useState<RvHistoryGraph>()


    useEffect(() => {
        fetchData();
      }, [dataChanged]);

      async function fetchData() {
        var data
         
        store.dispatch(setLoading(true))
            try{
              data = await ApiHelper.GetRvHistoryGraph(uarn);       
              setRVHistoryData(data);
              

            } 
            catch(err){
              console.log(err)
            }
            finally{
              store.dispatch(setLoading(false))
            }
     
      }    
  
    return( 
      <div style={{height: Height, width: Width}}>
        {rvHistoryData &&
       <Line style={{marginLeft: '100px'}}  data= {{labels:rvHistoryData.labels,
        datasets:[{
               label :'Effective Date - Rateable Value',
               data:rvHistoryData.data
             }]           
       }} />
       }
          
      </div>
    );
  };

  export default LineChart;