import React, { useEffect, useRef, useState } from 'react';
import { DataGridPro, GridRowsProp, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import { formatDateToString } from '../../Services/FormatService';
import DirectoryBA from '../../Models/SearchApiModels/DirectoryBAModel';
import DirectoryVoaUnits from '../../Models/SearchApiModels/DirectoryVOAUnitsModel';
import SummaryValuation from '../../Models/SearchApiModels/SummaryValuation';
import SurveySummary from '../../Models/SearchApiModels/SurveySummary';
import SurveyPlant from '../../Models/SearchApiModels/SurveyPlant';
import {selectListYear} from '../../Redux/SearchSlicer';
import { useSelector } from 'react-redux';




export default function SVSummaryTable(props) {
  const {uarn,data} = props

 
 
  const [selectedUARN,setSelectedUARN]=useState<string>()


  useEffect(()=>{
    setSelectedUARN(selectedUARN)
  },[selectedUARN])


 

  const rows = data;

  return (
     
    <>
    {rows.length !=0 ? (
   <div style={{ padding: '1px' }}>
<table className="bordered-table">
        <thead>
            <tr style={{color: '#0173C7'}}>
            <th >PLANT VALUE(£)</th>

            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{data?.plANT_VALUE}</td>
                
            </tr>          
        </tbody>
    </table>
 </div>
     ):
     (<>
      {<p style={{marginLeft: '30px', marginTop: '30px'}}>No data available</p>}
    </>)
    
    }
    </>
    
   );
}