import { Button } from '@mui/material';
import React, { useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { validatePasswords } from '../Services/ValidationService';
import * as ApiHelper from '../API/APIHelper';
import { ResetPasswordModel } from '../Models/ResetPasswordModel';

export default function ResetPasswordComponent(){
  const location = useLocation();
  const [password,setPassword] = useState('');
  const [repeatPassword,setRepeatPassword] = useState('');
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const email = queryParams.get('email');
  const [errorList,setErrorList] = useState<Array<string>>([]);
  const navigate = useNavigate();

  const handleReset = ()=>{
        const{isValid,errors} = validatePasswords(password,repeatPassword)
        if(isValid){
            setErrorList([])
            try{
                var request = new ResetPasswordModel();
                request.email = email!;
                request.newPassword = password;
                request.token = token!;
                ApiHelper.ResetPasswordWithToken(request)
            }
            catch(error){
                //hide errors
            }
            finally{
                navigate("/login");
            }
        }
        else{
            setErrorList(errors)
        
    
    }
    }
  if(token==''||token==null||token === undefined){
    return <Navigate to='/login'/>
  }
  if(email==''||null==email||undefined === email){
    return <Navigate to='/login'/>
  }
  return (
    <div className='logincomponent'>
    <div className="analyselogo">
    </div>
    <div className="logininputdiv layoutcolumn">
        <label htmlFor="passwword" className="loginlabel"> Password</label>
        <input type='password' name='password' id="password" className="loginfield" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
        {errorList.length>0 &&
            errorList.map((element) =>(
                    <div className="loginerrormessage">{element}</div>
            ))
        }
    </div>
    <div className="logininputdiv layoutcolumn">
        <label htmlFor="passwword" className="loginlabel">Repeat Password</label>
        <input type='password' name='password' id="password" className="loginfield" value={repeatPassword} onChange={(e) => setRepeatPassword(e.target.value)} placeholder="Repeat Password" />
    </div>
    <Button variant="contained" sx={{ marginTop:'40px', bgcolor: 'lightblue.main',fontSize:'18px'}} className="loginbutton" onClick={() => handleReset()}>Reset Password</Button>
</div>
  );
};

