import { useState } from "react"
import RVHistoryTable from "../RVHistoryTable"
import BAScheduleTable from "../BAScheduleTable"
import DirectoryBATable from "../DirectoryBATable"
import DirectoryVOAUnitsTable from "../DirectoryVoaUnitsTable"
import DirectoryVTSTable from "../DirectoryVTSTable"
// import DirectoryVTSTable from "../DirectoryVTSTable"
const tabs=[
    'Billing Authority','VOA Units', 'VTS'

]
export default function DirectoryPopup(props){
    const {uarn} = props
    const [tabContext,setTabContext]=useState(0)
    const handleTabClick=(index)=>{
        setTabContext(index)
    }
    return(
    <>
    <div className="popovertabcontainer">
        {tabs.map(( item,index)=>(<div key={index} className={index == tabContext?"popovertab selected": "popovertab"} onClick={()=>handleTabClick(index)}>{item}</div>))}
    </div>
    <div >
        {
            tabContext == 0&&
            <DirectoryBATable uarn={uarn} />
        }
                {
            tabContext == 1&&
            <DirectoryVOAUnitsTable uarn={uarn} />
        }
                {
            tabContext == 2&&
            <DirectoryVTSTable uarn={uarn} />
            
        }
                              
    </div>
    </>)
    
}