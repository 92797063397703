import { useEffect, useState } from "react";
import store from "../../Redux/ConfigureStore";
import { cleanup } from "@testing-library/react";
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import BasicCodeModel from "../../Models/BasicCodeModel";
import { useSelector } from "react-redux";
import { selectPSDDropDown, selectSCATDropDown } from "../../Redux/PreloadedSlicer";
import BasicCodeSearch from "../../Models/BasicCodeSearch";
import { setBasicCodeSearch } from "../../Redux/SearchSlicer";
import { Checkbox } from "@mui/material";
import Select from "react-select";
import BasicSearch from "../../Models/BasicSearch";

export default function BasicCodeComponent(props) {
  const [searchData, setSearchData] = useState<BasicCodeSearch | null>(null);
  const { searchCount } = props;
  const psdDropDown = useSelector(selectPSDDropDown) as Array<BasicCodeModel>;
  const scatDropDown = useSelector(selectSCATDropDown) as Array<BasicCodeModel>;
  const [currentDropdown, setCurrentDropdown] = useState<Array<BasicCodeModel>>([]);
  const [currentDropdownValue, setCurrentDropdownValue] = useState<BasicCodeModel|undefined>();
  const [scatMap, setSCATMap] = useState<Array<BasicCodeModel>>();
  const [psdMap, setPSDMap] = useState<Array<BasicCodeModel>>();
  useEffect(() => {
    var basic = new BasicCodeSearch(store.getState().search.basicCodeSearch)
    setSearchData(basic);
    if (basic.codeSearch != '') {
      var value
      if (basic.codeContext == 0 && psdDropDown) {
         value = psdDropDown.find((item) =>
          item.value == basic.codeSearch
        )
      }
      else {
        if(scatDropDown){
        value = scatDropDown.find((item) =>
          item.value == basic.codeSearch
        )}
      }
      if (value) {
        setCurrentDropdownValue({label:value.value,value:value.value})
      }
      else(
        
        setCurrentDropdownValue(undefined)
      )
    }
    return () => {
      cleanup();
    };
  }, []);

  useEffect(() => {
    if (searchCount < 1) {
      setSearchData(new BasicCodeSearch(store.getState().search.basicCodeSearch))

    }
    return () => {
      cleanup();
    };
  }, [searchCount]);
  useEffect(() => {
    if(searchData){
    sendSearchData();}
}, [searchData]);

  useEffect(() => {
    var psdmap:BasicCodeModel[] = psdDropDown.map((item) => ({label:item.value, value:item.value}))
    
    setPSDMap(psdmap)
  }, [psdDropDown]);

  useEffect(() => {
    var scatmap :BasicCodeModel[] = scatDropDown.map((item) => ({label:item.value, value:item.value}))
    setSCATMap(scatmap)
  }, [scatDropDown]);

  const handleCheckBoxChange = async (e) => {
    const { checked, value, name } = e.target;
    let updatedBasicCodeSearch;
    if (!searchData) return;
    if (checked) {
      if (name === 'psd') {
        updatedBasicCodeSearch = await new BasicCodeSearch({
          ...searchData,
          PSD: [...searchData.PSD, value]
        });
        await setSearchData(updatedBasicCodeSearch);

      } else {
        updatedBasicCodeSearch = await new BasicCodeSearch({
          ...searchData,
          SCAT: [...searchData.SCAT, value]
        });
        await setSearchData(updatedBasicCodeSearch);

      }
    } else {
      if (name === 'psd') {
        updatedBasicCodeSearch = await new BasicCodeSearch({
          ...searchData,
          PSD: searchData.PSD.filter(item => item !== value)
        });
        await setSearchData(updatedBasicCodeSearch);

      } else {
        updatedBasicCodeSearch = await new BasicCodeSearch({
          ...searchData,
          SCAT: searchData.SCAT.filter(item => item !== value)
        });
        await setSearchData(updatedBasicCodeSearch);

      }
    }

  }
  useEffect(() => {
    if(psdMap && psdMap.length >0){
      if (searchData?.codeContext == 0) {
        setCurrentDropdown(psdMap)
      }
    }
  }, [psdMap]);

  useEffect(() => {
    if(scatMap && scatMap.length >0){
      if (searchData?.codeContext == 1) {
        setCurrentDropdown(scatMap)
      }
    }
  }, [scatMap]);
 const handleCodeContextChange = async (e) => {

  if(e != searchData?.codeContext){
  setCurrentDropdownValue(undefined)
    if (searchData?.codeContext == 0) {
      if(scatMap){
      setCurrentDropdown(scatMap)
    }
    }
    else {
      if(psdMap){
      setCurrentDropdown(psdMap)}

    }
     setSearchData(new BasicCodeSearch({
      ...searchData,
      codeContext: e,
      codeSearch:'',
    }))
  }
  }

  const sendSearchData = () => {
    if (searchData) {
      store.dispatch(setBasicCodeSearch(searchData.serialize()));

    }
  }
  const selectStyling = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      border:state.isFocused ? 'none': state.isSelected  ?'none':'none',
      minHeight:'21px',
      height:'21px',
      borderRadius:'0px',
  }),
  indicatorsContainer:(baseStyles, state) => ({
      ...baseStyles,
      minHeight:'22px',
      height:'22px'
  }),
  indicatorSeparator:(baseStyles, state) => ({
      ...baseStyles,
      display:'none',
  }),
  valueContainer:(baseStyles,state) => ({
      ...baseStyles,
      padding:'0px 0px 0px 0px',
      minHeight:'22px',
      height:'22px',
      border:state.isFocused ? 'none': state.isSelected  ?'none':'none',
  }),
  clearIndicator: (baseStyles,state) => ({
    ...baseStyles,
    
    padding:state.isFocused ? 0: state.isSelected ? 0:state.menuIsOpen ? 0:0,
    }),
  }
  const handleCodeString = async(e) => {
    console.log(e)
    if(e){
    const { label, value } = e;
    const updatedSearchData = { ...searchData, codeSearch: value };
    await setSearchData(new BasicCodeSearch(updatedSearchData));
    setCurrentDropdownValue(new BasicCodeModel({value:value,label:value}))
    }
    else{
      const updatedSearchData = { ...searchData, codeSearch: '' };
      await setSearchData(new BasicCodeSearch(updatedSearchData));
      setCurrentDropdownValue(undefined)
    }
  }

  return (
    <>{searchData &&
      <div className="basiccodecomponentcategory">
        <div className="layoutcolumn basiccodebuttondiv">
          <div className="layoutrow" >
            <button className={searchData.codeContext == 0 ? "basiccodebutton button active" : "basiccodebutton button"} onClick={() => handleCodeContextChange(0)}>
              PSD
            </button>
            <button className={searchData.codeContext == 1 ? "basiccodebutton button active" : "basiccodebutton button"} onClick={() => handleCodeContextChange(1)}>
              SCAT
            </button>
          </div>
          <div className="basicodeinputdiv">
            <div>Code is...</div>
            <Select name="codeSearch" onChange={(e) => handleCodeString(e)} id="dropdown" className='authorityadv1select' styles={selectStyling} isLoading={currentDropdown == undefined || currentDropdown.length == 0} value={currentDropdownValue ? currentDropdownValue : ''} isSearchable options={currentDropdown} isClearable/>
          </div>
        </div>
        {searchData.codeContext == 0 && psdDropDown.length != 0 &&
          <div className={(currentDropdownValue)?"basiccodecomponent layoutcolumn disabled":"basiccodecomponent layoutcolumn "} >
            {psdDropDown.map((item, index) => (
              <div className='basiccodecomponentrow' key={index}>
                <Checkbox name="psd" checked={searchData.PSD.includes(item.value)} value={item.value} onChange={handleCheckBoxChange} color="secondary" sx={{ color: '#009FE3', p: 0, }} disabled={currentDropdownValue !=null ||currentDropdownValue !=undefined} />
                <label className="checkboxlabel" htmlFor={`checkbox-${index}`}>{item.value} {item.label}</label>
              </div>
            ))}
          </div>
        }
        {searchData.codeContext == 1 && scatDropDown.length != 0 &&
          <div  className={(currentDropdownValue)?"basiccodecomponent layoutcolumn disabled":"basiccodecomponent layoutcolumn "}>
            {scatDropDown.map((item, index) => (
              <div className='basiccodecomponentrow' key={index}>
                <Checkbox name="scat" checked={searchData.SCAT.includes(item.value)} value={item.value} onChange={handleCheckBoxChange} color="secondary" sx={{ color: '#009FE3', p: 0, }}disabled={currentDropdownValue !=null ||currentDropdownValue !=undefined} />
                <label className="checkboxlabel" htmlFor={`checkbox-${index}`}>{item.value} {item.label}</label>
              </div>
            ))}
          </div>
        }
      </div>
    }
    </>
  )
}