export default class SummaryValuationExcel{
    firm_name?: string;
    address?: string;
    postcode?: string;
    schemE_REFERENCE?: number;
    bA_REFERENCE_NUMBER?: string;
    description?: string;
    psD_CODE?: string;
    scat?: string;
    measuremenT_BASIS?: string;
    totaL_AREA?: number;
    suB_TOTAL?: number;
    totaL_VALUE?: number;
    rateablE_VALUE?: number;
    froM_DATE?: Date;
    tO_DATE?: Date;
    lisT_ALTERATION_DATE?: Date;
    compileD_RATEABLE_VALUE?: number;
    rV_PERCENTAGE?: number;
    uaR_A?: number;
    uaR_B?: number;
    uaR_PERCENTAGE?: number;
    uaR_IBR_PERCENTAGE?: number;
    ibR_A?: number;
    ibR_B?: number;
    ibR_DESCRIPTION?: string;
    ibR_PERCENTAGE?: number;
}