import { validateAuthorityScheduleAdv1ApiModel } from "../../Services/ValidationService";
import AuthorityScheduleAdv1 from "../AuthorityScheduleAdv1";
import { CaseTypeLogic } from "../Enums/CaseTypeLogic";
import { CertificationLogic } from "../Enums/CertificationLogic";
import { ListTypeLogic } from "../Enums/ListTypeLogic";
import { ScheduleDateLogic } from "../Enums/ScheduleDateLogic";
import { StartsWithContains } from "../Enums/StartsWithContains";

export default class AuthorityScheduleAdv1ApiModel {
    scheduleDateLogic?: ScheduleDateLogic;
    scheduleDate?: string;
    scheduleFromDate?: string;
    scheduleToDate?: string;
    listYear?: string;
    listTypeLogic?: ListTypeLogic;
    certification?: boolean;
    listCertificationLogic?: CertificationLogic;
    changeReason?: string;
    changeReasonLogic?: StartsWithContains;
    voaCaseReferenceNumber?: string;
    caseType?: string;
    listcaseTypeLogic?: CaseTypeLogic;

    constructor(data: Partial<AuthorityScheduleAdv1>) {
        this.scheduleDateLogic = Number(data.scheduleDateLogic) != 0&&data.scheduleDateLogic ? Number(data.scheduleDateLogic) :undefined;
        this.scheduleDate = Number(data.scheduleDateLogic)===1 && data.scheduleDate?data.scheduleDate: undefined;
        this.scheduleFromDate =Number(data.scheduleDateLogic)===2 &&data.scheduleFromDate? data.scheduleFromDate : undefined;
        this.scheduleToDate = Number(data.scheduleDateLogic)===2 && data.scheduleToDate ? data.scheduleToDate : undefined;
        this.listYear = Number(data.listYear) === 0 ? undefined : String(data.listYear);
        this.listTypeLogic = data.listTypeLogic != undefined && Number(data.listTypeLogic) !=0 ? Number(data.listTypeLogic) : undefined;
        this.certification =  data.certification?data.certification:undefined;
        this.listCertificationLogic = data.certification ? Number(data.listCertificationLogic) : undefined;
        this.changeReason = data.changeReason === '' ? undefined : data.changeReason;
        this.changeReasonLogic = data.changeReason === '' ? undefined : data.changeReasonLogic;
        this.voaCaseReferenceNumber = data.voaCaseReferenceNumber === '' ? undefined : data.voaCaseReferenceNumber;
        this.caseType = data.caseType === '' ? undefined : data.caseType;
        this.listcaseTypeLogic = (Number(data.listcaseTypeLogic) === 0 ||!data.listcaseTypeLogic)? undefined : Number(data.listcaseTypeLogic);
    }

    validate() {
        const errors = validateAuthorityScheduleAdv1ApiModel(this);
        return {
          valid: errors.length === 0,
          errors
        };
      }
}