import React, { useState, useEffect, useRef } from 'react';
import { DataGridPro, GridRowsProp, GridColDef, useGridApiRef, GridRowSelectionModel, GridColumnGroup } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import AuthorityScheduleSearch, { validateAuthorityScheduleSearch } from '../../Models/SearchApiModels/AuthorityScheduleSearch';
import AuthorityScheduleResults from '../../Models/AuthorityScheduleResults';
import store from '../../Redux/ConfigureStore';
import BasicSearchApiModel from '../../Models/SearchApiModels/BasicSearchApiModel';
import { formatDateToString, FormatNumberWithCommas, formatScheduleStatus, FormatSettlementCode, stringifyList } from '../../Services/FormatService';
import AuthorityScheduleAdv2ApiModel from '../../Models/SearchApiModels/AuthorityScheduleAdv2ApiModel';
import AuthorityScheduleAdv1ApiModel from '../../Models/SearchApiModels/AuthorityScheduleAdv1ApiModel';
import { addUARNs, selectAddSelection, setDispatchAddSelection } from '../../Redux/ListBuilderSlicer';
import { useSelector } from 'react-redux';
import TableRightClickMenu from './TableContextMenus/TableRightClickMenu';
import { selectTriggerShowScheme, selectListYear, selectSelectedUARN, selectTriggerGOVUK, selectTriggerGetTotal, selectTriggerGetCount, selectTriggerGoogleMaps, selectTriggerPrint, setHeaderBarContext, setSelectedUARN, setTotal, setCount, setTriggerGOVUK, setTriggerGetTotal, setTriggerGetCount, setTriggerGoogleMaps, setTriggerPrint, setTriggerShowScheme, setLoading, setDisplayDetails, selectTriggerExportExcel, setTriggerExportExcel } from '../../Redux/SearchSlicer';
import HomeIcon from '@mui/icons-material/Home';
import { VONoticeReportModel } from '../../Models/VONoticeReportModel';
import { GetScheme } from '../../API/AnalyseAPIHelper';
import { Button, ClickAwayListener } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReportWindow from '../SubComponents/ReportWindow';
import HomeScreenComponent from '../SubComponents/HomeScreenComponent';
import BasicSearch from '../../Models/BasicSearch';
import { dataToFile } from '../../Services/ExcelExportService';

export default function AuthorityScheduleTable(props) {
  const [authorityScheduleData, setAuthorityScheduleData] = useState<Array<AuthorityScheduleResults>>([])
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [uarnSelectionModel, setUARNSelectionModel] = useState<string[]>([]);
  const triggerAddSelection = useSelector(selectAddSelection);
  const apiRef = useGridApiRef();
  var pageNo = useRef(0)
  const [contextMenuPosition, setContextMenuPosition] = useState<{ mouseX: number | null; mouseY: number | null }>({ mouseX: null, mouseY: null });
  const [selectedRow, setSelectedRow] = useState<AuthorityScheduleResults | undefined>();
  const { searchCount, handleUARNChange } = props;
  const triggerGoogleMaps = useSelector(selectTriggerGoogleMaps);
  const triggerGOVUK = useSelector(selectTriggerGOVUK);
  const triggerSearchButton = useRef(false)
  const keepSelection = useRef(false)
  const [storedQuery, setStoredQuery] = useState<AuthorityScheduleSearch>();
  const selectedUARN = useSelector(selectSelectedUARN);
  const triggerPrint = useSelector(selectTriggerPrint);
  const [report, setReport] = useState();
  const [selectedRowID, setSelectedRowID] = useState<number>()
  const triggerGetTotal = useSelector(selectTriggerGetTotal);
  const triggerGetCount = useSelector(selectTriggerGetCount);
  const summaryListYear = useSelector(selectListYear);
  const [showNoScheme, setShowNoScheme] = useState(false);
  const showScheme = useSelector(selectTriggerShowScheme);
  const [searchEmpty, setSearchEmpty] = useState(false);
  const [caseData, setCaseData] = useState<Array<AuthorityScheduleResults>>([]);
  const [showCaseTable, setShowCaseTable] = useState(false);
  const triggerExport = useSelector(selectTriggerExportExcel);
  const [columnWidths, setColumnWidths] = useState({});

  const getCaseData = async () => {
    try {
      store.dispatch(setLoading(true))
      var caseData = await ApiHelper.GetAuthorityScheduleByCase(selectedRow?.voA_CASE)
      var caseDataArray = caseData ? caseData : [];
      if (caseDataArray.length > 0) {

        setCaseData(caseDataArray);
        store.dispatch(setSelectedUARN(caseDataArray[0].uarn))
        setRowSelectionModel([caseDataArray[0].key_field!])
        setShowCaseTable(true);
      }
    }
    catch (err) {
      console.error(err)
    }
    finally {
      store.dispatch(setLoading(false))

    }
  }
  
  const handleColumnResize = (params) => {
    setColumnWidths((prevWidths) => ({
      ...prevWidths,
      [params.colDef.field]: params.width,
    }));
  };
  
  useEffect(() => {
    pageNo.current = 0;
    var query = getSearchQuery();
    fetchData(query);
    triggerSearchButton.current = true;
    store.dispatch(setSelectedUARN(''))
    setSelectedRow(undefined);
    setShowCaseTable(false);
  }, [searchCount]);

  useEffect(() => {
    if (showScheme) {
      handleScheme()
    }
  }, [showScheme]);

  useEffect(() => {
    if (triggerPrint[0]) {
      handlePrint(triggerPrint)

      store.dispatch(setTriggerPrint([0, triggerPrint[1]]))
    }
  }, [triggerPrint]);

  useEffect(() => {
    if (triggerExport > 0) {
      handleExportExcel(triggerExport)
      store.dispatch(setTriggerExportExcel(0))
    }
  }, [triggerExport])

  useEffect(() => {
    if (triggerGetTotal == true) {
      handleGetTotal();
    }
  }, [triggerGetTotal]);

  useEffect(() => {
    if (triggerGetCount == true) {
      handleGetCount();
    }
  }, [triggerGetCount]);

  const handleGetCount= async () => {
    if (storedQuery) {
      try {
        var totalQuery = storedQuery;
        totalQuery.totals = true;

        store.dispatch(setLoading(true))
        var data = await ApiHelper.GetAuthorityScheduleCount(totalQuery);
        storedQuery.totals = false;
        if (data) {
          store.dispatch(setCount(data[0].key_field))

        }
        // if (store.getState().search.sqlDump) {
        //   var totalQuery = storedQuery
        //   storedQuery.sqldump = true;
        //   totalQuery.totals = true;
        //   var dump = await ApiHelper.GetAuthorityScheduleCount(totalQuery);
        //   storedQuery.totals = false;
        //   storedQuery.sqldump = false;
        //   console.log(dump)
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        store.dispatch(setLoading(false))
      }
    }
  }

  
  const handleGetTotal = async () => {
    if (storedQuery) {
      try {
        var totalQuery = storedQuery;
        totalQuery.totals = true;

        store.dispatch(setLoading(true))
        var data = await ApiHelper.GetAuthorityScheduleSum(totalQuery);
        storedQuery.totals = false;
        if (data) {
          store.dispatch(setTotal(data[0].rateablE_VALUE))

        }
        // if (store.getState().search.sqlDump) {
        //   var totalQuery = storedQuery
        //   storedQuery.sqldump = true;
        //   totalQuery.totals = true;
        //   var dump = await ApiHelper.GetAuthorityScheduleSum(totalQuery);
        //   storedQuery.totals = false;
        //   storedQuery.sqldump = false;
        //   console.log(dump)
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        store.dispatch(setLoading(false))
      }
    }
  }
  const onClipboardCopy = (data, event, { api }) => {
    const visibleColumns = apiRef.current.getVisibleColumns();
    
    const columnsAsString = visibleColumns.filter(col => col.field !== '__check__').map(col => col.headerName).join('\t');
    navigator.clipboard.writeText(columnsAsString + '\n' + data);
  }
  const handlePrint = async (props) => {

    store.dispatch(setLoading(true))
    var newreport
    try {
      switch (props[0]) {
        case 1:
          var query1 = new VONoticeReportModel(stringifyList(rowSelectionModel));
          newreport = await ApiHelper.GetVONoticeReport(query1);
          break;
        case 3:
          var query2 = new AuthorityScheduleSearch();
          query2.SelectedItems = true;
          query2.keyID = stringifyList(rowSelectionModel);
          newreport = await ApiHelper.GetScheduleListReportSelected(query2);
          break;

        case 4:

          newreport = await ApiHelper.GetScheduleListReport(storedQuery!);
          break;
      }
    }
    catch (e) {
      console.error(e)
    }
    finally {
      store.dispatch(setLoading(false));
    }
    setReport(newreport)
  }


  useEffect(() => {
    const selectedUARNs = rowSelectionModel.map(id => {
      return authorityScheduleData.find(row => row.key_field === id)?.uarn;
    }).filter(Boolean) as string[];
    setUARNSelectionModel(selectedUARNs);
  }, [rowSelectionModel]);

  useEffect(() => {
    if (triggerAddSelection == true) {
      handleAddToListBuilder();
      store.dispatch(setDispatchAddSelection(false));
    }
  }, [triggerAddSelection]);

  const handleAddToListBuilder = () => {
    try{
      ApiHelper.postLogThis("Adding to List: "+ stringifyList(uarnSelectionModel))
    }catch(error){
      console.error(error)
    }
    finally{
      store.dispatch(addUARNs(uarnSelectionModel));
    }
  }
  const handleContextMenu = (event, row) => {
    event.preventDefault();

    if (event.shiftKey) {
      console.log('Shift key is held down.');
    }
    else {
      if (event.ctrlKey) {
        setRowSelectionModel([...rowSelectionModel, row.key_field]);
      }
      else {
        if (rowSelectionModel.findIndex(elem => row.key_field == elem) > -1) {
        }
        else {
          setRowSelectionModel([row.key_field]);
        }

      }
      if (row.uarn !== selectedUARN) {
        store.dispatch(setSelectedUARN(row.uarn));
      }
      if (!showCaseTable) {
        setSelectedRow(row)
      }
    }

    if (contextMenuPosition.mouseX !== null && contextMenuPosition.mouseY !== null) {
      handleCloseContextMenu(() => {
        setContextMenuPosition({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
      });
    } else {
      setContextMenuPosition({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
    }
  };

  const openExternalLink = (url) => {
    try{
      ApiHelper.postLogThis("sending user to url: "+ url)
    }catch(error){
      console.error(error)
    }
    finally{
      window.open(url, '_blank', 'noopener,noreferrer');

    }
  };
  useEffect(() => {
    if (triggerGoogleMaps == true && selectedUARN) {
      handleGoogleMapSearch();

    }
    store.dispatch(setTriggerGoogleMaps(false));
  }, [triggerGoogleMaps]);

  const handleGoogleSearch = ()=>{
    var row = authorityScheduleData.find(item =>
      item.uarn == selectedUARN
    )
    var mapquery = ''
    mapquery += row?.address ? row?.address + ',' : ''
    mapquery += row?.postcode;
    openExternalLink('https://www.google.co.uk/search?q=' + mapquery);
  }
  
  const handleGoogleMapSearch = (param = '') => {
    var row = authorityScheduleData.find(item =>
      item.uarn == selectedUARN
    )
    var mapquery = ''
    switch (param) {
      case "address":
        mapquery += row?.address;
        break;
      case "postcode":
        mapquery += row?.postcode;
        break;

      case "addressandpostcode":
        mapquery += row?.address ? row?.address + ',' : ''
        mapquery += row?.postcode;
        break;

      default:
        mapquery += row?.postcode;
        break;
    }
    openExternalLink('https://www.google.co.uk/maps?q=' + mapquery);

  }
  useEffect(() => {
    if (triggerGOVUK == true && selectedUARN) {
      openExternalLink('https://www.tax.service.gov.uk/business-rates-find/valuations/' + selectedUARN)
    }
    store.dispatch(setTriggerGOVUK(false));
  }, [triggerGOVUK]);


  const handleCloseContextMenu = (callback) => {
    setContextMenuPosition({ mouseX: null, mouseY: null });
    if (callback) {
      setTimeout(callback, 0);
    }
  };
  const handleMenuItemClick = (item, param = '') => {
    switch (item) {
      case "Add to 'ListBuilder'":
        handleAddToListBuilder();
        break;
      case "Show Scheme":
        handleScheme();
        break;
      case "Show Entry on GOV.UK":
        store.dispatch(setTriggerGOVUK(true));
        break;
      case "Print Quick Notice Report":
        handlePrint([1, 0])
        break;
      case "Show Assessments Related By Case":
        getCaseData();
        break;
      case "Return To Search Results":
        setShowCaseTable(false);
        break;
      case "Show in Google Maps":
        handleGoogleMapSearch(param);
        break;
        case "Search in Google":
          handleGoogleSearch();
          break;
      default:
        break;
    }
    handleCloseContextMenu(null);
  };
  const handleScheme = async () => {
    try {
      store.dispatch(setLoading(true))
      var response = await GetScheme(summaryListYear, selectedUARN)
      if (response && response.assessmenT_REFERENCE && response.schemE_REFERENCE && selectedUARN) {
        openExternalLink(`https://www.tax.service.gov.uk/business-rates-find/valuations/${response.assessmenT_REFERENCE}/schemes/${response.schemE_REFERENCE}?uarn=${selectedUARN}`);
      }
      else {
        setShowNoScheme(true);
      }
    }
    catch {
      setShowNoScheme(true);
    } finally {
      store.dispatch(setTriggerShowScheme(false))
      store.dispatch(setLoading(false))

    }
  }

  useEffect(() => {
    handleUARNChange(selectedUARN)
  }, [selectedUARN])
  async function fetchData(query) {

    query.sortcolumn = "l.key_field";
    query.sortorder = 'asc';
    if (apiRef.current.state) {
      const { sortModel } = apiRef.current.state.sorting;
      if (sortModel.length != 0) {
        query.sortcolumn = sortModel[0]['field'];
        query.sortorder = sortModel[0]['sort'] ? sortModel[0]['sort'] : 'asc';
      }

    }
    query.pageno = pageNo.current
    var data;
    var errors = validateAuthorityScheduleSearch(query);
    if (errors.length == 0) {
      try {
        store.dispatch(setLoading(true))
        data = await ApiHelper.GetAuthoritySchedule(query);
        var dataarray = data ? data : [];

        if (dataarray.length > 0) {
          if (pageNo.current === 0) {
            setAuthorityScheduleData(dataarray);
            store.dispatch(setTriggerGetTotal(false))
            store.dispatch(setTriggerGetCount(false))
            setStoredQuery(query)
            store.dispatch(setSelectedUARN(dataarray[0].uarn))
            setRowSelectionModel([dataarray[0].key_field])
            setSelectedRow(dataarray[0])
            if(searchCount==1){
              store.dispatch(setDisplayDetails(true))
            }
          }
          if (triggerSearchButton.current) {
            store.dispatch(setHeaderBarContext(1))
          } else {
            setAuthorityScheduleData(prevData => [...prevData, ...dataarray]);
          }
        }
        else {
          if (query.pageno == 0) {
            setSearchEmpty(true);
          }
        }
      }
      catch (ex) {
        console.log(ex)

      }
      finally {
        // if (store.getState().search.sqlDump) {
        //   query.sqldump = true;
        //   var dump = await ApiHelper.GetAuthorityScheduleDump(query);
        //   console.log(dump)
        // }
        store.dispatch(setLoading(false))
      }
    }
    triggerSearchButton.current = false;
  }
  const getSearchQuery = () => {
    var storeQueryData = store.getState().search;
    var basic = storeQueryData.basicSearch
    var adv1 = storeQueryData.authorityScheduleAdv1
    var adv2 = storeQueryData.authorityScheduleAdv2
    var settlement = storeQueryData.integratedSettlementSearch
    var query = new AuthorityScheduleSearch();
    query.basic = new BasicSearchApiModel(basic);
    var basicCodes = storeQueryData.basicCodeSearch;
    if (basicCodes.PSD?.length != 0 || basicCodes.SCAT?.length != 0 || basicCodes.codeSearch != '') {
      if (basicCodes.codeSearch) {
        if (basicCodes.codeContext == 0) {
          query.basic.PSD = [basicCodes.codeSearch]
        }
        else {
          query.basic.SCAT = [basicCodes.codeSearch]

        }
      }
      else {
        if (basicCodes.PSD?.length == 0) {
          query.basic.SCAT = basicCodes.SCAT;
        }
        if (basicCodes.SCAT?.length == 0) {
          query.basic.PSD = basicCodes.PSD;
        }
        if (basicCodes.PSD?.length != 0 && basicCodes.SCAT?.length != 0) {
          if (basicCodes.codeContext == 1) {
            query.basic.SCAT = basicCodes.SCAT;
          } else {
            query.basic.PSD = basicCodes.PSD;
          }
        }
      }


    }
    var areaSearch = storeQueryData.areaSearch;
    if (areaSearch.BACODE != '' && areaSearch.BACODE) {
      query.basic.BACODE = areaSearch.BACODE
    }
    if (areaSearch.TOFFICE != '' && areaSearch.TOFFICE) {
      query.basic.TOFFICE = areaSearch.TOFFICE
    }
    if (areaSearch.VOANNDRRVU != '' && areaSearch.VOANNDRRVU) {
      query.basic.VOANNDRRVU = areaSearch.VOANNDRRVU
    }
    if (areaSearch.VOOFFICELOCAL != '' && areaSearch.VOOFFICELOCAL) {
      query.basic.VOOFFICELOCAL = areaSearch.VOOFFICELOCAL
    }
    query.basic.region = Number(areaSearch.region)
    if (adv1.certification || Number(adv1.listcaseTypeLogic) != 0 || adv1.changeReason != '' || Number(adv1.listYear) != 0 || Number(adv1.listTypeLogic) != 0 || Number(adv1.scheduleDateLogic) != 0 || adv1.voaCaseReferenceNumber != '') {
      query.advancedScheduleAd1 = new AuthorityScheduleAdv1ApiModel(adv1);
    }
    if ((adv2.edSearch && adv2.edValue) || (adv2.ladSearch && adv2.ladValue) || (adv2.rvSearch && adv2.rvValue !=null && adv2.rvValue!=undefined) || adv2.partDomesticLogic) {
      query.advancedScheduleAd2 = new AuthorityScheduleAdv2ApiModel(adv2)
    }
    if( settlement.searchSettlement){
      query.settlement = settlement
    }
    return query;
  }
  const handleExportExcel = async (options) => {
    var excelData
    store.dispatch(setLoading(true))
    try {
      switch (options) {
        case 1:
          var querystring = stringifyList(rowSelectionModel);
          excelData = await ApiHelper.GetAuthorityScheduleExcelSelected(querystring);
          break;
        case 2:
          var query = storedQuery!;
          query.report = true;
          excelData = await ApiHelper.GetAuthorityScheduleExcel(query);
          break;
      }
    }
    catch (err) {
      console.error(err)
    }
    finally {
      storedQuery!.report = false;
      if (excelData.length) {
        excelData.forEach(item => {
          item.status = item.status? formatScheduleStatus(item.status):null;
          item.settlemenT_TYPE = item.settlemenT_TYPE? FormatSettlementCode(item.lisT_YEAR,item.settlemenT_TYPE):null;
          item.lisT_ALTERATION_DATE = item.lisT_ALTERATION_DATE? formatDateToString(item.lisT_ALTERATION_DATE):null;
          item.schedulE_DATE = item.schedulE_DATE? formatDateToString(item.schedulE_DATE):null;
          item.effectivE_DATE = item.effectivE_DATE? formatDateToString(item.effectivE_DATE):null;
           });
        dataToFile(excelData, ExcelGroupingModel, ExcelColumnModel)
      }
      store.dispatch(setLoading(false))
    }
  }
  const handleRowOrderChange = (model) => {
    pageNo.current = 0
    fetchData(storedQuery);
    apiRef.current.scroll({ top: 0 });
  }
  const handleRowsScrollEnd = () => {
    if (!showCaseTable) {
      pageNo.current += 1;
      fetchData(storedQuery);
    }
  }

  const handleRowClick = (e) => {
    const { row, id } = e;
    if (row.uarn !== selectedUARN) {
      setSelectedRowID(id)
      store.dispatch(setSelectedUARN(row.uarn));
      setSelectedRow(row)
    }
  }

  const ExcelColumnModel = [
    { key: 'schedulE_DATE', header: 'SCHEDULE DATE', width: 10 },
    { key: 'lisT_YEAR', header: 'LIST', width: 8 },
    { key: 'status', header: 'STATUS', width: 8 },
    { key: 'firM_NAME', header: 'FIRM NAME', width: 12 },
    { key: 'address', header: 'ADDRESS', width: 48},
    { key: 'postcode', header: 'POSTCODE', width: 12 },
    { key: 'bA_REFERENCE_NUMBER', header: 'BA REFERENCE NUMBER', width: 12 },
    { key: 'assessmenT_REFERENCE', header: 'ASSESSMENT REF', width: 12 },
    { key: 'description', header: 'DESCRIPTION', width: 20 },
    { key: 'psD_CODE', header: 'PSD', width: 8 },
    { key: 'scat', header: 'SCAT', width: 8 },
    { key: 'rateablE_VALUE', header: 'RATEABLE VALUE', width: 10 , style: { numFmt: '#,##0' }},
    { key: 'effectivE_DATE', header: 'EFFECTIVE DATE', width: 12 },
    { key: 'lisT_ALTERATION_DATE', header: 'ALTERATION DATE', width: 12 },
    { key: 'composite', header: 'COMPOSITE', width: 12 },
    { key: 'voA_CASE', header: 'VOA CASE', width: 12 },
    { key: 'settlemenT_TYPE', header: 'SETTLEMENT', width: 12 },
    { key: 'casE_TYPE', header: 'CASE TYPE', width: 10 },
    { key: 'reporT_NUMBER', header: 'BA REPORT NUMBER', width: 10 },
    { key: 'changE_REASON', header: 'CHANGE REASON', width: 16 },
    { key: 'certified', header: 'CERTIFICATION', width: 12 }
  ];

  const ExcelGroupingModel = [
    {
      groupId: 'STATUS/ADDRESS',
      children: [ 'address', 'lisT_YEAR', 'schedulE_DATE', 'postcode', 'status', 'firM_NAME']
    },
    {
      groupId: 'HEREDITAMENT INFORMATION',
      children: ['description', 'psD_CODE', 'scat', 'bA_REFERENCE_NUMBER', 'assessmenT_REFERENCE']
    },
    {
      groupId: 'INFORMATION SENT TO BILLING AUTHORITY',
      children: [
        'rateablE_VALUE', 'effectivE_DATE','lisT_ALTERATION_DATE', 'composite',  'voA_CASE','settlemenT_TYPE', 'casE_TYPE', 
        'reporT_NUMBER',  'changE_REASON', 'certified'
      ]
    }
  ];
  const columns: GridColDef[] = [
    {
      field: 'schedulE_DATE', headerName: 'SCHEDULE DATE', width: columnWidths['schedulE_DATE'] || 75, align: 'center', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return formatDateToString(value)
      }
    },
    {
      field: 'lisT_YEAR', headerName: 'LIST', type: 'number', minWidth: 40, width: columnWidths['lisT_YEAR'] || 40, align: 'center', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return value
      }
    },
    {
      field: 'status', headerName: 'STATUS', minWidth: 40, width: columnWidths['status'] || 40, align: 'center', headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatScheduleStatus(params.value) || <span>&nbsp;</span>}

        </div>
      ),
      valueFormatter:(value,row)=>{
        return formatScheduleStatus(value)
      }
    },
    {
      field: 'firM_NAME', headerName: 'FIRM NAME', width: columnWidths['firM_NAME'] || 75, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'address', headerName: 'ADDRESS', width: columnWidths['address'] || 458, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'postcode', headerName: 'POSTCODE', width: columnWidths['postcode'] || 80, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },

    {
      field: 'bA_REFERENCE_NUMBER', headerName: 'BA REFERENCE NUMBER', width: columnWidths['bA_REFERENCE_NUMBER'] || 82, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'assessmenT_REFERENCE', headerName: 'ASSESSMENT REF', width: columnWidths['assessmenT_REFERENCE'] || 104, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'description', headerName: 'DESCRIPTION', width: columnWidths['description'] || 150, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>

          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'psD_CODE', headerName: 'PSD', width: columnWidths['psD_CODE'] || 40, minWidth: 40, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>

          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'scat', headerName: 'SCAT', width: columnWidths['scat'] || 40, minWidth: 40, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>

          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'rateablE_VALUE', headerName: 'RATEABLE VALUE', type: 'number', width: columnWidths['rateablE_VALUE'] || 75, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {FormatNumberWithCommas(params.value) || <span>DELETED</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'effectivE_DATE', headerName: 'EFFECTIVE DATE', width: columnWidths['effectivE_DATE'] || 75, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return formatDateToString(value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE', headerName: 'ALTERATION DATE', width: columnWidths['lisT_ALTERATION_DATE'] || 75, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return formatDateToString(value)
      }
    },
    {
      field: 'composite', headerName: 'COMPOSITE', width: columnWidths['composite'] || 40, minWidth: 40, headerAlign: 'left',
      renderCell: (params: any) => (
        <div className='compositivediv' onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <div className='houseicon'><HomeIcon sx={{ height: 18, width: 18 }} color='secondary' /></div>}
        </div>
      ),
    },
    {
      field: 'voA_CASE', headerName: 'VOA CASE', width: columnWidths['voA_CASE'] || 75, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>

          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    { field: 'settlemenT_TYPE', headerName: 'SETTLEMENT', width: columnWidths['settlemenT_TYPE'] || 120, headerAlign: 'center',align:'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? <div>{FormatSettlementCode(params.row.lisT_YEAR,params.value)}</div> : <span>&nbsp;</span>}
      </div>
    ),
    valueFormatter:(value,row)=>{
      return FormatSettlementCode(row.lisT_YEAR,value)
    }
  },
    {
      field: 'casE_TYPE', headerName: 'CASE TYPE', width: columnWidths['casE_TYPE'] || 75, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>

          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'reporT_NUMBER', headerName: 'BA REPORT NUMBER', width: columnWidths['reporT_NUMBER'] || 55, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>

          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'changE_REASON', headerName: 'CHANGE REASON', width: columnWidths['changE_REASON'] || 120, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>

          {params.value != null || params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'certified', headerName: 'CERTIFICATION', width: columnWidths['certified'] || 100, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value == 'Y ' ? <>YES</> : <span>&nbsp;</span>}
          {params.value == 'L ' ? <>INVITED</> : <span>&nbsp;</span>}
        </div>
      ),
    }
  ]
  const columnGroupingModel: GridColumnGroup[] = [
    {
      groupId: 'ADDRESS/STATUS',
      children: [{ field: 'firm_name' }, { field: 'address' }, { field: 'lisT_YEAR' }, { field: 'schedulE_DATE' }, { field: 'postcode' }, { field: 'status' }, { field: 'firM_NAME' }]
      , headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: 'HEREDITAMENT INFORMATION',
      children: [{ field: 'description' }, { field: 'psD_CODE' }, { field: 'scat' }, { field: 'bA_REFERENCE_NUMBER' }, { field: 'assessmenT_REFERENCE' }]
      , headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: 'INFORMATION SENT TO BILLING AUTHORITY',
      children: [{ field: 'rateablE_VALUE' }, { field: 'effectivE_DATE' }, { field: 'composite' }, { field: 'voA_CASE' }, { field: 'casE_TYPE' }, { field: 'reporT_NUMBER' }, { field: 'changE_CODE' }, { field: 'changE_REASON' }, { field: 'lisT_ALTERATION_DATE' }, { field: 'settlemenT_TYPE' }, { field: 'numbeR_AND_NAME' }, { field: 'street' }, { field: 'town' }, { field: 'postaL_DISTRICT' }, { field: 'county' }, { field: 'certified' }, { field: 'occupier' },]
      , headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    }
  ]

  const rows = showCaseTable ? caseData : authorityScheduleData;
  return (
    <div style={{ height: '100%', width: '100%'}}>
      {rows.length != 0 &&
        <>
          <DataGridPro rows={rows} columns={columns} rowHeight={22} getRowId={(row: AuthorityScheduleResults) => row.key_field!}
            
            columnHeaderHeight={22}
            sortingMode="server"
            columnGroupingModel={columnGroupingModel}
            apiRef={apiRef}
            onRowsScrollEnd={handleRowsScrollEnd}
            onSortModelChange={(model) => handleRowOrderChange(model)}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            autosizeOptions={{
              includeOutliers: false,
              includeHeaders: false,
            }}
            onColumnWidthChange={handleColumnResize}
            onClipboardCopy={onClipboardCopy}
            onRowClick={handleRowClick}
            hideFooter
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
          />
          <TableRightClickMenu
            contextMenuPosition={contextMenuPosition}
            handleClose={handleCloseContextMenu}
            handleMenuItemClick={handleMenuItemClick}
            table={2}
            subtable={showCaseTable}
          />

        </>
      }
      {report &&
        <ReportWindow reportString={report} onClose={() => setReport(undefined)}></ReportWindow>
      }
      {showNoScheme &&
        <ClickAwayListener onClickAway={(event) => (console.log(event), setShowNoScheme(false))}>
          <div className='noschemepopup'>
            <div className='closeme' onClick={() => setShowNoScheme(false)}>
              <CloseIcon />
            </div>
            <div className='popupheader'>Analyse</div>
            <div className='popupbody'>Valuation Scheme Not Available</div>
            <Button variant='outlined' className='popupbutton' onClick={() => setShowNoScheme(false)}>OK</Button>
          </div>
        </ClickAwayListener>
      }
      {
        searchEmpty &&
        <ClickAwayListener onClickAway={(event) => (setSearchEmpty(false))}>
          <div className='searchemptypopup'>
            <div className='closeme' onClick={() => setSearchEmpty(false)}>
              <CloseIcon />
            </div>
            <div className='popupheader'>Analyse</div>
            <div className='popupbody'>No Assessments found - Please refine your search</div>
            <Button variant='outlined' className='popupbutton' onClick={() => setSearchEmpty(false)}>OK</Button>
          </div>
        </ClickAwayListener>
      }
      {rows.length == 0 &&
        <HomeScreenComponent />
      }
    </div>
  );
}