import { DateField } from "./Enums/DateField";
import { DateLogic } from "./Enums/DateLogic";
import { SearchLogic } from "./Enums/SearchLogic";
import { StatusList } from "./Enums/StatusList";

export class ValuationTribunalVTS {
    agentLogic?: boolean;
    agentName?: string;
    appealNo?: string;
    sprogID?: string;
    decisionLogic?: boolean;
    decisionValue?: string;
    listYear?: number;
    vtsStatus?: StatusList;
    goneOntoLandsTribunal?: boolean;
    showFullDecisionsOnly?: boolean;
    dateSearch?: boolean;
    dateField?: DateField;
    dateLogic?: DateLogic;
    dateValue?: string;
    rvSearch?: boolean;
    rvLogic?: SearchLogic;
    rvValue?: number;
    rvValueLessThan?:number;
    constructor(data?: Partial<ValuationTribunalVTS>) {
        Object.assign(this, {
            agentLogic: false,
            agentName: '',
            appealNo: '',
            sprogID: '',
            decisionLogic: false,
            decisionValue: '',
            listYear: 0,
            vtsStatus: 0,
            goneOntoLandsTribunal: false,
            showFullDecisionsOnly: false,
            dateSearch: false,
            dateField: 0,
            dateLogic: 0,
            dateValue: '',
            rvSearch: false,
            rvLogic: 0,
            rvValue: 0,
            rvValueLessThan:0,
            ...data,
        });
    }

    serialize(): Partial<ValuationTribunalVTS> {
        return {
            agentLogic: this.agentLogic,
            agentName: this.agentName,
            appealNo: this.appealNo,
            sprogID: this.sprogID,
            decisionLogic: this.decisionLogic,
            decisionValue: this.decisionValue,
            listYear: this.listYear,
            vtsStatus: this.vtsStatus,
            goneOntoLandsTribunal: this.goneOntoLandsTribunal,
            showFullDecisionsOnly: this.showFullDecisionsOnly,
            dateSearch: this.dateSearch,
            dateField: this.dateField,
            dateLogic: this.dateLogic,
            dateValue: this.dateValue,
            rvSearch: this.rvSearch,
            rvLogic: this.rvLogic,
            rvValue: this.rvValue,
            rvValueLessThan:this.rvValueLessThan,
        };
    }
}