import { useEffect, useState } from "react";
import { SurveyAdvanced } from "../../Models/SurveyAdvanced";
import { SearchLogic } from "../../Models/Enums/SearchLogic";
import { cleanup } from "@testing-library/react";
import store from "../../Redux/ConfigureStore";
import { TrendLogic } from "../../Models/Enums/TrendLogic";
import { UARvsIBR } from "../../Models/Enums/UARvsIBR";
import { setAdvancedSurveySearch } from "../../Redux/SearchSlicer";
import { Checkbox } from "@mui/material";

export default function AdvancedSurveySearchComponent(props) {
    const [searchData, setSearchData] = useState<SurveyAdvanced | null>(null);
    const { searchCount } = props;
    useEffect(() => {

        setSearchData(new SurveyAdvanced(store.getState().search.advancedSurveySearch));
        return () => {
            cleanup();
        };
    }, []);
    useEffect(() => {
        if (searchCount < 1) {
            setSearchData(new SurveyAdvanced(store.getState().search.advancedSurveySearch));
        }
        return () => {
            cleanup();
        };
    }, [searchCount]);
    const handleCheckBoxChange = (e) => {
        const { checked, name } = e.target;
        const updatedSearchData = { ...searchData, [name]: checked };
        setSearchData(new SurveyAdvanced(updatedSearchData));
    }

    const handleBlur= (e) => {
        const {value,name} = e.target;
        if (value.trim() === ''){
            const updatedSearchData = { ...searchData, [name]: 0 };
            setSearchData(new SurveyAdvanced(updatedSearchData));
        }
    }
    const handleChange = async (e) => {
        const { name, value } = e.target;
        const updatedSearchData = { ...searchData, [name]: value };
        setSearchData(new SurveyAdvanced(updatedSearchData));
    };
    useEffect(() => {
        sendSearchData();
      }, [searchData]);

      const sendSearchData=()=>{
        if (searchData){
          store.dispatch(setAdvancedSurveySearch(searchData.serialize()));

        }
      }
    return (
        <>
            {searchData &&
                <div className="searchcomponentcategory">

                    <div className="trendminicomponent">
                        <div className="advancedsurveyminiheader">
                            Valuation has:
                        </div>
                        <div className="advancedsurveyminiheader">
                            <Checkbox color="secondary"  sx={{color: '#009FE3',p:0, }} name='hasCarpark' checked={searchData.hasCarpark} onChange={handleCheckBoxChange} /> Car Parking
                        </div>
                        <div className="advancedsurveyminiheader">
                            <Checkbox color="secondary"  sx={{color: '#009FE3',p:0, }} name='hasPlantMachinery' checked={searchData.hasPlantMachinery} onChange={handleCheckBoxChange} /> Plant & Machinary
                        </div>
                        <div className="advancedsurveyminiheader">
                        <div className="trendminiheader">
                            <Checkbox color="secondary"  sx={{color: '#009FE3',p:0, }} name='totalAreaSearch' checked={searchData.totalAreaSearch} onChange={handleCheckBoxChange} /> Total Area
                        </div>
                        <div className="surveylineselectcontainer">
                            <div className="advancedsurveytext">Total Area (m²) </div>
                            <select id="dropdown" className='advancedsurveyselect' name='totalAreaLogic' disabled={!searchData.totalAreaSearch} value={searchData.totalAreaLogic} onChange={handleChange}>
                                {Object.values(SearchLogic).filter(key => isNaN(Number(SearchLogic[key]))).map((value) => (
                                    <option key={value} value={value}>
                                        {SearchLogic[value]}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="surveylinevalues">
                            <input type='number' onBlur={handleBlur} className={searchData.totalAreaLogic == 3 ? "advsurveylinenumberinput shrink" : "advsurveylinenumberinput"} name='totalArea' value={searchData.totalArea} disabled={!searchData.totalAreaSearch} step='100' onChange={handleChange} />
                            {searchData.totalAreaLogic == 3 &&
                                <>
                                    <label htmlFor="totalAreaLessThan" className="surveylinelessthanlabel">and</label>
                                    <input type='number' onBlur={handleBlur} className="advsurveylinenumberinput lessthan" name='totalAreaLessThan' value={searchData.totalAreaLessThan} disabled={!searchData.totalAreaSearch} step='100' onChange={handleChange} />
                                </>
                            }
                        </div>
                        </div>
                        <div className="advancedsurveyminiheader">

                        <div className="trendminiheader">
                            <Checkbox color="secondary"  sx={{color: '#009FE3',p:0, }} name='ibrSearch' checked={searchData.ibrSearch} onChange={handleCheckBoxChange} /> Indicative Base Rate (IBR) Trend
                        </div>
                        <div className="trendminicontent">
                            <div className="text advancedsurvey">IBRs that </div>
                            <select id="dropdown" className='trendlogicinput' name='ibrLogic' disabled={!searchData.ibrSearch} value={searchData.ibrLogic} onChange={handleChange}>
                                {Object.values(TrendLogic).filter(key => isNaN(Number(TrendLogic[key]))).map((value) => (
                                    <option key={value} value={value}>
                                        {TrendLogic[value]}
                                    </option>
                                ))}
                            </select>
                            <input type='number' onBlur={handleBlur} className="trendamountinput" name='ibrValue' value={searchData.ibrValue} disabled={searchData.ibrLogic != 1 && searchData.ibrLogic != 4 ? true : false} onChange={handleChange} />
                        </div>
                        </div>
                        <div className="advancedsurveyminiheader">
                        <div className="trendminiheader">
                            <Checkbox color="secondary"  sx={{color: '#009FE3',p:0, }} name='uarTrendSearch' checked={searchData.uarTrendSearch} onChange={handleCheckBoxChange} /> Unadjusted Rate (UAR) Trend
                        </div>
                        <div className="trendminicontent uar">
                            <div className="text advancedsurvey">UARs that </div>
                            <select id="dropdown" className='trendlogicinput' name='uarTrendLogic' disabled={!searchData.uarTrendSearch} value={searchData.uarTrendLogic} onChange={handleChange}>
                                {Object.values(TrendLogic).filter(key => isNaN(Number(TrendLogic[key]))).map((value) => (
                                    <option key={value} value={value}>
                                        {TrendLogic[value]}
                                    </option>
                                ))}
                            </select>
                            <input type='number' onBlur={handleBlur} className="trendamountinput" name='uarTrendValue' value={searchData.uarTrendValue} disabled={searchData.uarTrendLogic != 1 && searchData.uarTrendLogic != 4 ? true : false} onChange={handleChange} />
                        </div>
                        <div className="trendminicontent uar">
                            <div className="text advancedsurvey">UAR vs IBR </div>
                            <select id="dropdown" className='uarvsibrselect' name='uarVsIBRSearch'  value={searchData.uarVsIBRSearch} onChange={handleChange}>
                                {Object.values(UARvsIBR).filter(key => isNaN(Number(UARvsIBR[key]))).map((value) => (
                                    <option key={value} value={value}>
                                        {UARvsIBR[value]}
                                    </option>
                                ))}
                            </select>
                        </div>
                        </div>
                        <div className="advancedsurveyminiheader">
                        <div className="trendminiheader">
                            <Checkbox color="secondary"  sx={{color: '#009FE3',p:0, }} name='uarSearch' checked={searchData.uarSearch} onChange={handleCheckBoxChange} /> Unadjusted Rate Price
                            <div className="trendminicontent">
                            <div className="surveylineselectcontainer">
                                <div className="advancedsurveytext">UAR Value </div>
                                <select id="dropdown" className='advancedsurveyselect' name='uarLogic' disabled={!searchData.uarSearch} value={searchData.uarLogic} onChange={handleChange}>
                                    {Object.values(SearchLogic).filter(key => isNaN(Number(SearchLogic[key]))).map((value) => (
                                        <option key={value} value={value}>
                                            {SearchLogic[value]}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>
                        <div className="surveylinevalues">
                            <input type='number' onBlur={handleBlur} className={searchData.uarLogic == 3 ? "advsurveylinenumberinput shrink" : "advsurveylinenumberinput"} name='uarValue' value={searchData.uarValue} disabled={!searchData.uarSearch} step='100' onChange={handleChange} />
                            {searchData.uarLogic == 3 &&
                                <>
                                    <label htmlFor="uarValueLessThan" className="surveylinelessthanlabel">and</label>
                                    <input type='number' onBlur={handleBlur} className="advsurveylinenumberinput lessthan" name='uarValueLessThan' value={searchData.uarValueLessThan} step='100' onChange={handleChange} />
                                </>
                            }
                        </div>
                    </div>
                    </div>
                    <div className="advancedsurveyminiheader">
                    <div className="trendminiheader">
                            <Checkbox color="secondary"  sx={{color: '#009FE3',p:0, }} name='rvSearch' checked={searchData.rvSearch} onChange={handleCheckBoxChange} /> Rateable Value
                            <div className="trendminicontent">
                            <div className="surveylineselectcontainer">
                                <div className="advancedsurveytext">RV=</div>
                                <select id="dropdown" className='advancedsurveyselect' name='rvLogic' disabled={!searchData.rvSearch} value={searchData.rvLogic} onChange={handleChange}>
                                    {Object.values(SearchLogic).filter(key => isNaN(Number(SearchLogic[key]))).map((value) => (
                                        <option key={value} value={value}>
                                            {SearchLogic[value]}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>
                        <div className="surveylinevalues">
                            <input type='number' onBlur={handleBlur} className={searchData.rvLogic == 3 ? "advsurveylinenumberinput shrink" : "advsurveylinenumberinput"} name='rvValue' value={searchData.rvValue} disabled={!searchData.rvSearch} step='100' onChange={handleChange} />
                            {searchData.rvLogic == 3 &&
                                <>
                                    <label htmlFor="rvValueLessThan" className="surveylinelessthanlabel">and</label>
                                    <input type='number' onBlur={handleBlur} className="advsurveylinenumberinput lessthan" name='rvValueLessThan' value={searchData.rvValueLessThan} step='100' onChange={handleChange} />
                                </>
                            }
                        </div>
                    </div>
                    </div>
                    <div className="advancedsurveyminiheader">
                    <label htmlFor="schemeReference">Scheme Reference</label>
                    <input
                        className="advsurveylineinput"
                        type="text"
                        id="schemeReference"
                        name="schemeReference"
                        value={searchData.schemeReference}
                        onChange={handleChange}
                        />
                </div>
                </div>
                </div>
            }
        </>
    )
}

