import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route } from 'react-router-dom';
import { authenticated } from '../Redux/AuthorisationSlicer';

interface PrivateRouteProps {
    children: JSX.Element;
  }
  
  const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
    
  const  isAuthenticated  = useSelector(authenticated);
    return isAuthenticated ? children : <Navigate to="/login" />;
  };
  
  export default PrivateRoute;