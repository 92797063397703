import IntegratedSettlement from "../IntegratedSettlement";

export default class IntegratedListSettlementApiModel {
    searchSettlement?: boolean;
    listYear?: number;
    settlementType?: string[];
    constructor(data:Partial<IntegratedSettlement>)
    {
        this.searchSettlement = data.searchSettlement;
        this.listYear = data.listYear;
        this.settlementType = data.settlementType;
    }
}
