import { useEffect, useState } from "react";
import { SurveyLinesSurvey } from "../../Models/SurveyLinesSurvey";
import store from "../../Redux/ConfigureStore";
import { cleanup } from "@testing-library/react";
import { setSurveyLinesSurveySearch } from "../../Redux/SearchSlicer";
import { FloorHasLogic } from "../../Models/Enums/FloorHasLogic";
import { SearchLogic } from "../../Models/Enums/SearchLogic";
import { MeasurementBasis } from "../../Models/Enums/MeasurementBasis";
import { Checkbox } from "@mui/material";

export default function SurveyLineSearchComponent(props) {
    const [searchData, setSearchData] = useState<SurveyLinesSurvey | null>(null);
    const { searchCount } = props;
    useEffect(() => {

        setSearchData(new SurveyLinesSurvey(store.getState().search.surveyLinesSurveySearch));
        return () => {
            cleanup();
        };
    }, []);
    useEffect(() => {
        if (searchCount < 1) {
            setSearchData(new SurveyLinesSurvey(store.getState().search.surveyLinesSurveySearch));
        }
        return () => {
            cleanup();
        };
    }, [searchCount]);
    const handleCheckBoxChange = (e) => {
        const { checked, name } = e.target;
        const updatedSearchData = { ...searchData, [name]: checked };
        setSearchData(new SurveyLinesSurvey(updatedSearchData));
    }

    const handleChange = async (e) => {
        const { name, value } = e.target;
        const updatedSearchData = { ...searchData, [name]: value };
        // switch (name){
        //     case 'floorSearch':
        //         if(value==''){

        //         }
        //         break
        // }
        setSearchData(new SurveyLinesSurvey(updatedSearchData));
    };
    const handleDoubleClick = async (event, field) => {
        if (searchData) {
            const { name } = event.target;
            var value = searchData[field];
            value = value == 0 ? 1 : 0;
            const updatedSearchData = { ...searchData, [field]: value };
            setSearchData(new SurveyLinesSurvey(updatedSearchData));
        }
    };
    useEffect(() => {
        sendSearchData();
    }, [searchData]);
    const handleBlur = (e) => {
        const { value, name } = e.target;
        if (value.trim() === '') {
            const updatedSearchData = { ...searchData, [name]: 0 };
            setSearchData(new SurveyLinesSurvey(updatedSearchData));
        }
    }
    const sendSearchData = () => {
        if (searchData) {
            store.dispatch(setSurveyLinesSurveySearch(searchData.serialize()));

        }
    }
    return (
        <>{searchData &&
            <div className="searchcomponentcategory">
                <div className="surveylinecategory">

                    <label htmlFor="floorSearch">Floor {searchData.floorLogic == 0 ? 'Starts With' : 'Contains'}

                    </label>
                    <input
                        className= {"surveysearchtextinput" + (searchData.floorLogic == 0 ? "" : " contains")}
                        type="text"
                        id="floorSearch"
                        name="floorSearch"
                        value={searchData.floorSearch}
                        onChange={handleChange}
                        onDoubleClick={(event) => handleDoubleClick(event, 'floorLogic')}
                    />
                    <div className="minicomponent">
                        <div className="trendminiheader layoutrow">

                            <Checkbox color="secondary" sx={{ color: '#009FE3', p: 0 }} name='floorSpecifics' disabled={searchData.floorSearch == ''} checked={searchData.floorSpecifics} onChange={handleCheckBoxChange} />
                            <div className="integratedlistlabel">Additional floor specifics:</div>

                        </div>
                        <div className="surveylinecontainer">
                            <div className="surveylinetext">Has... </div>
                            <select id="dropdown" className='surveylineselect' name='floorHas' disabled={!searchData.floorSpecifics} value={searchData.floorHas} onChange={handleChange}>
                                {Object.values(FloorHasLogic).filter(key => isNaN(Number(FloorHasLogic[key]))).map((value) => (
                                    <option key={value} value={value}>
                                        {FloorHasLogic[value]}
                                    </option>
                                ))}
                            </select>
                            <select id="dropdown" className='surveylineselect' name='floorHasLogic' disabled={!searchData.floorSpecifics} value={searchData.floorHasLogic} onChange={handleChange}>
                                {Object.values(SearchLogic).filter(key => isNaN(Number(SearchLogic[key]))).map((value) => (
                                    <option key={value} value={value}>
                                        {SearchLogic[value]}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="surveylinevalues">
                        <input type='number' onBlur={handleBlur} className={searchData.floorHasLogic == 3 ? "surveylinenumberinput shrink" : "surveylinenumberinput"} name='floorHasValue' value={searchData.floorHasValue} disabled={!searchData.floorSpecifics} step='100' onChange={handleChange} />
                        {searchData.floorHasLogic == 3 &&
                            <>
                                <label htmlFor="floorValueLessThan" className="surveylinelessthanlabel">and</label>
                                <input type='number' onBlur={handleBlur} className="surveylinenumberinput lessthan" name='floorValueLessThan' value={searchData.floorValueLessThan} disabled={!searchData.floorSpecifics} step='100' onChange={handleChange} />
                            </>
                        }
                    </div>
                </div>
                <div className="surveylinecategory">
                    <label htmlFor="surveyLineSearch">
                        <div className="layoutrow">
                            <div>Survey Line Item {searchData.surveyLineLogic == 0 ? 'Starts With' : 'Contains'}</div>
                            <div className="layoutrow surveylinelinkfloor">
                                <Checkbox color="secondary" sx={{ color: '#009FE3', p: 0 }} name='linkFloors' disabled={searchData.surveyLineSearch == ''} checked={searchData.linkFloors} onChange={handleCheckBoxChange} />
                                <div className="surveylinelinkfloorlabel">Link Floors</div>
                            </div>
                        </div>
                    </label>
                    <input
                        className={"surveysearchtextinput" + (searchData.surveyLineLogic == 0 ? "" : " contains")}
                        type="text"
                        id="surveyLineSearch"
                        name="surveyLineSearch"
                        value={searchData.surveyLineSearch}
                        onChange={handleChange}
                        onDoubleClick={(event) => handleDoubleClick(event, 'surveyLineLogic')}
                    />
                    <div className="minicomponent">
                        <div className="trendminiheader layoutrow">
                            <Checkbox color="secondary" sx={{ color: '#009FE3', p: 0 }} name='surveySpecifics' disabled={searchData.surveyLineSearch == ''} checked={searchData.surveySpecifics} onChange={handleCheckBoxChange} />
                            <div className="integratedlistlabel">Additional Survey specifics:</div>

                        </div>
                        <div className="surveylinecontainer">
                            <div className="surveylinetext">Has... </div>
                            <select id="dropdown" className='surveylineselect' name='surveyHas' disabled={!searchData.surveySpecifics} value={searchData.surveyHas} onChange={handleChange}>
                                {Object.values(FloorHasLogic).filter(key => isNaN(Number(FloorHasLogic[key]))).map((value) => (
                                    <option key={value} value={value}>
                                        {FloorHasLogic[value]}
                                    </option>
                                ))}
                            </select>
                            <select id="dropdown" className='surveylineselect' name='surveyLogic' disabled={!searchData.surveySpecifics} value={searchData.surveyLogic} onChange={handleChange}>
                                {Object.values(SearchLogic).filter(key => isNaN(Number(SearchLogic[key]))).map((value) => (
                                    <option key={value} value={value}>
                                        {SearchLogic[value]}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div className="surveylinevalues">
                            <input type='number' onBlur={handleBlur} className={searchData.surveyLogic == 3 ? "surveylinenumberinput shrink" : "surveylinenumberinput"} name='surveyHasValue' value={searchData.surveyHasValue} disabled={!searchData.surveySpecifics} step='100' onChange={handleChange} />
                            {searchData.surveyLogic == 3 &&
                                <>
                                    <label htmlFor="surveyHasValueLessThan" className="surveylinelessthanlabel">and</label>
                                    <input type='number' onBlur={handleBlur} className="surveylinenumberinput lessthan" name='surveyHasValueLessThan' value={searchData.surveyHasValueLessThan} disabled={!searchData.surveySpecifics} step='100' onChange={handleChange} />
                                </>
                            }
                        </div>
                    </div>
                </div>
                <div className="surveylinecategory">
                    <label htmlFor="additionalItemSearch">Additional Items {searchData.additionalItemsLogic == 0 ? 'Starts With' : 'Contains'}  </label>
                    <input
                        className={"surveysearchtextinput" + (searchData.additionalItemsLogic == 0 ? "" : " contains")}
                        type="text"
                        id="additionalItemSearch"
                        name="additionalItemSearch"
                        value={searchData.additionalItemSearch}
                        onChange={handleChange}
                        onDoubleClick={(event) => handleDoubleClick(event, 'additionalItemsLogic')}
                    />
                    <div className="minicomponent">
                        <div className="trendminiheader layoutrow">

                            <Checkbox color="secondary" sx={{ color: '#009FE3', p: 0 }} name='additionalSpecifics' disabled={searchData.additionalItemSearch == ''} checked={searchData.additionalSpecifics} onChange={handleCheckBoxChange} />
                            <div className="integratedlistlabel">Additional Item specifics:</div>
                        </div>
                        <div className="surveylinecontainer">
                            <div className="surveylinetext">Has... </div>
                            <select id="dropdown" className='surveylineselect' name='additionalHas' disabled={!searchData.additionalSpecifics} value={searchData.additionalHas} onChange={handleChange}>
                                {Object.values(FloorHasLogic).filter(key => isNaN(Number(FloorHasLogic[key]))).map((value) => (
                                    <option key={value} value={value}>
                                        {FloorHasLogic[value]}
                                    </option>
                                ))}
                            </select>
                            <select id="dropdown" className='surveylineselect' name='additionalHasLogic' disabled={!searchData.additionalSpecifics} value={searchData.additionalHasLogic} onChange={handleChange}>
                                {Object.values(SearchLogic).filter(key => isNaN(Number(SearchLogic[key]))).map((value) => (
                                    <option key={value} value={value}>
                                        {SearchLogic[value]}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="surveylinevalues">
                        <input type='number' onBlur={handleBlur} className={searchData.additionalHasLogic == 3 ? "surveylinenumberinput shrink" : "surveylinenumberinput"} name='additionalHasValue' value={searchData.additionalHasValue} disabled={!searchData.additionalSpecifics} step='100' onChange={handleChange} />
                        {searchData.additionalHasLogic == 3 &&
                            <>
                                <label htmlFor="additionalHasValueLessThan" className="surveylinelessthanlabel">and</label>
                                <input type='number' onBlur={handleBlur} className="surveylinenumberinput lessthan" name='additionalHasValueLessThan' value={searchData.additionalHasValueLessThan} disabled={!searchData.additionalSpecifics} step='100' onChange={handleChange} />
                            </>
                        }
                    </div>
                </div>

                <div className="surveylinecategory">
                    <label htmlFor="surveyLineSearch">Adjustment {searchData.adjustmentsLogic == 0 ? 'Starts With' : 'Contains'}
                    </label>
                    <input
                        className={"surveysearchtextinput" + (searchData.adjustmentsLogic == 0 ? "" : " contains")}
                        type="text"
                        id="adjustmentsSearch"
                        name="adjustmentsSearch"
                        value={searchData.adjustmentsSearch}
                        onChange={handleChange}
                        onDoubleClick={(event) => handleDoubleClick(event, 'adjustmentsLogic')}
                    />
                <div className="minicomponent">
                    <div className="trendminiheader layoutrow">
                        <Checkbox color="secondary" sx={{ color: '#009FE3', p: 0 }} name='adjustmentsPercentage' disabled={searchData.adjustmentsSearch == ''} checked={searchData.adjustmentsPercentage} onChange={handleCheckBoxChange} />
                        <div className="integratedlistlabel">Adjustments Percentage:</div>
                    </div>
                    <div className="layoutrow">
                        <div className="surveylinetextaddtional">is </div>
                        <select id="dropdown" className='surveylineselectadditional' name='adjustmentsPercentageLogic' disabled={!searchData.adjustmentsPercentage} value={searchData.adjustmentsPercentageLogic} onChange={handleChange}>
                            {Object.values(SearchLogic).filter(key => isNaN(Number(SearchLogic[key]))).map((value) => (
                                <option key={value} value={value}>
                                    {SearchLogic[value]}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="surveylinevalues">
                        <input type='number' onBlur={handleBlur} className={searchData.adjustmentsPercentageLogic == 3 ? "surveylinenumberinput shrink" : "surveylinenumberinput"} name='adjustmentsPercentageValue' value={searchData.adjustmentsPercentageValue} disabled={!searchData.adjustmentsPercentage} step='100' onChange={handleChange} />
                        {searchData.adjustmentsPercentageLogic == 3 &&
                            <>
                                <label htmlFor="surveyHasValueLessThan" className="surveylinelessthanlabel">and</label>
                                <input type='number' onBlur={handleBlur} className="surveylinenumberinput lessthan" name='adjustmentsPercentageLessThan' value={searchData.adjustmentsPercentageLessThan} disabled={!searchData.adjustmentsPercentage} step='100' onChange={handleChange} />
                            </>
                        }
                    </div>
                </div>
                </div>
                <div className="surveylinecategory measurement">
                    <div className="measurementlabel">Measurement basis</div>
                    <select id="dropdown" className='surveylineselectmeasurement' name='measurementBasis' value={searchData.measurementBasis} onChange={handleChange}>
                        {Object.values(MeasurementBasis).filter(key => isNaN(Number(MeasurementBasis[key]))).map((value) => (
                            <option key={value} value={value}>
                                {MeasurementBasis[value]}
                            </option>
                        ))}
                    </select>
                </div></div>
        }
            </>
    )
}