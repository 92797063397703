import { FloorHasLogic } from "./Enums/FloorHasLogic";
import { MeasurementBasis } from "./Enums/MeasurementBasis";
import { SearchLogic } from "./Enums/SearchLogic";
import { StartsWithContains } from "./Enums/StartsWithContains";

export class SurveyLinesSurvey {
    // FLOOR BLOCK
    floorLogic?: StartsWithContains;
    floorSearch?: string;
    floorSpecifics?: boolean;
    floorHas?: FloorHasLogic;
    floorHasLogic?: SearchLogic;
    floorHasValue?: number;
    floorValueLessThan?: number;

    // SURVEY LINE BLOCK
    surveyLineLogic?: StartsWithContains;
    surveyLineSearch?: string;
    linkFloors?: boolean;
    surveySpecifics?: boolean;
    surveyHas?: FloorHasLogic;
    surveyLogic?: SearchLogic;
    surveyHasValue?: number;
    surveyHasValueLessThan?: number;

    // ADDITIONAL ITEMS BLOCK
    additionalItemsLogic?: StartsWithContains;
    additionalItemSearch?: string;
    additionalSpecifics?: boolean;
    additionalHas?: FloorHasLogic;
    additionalHasLogic?: SearchLogic;
    additionalHasValue?: number;
    additionalHasValueLessThan?: number;

    // ADJUSTMENT ITEMS BLOCK
    adjustmentsLogic?: StartsWithContains;
    adjustmentsSearch?: string;
    adjustmentsPercentage?: boolean;
    adjustmentsPercentageLogic?: SearchLogic;
    adjustmentsPercentageValue?: number;
    adjustmentsPercentageLessThan?: number;

    // OTHER
    measurementBasis?: MeasurementBasis;

    
    constructor(data?: Partial<SurveyLinesSurvey>) {
        Object.assign(this, {
            floorLogic: 0,
            floorSearch: '',
            floorSpecifics: false,
            floorHas: 0,
            floorHasLogic: 0,
            floorHasValue: 0,
            floorValueLessThan: 0,
            surveyLineLogic: 0,
            surveyLineSearch: '',
            linkFloors: false,
            surveySpecifics: false,
            surveyHas: 0,
            surveyLogic: 0,
            surveyHasValue: 0,
            surveyHasValueLessThan: 0,
            additionalItemsLogic: 0,
            additionalItemSearch: '',
            additionalSpecifics: false,
            additionalHas: 0,
            additionalHasLogic: 0,
            additionalHasValue: 0,
            additionalHasValueLessThan: 0,
            adjustmentsLogic: 0,
            adjustmentsSearch: '',
            adjustmentsPercentage: false,
            adjustmentsPercentageLogic: 0,
            adjustmentsPercentageValue: 0,
            adjustmentsPercentageLessThan: 0,
            measurementBasis: 0,
            ...data,
        });
    }
    serialize(): Partial<SurveyLinesSurvey> {
        return {
            floorLogic: this.floorLogic,
            floorSearch: this.floorSearch,
            floorSpecifics: this.floorSpecifics,
            floorHas: this.floorHas,
            floorHasLogic: this.floorHasLogic,
            floorHasValue: this.floorHasValue,
            floorValueLessThan: this.floorValueLessThan,
            surveyLineLogic: this.surveyLineLogic,
            surveyLineSearch: this.surveyLineSearch,
            linkFloors: this.linkFloors,
            surveySpecifics: this.surveySpecifics,
            surveyHas: this.surveyHas,
            surveyLogic: this.surveyLogic,
            surveyHasValue: this.surveyHasValue,
            surveyHasValueLessThan: this.surveyHasValueLessThan,
            additionalItemsLogic: this.additionalItemsLogic,
            additionalItemSearch: this.additionalItemSearch,
            additionalSpecifics: this.additionalSpecifics,
            additionalHas: this.additionalHas,
            additionalHasLogic: this.additionalHasLogic,
            additionalHasValue: this.additionalHasValue,
            additionalHasValueLessThan: this.additionalHasValueLessThan,
            adjustmentsLogic: this.adjustmentsLogic,
            adjustmentsSearch: this.adjustmentsSearch,
            adjustmentsPercentage: this.adjustmentsPercentage,
            adjustmentsPercentageLogic: this.adjustmentsPercentageLogic,
            adjustmentsPercentageValue: this.adjustmentsPercentageValue,
            adjustmentsPercentageLessThan: this.adjustmentsPercentageLessThan,
            measurementBasis: this.measurementBasis
        };
    }
}