import { useEffect, useState } from "react";
import IntegratedListAdvancedSearch from "../../Models/IntegratedListAdvancedSearch";
import { cleanup } from "@testing-library/react";
import store from "../../Redux/ConfigureStore";
import { SearchLogic } from "../../Models/Enums/SearchLogic";
import { DateSearchLogic } from "../../Models/Enums/DateSearchLogic";
import { DatePicker, DateRange, DesktopDatePicker, DesktopDateRangePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import AdapterDayjs from '@mui/x-date-pickers/AdapterDayjs';
import { Checkbox, ClickAwayListener, colors, FormControlLabel, Radio, TextField } from "@mui/material";
import { setIntegratedListAdvancedSearch } from "../../Redux/SearchSlicer";
import { useSelector } from "react-redux";
import { selectListDropDown } from "../../Redux/PreloadedSlicer";
import { BorderVerticalOutlined } from "@mui/icons-material";

export default function IntegratedListAdvComponent(props) {
    const [searchData, setSearchData] = useState<IntegratedListAdvancedSearch | null>(null);
    const { searchCount } = props;
    const [edDateRange, setEDDateRange] = useState<DateRange<Dayjs>>([null, null]);

    const [ladDateRange, setLADDateRange] = useState<DateRange<Dayjs>>([null, null]);
    const [isEDOpen, setIsEDOpen] = useState(false);

    const [isLADOpen, setIsLADOpen] = useState(false);
    const listDropDown = useSelector(selectListDropDown);
    useEffect(() => {
        var search = store.getState().search.integratedListAdvancedSearch
        setSearchData(new IntegratedListAdvancedSearch(search));
        var startEd = search.edValue ? dayjs(search.edValue) : null;
        var endEd = search.edValueLessThan ? dayjs(search.edValueLessThan) : null;
        var startLad = search.ladValue ? dayjs(search.ladValue) : null;
        var endLad = search.ladValueLessThan ? dayjs(search.ladValueLessThan) : null;
        setEDDateRange([startEd, endEd])
        setLADDateRange([startLad, endLad])
        return () => {
            cleanup();
        };
    }, []);
    useEffect(() => {
        if (searchCount < 1) {
            setSearchData(new IntegratedListAdvancedSearch(store.getState().search.integratedListAdvancedSearch));
        }
        return () => {
            cleanup();
        };
    }, [searchCount]);
    const handleDateRangeChange = (value, name) => {
        if (searchData) {
            const updatedSearchData = searchData;
            if (value[0]) {
                updatedSearchData[name + 'Value'] = value[0].toJSON()
            }
            if (value[1]) {
                updatedSearchData[name + 'ValueLessThan'] = value[1].toJSON()
            }
            setSearchData(new IntegratedListAdvancedSearch(updatedSearchData));
            if (name == 'ed') {
                setEDDateRange(value)
            }
            if (name == 'lad') {
                setLADDateRange(value)

            }
        }

    }
    const handleDateChange = (value, name) => {
        if (searchData) {
            const updatedSearchData = searchData;
            if (value) {
                updatedSearchData[name + 'Value'] = value.toJSON()
            }
            var newDateRange: DateRange<Dayjs> = [value, null]
            setSearchData(new IntegratedListAdvancedSearch(updatedSearchData));
            if (name == 'ed') {
                setEDDateRange(newDateRange)
                setIsEDOpen(false)

            }
            if (name == 'lad') {
                setLADDateRange(newDateRange)
                setIsLADOpen(false)
            }
        }

    }
    const handleChange = async (e) => {
        const { name, value} = e.target;
        const updatedSearchData = { ...searchData, [name]: value };
        setSearchData(new IntegratedListAdvancedSearch(updatedSearchData));
    };
    const handleNumberChange = async(e)=>{
        const { name, value} = e.target;
        const updatedSearchData = { ...searchData, [name]: Number(value) };
        setSearchData(new IntegratedListAdvancedSearch(updatedSearchData));
    }
    const handleCheckBoxChange = (e) => {
        const { checked, name } = e.target;
        const updatedSearchData = { ...searchData, [name]: checked };
        setSearchData(new IntegratedListAdvancedSearch(updatedSearchData));
    }
    const handleRadioChange = (e) => {
        const { name, value } = e.target;

        const radiovalue = value == 'true' ? true : false;
        const updatedSearchData = { ...searchData, [name]: radiovalue };
        setSearchData(new IntegratedListAdvancedSearch(updatedSearchData));
    }

    useEffect(() => {
        sendSearchData();
    }, [searchData]);
    
    const sendSearchData = () => {
        
        if (searchData) {
            store.dispatch(setIntegratedListAdvancedSearch(searchData.serialize()));

        }
    }
    return (
        <>
            {searchData &&
                <div className="searchcomponentcategory">
                    <div className="miniheader">
                        <div className="layoutrow">
                            <Checkbox color="secondary" sx={{color: '#009FE3',p:0}} name='rvSearch' checked={searchData.rvSearch} onChange={handleCheckBoxChange} />
                            <div className="integratedlistlabel">Rateable Value</div>
                        </div>
                        <div className="trendminicontent">
                            <div className="surveylineselectcontainer">
                                <select id="dropdown" className='settlementselect' name='rvList' disabled={!searchData.rvSearch} value={searchData.rvList} onChange={handleChange}>

                                    {listDropDown.length != 0 && listDropDown.map((value) => (
                                        <option key={value} value={value}>
                                            {value}
                                        </option>
                                    ))}
                                </select>
                                <div className="advancedsurveytextleft">List</div>
                                <div className="advancedsurveytextright">RV  </div>
                                <select id="dropdown" className='integratedlistselecta' name='rvLogic' disabled={!searchData.rvSearch} value={searchData.rvLogic} onChange={handleChange}>
                                    {Object.values(SearchLogic).filter(key => isNaN(Number(SearchLogic[key]))).map((value) => (
                                        <option key={value} value={value}>
                                            {SearchLogic[value]}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>
                        <div className="surveylinevalues">
                            <input type='number' className={searchData.rvLogic == 3 ? "intadvnumberinput shrink" : "intadvnumberinput"} name='rvValue' value={searchData.rvValue} disabled={!searchData.rvSearch} step='100' onChange={handleNumberChange} />
                            {searchData.rvLogic == 3 &&
                                <>
                                    <label htmlFor="rvValueLessThan" className="intadvlessthanlabel">and</label>
                                    <input type='number' className="intadvnumberinput lessthan" name='rvValueLessThan' value={searchData.rvValueLessThan} step='100'  disabled={!searchData.rvSearch} onChange={handleNumberChange} />
                                </>
                            }
                        </div>

                    </div>
                    <div className="miniheader">
                        <div className="layoutrow">
                            <Checkbox name='edSearch' checked={searchData.edSearch} onChange={handleCheckBoxChange} color="secondary"  sx={{color: '#009FE3',p:0}} />
                            <div className="integratedlistlabel">Effective Date</div>
                        </div>
                        <div className="trendminicontent">
                            <div className="surveylineselectcontainer">
                                <select id="dropdown" className='settlementselect' name='edList' disabled={!searchData.edSearch} value={searchData.edList} onChange={handleChange}>

                                    {listDropDown.length != 0 && listDropDown.map((value) => (
                                        <option key={value} value={value}>
                                            {value}
                                        </option>
                                    ))}
                                </select>
                                <div className="advancedsurveytextleft">List</div>
                                <div className="advancedsurveytextrightb">Eff Date</div>
                                <select id="dropdown" className='integratedlistselectb' name='edLogic' disabled={!searchData.edSearch} value={searchData.edLogic} onChange={handleChange}>
                                    {Object.values(DateSearchLogic).filter(key => isNaN(Number(DateSearchLogic[key]))).map((value) => (
                                        <option key={value} value={value}>
                                            {DateSearchLogic[value]}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>
                        <div className="surveylinevalues">
                            <div className="adv2datepickers">

                                {searchData.edLogic == 4 &&
                                    <>
                                        <DesktopDateRangePicker
                                            value={edDateRange}
                                            onChange={(value) => handleDateRangeChange(value, "ed")}
                                            calendars={1}
                                            key='edrange'
                                            disabled={!searchData.edSearch}
                                            sx={{padding:0,borderRadius:0,borderColor: '#009FE3'}}
                                            slotProps={{
                                                field: { dateSeparator: 'and',sx:{fontSize:'12px'} },
                                                textField: {
                                                    label:false,
                                                    sx: {
                                                        '& fieldset': {
                                                            padding: 0,
                                                            height: 25,
                                                            borderColor:'#009FE3',

                                                        },
                                                        '& .MuiInputBase-root': {
                                                            padding: 0, 
                                                            borderColor:'#009FE3',
                                                            borderRadius:'0px',
                                                            '& .MuiInputBase-input': {
                                                                width: 300,
                                                                fontSize: 12,
                                                                height: 15,
                                                                borderRadius:'0px',
                                                                lineHeight:20,
                                                                padding: '5px',
                                                            },
                                                        },
                                                    },
                                                    style: {
                                                        padding:'0px',
                                                        height: '30px',
                                                        borderRadius:0,
                                                        lineHeight:'20px'
                                                    },
                                                    className: "hide-adornment ",
                                                },
                                            }}
                                        />
                                    </>
                                }
                                {searchData.edLogic != 4 &&
                                    <ClickAwayListener onClickAway={() => setIsEDOpen(false)}>
                                        <DesktopDatePicker
                                            value={edDateRange[0]}
                                            onChange={(value) => handleDateChange(value, "ed")}
                                            open={isEDOpen}
                                            disabled={!searchData.edSearch}
                                            key='ed'
                                            slotProps={{
                                                textField: {
                                                    sx: {
                                                        '& fieldset': {
                                                            padding: 0,
                                                            height: 25,
                                                            borderColor:'#009FE3',

                                                        },
                                                        '& .MuiInputBase-root': {
                                                            padding: 0, 
                                                            borderColor:'#009FE3',
                                                            borderRadius:'0px',
                                                            '& .MuiInputBase-input': {
                                                                width: 300,
                                                                fontSize: 12,
                                                                height: 15,
                                                                borderRadius:'0px',
                                                                lineHeight:20,
                                                                padding: '5px',
                                                            },
                                                        },
                                                    },
                                                    style: {
                                                        padding:'0px',
                                                        height: '30px',
                                                        borderRadius:0,
                                                        lineHeight:'20px'
                                                    },
                                                    className: "hide-adornment ",
                                                    onClick: () => setIsEDOpen(true)
                                                },
                                                
                                            }}
                                        />
                                    </ClickAwayListener>

                                }
                            </div>
                        </div>

                    </div>
                    <div className="miniheader">
                        <div className="layoutrow">
                            <Checkbox name='ladSearch' checked={searchData.ladSearch} onChange={handleCheckBoxChange} color="secondary"  sx={{color: '#009FE3',p:0, }} />
                            <div className="integratedlistlabel">List Alteration Date</div>
                        </div>
                        <div className="trendminicontent">
                            <div className="surveylineselectcontainer">
                                <select id="dropdown" className='settlementselect' name='ladList' disabled={!searchData.ladSearch} value={searchData.ladList} onChange={handleChange}>

                                    {listDropDown.length != 0 && listDropDown.map((value) => (
                                        <option key={value} value={value}>
                                            {value}
                                        </option>
                                    ))}
                                </select>
                                <div className="advancedsurveytextleft">List</div>
                                <div className="advancedsurveytextrightb">Alt Date</div>
                                <select id="dropdown" className='integratedlistselectb' name='ladLogic' disabled={!searchData.ladSearch} value={searchData.ladLogic} onChange={handleChange}>
                                    {Object.values(DateSearchLogic).filter(key => isNaN(Number(DateSearchLogic[key]))).map((value) => (
                                        <option key={value} value={value}>
                                            {DateSearchLogic[value]}
                                        </option>
                                    ))}
                                </select>
                            </div>

                        </div>
                        <div className="surveylinevalues">
                            <div className="adv2datepickers">

                                {searchData.ladLogic == 4 &&
                                    <>
                                        <DesktopDateRangePicker
                                            disabled={!searchData.ladSearch}
                                            value={ladDateRange}
                                            onChange={(value) => handleDateRangeChange(value, "lad")}
                                            calendars={1}
                                            key='ladrange'

                                            slotProps={{
                                                field: { dateSeparator: 'and',sx:{fontSize:'12px'} },
                                                textField: {
                                                    label:false,
                                                    sx: {
                                                        '& fieldset': {
                                                            padding: 0,
                                                            height: 25,
                                                            borderColor:'#009FE3',

                                                        },
                                                        '& .MuiInputBase-root': {
                                                            padding: 0, 
                                                            borderColor:'#009FE3',
                                                            borderRadius:'0px',
                                                            '& .MuiInputBase-input': {
                                                                width: 300,
                                                                fontSize: 12,
                                                                height: 15,
                                                                borderRadius:'0px',
                                                                lineHeight:20,
                                                                padding: '5px',
                                                            },
                                                        },
                                                    },
                                                    style: {
                                                        padding:'0px',
                                                        height: '30px',
                                                        borderRadius:0,
                                                        lineHeight:'20px'
                                                    },
                                                    className: "hide-adornment ",
                                                },
                                            }}
                                        />
                                    </>
                                }
                                {searchData.ladLogic != 4 &&
                                    <ClickAwayListener onClickAway={() => setIsLADOpen(false)}>
                                        <DesktopDatePicker
                                            value={ladDateRange[0]}
                                            onChange={(value) => handleDateChange(value, "lad")}
                                            open={isLADOpen}
                                            disabled={!searchData.ladSearch}
                                            key='lad'
                                            
                                            slotProps={{
                                                textField: {
                                                    sx: {
                                                        '& fieldset': {
                                                            padding: 0,
                                                            height: 25,
                                                            borderColor:'#009FE3',

                                                        },
                                                        '& .MuiInputBase-root': {
                                                            padding: 0, 
                                                            borderColor:'#009FE3',
                                                            borderRadius:'0px',
                                                            '& .MuiInputBase-input': {
                                                                width: 300,
                                                                fontSize: 12,
                                                                height: 15,
                                                                borderRadius:'0px',
                                                                lineHeight:25,
                                                                padding: '5px',
                                                            },
                                                        },
                                                    },
                                                    style: {
                                                        padding:'0px',
                                                        height: '25px',
                                                        borderRadius:0,
                                                        lineHeight:'20px'
                                                    },
                                                    className: "hide-adornment ",
                                                    onClick: () => setIsLADOpen(true),
                                                },

                                            }}
                                        />
                                    </ClickAwayListener>

                                }
                            </div>
                        </div>

                    </div>
                    <div className="miniheader">
                        <div className="partdomesticsection">
                            <div className="partdomesticlabel">
                                Part Domestic
                            </div>
                            <div className="layoutrow">
                            <Checkbox name='partDomesticSearch'  checked={searchData.partDomesticSearch}  color="secondary" sx={{color: '#009FE3',p:0}} onChange={handleCheckBoxChange} />  
                            <div className="partdomesticchecklabel">Active</div>
                            <div className="radiogroup">

                                <FormControlLabel value="best" control={
                                    <Radio
                                        checked={searchData.partDomestic === false}
                                        onChange={handleRadioChange}
                                        value={false}
                                        name="partDomestic"
                                        size="small"
                                        disabled={!searchData.partDomesticSearch}
                                        inputProps={{ 'aria-label': 'No' }}
                                    />}
                                    label="No" />

                                <FormControlLabel value="best" control={
                                    <Radio
                                        checked={searchData.partDomestic === true}
                                        onChange={handleRadioChange}
                                        value={true}
                                        name="partDomestic"
                                        size="small"
                                        disabled={!searchData.partDomesticSearch}
                                        inputProps={{ 'aria-label': 'Yes' }}
                                    />}
                                    label="Yes" />
                            </div>
                            </div>
                            <div className="trendminicontent">
                                <div className="surveylineselectcontainer">

                                    <div className="intadvtext">From </div>
                                    <select id="dropdown" className='settlementselect' name='partDomesticList' disabled={!searchData.partDomesticSearch} value={searchData.partDomesticList} onChange={handleChange}>

                                        {listDropDown.length != 0 && listDropDown.map((value) => (
                                            <option key={value} value={value}>
                                                {value}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="trendminicomponent">
                        <div className="miniheader layoutrow">
                            <Checkbox name='listStatusSearch'sx={{color: '#009FE3',p:0}}checked={searchData.listStatusSearch}  color="secondary" onChange={handleCheckBoxChange} />
                            <div className="integratedlistlabel"> List Status</div>
                        </div>
                        <div className='trendminicontent'>
                            <div className="text">Hereditaments are </div>
                            <select id="dropdown" className='heredimentsselectintadv' name='listStatus' disabled={!searchData.listStatusSearch} value={searchData.listStatus ? searchData.listStatus.toString() : 'false'} onChange={handleChange}>

                                <option key={'true'} value={'true'}>
                                    Live
                                </option>
                                <option key={'false'} value={'false'}>
                                    Deleted
                                </option>
                            </select>
                            <div className="liststatusintext">in</div>
                            <select id="dropdown" className='settlementselect' name='listStatusList' disabled={!searchData.listStatusSearch} value={searchData.listStatusList} onChange={handleChange}>

                                {listDropDown.length != 0 && listDropDown.map((value) => (
                                    <option key={value} value={value}>
                                        {value}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}