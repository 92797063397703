import { combineReducers } from 'redux';
import searchSlice, { resetSearch } from './SearchSlicer'
import preloadedSlice, { resetPreload } from './PreloadedSlicer'
import listBuilderSlicer, { resetListBuilder } from './ListBuilderSlicer';
import authorisationSlice, { logout } from './AuthorisationSlicer';
import { LOGOUT } from './Actions';


const appReducer  = combineReducers({
    search: searchSlice,
    preloadedData: preloadedSlice,
    listBuilder:listBuilderSlicer,
    authorisation:authorisationSlice
  });
  const rootReducer = (state, action) => {
    if (action.type === LOGOUT) {
        const authState = authorisationSlice(state.authorisation, logout());
        const listState = listBuilderSlicer(state.listBuilder, resetListBuilder());
        const preloadState = preloadedSlice(state.preloadedData, resetPreload());
        const searchState = searchSlice(state.search,resetSearch())
        return {
            ...state,
            search: searchState,
            preloadedData: preloadState,
            listBuilder: listState,
            authorisation:authState
        };
    }
    return appReducer(state, action);
};

export default rootReducer;