import { useState } from "react"
import RVHistoryTable from "../RVHistoryTable"
import SummaryValuationPopover from "./SummaryValuationPopover"
import DirectoryPopup from "./DirectoryPopup"
import BAScheduleTable from "../BAScheduleTable"
import VTS from "../../../Models/SearchApiModels/VTSModel"
import VTSTable from "../VTSTable"
const tabs=[
    'RV History','BA Schedule', 'Programming VTS','Summary Valuation','Directory'

]

const tabsSVMode=[
    'Summary Valuation','BA Schedule', 'Programming VTS','RV History','Directory'

]
export default function DetailsPopover(props){
    const {uarn, keyID,listYear,routeTabContext} = props
    const [tabContext,setTabContext]=useState(0)
    const handleTabClick=(index)=>{
    
        setTabContext(index);
    }
    

    return(
    <>
    <div className="detailstabsrow">
        {
            routeTabContext == 1?
            tabsSVMode.map(( item,index)=>(<div key={index} className={index == tabContext?"popovertab selected": "popovertab"} onClick={()=>handleTabClick(index)}>{item}</div>))
            :
            tabs.map(( item,index)=>(<div key={index} className={index == tabContext?"popovertab selected": "popovertab"} onClick={()=>handleTabClick(index)}>{item}</div>))
        }
        
  
    </div>
    
    <div className="tabcontentcontainer">
        {
            
            
            tabContext == 0 && routeTabContext != 1 &&
            <RVHistoryTable uarn={uarn} />
        }    
        {
            tabContext == 0 && routeTabContext == 1 &&
            <SummaryValuationPopover uarn ={uarn} keyID = {keyID} listYear ={listYear} />
        }    
        {
            tabContext == 1&&
            <BAScheduleTable uarn ={uarn} />
        }
        {
            tabContext == 2&&
            <VTSTable uarn ={uarn} />
        }
        {
            
            tabContext == 3 && routeTabContext != 1 &&
            <SummaryValuationPopover uarn ={uarn} keyID = {keyID} listYear ={listYear} />
        }
        {
            tabContext == 3 && routeTabContext == 1 &&
            <RVHistoryTable uarn={uarn} />
        }
        {
            tabContext == 4&&
            <DirectoryPopup uarn= {uarn}/>
        }
    </div>
    </>)
    
}