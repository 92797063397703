import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import store from "../Redux/ConfigureStore";
import { cleanup } from "@testing-library/react";
import Select from "react-select";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js/auto'
import { Chart, Line, Pie } from 'react-chartjs-2'
import {selectListYear, setLoading} from '../Redux/SearchSlicer';

// import { DataGridPro, GridRowsProp, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../API/AnalyseAPIHelper';
import SVSurveyPieChart from '../Models/SVSurveyPieChart';
import { Key } from '@mui/icons-material';
// import { Select } from '@mui/material';


ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)


function PieChart(props) {
  const { uarn,componentchanged } = props
  const [rvHistoryData, setRVHistoryData] = useState<SVSurveyPieChart>()
  const [summaryFloorDropDownValue, SetSummaryFloorDropDown] = useState<Array<string>>([])
  const [selectedOption, setSelectedOption] = useState<string>("All Floors");
  const summaryListYear = useSelector(selectListYear);

const handleChange = async (e) => {
   
  const { name } = e.target;
  const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
  setSelectedOption(value);
  fetchData1(value);
 
};

const handleSelectChange = (e) => {
   
  const { value } = e.target;
  handleChange(e);
 
}




  useEffect(() => {
    fetchData();
  }, [componentchanged]);



  async function fetchData() {
    var data;
    
    store.dispatch(setLoading(true))
    try {
      data = await ApiHelper.GetSVSurveyPieChart(uarn, summaryListYear);
      setRVHistoryData(data);
      SetSummaryFloorDropDown(data.labels);
      //setSelectedOption("Select Value");
      
    }
    catch (err) {
      console.log(err)
    }
    finally{

      store.dispatch(setLoading(false))
    }

  }

  async function fetchData1(value) {
    var data1;
    
    store.dispatch(setLoading(true))

    try {
      if(value == "All Floors")
      {
        data1 = await ApiHelper.GetSVSurveyPieChart(uarn, summaryListYear);
      }
      else
      {
        data1 = await ApiHelper.GetDescriptionByFloor(uarn, summaryListYear, value);
      }
      
      setRVHistoryData(data1);
      
    }
    catch (err) {
      console.log(err)
    }
    finally{
      
      store.dispatch(setLoading(false))
    }
  }

  return (

    <div className='container' style={{height: "200px"}}>
      <div className="areasearchdiv">
        <div>
          Data Levels : Floor
       
        <select id="dropdown" className='svPieChartDropdown' name='scheduleToDate' value={selectedOption} onChange={handleSelectChange}>
          <option value="All Floors">All Floors</option>

          {summaryFloorDropDownValue.length != 0 && summaryFloorDropDownValue.map((value) => (
            <option key={value} value={value}>
              {value}

            </option>
          ))}
        </select>
        </div>
      </div>
      {rvHistoryData &&
        <Pie data={{
          labels: rvHistoryData.labels,
          datasets: [{
            label: 'Effective Date - Rateable Value',
            data: rvHistoryData.data,
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
          }]
        }} />
      }

    </div>
  );
};

export default PieChart;