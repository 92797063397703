export default class IntegratedListExcel {
    flaG_2023?: string;
    flaG_2017?: string;
    flaG_2010?: string;
    flaG_2005?: string;
    flaG_2000?: string;
    flaG_1995?: string;
    flaG_1990?: string;
    firm_name?: string;
    address?: string;
    postcode?: string;
    bA_REFERENCE_NUMBER?: string;
    assessmenT_REFERENCE_2023?: string;
    description?: string;
    psD_CODE?: string;
    scat?: string;
    rateablE_VALUE_2023?: number;
    effectivE_DATE_2023?: Date;
    lisT_ALTERATION_DATE_2023?: Date;
    compositE_2023?: string;
    settlemenT_TYPE_2023?: string;
    compileD_RATEABLE_VALUE_2023?: number;
    lisT_PERCENTAGE_2023?: number;
    percentagE_BETWEEN_2023_2017?: number;
    drafT_2023_RATEABLE_VALUE?: number;
    rateablE_VALUE_2017?: number;
    effectivE_DATE_2017?: Date;
    lisT_ALTERATION_DATE_2017?: Date;
    compositE_2017?: string;
    settlemenT_TYPE_2017?: string;
    compileD_RATEABLE_VALUE_2017?: number;
    lisT_PERCENTAGE_2017?: number;
    percentagE_BETWEEN_2017_2010?: number;
    drafT_2017_RATEABLE_VALUE?: number;
    rateablE_VALUE_2010?: number;
    effectivE_DATE_2010?: Date;
    lisT_ALTERATION_DATE_2010?: Date;
    compositE_2010?: string;
    settlemenT_TYPE_2010?: string;
    compileD_RATEABLE_VALUE_2010?: number;
    lisT_PERCENTAGE_2010?: number;
    percentagE_BETWEEN_2010_2005?: number;
    drafT_2010_RATEABLE_VALUE?: number;
    rateablE_VALUE_2005?: number;
    effectivE_DATE_2005?: Date;
    lisT_ALTERATION_DATE_2005?: Date;
    compositE_2005?: string;
    settlemenT_TYPE_2005?: string;
    compileD_RATEABLE_VALUE_2005?: number;
    lisT_PERCENTAGE_2005?: number;
    percentagE_BETWEEN_2005_2000?: number;
    rateablE_VALUE_2000?: number;
    effectivE_DATE_2000?: Date;
    lisT_ALTERATION_DATE_2000?: Date;
    compositE_2000?: string;
    settlemenT_TYPE_2000?: string;
    compileD_RATEABLE_VALUE_2000?: number;
    lisT_PERCENTAGE_2000?: number;
    percentagE_BETWEEN_2000_1995?: number;
    rateablE_VALUE_1995?: number;
    effectivE_DATE_1995?: Date;
    lisT_ALTERATION_DATE_1995?: Date;
    compositE_1995?: string;
    settlemenT_TYPE_1995?: string;
    compileD_RATEABLE_VALUE_1995?: number;
    lisT_PERCENTAGE_1995?: number;
    percentagE_BETWEEN_1995_1990?: number;
    rateablE_VALUE_1990?: number;
    effectivE_DATE_1990?: Date;
    lisT_ALTERATION_DATE_1990?: Date;
    compositE_1990?: string;
    settlemenT_TYPE_1990?: string;
    compileD_RATEABLE_VALUE_1990?: number;
    lisT_PERCENTAGE_1990?: number;
}