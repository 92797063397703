import { Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
const windowstyling = `
        .fullscreen {
          width: 100%;
          height: 100%;
        }
        .overview {
          width: 15%;
          height: 100%;
        }
        .reportWindow{
          display: inline-flex;
          flex-direction: row;
          width:100%;
          height:100%;
        }
          .printButton{
            position: absolute;
            right: 33px;
            bottom: 25px;
            color: white;
            height: 32px;
            width: 80px;
            background-color: #009FE3;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            box-sizing: border-box;
            -webkit-tap-highlight-color: transparent;
            background-color: transparent;
            outline: 0;
            border: 0;
            margin: 0;
            border-radius: 0;
            padding: 0;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            vertical-align: middle;
            -moz-appearance: none;
            -webkit-appearance: none;
            -webkit-text-decoration: none;
            text-decoration: none;
            color: inherit;
            font-family: Selawik, sans-serif;
            text-transform: none;
            font-weight: 500;
            font-size: 0.75rem;
            min-width: 64px;
            padding: 6px 16px;
            opacity: 0.5;
            border-radius: 4px;
            -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
            color: #fff;
            background-color: #1976d2;
            box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
            margin-left: 5px;
          }
        .printButton:hover{
            background-color: #1565c0;
            opacity: 1.0;
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
        }
          .pageoverview{
            width:100%;
            height:100%
          }
      `;
export default function ReportWindow({ reportString,onClose }) {
  const viewRef = useRef<HTMLIFrameElement | null>(null);
  const externalWindow = useRef<Window | null>(null);
  const [containerEl, setContainerEl] = useState<HTMLDivElement | null>(null);

  useEffect(() => {
    
    createWindow();

  }, [reportString]);
  const createWindow=async()=>{
    if(externalWindow.current){
      const currentBody = externalWindow.current.document.body;
      if (currentBody) {
          currentBody.remove();
      }
  
      const newDiv = externalWindow.current.document.createElement('div');
      const newBody = externalWindow.current.document.createElement('body');
      newBody.appendChild(newDiv);
      externalWindow.current.document.body = newBody;
      externalWindow.current.focus(); 
      setContainerEl(newDiv);
    }
    else{
    const newWindow = window.open(
      '',
      '',
      `toolbar=no,location=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${window.screen.width -100},height=${window.screen.height - 100},left=0,top=0`
    );
    if (newWindow) {
      newWindow.addEventListener('beforeunload',onClose)
      const newDiv = newWindow.document.createElement('div');
      newWindow.document.body.appendChild(newDiv);
      setContainerEl(newDiv); 
      externalWindow.current = newWindow;
      const styleElement = newWindow.document.createElement('style');
      styleElement.innerHTML = windowstyling
      newWindow.document.head.appendChild(styleElement);
    }
  }
  }

  useEffect(() => {
    if (externalWindow && containerEl && reportString) {
      updateIframeContent(viewRef);
    }
  }, [externalWindow, containerEl]);


  const updateIframeContent = (iframeRef, scale = 1) => {
    const iframe = iframeRef.current;
    if (!iframe) {
      console.error('Failed to access the iframe.');
      return;
    }
    const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
    if (!iframeDoc) {
      console.error('Failed to access the iframe document.');
      return;
    }
    iframeDoc.open();
    iframeDoc.write(reportString);
    iframeDoc.close();
  };

  const print = () =>{
    if (!viewRef.current ) {
      console.error('Failed to access the iframe.');
      return;
    }
    const iframe = viewRef.current;
    const iframeWindow = iframe.contentWindow;
    const iframeDoc = iframe.contentDocument || iframeWindow?.document;
    if (!iframeDoc) {
      console.error('Failed to access the iframe document.');
      return;
    }
    const handleAfterPrint = () => {
         window.close();
         externalWindow.current = null;
    };
    
    iframeWindow?.addEventListener('afterprint', handleAfterPrint);

    iframeWindow?.focus();
    iframeWindow?.print();

    setTimeout(() => {
      iframeWindow?.removeEventListener('afterprint', handleAfterPrint);
    }, 1000);



  }
    useEffect(()=>{
      return ()=>{
        if(externalWindow.current){
          externalWindow.current.close()
          externalWindow.current = null;
          onClose();
      }
      }
    },[])
  const getDocument = () => {
    return (
      <div className='reportWindow'>

        <iframe
          className="fullscreen"
          ref={viewRef}
          title="Fullscreen"
        ></iframe>
        <Button className='printButton' onClick={()=>print()}>Print</Button>
      </div>
    );
  };
  return containerEl ? ReactDOM.createPortal(getDocument(), containerEl) : null;
}