import { useState } from "react"
import SearchBarComponent from "./SearchBarComponent"
import ResultBarComponent from "./ResultBarComponent";
import store from "../../Redux/ConfigureStore";
import { selectHeaderBarContext, setHeaderBarContext } from "../../Redux/SearchSlicer";
import { useSelector } from "react-redux";
import ReportBarComponent from "./ReportBarComponent";

enum HeaderBarContext {
    Search = 0,
    Result = 1,
    Report = 2
}

export default function HeaderBarHandlerComponent(props) {
    const { handleSearchClick, handleClearClick,searchCount } = props;
    const headerBarContext = useSelector(selectHeaderBarContext);
    return (
        <div className="layoutcolumn">
            <div className="headercontextbar layoutrow">
                {Object.values(HeaderBarContext).filter(key=> isNaN(Number(HeaderBarContext[key]))).map((value) => (
                        (value != 2 || searchCount>0 )&&
                            <div className={headerBarContext==value?"headercontextoption selected":"headercontextoption"} key={value} onClick={()=>store.dispatch(setHeaderBarContext(Number(value)))}>
                                {HeaderBarContext[value]}
                            </div>
                        
                        ))}
                
            </div>
            <div className="mainheaderbar">
            {headerBarContext == 0 &&
                <SearchBarComponent handleSearchClick={handleSearchClick} handleClearClick={handleClearClick} />
                }
            {headerBarContext == 1 &&
                <ResultBarComponent  />
            }
            {headerBarContext == 2 && searchCount>0 &&
                <ReportBarComponent  />
            }
            </div>
        </div>
    )
}