import { useSelector } from "react-redux"
import { selectSettlement2010, selectSettlement2023 } from "../Redux/PreloadedSlicer";
import { useEffect } from "react";

export default function SettlementGuideComponent(){
    return(
        <>
        <div className='settlementguideheaderbar layoutrow'>
            <div className="settlementguideheadericon settlementguideheadericonsize"></div>
            <div className="settlementguideheadertext">Guide to Settlement Types for Analyse 9</div>
            <div className="settlementguideheadericonsize"></div>
        </div>
        <div className="settlementcodes2023 settlementcodes">
            <div className="settlementcodesheader">2023/2017 England only </div>
                <div>1A = AGREE (Check stage) </div>
                <div>1D = DISAGREE (Check stage) </div>
                <div>1P = PARTIALLY AGREE (Check stage) </div>
                <div>2A = AGREE (Challenge stage) </div>
                <div>2D = DISAGREE (Challenge stage) </div>
                <div>2F = WELL FOUNDED (Challenge stage) </div>
                <div>2I = INCOMPLETE (Challenge stage) </div>
                <div>2W = WITHDRAWN (Challenge stage) </div>
                <div>3A = AGREED (CONSENT ORDER) (Appeal stage) </div>
                <div>3C = CONFIRMED BY VT (Appeal stage) </div>
                <div>3D = DISMISSED BY VT (Appeal stage) </div>
                <div>3F = WELL FOUNDED (Appeal stage) </div>
                <div>3V = DECISION BY VT (Appeal stage) </div>
                <div>3W = WITHDRAWN (CONSENT ORDER) (Appeal stage) </div>
            </div>
            <div className="settlementcodes2010 settlementcodes">
                <div className="settlementcodesheader">2023/2017 Wales, 2010 and earlier Lists England and Wales </div>
                <div>A = AGREED </div>
                <div>C = CONFIRMED BY VT </div>
                <div>D = DISMISSED BY VT </div>
                <div>F = WELL FOUNDED </div>
                <div>L = DECISION BY LT </div>
                <div>T = CONFIRMED BY LT </div>
                <div>V = DECISION BY VT </div>
                <div>W = WITHDRAWN DISMISSED BY LT</div>
                <div>DL = DISMISSED BY LT  </div>
                <div>WL = WITHDRAWN BY LT </div>
                <div>CA = CONSENT ORDER (AGREED) </div>
            </div>
            <div className="informiconcontainer">
                <div className="copyright">Copyright &copy; 1998-2024</div>
                <div className="informicon"></div>
            </div>
        </>
    )
}