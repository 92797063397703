import { useState } from "react";
import store from "../../Redux/ConfigureStore";
import CloseIcon from '@mui/icons-material/Close';
import { selectDARLiveHighlight, selectListYear, selectTableContext, setDARLiveHighlight, setSummaryListYear, setTriggerPrint} from "../../Redux/SearchSlicer";
import { useSelector } from "react-redux";
import { ClickAwayListener } from "@mui/material";
import SettlementGuideComponent from "../SettlementGuideComponent";

enum IntegratedReports{
    'DAR' = 1,
    'Show Valuation'=2,

}
enum ValuationReports{
    'Valuation' = 1,
    'Archive Valuation'=2,

}
enum ScheduleReports{
    'Valuation Office Notice'=1
}
enum VTSReports{
    'Details'=1
}
enum ReportsExtended{
    'Landscape Selected'=3,
    'Landscape All'=4
}
enum ReportsOptions{
    'Normal' = 1,
    'Normal Continuous'=2,
    'Two-Up'=3,
    'Two-Up Continuous'=4
}
export default function ReportBarComponent(){
    const tableContext = useSelector(selectTableContext)
    const[reportOption,setReportOption] = useState<ReportsOptions>(1);
    const summaryListYear = useSelector(selectListYear);
    const [showSettlementGuide,setShowSettlementGuide] = useState(false)
    const DARLiveHighlight = useSelector(selectDARLiveHighlight)
    return(
    <>

        <div className='layoutcolumn'>
            <div className='layoutrow'>
                {  tableContext == 0 &&
                <>              
                <div className='navbartableelement' onClick={(e)=>store.dispatch(setTriggerPrint([1,reportOption]))}>
                <figure className="icon reporticon"></figure>
                    <div className="navbartableelementtext">{IntegratedReports[1]}</div>
                </div>
                <div className='navbartableelement' onClick={(e)=>store.dispatch(setTriggerPrint([2,reportOption]))}>
                <figure className="icon reporticon"></figure>
                    <div className="navbartableelementtext">{IntegratedReports[2]}</div>
                </div>
                </>
                }
                {  tableContext == 1 &&
                <>              
                <div className='navbartableelement' onClick={(e)=>store.dispatch(setTriggerPrint([1,reportOption]))}>
                <figure className="icon reporticon"></figure>
                    <div className="navbartableelementtext">{ValuationReports[1]}</div>
                </div>
                <div className='navbartableelement' onClick={(e)=>store.dispatch(setTriggerPrint([2,reportOption]))}>
                <figure className="icon reporticon"></figure>
                    <div className="navbartableelementtext">{ValuationReports[2]}</div>
                </div>
                </>
                }
                {  tableContext == 2 &&
                <div className='navbartableelement' onClick={(e)=>store.dispatch(setTriggerPrint([1,reportOption]))}>
<figure className="icon reporticon"></figure>
                    <div className="navbartableelementtext">{ScheduleReports[1]}</div>
                </div>
                }
                {  tableContext == 3 &&
                <div className='navbartableelement' onClick={(e)=>store.dispatch(setTriggerPrint([1,reportOption]))}>
<figure className="icon reporticon"></figure>
                    <div className="navbartableelementtext">{VTSReports[1]}</div>
                </div>
                }
                <div className='navbartableelement' onClick={(e)=>store.dispatch(setTriggerPrint([3,reportOption]))}>
                <figure className="icon reporticon"></figure>
                    <div className="navbartableelementtext">{ReportsExtended[3]}</div>
                </div>
                <div className='navbartableelement' onClick={(e)=>store.dispatch(setTriggerPrint([4,reportOption]))}>
                <figure className="icon reporticon"></figure>
                    <div className="navbartableelementtext">{ReportsExtended[4]}</div>
                </div>
            </div>
            <div className="navbartableselectiondescription">
                <div>Report</div>
            </div>
        </div>
        <div className="vhr">
        </div>
        {/* <div className='layoutcolumn'>
            <div className='layoutrow'>
                <div className={reportOption ==1?'navbartableelement selected':'navbartableelement'} onClick={(e)=>setReportOption(1)}>

                    <div className="navbartableelementtext">{ReportsOptions[1]}</div>
                </div>
                <div className={reportOption ==2?'navbartableelement selected':'navbartableelement'} onClick={(e)=>setReportOption(2)}>

                    <div className="navbartableelementtext">{ReportsOptions[2]}</div>
                </div>
                <div className={reportOption ==3?'navbartableelement selected':'navbartableelement'} onClick={(e)=>setReportOption(3)}>

                    <div className="navbartableelementtext">{ReportsOptions[3]}</div>
                </div>
                <div className={reportOption ==4?'navbartableelement selected':'navbartableelement'} onClick={(e)=>setReportOption(4)}>

                    <div className="navbartableelementtext">{ReportsOptions[4]}</div>
                </div>
            </div>
            <div className="navbartableselectiondescription">
                <div>Report Preview Layout</div>
            </div>
        </div>
        <div className="vhr"> 
        </div>*/}
    <div className="layoutcolumn">
        <div className="navbaryearselection">
            <div className={`navbaryearelement ${summaryListYear === 2023 ? 'selected' : ''}`}  onClick={()=>store.dispatch(setSummaryListYear(2023))}>
                <figure className="icon list2023icon"></figure>
                <div className="navbaryearelementtext">2023</div>
            </div>
            <div className={`navbaryearelement ${summaryListYear === 2017 ? 'selected' : ''}`} onClick={()=>store.dispatch(setSummaryListYear(2017))}>
            <figure className="icon list2017icon"></figure>
                <div className="navbaryearelementtext">2017</div>
            </div>
            <div className={`navbaryearelement ${summaryListYear === 2010 ? 'selected' : ''}`} onClick={()=>store.dispatch(setSummaryListYear(2010))}>
            <figure className="icon list2010icon"></figure>
                <div className="navbaryearelementtext">2010</div>
            </div>
            <div className={`navbaryearelement ${summaryListYear === 2005 ? 'selected' : ''}`} onClick={()=>store.dispatch(setSummaryListYear(2005))}>
            <figure className="icon list2005icon"></figure>
                <div className="navbaryearelementtext">2005</div>
            </div>
        </div>
        <div className="navbartableselectiondescription">
            <div>Summary Valuation List</div>
        </div>  
    </div>
    <div className="vhr">
    </div>
    <div className="layoutcolumn">
        <div className="settlementguidesection">
            <div className={DARLiveHighlight?'darlive selected':'darlive'} onClick={()=>store.dispatch(setDARLiveHighlight(!DARLiveHighlight))}>
            <figure className="icon darliveicon"></figure>
                <div className="darlivetext">Highlight Live in DAR</div>
            </div>
        </div>
        <div className="navbartableselectiondescription">
            <div>Report Settings</div>
        </div>  
    </div>   
    <div className="vhr">
    </div>
    <div className="layoutcolumn">
        <div className="settlementguidesection">
            <div className='settlementguide' onClick={()=>setShowSettlementGuide(true)}>
            <figure className="icon guideicon"></figure>
                <div className="settlementguidetext">Guide</div>
            </div>
        </div>
        <div className="navbartableselectiondescription">
            <div>Settlement Types</div>
        </div>  
    </div>
    <div className="vhr">
    </div>
    {showSettlementGuide && <>
        <div className='popoverbackground'></div>
            <ClickAwayListener onClickAway={(event) =>(setShowSettlementGuide(false))}>
            <div className='settlementguidepopover'>
                
                <div className='closeme' onClick={()=>setShowSettlementGuide(false)}>
                    <CloseIcon />
                </div>

                <SettlementGuideComponent/>
            </div>
        </ClickAwayListener>
        </>}
    </>
    )
}