import React, { useEffect, useRef, useState } from 'react';
import { DataGridPro, GridRowsProp, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import SurveyAdjustments from '../../Models/SearchApiModels/SurveyAdjustments';
import {selectListYear} from '../../Redux/SearchSlicer';
import { useSelector } from 'react-redux';

export default function SVAdjustments(props) {
  const {uarn,data} = props
  const [adjustmentsData,setAdjustmentsData] = useState()
  let currentId = useRef(0);
  const apiRef = useGridApiRef();
  var pageNo = 0
  const getRowId = () => {
    return currentId.current++;
  };
  
  useEffect(()=>{
    const updatedData = data.map(item => ({
      ...item,
      key_field: getRowId(),
    }));
  
    setAdjustmentsData(updatedData);
  },[data])



 
  const columns: GridColDef[] = [
    
    { field: 'description', headerName: 'DESCRIPTION', width: 304},
    { field: 'adjustments_percentage', headerName: 'ADJUSTMENTS PERCENTAGE', width: 303 },
    
    
  ]
  const rows = adjustmentsData || []; 
  return (
     
    <>
    {rows.length > 0  ? (
    <div style={{ height: '100%', width: '100%', display: 'flex' }}>
    <div style={{ height: '100%', width: '44%', float:'left' }}>

        <DataGridPro rows={rows} columns={columns} rowHeight={22} columnHeaderHeight={22}
         
          hideFooter={true}
          getRowId={(row: SurveyAdjustments) => row.key_field!}
          sortingOrder={['asc', 'desc']}
          apiRef={apiRef}       
         
        />
     
    </div>

   
    </div>
     ):
     (<>
      {<p style={{marginLeft: '30px', marginTop: '30px'}}>No data available</p>}
    </>)
    }
    </>
    
   );

}