export default class IntegratedSettlement {
    searchSettlement: boolean=false;
    listYear: number = 2023;
    settlementType: string[]=[];
    constructor(data?: Partial<IntegratedSettlement>) {
        Object.assign(this, {
            listYear: 2023,
            searchSettlement: false,
            settlementType: [],
            ...data,
        });
    }

    serialize(): Partial<IntegratedSettlement> {
        return {
            listYear: this.listYear,
            searchSettlement: this.searchSettlement,
            settlementType: this.settlementType,
            
        };
    }
}