export class ReportModel{
    uarn:string = '';
    list:number = 0;
    constructor(data?){
        Object.assign(this, {
        uarn : data.uarn,
        list: data.list,
        ...data,
    });
    }
}