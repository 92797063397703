
export enum CaseTypeLogic {
    'All Case Types' =0,
    'Interested Party Proposal'=1,
    'VOA Report'=2,
    'Billing Authority report'=3,
    'VOA or Billing Authority report'=4,
    'CHK Check 2017 Only'=5,
    'CHG Challenge 2017 Only'=6,
    'CHK and CHG 2017 Only'=7,
    'CHK, CHG and IPP 2017 Only'=8,
    'APL Appeal 2017 Only'=9,
    'CHK, CHG, IPP and APL 2017 Only'=10,
    'LT Lands Tribunal'=11,
    'REVEAL Draft Challenge 2017 Only'=12
}