import React, { useEffect, useRef, useState } from 'react';
import { DataGridPro, GridRowsProp, GridColDef, useGridApiRef, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import { formatDateToString, FormatNumberWithCommas, FormatSettlementCode } from '../../Services/FormatService';
import RvHistory from '../../Models/SearchApiModels/RvHistoryModel';
import RvHistoryGraph from '../RvHistoryGraph';
import store from '../../Redux/ConfigureStore';
import { setLoading } from '../../Redux/SearchSlicer';



export default function RVHistoryTable(props) {
  const {uarn} = props

  const [rvHistoryData, setRVHistoryData] = useState<Array<RvHistory>>([]);
  const [localLoading, setLocalLoading] = useState(true);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [contextMenuPosition, setContextMenuPosition] = useState<{ mouseX: number | null; mouseY: number | null }>({ mouseX: null, mouseY: null });
  const [selectedRow, setSelectedRow] = useState(null);
  let currentId = useRef(0);
  var pageNo = 0
  const getRowId = () => {
    return currentId.current++;
  };
 
  const [selectedUARN,setSelectedUARN]=useState<string>()
  useEffect(() => {
    fetchData();
  }, [uarn]);

  useEffect(() => {
    setSelectedUARN(uarn);
    
  }, [rvHistoryData]);
  useEffect(()=>{
    setSelectedUARN(selectedUARN)
  },[selectedUARN])

  
  const [size, setSize] = useState({
    width: 0,
    height: 0,
    contentWidth: 0
  });

  useEffect(() => {
 
    window.addEventListener('resize', fetchData);
    return () => window.removeEventListener('resize', fetchData);
  }, []);

  
  async function fetchData() {
    store.dispatch(setLoading(true))
    var data;
    setSize({
      width: window.innerWidth/2.2,
      height: window.innerHeight/3,
      contentWidth: window.innerWidth/2.1
    });
 
    setLocalLoading(true);
    
        data = await ApiHelper.GetRvHistory(uarn);
        var dataarray = data ? data : [];
        data.forEach(element => 
          element.key_field = getRowId()
        );
       
        if (dataarray.length > 0) {
     
            setRVHistoryData(dataarray);
        }
        setLocalLoading(false);
        store.dispatch(setLoading(false))
    
  }
 


  const handleContextMenu = (event, row) => {
    
    event.preventDefault();

    if (event.shiftKey) {
      console.log('Shift key is held down.');
    }
    else {
      if (event.ctrlKey) {
        console.log('Control key is held down.');
      }
      else {
        if(rowSelectionModel.findIndex(elem=>row.keY_FIELD == elem) > -1){
        }
        else{
          setRowSelectionModel([row.keY_FIELD]);
          if (row.uarn !== selectedUARN) {

            setSelectedUARN(row.uarn);
      
          }
        }
          
      }
    }

   
  };



  const columns: GridColDef[] = [
   
    { field: 'list', headerName: 'LIST YEAR', width: 70, headerAlign:  'center',align:'center' },
    { field: 'status', headerName: 'STATUS', width: 82, headerAlign:  'center',align:'center', },
    { 
      field: 'rateablE_VALUE', headerName: 'RATEABLE VALUE', width: 105, headerAlign:  'center',align:'right',
      disableColumnMenu: true,
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>  
         {params.value != null && FormatNumberWithCommas(params.value)}       
          {params.value == null && <span>DELETED</span>}
        </div>
      ),
    },
    { field: 'effective_date', headerName: 'EFFECTIVE', type: 'date', width: 95,  valueFormatter: (params) => formatDateToString(params), headerAlign:  'center',align:'center', },

    { field: 'altered', headerName: 'ALTERED', type: 'date', width: 100,  valueFormatter: (params) => formatDateToString(params), headerAlign:  'center',align:'center', },
    { field: 'settlement_type', headerName: 'SETTLEMENT', width: 100, headerAlign:  'center',align:'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(params.row.list , params.value)}</span> : <span>&nbsp;</span>}
 
        </div>
      ),
     },
    { field: 'psD_code', headerName: 'PSD', width: 75, headerAlign:  'center',align:'center',},      
    { field: 'composite', headerName: 'COMPOSITE', width: 100, headerAlign:  'center',align:'center', },
    
  ]
  const rows = rvHistoryData || [];

  if (localLoading) {
    return (
      <div className="loading">
        <p>Loading...</p>
      </div>
    );
  }
 
  return (
    
    <div style={{ height: '100%', width: '100%' }}>
    <div style={{ height: '100%', width: size.contentWidth, maxWidth: '730px', float:'left' }}>
      {rows.length != 0 &&
        <DataGridPro rows={rows} columns={columns} rowHeight={22} columnHeaderHeight={22}
          sortingOrder={['asc', 'desc']}
          hideFooter={true}
          getRowId={(row: RvHistory) => row.key_field!}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          
          autosizeOptions={{
            includeOutliers: false,
            includeHeaders: false,
          }}
        />
      }
    </div>
    <div style={{ float:'right'}}>
      <RvHistoryGraph uarn={uarn} dataChanged = {rvHistoryData} Height={size.height} Width={size.width} />
    </div>
    </div>
  );
}