import { useSelector } from "react-redux";
import { selectListDropDown } from "../../Redux/PreloadedSlicer";
import { ValuationTribunalVTS } from "../../Models/ValuationTribunalVTS";
import { useEffect, useState } from "react";
import store from "../../Redux/ConfigureStore";
import { cleanup } from "@testing-library/react";
import { setValuationTribunalVTSSearch } from "../../Redux/SearchSlicer";
import { StatusList } from "../../Models/Enums/StatusList";
import { SearchLogic } from "../../Models/Enums/SearchLogic";
import { DateField } from "../../Models/Enums/DateField";
import { DateLogic } from "../../Models/Enums/DateLogic";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import dayjs, { Dayjs } from "dayjs";
import { Checkbox } from "@mui/material";

export default function VTSSearchComponent(props) {
    const { searchCount } = props;
    const listDropDown = useSelector(selectListDropDown);
    const [searchData, setSearchData] = useState<ValuationTribunalVTS | null>(null);
    const [dateValue, setDateValue] = useState<Dayjs>(dayjs());
    const [isDateOpen, setIsDateOpen] = useState(false);
    useEffect(() => {

        var search = store.getState().search.valuationTribunalVTS
        setSearchData(new ValuationTribunalVTS(search));
        var date = search.dateValue ? dayjs(search.dateValue) : null;
        if (date) {
            setDateValue(date)

        }
        return () => {
            cleanup();
        };
    }, []);
    useEffect(()=>{
        if(searchCount<1){
        var search = store.getState().search.valuationTribunalVTS
        setSearchData(new ValuationTribunalVTS(search));
        var date = search.dateValue ? dayjs(search.dateValue) : null;
        if (date) {
            setDateValue(dayjs(search.dateValue))

        }}
    },[searchCount])
    const sendSearchData = () => {
        if (searchData) {
            store.dispatch(setValuationTribunalVTSSearch(searchData.serialize()));

        }
    }
    const handleCheckBoxChange = (e) => {
        const { checked, name } = e.target;
        const updatedSearchData = { ...searchData, [name]: checked };
        setSearchData(new ValuationTribunalVTS(updatedSearchData));
    }
    const handleChange = async (e) => {
        const { name, value } = e.target;
        const updatedSearchData = { ...searchData, [name]: value };
        setSearchData(new ValuationTribunalVTS(updatedSearchData));
    };
    useEffect(() => {
        sendSearchData();
    }, [searchData]);
    const handleBlur= (e) => {
        const {value,name} = e.target;
        if (value.trim() === ''){
            const updatedSearchData = { ...searchData, [name]: 0 };
            setSearchData(new ValuationTribunalVTS(updatedSearchData));
        }
    }
    const handleDateChange = (value) => {
        if (searchData) {
            const updatedSearchData = searchData;
            if (value) {
                updatedSearchData.dateValue = value.toJSON()
            }
            setSearchData(new ValuationTribunalVTS(updatedSearchData));
            setDateValue(value)

            setIsDateOpen(false)

        }

    }
    return (
        <>{searchData &&
            <div className="searchcomponentcategory">
                <div className="trendminicomponent">
                <label htmlFor="agentName">Agent Name starting with...</label>
                <input
                    className="basicsearchinput"
                    type="text"
                    id="agentName"
                    name="agentName"
                    value={searchData.agentName}
                    onChange={handleChange}
                />
                </div>
                <div className="trendminicomponent">
                <label htmlFor="appealNo">Appeal Number is</label>
                <input
                    className="basicsearchinput"
                    type="text"
                    id="appealNo"
                    name="appealNo"
                    value={searchData.appealNo}
                    onChange={handleChange}
                />
                </div>
                <div className="trendminicomponent">
                <label htmlFor="sprogID">SPROG ID Number is</label>
                <input
                    className="basicsearchinput"
                    type="text"
                    id="sprogID"
                    name="sprogID"
                    value={searchData.sprogID}
                    onChange={handleChange}
                />
                </div>
                <div className="trendminicomponent">
                <label htmlFor="decisionValue">Decision containing...</label>
                <input
                    className="basicsearchinput"
                    type="text"
                    id="decisionValue"
                    name="decisionValue"
                    value={searchData.decisionValue}
                    onChange={handleChange}
                />
                </div>
                <div className="layoutrow trendminicomponent">
                    <div className="vtsselecttext">Show information from</div>
                    <select id="dropdown" className='vtsselect' name='listYear' value={searchData.listYear} onChange={handleChange}>
                        <option key={0} value={0}>{'All Lists'} </option>
                        {listDropDown.length != 0 && listDropDown.map((value) => (
                            <option key={value} value={value}>
                                the {value} list
                            </option>
                        ))}
                    </select>
                </div>
                <div className="layoutrow trendminicomponent">
                    <div className="vtsselecttext">Status</div>
                    <select id="dropdown" className='vtsselect' name='vtsStatus' value={searchData.vtsStatus} onChange={handleChange}>
                        {Object.values(StatusList).filter(key => isNaN(Number(StatusList[key]))).map((value) => (
                            <option key={value} value={value}>
                                {StatusList[value]}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="trendminiheader trendminicomponent">
                    <Checkbox color="secondary" sx={{color: '#009FE3',p:0}} name='goneOntoLandsTribunal' checked={searchData.goneOntoLandsTribunal} onChange={handleCheckBoxChange} /> Gone onto Lands Tribunal
                </div>    
                <div className="trendminiheader trendminicomponent">                
                    <Checkbox color="secondary" sx={{color: '#009FE3',p:0}} name='showFullDecisionsOnly' checked={searchData.showFullDecisionsOnly} onChange={handleCheckBoxChange} /> Show full Decisions Only
                </div>
                <div className="trendminiheader trendminicomponent">
                    <Checkbox color="secondary" sx={{color: '#009FE3',p:0}} name='dateSearch' checked={searchData.dateSearch} onChange={handleCheckBoxChange} /> Specific Date
                    <div className="trendminicontent trendminicomponent">
                        <div className="surveylineselectcontainer">
                            <select id="dropdown" className='vtsselectsplit' name='dateField' disabled={!searchData.dateSearch} value={searchData.dateField} onChange={handleChange}>
                                {Object.values(DateField).filter(key => isNaN(Number(DateField[key]))).map((value) => (
                                    <option key={value} value={value}>
                                        {DateField[value]}
                                    </option>
                                ))}
                            </select>
                            <select id="dropdown" className='vtsselectsplit' name='dateLogic' disabled={!searchData.dateSearch} value={searchData.dateLogic} onChange={handleChange}>
                                {Object.values(DateLogic).filter(key => isNaN(Number(DateLogic[key]))).map((value) => (
                                    <option key={value} value={value}>
                                        {DateLogic[value]}
                                    </option>
                                ))}
                            </select>
                        </div>

                    </div>
                    <DesktopDatePicker
                        value={dateValue}
                        onChange={(value) => handleDateChange(value)}
                        open={isDateOpen}
                        disabled={!searchData.dateSearch}
                        slotProps={{
                            textField: {
                                sx: {
                                    '& fieldset': {
                                        padding: 0,
                                        height: 25,
                                        borderColor:'#009FE3',

                                    },
                                    '& .MuiInputBase-root': {
                                        padding: 0, 
                                        borderColor:'#009FE3',
                                        borderRadius:'0px',
                                        '& .MuiInputBase-input': {
                                            width: 300,
                                            fontSize: 12,
                                            height: 15,
                                            borderRadius:'0px',
                                            lineHeight:'20px',
                                            padding: '5px',
                                        },
                                    },
                                },
                                style: {
                                    padding:'0px',
                                    height: '30px',
                                    borderRadius:0,
                                    lineHeight:'20px'
                                },
                                className: "hide-adornment ",
                                onClick: () => setIsDateOpen(true)
                            },

                        }}
                    />
                </div>
                <div className="trendminiheader">
                    <Checkbox color="secondary" sx={{color: '#009FE3',p:0}} name='rvSearch' checked={searchData.rvSearch} onChange={handleCheckBoxChange} /> Rateable Value
                    <div className="trendminicontent">
                        <div className="surveylineselectcontainer">
                            <div className="vtsselecttext">RV that is</div>
                            <select id="dropdown" className='vtsselect' name='rvLogic' disabled={!searchData.rvSearch} value={searchData.rvLogic} onChange={handleChange}>
                                {Object.values(SearchLogic).filter(key => isNaN(Number(SearchLogic[key]))).map((value) => (
                                    <option key={value} value={value}>
                                        {SearchLogic[value]}
                                    </option>
                                ))}
                            </select>
                        </div>

                    </div>
                    <div className="surveylinevalues">
                        <input type='number' onBlur={handleBlur}  className={searchData.rvLogic == 3 ? "vtsnumericinput shrink" : "vtsnumericinput"} name='rvValue' value={searchData.rvValue} disabled={!searchData.rvSearch} step='100' onChange={handleChange} />
                        {searchData.rvLogic == 3 &&
                            <>
                                <label htmlFor="rvValueLessThan" className="intadvlessthanlabel">and</label>
                                <input type='number' onBlur={handleBlur}  className="vtsnumericinput lessthan" name='rvValueLessThan' value={searchData.rvValueLessThan} step='100' onChange={handleChange} disabled={!searchData.rvSearch}/>
                            </>
                        }
                    </div>

                </div>
            </div>
        }</>
    )

}