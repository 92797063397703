import React, { useEffect, useRef, useState } from 'react';
import { DataGridPro, GridRowsProp, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import { formatDateToString } from '../../Services/FormatService';
import DirectoryBA from '../../Models/SearchApiModels/DirectoryBAModel';
import DirectoryVoaUnits from '../../Models/SearchApiModels/DirectoryVOAUnitsModel';
import store from '../../Redux/ConfigureStore';
import { setLoading } from '../../Redux/SearchSlicer';



export default function DirectoryVoaUnitsTable(props) {
  const {uarn} = props

  const [directoryVoaUnitesData, setDirectoryVoaUnitsData] = useState<DirectoryVoaUnits>();
  const [localLoading, setLocalLoading] = useState(true);
  let currentId = useRef(0);
  const apiRef = useGridApiRef();
  var pageNo = 0
  const getRowId = () => {
    return currentId.current++;
  };
 
  const [selectedUARN,setSelectedUARN]=useState<string>()
  useEffect(() => {
    fetchData();
  }, [uarn]);

  useEffect(() => {
    setSelectedUARN(uarn);
    
  }, [directoryVoaUnitesData]);
  useEffect(()=>{
    setSelectedUARN(selectedUARN)
  },[selectedUARN])

  
  async function fetchData() {
    store.dispatch(setLoading(true))
    setLocalLoading(true);
    try{
      var data;
    
        data = await ApiHelper.GetDirectory(uarn);
        var dataarray = data ? data : [];
     
          setDirectoryVoaUnitsData(dataarray);     
          setLocalLoading(false); 
      }
      catch(e){
        console.log(e)
      }
      finally{

      store.dispatch(setLoading(false))

      }     
  }

  if (localLoading) {
    return (
      <div className="loading">
        <p>Loading...</p>
      </div>
    );
  }
 
  return (
     
   <>
   {directoryVoaUnitesData  ? (
   <div className='voaUnitsTab' style={{height: '345px'}}>
    <div style={{ width: '25%', float: 'left' }}>
    NAME: 
    <br></br> <br></br>
   
    TEL: 
    <br></br> <br></br>

    EMAIL: 
    <br></br> <br></br>
    SRU CONTACT:
    <br></br> <br></br>
    SRU TEL: 
    <br></br> <br></br>
    SRU FAX: 
    <br></br> <br></br>
    ADDRESS: 
    <br></br> <br></br>
    : 
    <br></br> <br></br>
    : 
    <br></br> <br></br>
    : 
    <br></br> <br></br>
    : 
    <br></br> <br></br>
    
    POSTCODE:
    <br></br> <br></br>
    </div>
   
    <div style={{ width: '75%', float: 'right' }}>
     {directoryVoaUnitesData.g_NAME}
    <br></br> <br></br>
   
   {directoryVoaUnitesData.g_TEL}
    <br></br> <br></br>

    {directoryVoaUnitesData.g_EMAIL}
    <br></br> <br></br>
    {directoryVoaUnitesData.srU_CONTACT}
    <br></br> <br></br>
     {directoryVoaUnitesData.srU_TEL}
     <br></br> <br></br>
     {directoryVoaUnitesData.srU_FAX}
    <br></br> <br></br>
     {directoryVoaUnitesData.g_ADD1}
    <br></br> <br></br>
     {directoryVoaUnitesData.g_ADD2}
    <br></br> <br></br>
    {directoryVoaUnitesData.g_ADD3}
    <br></br> <br></br>
     {directoryVoaUnitesData.g_ADD4}
    <br></br> <br></br>
     {directoryVoaUnitesData.g_ADD5}
    <br></br> <br></br>
    
    {directoryVoaUnitesData.g_POST}
    <br></br> <br></br>
    </div>
   
   </div>
    ):
    (<>
     {directoryVoaUnitesData !=undefined && <p>No data available</p>}
   </>)
   }
   </>
   
  );
}