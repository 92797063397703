import React, { useState, useEffect, useRef } from 'react';
import { DataGridPro, GridColDef, useGridApiRef, GridRowSelectionModel, GridColumnGroup } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import AuthorityScheduleSearch, { validateAuthorityScheduleSearch } from '../../Models/SearchApiModels/AuthorityScheduleSearch';

import ValuationTribunalSearch, { validateValuationTribunalSearch } from '../../Models/SearchApiModels/ValuationTribunalSearch';
import ValuationTribunalResults from '../../Models/ValuationTribunalResults';
import { ValuationTribunalVTS } from '../../Models/ValuationTribunalVTS';
import store from '../../Redux/ConfigureStore';
import BasicSearchApiModel from '../../Models/SearchApiModels/BasicSearchApiModel';
import { formatDateToString, FormatNumberWithCommas, formatVTSStatus, stringifyList } from '../../Services/FormatService';
import ValuationTribunalVTSApiModel from '../../Models/SearchApiModels/ValuationTribunalVTSApiModel';
import { useSelector } from 'react-redux';
import { addUARNs, selectAddSelection, setDispatchAddSelection } from '../../Redux/ListBuilderSlicer';
import TableRightClickMenu from './TableContextMenus/TableRightClickMenu';
import { selectTriggerShowScheme, selectListYear, selectSelectedUARN, selectTriggerGOVUK, selectTriggerGetTotal, selectTriggerGetCount, selectTriggerGoogleMaps, selectTriggerPrint, setHeaderBarContext, setSelectedUARN, setTriggerGOVUK, setCount, setTriggerGetCount, setTotal, setTriggerGetTotal, setTriggerGoogleMaps, setTriggerPrint, setTriggerShowScheme, setLoading, setDisplayDetails, selectTriggerExportExcel, setTriggerExportExcel } from '../../Redux/SearchSlicer';
import HomeIcon from '@mui/icons-material/Home';
import { VTSReportModel } from '../../Models/VTSReportModel';
import BasicSearch from '../../Models/BasicSearch';
import { GetScheme } from '../../API/AnalyseAPIHelper';
import CloseIcon from '@mui/icons-material/Close';
import { Button, ClickAwayListener } from '@mui/material';
import { validateBasicSearch } from '../../Services/ValidationService';
import ReportWindow from '../SubComponents/ReportWindow';
import HomeScreenComponent from '../SubComponents/HomeScreenComponent';
import { dataToFile } from '../../Services/ExcelExportService';

export default function ValuationTribunalTable(props) {
  const [valuationTribunalData, setValuationTribunalData] = useState<Array<ValuationTribunalResults>>([])
  const apiRef = useGridApiRef();
  var pageNo = useRef(0);
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [contextMenuPosition, setContextMenuPosition] = useState<{ mouseX: number | null; mouseY: number | null }>({ mouseX: null, mouseY: null });
  const [selectedRow, setSelectedRow] = useState<ValuationTribunalResults>();
  const { searchCount, handleUARNChange } = props;
  //const [selectedRowID, setSelectedRowID] = useState<string>()
  const [uarnSelectionModel, setUARNSelectionModel] = useState<string[]>([]);
  const triggerAddSelection = useSelector(selectAddSelection);
  const triggerGoogleMaps = useSelector(selectTriggerGoogleMaps);
  const triggerGOVUK = useSelector(selectTriggerGOVUK);
  const triggerSearchButton = useRef(false);
  const keepSelection = useRef(false);
  const [storedQuery, setStoredQuery] = useState<ValuationTribunalSearch>();
  const selectedUARN = useSelector(selectSelectedUARN);
  const triggerPrint = useSelector(selectTriggerPrint);
  const [report,setReport] = useState();
  const triggerGetTotal = useSelector(selectTriggerGetTotal);
  const triggerGetCount = useSelector(selectTriggerGetCount);
  const summaryListYear = useSelector(selectListYear);
  const [showNoScheme,setShowNoScheme]=useState(false);
  const showScheme = useSelector(selectTriggerShowScheme);
  const [searchEmpty, setSearchEmpty] = useState(false);
  const triggerExport = useSelector(selectTriggerExportExcel);
  const [columnWidths, setColumnWidths] = useState({});

  useEffect(() => {
    
    pageNo.current =0;
    var query = getSearchQuery();
    fetchData(query);
    setSelectedRow(undefined);
    triggerSearchButton.current = true;
    store.dispatch(setSelectedUARN(''))
  }, [searchCount]);

  useEffect(() => {
    if (triggerExport > 0) {
      handleExportExcel(triggerExport)
      store.dispatch(setTriggerExportExcel(0))
    }
  }, [triggerExport])

  const handleColumnResize = (params) => {
    setColumnWidths((prevWidths) => ({
      ...prevWidths,
      [params.colDef.field]: params.width,
    }));
  };
  
  const handleExportExcel = async (options) => {
    var excelData
    store.dispatch(setLoading(true))
    try {
      switch (options) {
        case 1:
          var querystring = stringifyList(rowSelectionModel);
          excelData = await ApiHelper.GetValuationTribunalExcelSelected(querystring);
          break;
        case 2:
          var query = storedQuery!;
          query.report = true;
          excelData = await ApiHelper.GetValuationTribunalExcel(query);
          break;
      }
    }
    catch (err) {
      console.error(err)
    }
    finally {
      storedQuery!.report = false;
      if (excelData.length) {
        excelData.forEach(item => {
          item.appeaL_STATUS = item.appeaL_STATUS? formatVTSStatus(item.appeaL_STATUS):null;
          item.effectivE_DATE = item.effectivE_DATE? formatDateToString(item.effectivE_DATE):null;
          item.starT_DATE =item.starT_DATE?  formatDateToString(item.starT_DATE):null;
          item.targeT_DATE = item.targeT_DATE? formatDateToString(item.targeT_DATE):null;
          item.tribunaL_DATE = item.tribunaL_DATE? formatDateToString(item.tribunaL_DATE):null;
        });
        dataToFile(excelData, ExcelGroupingModel, ExcelColumnModel)
      }
    }
  }


 useEffect(() => {
  if(triggerGetTotal== true){
    handleGetTotal();
  }
}, [triggerGetTotal]);

useEffect(() => {
  if(triggerGetCount== true){
    handleGetCount();
  }
}, [triggerGetCount]);
useEffect(()=>{
  if(showScheme){
     handleScheme()
  }
},[showScheme]);

const handleGetTotal=async()=>{
  if(storedQuery)
  {    
    store.dispatch(setLoading(true))
    try {
      var totalQuery = storedQuery;
      totalQuery.totals = true;
      var data = await ApiHelper.GetValuationTribunalSum(totalQuery);
      totalQuery.totals = false;
      store.dispatch(setLoading(false))
      if(data){
        store.dispatch(setTotal(data[0].rateablE_VALUE))

      }
      // if (store.getState().search.sqlDump) {
      //   var totalQuery = storedQuery
      //   storedQuery.sqldump = true;
      //   totalQuery.totals = true;
      //   var dump = await ApiHelper.GetValuationTribunalSum(totalQuery);
      //   storedQuery.totals = false;
      //   storedQuery.sqldump = false;
      //   console.log(dump)
      // }
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    finally{
      store.dispatch(setTriggerShowScheme(false))
  
    }
  }
}

const handleGetCount=async()=>{
  if(storedQuery)
  {    
    store.dispatch(setLoading(true))
    try {
      var totalQuery = storedQuery;
      totalQuery.totals = true;
      var data = await ApiHelper.GetValuationTribunalCount(totalQuery);
      totalQuery.totals = false;
      store.dispatch(setLoading(false))
      if(data){
        store.dispatch(setCount(data[0].key_field))

      }
      // if (store.getState().search.sqlDump) {
      //   var totalQuery = storedQuery
      //   storedQuery.sqldump = true;
      //   totalQuery.totals = true;
      //   var dump = await ApiHelper.GetValuationTribunalCount(totalQuery);
      //   storedQuery.totals = false;
      //   storedQuery.sqldump = false;
      //   console.log(dump)
      // }
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    finally{
      store.dispatch(setTriggerShowScheme(false))
  
    }
  }
}

  useEffect(() => {
    const selectedUARNs = rowSelectionModel.map(id => {
      return valuationTribunalData.find(row => row.key_field === id)?.uarn;
    }).filter(Boolean) as string[];
    setUARNSelectionModel(selectedUARNs);
  }, [rowSelectionModel]);
  
  useEffect(() => {
    if (triggerPrint[0]){
      handlePrint(triggerPrint)
  
      store.dispatch(setTriggerPrint([0,triggerPrint[1]]))
    }
  }, [triggerPrint]);
  const handlePrint=async (props)=>{
    store.dispatch(setLoading(true))
  
   var newreport
   try{
   switch (props[0]){
     case 1:
      var query1 = new VTSReportModel(stringifyList(rowSelectionModel));
      newreport = await ApiHelper.GetVTSReport(query1);
       break    
     case 3:
      var query2 = new ValuationTribunalSearch();
      query2.selectedItems=true;
      query2.vtsItems= stringifyList(rowSelectionModel);
      //query2.basic.LIST = summaryListYear!;
      newreport = await ApiHelper.GetVTSListReportSelected(query2);
      break;
     case 4:
      newreport = await ApiHelper.GetVTSListReport(storedQuery!);
      break;
   }     
  }     
   catch(e){
     console.error(e)
   }
   finally{
    
    store.dispatch(setLoading(false))
   }  
   setReport(newreport)
  }

  useEffect(() => {
    if(triggerAddSelection== true){
      handleAddToListBuilder();
      store.dispatch(setDispatchAddSelection(false));
    }
  }, [triggerAddSelection]);
  const handleAddToListBuilder=()=>{
      try{
        ApiHelper.postLogThis("Adding to List: "+ stringifyList(uarnSelectionModel))
      }catch(error){
        console.error(error)
      }
      finally{
        store.dispatch(addUARNs(uarnSelectionModel));
      }
  }
  useEffect(() => {
    handleUARNChange(selectedUARN)
  }, [selectedUARN])
  useEffect(() => {
    var row = valuationTribunalData.find(elem=>elem.key_field == rowSelectionModel[rowSelectionModel.length-1])

    if (row){
      setSelectedRow(row);

    }
  }, [rowSelectionModel])
  const getSearchQuery=()=>{
    var storeQueryData = store.getState().search;
    var query = new ValuationTribunalSearch();
    query.basic = new BasicSearchApiModel(storeQueryData.basicSearch);
    var basicCodes = storeQueryData.basicCodeSearch;
    if (basicCodes.PSD?.length != 0 || basicCodes.SCAT?.length != 0 || basicCodes.codeSearch !='') {
      if(basicCodes.codeSearch){
        if(basicCodes.codeContext == 0){
          query.basic.PSD =[basicCodes.codeSearch]
        }
        else{
          query.basic.SCAT =[basicCodes.codeSearch]

        }
      }
      else{
        if(basicCodes.PSD?.length==0){
        query.basic.SCAT = basicCodes.SCAT;
      }
      if(basicCodes.SCAT?.length==0){
        query.basic.PSD = basicCodes.PSD;
      }
      if(basicCodes.PSD?.length != 0 && basicCodes.SCAT?.length != 0){
        if(basicCodes.codeContext ==1){
          query.basic.SCAT = basicCodes.SCAT;
        }else{
          query.basic.PSD = basicCodes.PSD;
        }
      }
    }

    }
    var areaSearch = storeQueryData.areaSearch;
    if (areaSearch.BACODE != '' && areaSearch.BACODE) {
      query.basic.BACODE = areaSearch.BACODE
    }
    if (areaSearch.TOFFICE != '' && areaSearch.TOFFICE) {
      query.basic.TOFFICE = areaSearch.TOFFICE
    }
    if (areaSearch.VOANNDRRVU != '' && areaSearch.VOANNDRRVU) {
      query.basic.VOANNDRRVU = areaSearch.VOANNDRRVU
    }
    if (areaSearch.VOOFFICELOCAL != '' && areaSearch.VOOFFICELOCAL) {
      query.basic.VOOFFICELOCAL = areaSearch.VOOFFICELOCAL
    }
    query.basic.region = Number(areaSearch.region)
    var valuationTribunalVTS = storeQueryData.valuationTribunalVTS
    if (valuationTribunalVTS.agentName != '' || valuationTribunalVTS.appealNo != '' || valuationTribunalVTS.dateSearch || valuationTribunalVTS.decisionValue != '' || valuationTribunalVTS.vtsStatus != 0
      || Number(valuationTribunalVTS.listYear) != 0 || valuationTribunalVTS.goneOntoLandsTribunal || valuationTribunalVTS.rvLogic || valuationTribunalVTS.sprogID != '') {
      query.vts = new ValuationTribunalVTSApiModel(valuationTribunalVTS)
    }
    return query
  }
  async function fetchData(query) {
    query.sortcolumn = "l.key_field";
    query.sortorder = 'asc';
    if (apiRef.current.state) {
      const { sortModel } = apiRef.current.state.sorting;
      if (sortModel.length != 0) {
        query.sortcolumn = sortModel[0]['field'];
        query.sortorder = sortModel[0]['sort'] ? sortModel[0]['sort'] : 'asc';
      }

    }
    query.pageno = pageNo.current;
    var errors = validateValuationTribunalSearch(query);
    var data;

    if (errors.length == 0) {
      try {
        store.dispatch(setLoading(true))
        data = await ApiHelper.GetValuationTribunal(query);
        var dataarray = data ? data : [];

        if (dataarray.length > 0) {
          if (pageNo.current === 0) {
            setValuationTribunalData(dataarray);
            store.dispatch(setTriggerGetTotal(false))
            store.dispatch(setTriggerGetCount(false))
            setStoredQuery(query)
            if(triggerSearchButton.current){
              store.dispatch(setHeaderBarContext(1))
            }
            if(!keepSelection.current){
              store.dispatch(setSelectedUARN(dataarray[0].uarn))
              setRowSelectionModel([dataarray[0].key_field])
            }
            if(searchCount==1){
              store.dispatch(setDisplayDetails(true))
            }
          } else {
            setValuationTribunalData(prevData => [...prevData, ...dataarray]);
          }
        }
        else{
          if(query.pageno == 0){
            setSearchEmpty(true);
          }
        }
        // if (store.getState().search.sqlDump) {
        //   query.sqldump = true;
        //   var dump = await ApiHelper.GetValuationTribunalDump(query);
        //   console.log(dump)
        // }
      }
      catch (ex) {
        console.log(ex)

      }
      finally{
        
        store.dispatch(setLoading(false))
      }
    }
    triggerSearchButton.current = false;
  }

  
  const openExternalLink = (url) => {
    try{
      ApiHelper.postLogThis("sending user to url: "+ url)
    }catch(error){
      console.error(error)
    }
    finally{
      window.open(url, '_blank', 'noopener,noreferrer');

    }
  };
useEffect(() => {
  if(triggerGoogleMaps== true&&selectedUARN)
    {
      handleGoogleMapSearch();
  
  }
  store.dispatch(setTriggerGoogleMaps(false));
}, [triggerGoogleMaps]);

const handleGoogleSearch = ()=>{
  var row = valuationTribunalData.find(item =>
    item.uarn == selectedUARN
  )
  var mapquery = ''
  mapquery += row?.address ? row?.address + ',' : ''
  mapquery += row?.postcode;
  openExternalLink('https://www.google.co.uk/search?q=' + mapquery);
}

const handleGoogleMapSearch=(param='')=>{
  var row  = valuationTribunalData.find(item=>
    item.uarn == selectedUARN
  )
  var mapquery = ''
  switch(param){
    case"address":
      mapquery += row?.address;
      break;
    case"postcode":
      mapquery += row?.postcode;
      break;
    
    case"addressandpostcode":
      mapquery +=row?.address? row?.address+',':''
      mapquery += row?.postcode;
      break;
    
    default:
      mapquery += row?.postcode;
      break;
  }
  openExternalLink('https://www.google.co.uk/maps?q='+mapquery);

}

useEffect(() => {
  if(triggerGOVUK== true &&selectedUARN){
    openExternalLink('https://www.tax.service.gov.uk/business-rates-find/valuations/'+selectedUARN)
  }
    store.dispatch(setTriggerGOVUK(false));
}, [triggerGOVUK]);


  const handleContextMenu = (event, row) => {
    event.preventDefault();

    if (event.shiftKey) {
      console.log('Shift key is held down.');
    }
    else {
      if (event.ctrlKey) {
        setRowSelectionModel([...rowSelectionModel,row.key_field]);
      }
      else {
        if(rowSelectionModel.findIndex(elem=>row.key_field == elem) > -1){
        }
        else{
          setRowSelectionModel([row.key_field]);
        }
          
      }
      
      if (row.uarn !== selectedUARN) {
        store.dispatch(setSelectedUARN(row.uarn));
      }
    }

    if (contextMenuPosition.mouseX !== null && contextMenuPosition.mouseY !== null) {
      handleCloseContextMenu(() => {
        setContextMenuPosition({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
      });
    } else {
      setContextMenuPosition({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
    }
  };
  const handleCloseContextMenu = (callback) => {
    setContextMenuPosition({ mouseX: null, mouseY: null });
    if (callback) {
      setTimeout(callback, 0);
    }
  };
  const handleRowOrderChange = (model) => {
    pageNo.current = 0
    fetchData(storedQuery);
    apiRef.current.scroll({ top: 0 });
  }
  const handleMenuItemClick = (item,param='') => {
    switch (item) {
      case "Add to 'ListBuilder'":
        handleAddToListBuilder();
        break;      
      case "Show Scheme":
        handleScheme();
        break;
      case "Show Entry on GOV.UK":
        store.dispatch(setTriggerGOVUK(true));
        break;
      case "Print Quick VTS Print":
        handlePrint([1,0])
        break;
        
      case "Show in Google Maps":
        handleGoogleMapSearch(param);
        break;
      case "Search in Google":
        handleGoogleSearch();
        break;
      default:
        break;
    }
    handleCloseContextMenu(null);
  };
const handleDecisionClick= ()=>{
  if(selectedRow ){
    var encodedAppeal = selectedRow.appeaL_NUMBER?encodeURIComponent(selectedRow.appeaL_NUMBER.trim()):'';
    var encodedPostcode = selectedRow.postcode? encodeURIComponent(selectedRow.postcode.trim()):'';
    openExternalLink(`https://appealsearch.valuationtribunal.gov.uk/Home/Decisions?AppealSearchType=ND&SearchByType=address&AppealNumber=${encodedAppeal}&Postcode=${encodedPostcode}&FreeText=&HearingId=00000000-0000-0000-0000-000000000000&SortDesc=False&HideShowingResultsForChange=False&ShowingResultsForItems=Microsoft.AspNetCore.Mvc.Rendering.SelectListItem&Skip=0&Page=1&PageSize=10&ResultCount=0`);
  }
}
const handleScheme = async()=>{
  store.dispatch(setLoading(true))
  try{
    var response = await GetScheme(summaryListYear,selectedUARN)
    if(response && response.assessmenT_REFERENCE && response.schemE_REFERENCE && selectedUARN){
      openExternalLink(`https://www.tax.service.gov.uk/business-rates-find/valuations/${response.assessmenT_REFERENCE}/schemes/${response.schemE_REFERENCE}?uarn=${selectedUARN}`);
    }  
    else{
      setShowNoScheme(true);
    }
  }
  catch{
    setShowNoScheme(true);
  }finally{
    store.dispatch(setTriggerShowScheme(false))

    store.dispatch(setLoading(false))
  }
}
const onClipboardCopy = (data, event, { api }) => {
  const visibleColumns = apiRef.current.getVisibleColumns();
    
  const columnsAsString = visibleColumns.filter(col => col.field !== '__check__').map(col => col.headerName).join('\t');
  navigator.clipboard.writeText(columnsAsString + '\n' + data);
}
  const handleRowsScrollEnd = () => {
    pageNo.current += 1;
    keepSelection.current = true;
   
    fetchData(storedQuery);
  }
  const handleRowClick = (e) => {
    const { row,id } = e;
    if (row.uarn !== selectedUARN) {
      //setSelectedRowID(id)
      store.dispatch(setSelectedUARN(row.uarn));

    }
  }
  const ExcelColumnModel = [
    { key: 'lisT_YEAR', header: 'LIST',  width: 8 },
    { key: 'appeaL_STATUS', header: 'STATUS', width: 10 },
    { key: 'firM_NAME', header: 'FIRM NAME', width: 12 },
    { key: 'address', header: 'ADDRESS', width: 48 },
    { key: 'postcode', header: 'POSTCODE', width: 10 },
    { key: 'bA_REFERENCE_NUMBER', header: 'BA REFERENCE NUMBER', width: 12 },
    { key: 'description', header: 'DESCRIPTION', width: 30 },
    { key: 'psD_CODE', header: 'PSD', width: 8 },
    { key: 'composite', header: 'COMPOSITE', width: 12 },
    { key: 'rateablE_VALUE', header: 'RATEABLE VALUE', width: 12, style: { numFmt: '#,##0' } },
    { key: 'effectivE_DATE', header: 'EFFECTIVE DATE', width: 12 },
    { key: 'agenT_NAME', header: 'AGENT', width: 12 },
    { key: 'agenT_POSTCODE', header: 'AGENT POSTCODE', width: 12 },
    { key: 'appeaL_NUMBER', header: 'APPEAL NUMBER', width: 16},
    { key: 'suB_PROGRAMME_ID', header: 'SPROG ID', width: 12 },
    { key: 'starT_DATE', header: 'START DATE', width: 12 },
    { key: 'targeT_DATE', header: 'TARGET DATE', width: 12 },
    { key: 'tribunaL_DATE', header: 'TRIBUNAL DATE', width: 12 },
    { key: 'tribunaL_VENUE', header: 'TRIBUNAL VENUE', width: 12 },
    { key: 'tribunaL_START_TIME', header: 'TRIBUNAL START TIME', width: 12 },
    { key: 'landS_TRIBUNAL_INDICATOR', header: 'LANDS TRIBUNAL INDICATOR', width: 16 }
  ];
  const ExcelGroupingModel = [
    {
      groupId: 'ADDRESS/STATUS',
      children: ['firM_NAME', 'appeaL_STATUS', 'address', 'lisT_YEAR', 'postcode']
    },
    {
      groupId: 'HEREDITAMENT INFORMATION',
      children: ['description', 'psD_CODE', 'bA_REFERENCE_NUMBER', 'composite']
    },
    {
      groupId: 'VT SPECIFIC INFORMATION',
      children: [
        'rateablE_VALUE', 'effectivE_DATE', 'agenT_NAME', 'agenT_POSTCODE', 'appeaL_NUMBER', 'suB_PROGRAMME_ID',
        'starT_DATE', 'targeT_DATE', 'tribunaL_DATE', 'tribunaL_VENUE', 'tribunaL_START_TIME', 'landS_TRIBUNAL_INDICATOR'
      ]
    }
  ];


  const columns: GridColDef[] = [
    //{
    //   field: 'key_field',
    //   headerName: '',
    //   width: 10,
    //   minWidth: 10,
    //   disableColumnMenu: true,
    //   disableReorder: true,
    //   resizable: false,
    //   sortable:false,
    //   headerAlign: 'center',
    //   renderCell: (params: any) => (
    //     <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
    //       <>
    //         {params && rowSelectionModel.includes(params.row.key_field) &&
    //           <div className='rowindicator'>
    //           </div>
    //         }
    //       </>
    //     </div>
    //   ),
    // },
    { field: 'lisT_YEAR', headerName: 'LIST', minWidth:40, width: columnWidths['lisT_YEAR'] || 38, align:'center',headerAlign:'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value!=null ||params.value !=undefined?params.value : <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return value
      }
     },
    { field: 'appeaL_STATUS', headerName: 'STATUS', width: columnWidths['appeaL_STATUS'] || 200, align:'center',headerAlign:'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatVTSStatus(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return formatVTSStatus(value)
      } },
    { field: 'firM_NAME', headerName: 'FIRM NAME', width: columnWidths['firM_NAME'] || 80,headerAlign:'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value!=null ||params.value !=undefined?params.value : <span>&nbsp;</span>}
        </div>
      ), },
    { field: 'address', headerName: 'ADDRESS', width: columnWidths['address'] || 458,headerAlign:'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value!=null ||params.value !=undefined?params.value : <span>&nbsp;</span>}
        </div>
      ),},
    { field: 'postcode', headerName: 'POSTCODE', width: columnWidths['postcode'] || 80 ,headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value!=null ||params.value !=undefined?params.value : <span>&nbsp;</span>}
        </div>
      ),},
    { field: 'bA_REFERENCE_NUMBER', headerName: 'BA REFERENCE NUMBER', width: columnWidths['bA_REFERENCE_NUMBER'] || 82,headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value!=null ||params.value !=undefined?params.value : <span>&nbsp;</span>}
        </div>
      ),},
    // { field: 'assessmenT_REFERENCE',  headerName: 'ASSESSMENT REF', width: 100,headerAlign: 'left',
    //   renderCell: (params: any) => (
    //     <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
    //       {params.value!=null ||params.value !=undefined?params.value : <span>&nbsp;</span>}
    //     </div>
    //   ),},
    { field: 'description', headerName: 'DESCRIPTION', width: columnWidths['description'] || 150,headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value!=null ||params.value !=undefined?params.value : <span>&nbsp;</span>}
        </div>
      ),},
    { field: 'psD_CODE', headerName: 'PSD', width: columnWidths['psD_CODE'] || 38,minWidth:38, headerAlign: 'left',align:'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value!=null ||params.value !=undefined?params.value : <span>&nbsp;</span>}
        </div>
      ),},
      { field: 'composite', headerName: 'COMPOSITE',  width: columnWidths['composite'] || 20,minWidth:20,headerAlign: 'left',
        renderCell: (params: any) => (
          <div className='compositivediv' onContextMenu={(event) => handleContextMenu(event, params.row)}>
                    {params.value != 'C' && <span>&nbsp;</span>}
                    {params.value =='C' && <div className='houseicon'><HomeIcon sx={{height:18,width:18}} color='secondary' /></div>}
          </div>
        ),},
    { field: 'rateablE_VALUE', headerName: 'RATEABLE VALUE', width: columnWidths['rateablE_VALUE'] || 75,headerAlign: 'left',align:'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'effectivE_DATE', headerName: 'EFFECTIVE DATE', width: columnWidths['effectivE_DATE'] || 75, headerAlign: 'left',align:'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return formatDateToString(value)
      }
    },
    { field: 'agenT_NAME', headerName: 'AGENT',  width: columnWidths['agenT_NAME'] || 75, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value!=null ||params.value !=undefined?params.value : <span>&nbsp;</span>}
        </div>
      ),},
    { field: 'agenT_POSTCODE', headerName: 'AGENT POSTCODE',  width: columnWidths['agenT_POSTCODE'] || 75, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value!=null ||params.value !=undefined?params.value : <span>&nbsp;</span>}
        </div>
      ),},
    { field: 'appeaL_NUMBER', headerName: 'APPEAL NUMBER',   width: columnWidths['id'] || 75, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value!=null ||params.value !=undefined?params.value : <span>&nbsp;</span>}
        </div>
      ),},
    { field: 'suB_PROGRAMME_ID', headerName: 'SPROG ID',  width: columnWidths['appeaL_NUMBER'] || 75, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value!=null ||params.value !=undefined?params.value : <span>&nbsp;</span>}
        </div>
      ),},
    {
      field: 'starT_DATE', headerName: 'START DATE',  width: columnWidths['starT_DATE'] || 75, headerAlign: 'left',align:'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return formatDateToString(value)
      }
    },
    {
      field: 'targeT_DATE', headerName: 'TARGET DATE',  width: columnWidths['targeT_DATE'] || 75, headerAlign: 'left',align:'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return formatDateToString(value)
      }
    },
    {
      field: 'tribunaL_DATE', headerName: 'TRIBUNAL DATE',  width: columnWidths['tribunaL_DATE'] || 75, headerAlign: 'left',align:'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return formatDateToString(value)
      }
    },
    { field: 'tribunaL_VENUE', headerName: 'TRIBUNAL VENUE',width: columnWidths['tribunaL_VENUE'] || 100, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value!=null ||params.value !=undefined?params.value : <span>&nbsp;</span>}
        </div>
      ),},
    { field: 'tribunaL_START_TIME', headerName: 'TRIBUNAL START TIME', width: columnWidths['tribunaL_START_TIME'] || 75 , headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value!=null ||params.value !=undefined?params.value : <span>&nbsp;</span>}
        </div>
      ),},
    { field: 'landS_TRIBUNAL_INDICATOR', headerName: 'LANDS TRIBUNAL',  width: columnWidths['landS_TRIBUNAL_INDICATOR'] || 50 , headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value!=null ||params.value !=undefined? 'YES': <span>&nbsp;</span>}
        </div>
      ),}
  ]

  const columnGroupingModel: GridColumnGroup[] = [
    {
      groupId: 'ADDRESS/STATUS',
      children: [{ field: 'firm_name' },{ field: 'appeaL_STATUS' }, { field: 'address' }, { field: 'lisT_YEAR' }, { field: 'schedulE_DATE' }, { field: 'postcode' }, { field: 'status' },{ field: 'firM_NAME' }]  
      ,headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: 'HEREDITAMENT INFORMATION',
      children: [ { field: 'description' }, { field: 'psD_CODE' }, { field: 'scat' }, { field: 'bA_REFERENCE_NUMBER' },{field:'assessmenT_REFERENCE'},{ field: 'composite' }]
      , headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: 'VT SPECIFIC INFORMATION',
      children:[{field:'rateablE_VALUE'},{field:'effectivE_DATE'},{field:'agenT_NAME'},{field:'agenT_POSTCODE'},{field:'appeaL_NUMBER'},{field:'suB_PROGRAMME_ID'},{field:'starT_DATE'},{field:'targeT_DATE'},{field:'tribunaL_VENUE'},{field:'tribunaL_START_TIME'},{field:'landS_TRIBUNAL_INDICATOR'},{field:'tribunaL_DATE'}]
      ,headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    }
  ]
const rows = valuationTribunalData || [];
  return (
    <div style={{ height: '100%', width: '100%' }} className='layoutrow'>
      <div className={selectedRow && selectedRow.decisioN_TEXT?'mainvtstable':'mainvtstable full'}>
      {rows.length > 0 &&
      <>
        <DataGridPro rows={rows} columns={columns} rowHeight={22} getRowId={(row: ValuationTribunalResults) => row.key_field!}
          
          columnHeaderHeight={22}  
          sortingMode="server"
          columnGroupingModel={columnGroupingModel}
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          apiRef={apiRef}
          onRowsScrollEnd={handleRowsScrollEnd}
          onSortModelChange={(model) => handleRowOrderChange(model)}
          autosizeOptions={{
            includeOutliers: false,
            includeHeaders: false,
          }}
          onClipboardCopy={onClipboardCopy}
          onRowClick={handleRowClick}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
          onColumnWidthChange={handleColumnResize}
          hideFooter
        />
        <TableRightClickMenu
        contextMenuPosition={contextMenuPosition}
        handleClose={handleCloseContextMenu}
        handleMenuItemClick={handleMenuItemClick}
        table={3}
      />
          
      </>
    }
    </div>
    {selectedRow && selectedRow.decisioN_TEXT&&
      <div className='tribunaldecisiontab layoutcolumn'>
        <div className='header'>Decision Dated: {formatDateToString(selectedRow.decisioN_DATE)}</div>
        {selectedRow.decisioN_TEXT != '- Word' &&<div className='content'>{selectedRow.decisioN_TEXT}</div>}
        {selectedRow.decisioN_TEXT == '- Word' &&<div className='content'><Button variant='contained' className='tribunalbutton' onClick={()=>handleDecisionClick()}>Click To Show Full Decision</Button></div>}
      </div>
    
    }     
      {report && 
      <ReportWindow reportString = {report} onClose={()=>setReport(undefined)}></ReportWindow>
      }
      {showNoScheme&&
        <ClickAwayListener onClickAway={(event) =>(console.log(event),setShowNoScheme(false))}>
          <div className='noschemepopup'>
          <div className='closeme' onClick={()=>setShowNoScheme(false)}>
            <CloseIcon />
          </div>
              <div className='popupheader'>Analyse</div>
              <div className='popupbody'>Valuation Scheme Not Available</div>
              <Button variant='outlined' className='popupbutton' onClick={()=>setShowNoScheme(false)}>OK</Button>
          </div>
        </ClickAwayListener>
          }
          {
            searchEmpty &&
            <ClickAwayListener onClickAway={(event) => (setSearchEmpty(false))}>
              <div className='searchemptypopup'>
                <div className='closeme' onClick={() => setSearchEmpty(false)}>
                  <CloseIcon />
                </div>
                <div className='popupheader'>Analyse</div>
                <div className='popupbody'>No Assessments found - Please refine your search</div>
                <Button variant='outlined' className='popupbutton' onClick={() => setSearchEmpty(false)}>OK</Button>
              </div>
            </ClickAwayListener>
          }
      {rows.length ==0 &&
        <HomeScreenComponent/>        
      }
  </div>
  );
}