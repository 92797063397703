import { TrendLogic } from "./Enums/TrendLogic";


export default class IntegratedListTrends {
    list2023?: boolean;
    list2023Logic?: TrendLogic;
    list2023Amount?: number;

    list2023v2017?:boolean;
    list2023Logicv2017?: TrendLogic;
    list2023Amountv2017?: number;

    list2017?: boolean;
    list2017Logic?: TrendLogic;
    list2017Amount?: number;

    list2017v2010?: boolean;
    list2017Logicv2010?: TrendLogic;
    list2017Amountv2010?: number;

    list2010?: boolean;
    list2010Logic?: TrendLogic;
    list2010Amount?: number;

    list2010v2005?: boolean;
    list2010Logicv2005?: TrendLogic;
    list2010Amountv2005?: number;

    list2005?: boolean;
    list2005Logic?: TrendLogic;
    list2005Amount?: number;

    list2005v2000?: boolean;
    list2005Logicv2000?: TrendLogic;
    list2005Amountv2000?: number;

    list2000?: boolean;
    list2000Logic?: TrendLogic;
    list2000Amount?: number;

    list2000v1995?: boolean;
    list2000Logicv1995?: TrendLogic;
    list2000Amountv1995?: number;

    list1995?: boolean;
    list1995Logic?: TrendLogic;
    list1995Amount?: number;

    list1995v1990?: boolean;
    list1995Logicv1990?: TrendLogic;
    list1995Amountv1990?: number;

    list1990?: boolean;
    list1990Logic?: TrendLogic;
    list1990Amount?: number;

    constructor(data?: Partial<IntegratedListTrends>) {
        Object.assign(this, {
            list2023: false,
            list2023Logic: 0,
            list2023Amount: 0,
            list2023v2017: false,
            list2023Logicv2017: 0,
            list2023Amountv2017: 0,
            list2017: false,
            list2017Logic: 0,
            list2017Amount: 0,
            list2017v2010: false,
            list2017Logicv2010: 0,
            list2017Amountv2010: 0,
            list2010: false,
            list2010Logic: 0,
            list2010Amount: 0,
            list2010v2005: false,
            list2010Logicv2005: 0,
            list2010Amountv2005: 0,
            list2005: false,
            list2005Logic: 0,
            list2005Amount: 0,
            list2005v2000: false,
            list2005Logicv2000: 0,
            list2005Amountv2000: 0,
            list2000: false,
            list2000Logic: 0,
            list2000Amount: 0,
            list2000v1995: false,
            list2000Logicv1995: 0,
            list2000Amountv1995: 0,
            list1995: false,
            list1995Logic: 0,
            list1995Amount: 0,
            list1995v1990: false,
            list1995Logicv1990: 0,
            list1995Amountv1990: 0,
            list1990: false,
            list1990Logic: 0,
            list1990Amount: 0,
            ...data,
        });
    }

    serialize(): Partial<IntegratedListTrends> {
        return {
            list2023: this.list2023,
            list2023Logic: this.list2023Logic,
            list2023Amount: this.list2023Amount,
            list2023v2017: this.list2023v2017,
            list2023Logicv2017: this.list2023Logicv2017,
            list2023Amountv2017: this.list2023Amountv2017,
            list2017: this.list2017,
            list2017Logic: this.list2017Logic,
            list2017Amount: this.list2017Amount,
            list2017v2010: this.list2017v2010,
            list2017Logicv2010: this.list2017Logicv2010,
            list2017Amountv2010: this.list2017Amountv2010,
            list2010: this.list2010,
            list2010Logic: this.list2010Logic,
            list2010Amount: this.list2010Amount,
            list2010v2005: this.list2010v2005,
            list2010Logicv2005: this.list2010Logicv2005,
            list2010Amountv2005: this.list2010Amountv2005,
            list2005: this.list2005,
            list2005Logic: this.list2005Logic,
            list2005Amount: this.list2005Amount,
            list2005v2000: this.list2005v2000,
            list2005Logicv2000: this.list2005Logicv2000,
            list2005Amountv2000: this.list2005Amountv2000,
            list2000: this.list2000,
            list2000Logic: this.list2000Logic,
            list2000Amount: this.list2000Amount,
            list2000v1995: this.list2000v1995,
            list2000Logicv1995: this.list2000Logicv1995,
            list2000Amountv1995: this.list2000Amountv1995,
            list1995: this.list1995,
            list1995Logic: this.list1995Logic,
            list1995Amount: this.list1995Amount,
            list1995v1990: this.list1995v1990,
            list1995Logicv1990: this.list1995Logicv1990,
            list1995Amountv1990: this.list1995Amountv1990,
            list1990: this.list1990,
            list1990Logic: this.list1990Logic,
            list1990Amount: this.list1990Amount
        };
    }
}