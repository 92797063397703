export default class AuthorityScheduleExcel {
    schedulE_DATE?: Date;
    lisT_YEAR?: number;
    status?:string;
    firM_NAME?: string;
    address?: string;
    postcode?: string;
    bA_REFERENCE_NUMBER?: string;
    assessmenT_REFERENCE?: string;
    description?: string;
    psD_CODE?: string;
    scat?:string;
    rateablE_VALUE?: number;
    effectivE_DATE?: Date;
    lisT_ALTERATION_DATE?: Date;
    settlemenT_TYPE?:string;
    composite?: string;
    voA_CASE?: string;
    casE_TYPE?: string;
    reporT_NUMBER?: string;
    changE_REASON?: string;
    certified?: string;
}