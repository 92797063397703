
import { UserLoginModel } from '../Models/UserLoginModel';
import { UserTokens } from '../Models/UserTokens';
import { post } from './CommonApiHelper';
//import User from '../Models/User';

let setAPIURL="";
export async function getConfig() {

    let config = "";
    await fetch("./settings.json", {
        method: "GET",
    })
        .then((response) => response.json())
        .then((json) => {
            config = json.appSettings.API;
            setAPIURL = config
        })
        return config;
}
export async function loginUser(loginobject: UserLoginModel) {
    return await post<UserTokens>(setAPIURL+"api/Authenticate/Login", loginobject);
}

export async function refreshTokens(tokens: UserTokens) {
    return await post<UserTokens>(setAPIURL+"api/Authenticate/refresh-token", tokens);
}

export async function InitPasswordReset(email){
    return await post(setAPIURL+`api/Authenticate/InitiatePasswordReset?email=${email}`,{})
}

export async function ResetPasswordWithToken(request){
    return await post(setAPIURL+`api/Authenticate/ResetPasswordWithToken`,request)
}
// export async function GetBaseUrl(config: string) {
//     let baseUrl = "";
//     await fetch(config+`Token/GetBaseUrl`, {
//         method: "GET"
//     })
//         .then((response) => response.text())
//         .then((json) => {
//             baseUrl = json
//         })
//     return baseUrl;
// }

// export const TokenValidation = async (token: string, config: string) => {
//     let tokenValid = false;
//     if (token) {
//         await fetch(config+`Token/ValidateToken?token=${encodeURIComponent(token)}`,
//             {
//                 method: 'POST',
//                 headers: {
//                     "Content-Type": "application/json"
//                 }
//             }
//         ).then(response => {
//             tokenValid = response.ok;
//         });
//     }
//     return tokenValid;
// }