import IntegratedListTrends from "../IntegratedListTrends";
import IntegratedSettlement from "../IntegratedSettlement";
import IntegratedListAdvancedSearch from "../IntegratedListAdvancedSearch";
import BasicSearchApiModel from "./BasicSearchApiModel";
import IntegratedListTrendsApiModel from "./IntegratedListTrendsApiModel"
import IntegratedListSettlementApiModel from "./IntegratedListSettlementApiModel";
import IntegratedListAdvancedApiModel from "./IntegratedListAdvancedApiModel";
import { ValidationError } from "../ValidationError";

export default class IntegratedSearch {
    basic?: BasicSearchApiModel;
    advanced?: IntegratedListAdvancedApiModel;
    settlement?: IntegratedListSettlementApiModel;
    trends?: IntegratedListTrendsApiModel;
    pageno: number = 0;
    sortcolumn?: string = "l.key_field";
    sortorder?: string = "asc";
    //sqldump?:boolean;
    totals:boolean=false;
    report:boolean = false
}
export function validateIntegratedSearch(model: IntegratedSearch): ValidationError[] {
    let errors: ValidationError[] = [];
  
    if (model.basic) {
      errors = errors.concat(model.basic.validate().errors);
    }
  
  
    return errors;
  }