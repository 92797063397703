export enum StatusList {
    'All' = 0,
    'Acknowledged' = 1,
    'Acknowledged Listed' = 2,
    'Withdrawn' = 3,
    'Ageed' = 4,
    'Decided' = 5,
    'Agreed Decided' = 6,
    'Completion Notice Listed' = 7,
    'Completion Notice Acknowledged' = 8,
    'Completion Notice Decision' = 9,
    'Penalty Notice' = 10
}