import { StartsWithContains } from "./Enums/StartsWithContains";
import {ScheduleDateLogic} from "./Enums/ScheduleDateLogic"
import {ListTypeLogic} from "./Enums/ListTypeLogic"
import {CertificationLogic} from "./Enums/CertificationLogic"
import {CaseTypeLogic} from "./Enums/CaseTypeLogic"

export default class AuthorityScheduleAdv1 {
    scheduleDateLogic?: ScheduleDateLogic;
    scheduleDate?: string;
    scheduleFromDate?: string;
    scheduleToDate?: string;
    listYear?: number;
    listTypeLogic?: ListTypeLogic;
    certification?: boolean;
    listCertificationLogic?: CertificationLogic;
    changeReason?: string;
    changeReasonLogic?: StartsWithContains;
    voaCaseReferenceNumber?: string;
    caseType?: string;
    listcaseTypeLogic?: CaseTypeLogic;
    
        constructor(data?: Partial<AuthorityScheduleAdv1>) {
            Object.assign(this, {
                scheduleDateLogic: 0,
                scheduleDate: '',
                scheduleFromDate: '',
                scheduleToDate: '',
                listYear: 0,
                listTypeLogic: 0,
                certification: false,
                listCertificationLogic: 0,
                changeReason: '',
                changeReasonLogic: 0,
                voaCaseReferenceNumber: '',
                caseType: '',
                listcaseTypeLogic: 0,
                ...data,
            });
        }
    
        serialize(): Partial<AuthorityScheduleAdv1> {
            return {
                scheduleDateLogic: this.scheduleDateLogic,
                scheduleDate: this.scheduleDate,
                scheduleFromDate: this.scheduleFromDate,
                scheduleToDate: this.scheduleToDate,
                listYear: this.listYear,
                listTypeLogic: this.listTypeLogic,
                certification: this.certification,
                listCertificationLogic: this.listCertificationLogic,
                changeReason: this.changeReason,
                changeReasonLogic: this.changeReasonLogic,
                voaCaseReferenceNumber: this.voaCaseReferenceNumber,
                caseType: this.caseType,
                listcaseTypeLogic: this.listcaseTypeLogic
            };
        }
    }