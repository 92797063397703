import React, { useEffect, useRef, useState } from 'react';
import { DataGridPro, GridRowsProp, GridColDef, useGridApiRef, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import AuthorityScheduleSearch, { validateAuthorityScheduleSearch } from '../../Models/SearchApiModels/AuthorityScheduleSearch';
import AuthorityScheduleResults from '../../Models/AuthorityScheduleResults';
import { cleanup } from '@testing-library/react';
import AuthorityScheduleAdv1 from '../../Models/AuthorityScheduleAdv1';
import store from '../../Redux/ConfigureStore';
import BasicSearchApiModel from '../../Models/SearchApiModels/BasicSearchApiModel';
import { formatDateToString, FormatNumberWithCommas } from '../../Services/FormatService';
import FlagComponent from '../SubComponents/FlagComponent';
import AuthorityScheduleAdv2ApiModel from '../../Models/SearchApiModels/AuthorityScheduleAdv2ApiModel';
import AuthorityScheduleAdv1ApiModel from '../../Models/SearchApiModels/AuthorityScheduleAdv1ApiModel';
import RvHistory from '../../Models/SearchApiModels/RvHistoryModel';
import RvHistoryGraph from '../RvHistoryGraph';
import SurveyAdjustments from '../../Models/SearchApiModels/SurveyAdjustments';
import SurveyAditionalItems from '../../Models/SearchApiModels/SurveyAdditionalItems';
import {selectListYear} from '../../Redux/SearchSlicer';
import { useSelector } from 'react-redux';



export default function SVAdditional(props) {

  const summaryListYear = useSelector(selectListYear);

  const {uarn,data} = props

  const [localLoading, setLocalLoading] = useState(true);
  let currentId = useRef(0);
  const apiRef = useGridApiRef();
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [contextMenuPosition, setContextMenuPosition] = useState<{ mouseX: number | null; mouseY: number | null }>({ mouseX: null, mouseY: null });
  const [selectedRow, setSelectedRow] = useState(null);
  const [additionalData,setAdditionData]= useState([])
  var pageNo = 0
  const getRowId = () => {
    return currentId.current++;
  };
  useEffect(()=>{
    const updatedData = data.map(item => ({
      ...item,
      key_field: getRowId(),
    }));
  
    setAdditionData(updatedData);
  },[data])



  const handleContextMenu = (event, row) => {
    event.preventDefault();

    if (event.shiftKey) {
      console.log('Shift key is held down.');
    }
    else {
      if (event.ctrlKey) {
        console.log('Control key is held down.');
      }
      else {
        if(rowSelectionModel.findIndex(elem=>row.keY_FIELD == elem) > -1){
        }
        else{
          setRowSelectionModel([row.keY_FIELD]);

        }
          
      }
    }

   
  };
 
  const columns: GridColDef[] = [

    { field: 'oA_Description', headerName: 'DESCRIPTION', width: 185 },
    { field: 'oA_Size', headerName: 'SIZE(M2)', width: 85, align:'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>  
         {params.value != null && FormatNumberWithCommas(params.value)}       
        </div>
      ),
      },
    { field: 'oA_Price', headerName: 'PRICE(£/M2)', width: 105, align:'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>  
         {params.value != null && FormatNumberWithCommas(params.value)}       
        </div>
      ),
     },
    { field: 'oA_Value', headerName: 'VALUE(£)', width: 100, align:'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>  
         {params.value != null && FormatNumberWithCommas(params.value)}       
          {params.value == null && <span>DELETED</span>}
        </div>
      ),
    },
    
  ]
  const rows = additionalData || []; 

  // if (loading) {
  //   return (
  //     <div className="loading">
  //       <p>Loading...</p>
  //     </div>
  //   );
  // }


  return (
     
    <>
    {rows.length > 0  ? (
     <div style={{ height: '100%', width: '34.5%' }}>
   
       
         <DataGridPro rows={rows} columns={columns} rowHeight={22} columnHeaderHeight={22}
           
           sortingMode="server"
           getRowId={(row: SurveyAditionalItems) => row.key_field!}
        
           apiRef={apiRef}
           getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
          }
           sortingOrder={['asc', 'desc']}
           hideFooter ={true}
          
           autosizeOptions={{
             includeOutliers: false,
             includeHeaders: false,
           }}
         />
       
    
    
     </div>
     ):
     (<>
      {<p style={{marginLeft: '30px', marginTop: '30px'}}>No data available</p>}
    </>)
    }
    </>
    
   );


  
}