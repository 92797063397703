import { GridRowId } from "@mui/x-data-grid";
import store from "../Redux/ConfigureStore";

export function formatDateToString(dateString) {
  if (dateString == null || dateString == '' || dateString == '0001-01-01T00:00:00') {
    return ''
  }
  if (dateString == 'COMPILED') {
    return dateString
  }
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return ''
  }

  const formattedDate = new Intl.DateTimeFormat('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }).format(date).toString();

  return formattedDate;
}
export const formatDateForApi=(raw)=>{
  let date = new Date(raw); 

  let year = date.getFullYear();
  let month = String(date.getMonth() + 1).padStart(2, '0');
  let day = String(date.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}T00:00:00.000`;
}
const currencyFormatter = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
});
const currencyFormatterWithoutDecimal = new Intl.NumberFormat('en-GB', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const formatCurrency = (value) => {
  if (value === null || value === undefined) {
    return null;
  }
  return currencyFormatter.format(value);
};
export const formatCurrencywithoutDecimals= (value) => {
  if (value === null || value === undefined) {
    return null;
  }
  return currencyFormatterWithoutDecimal.format(value);
};

export const formatScheduleStatus = (value) => {
  if (value === null || value === undefined) {
    return null;
  }
  switch (value) {
    case '2':
      return 'LIVE';
    case '3':
      return '3';
    case '4':
      return 'HISTORIC';
    case 'D':
      return 'DRAFT';
    default:
      return null;
  }
};
export const formatVTSStatus = (value) => {
  if (value === null || value === undefined) {
    return null;
  }
  switch (value) {
    case 'CD':
      return 'DECIDED';
    case 'CG':
      return 'ADREED';
    case 'CW':
      return 'WITHDRAWN';
    case 'CR':
      return 'COMPLETION NOTICE ACKNOWLEDGED';
    case 'NL':
      return 'COMPLETION NOTICE LISTED';
    case 'CP':
      return 'PENALTY NOTICE DECISION';
    case 'CN':
      return 'COMPLETION NOTICE DECISION';
    case 'OL':
      return 'LISTED';
    case 'OR':
      return 'ACKNOWLEDGED';
    default:
      return null;
  }
};
export const stringifyList = (data: Array<string> | Array<number> | Array<GridRowId>) => {
  var stringValue = '';
  var length = data.length;
  data.forEach((element, index) => {

    stringValue += String(element);
    if (index < length - 1) {
      stringValue += ',';
    }
  });
  return stringValue;
}
export const FormatNumberWithCommas = (number: number) => {

  if (number != null || number != undefined) {
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return parts.join(".");
  }
  else {
    return null
  }
}

export const FormatSettlementCode = (list, code) => {
  if (list == null || list == undefined) {
    return null
  }
  if (code == null || code == undefined) {
    return null
  }
  const trimmedCode = code.trim();
  switch (list) {
    case 2023:
      var settlement = store.getState().preloadedData.Settlement2023.find(item=>item.value == trimmedCode)
      if(settlement){
        return settlement.label;
      }
      return code

    case 2017:
      var settlement = store.getState().preloadedData.Settlement2017.find(item=>item.value == trimmedCode)
      if(settlement){
        return settlement.label;
      }
      return code

    case 2010:
      var settlement = store.getState().preloadedData.Settlement2010.find(item=>item.value == trimmedCode)
      if(settlement){
        return settlement.label;
      }
      return code

    case 2005:
      var settlement = store.getState().preloadedData.Settlement2005.find(item=>item.value == trimmedCode)
      if(settlement){
        return settlement.label;
      }
      return code

    case 2000:
      var settlement = store.getState().preloadedData.Settlement2000.find(item=>item.value == trimmedCode)
      if(settlement){
        return settlement.label;
      }
      return code

    case 1995:
      var settlement = store.getState().preloadedData.Settlement1995.find(item=>item.value == trimmedCode)
      if(settlement){
        return settlement.label;
      }
      return code

    case 1990:
      var settlement = store.getState().preloadedData.Settlement1990.find(item=>item.value == trimmedCode)
      if(settlement){
        return settlement.label;
      }
      return code

  }
}