import { FloorHasLogic } from "../Enums/FloorHasLogic";
import { MeasurementBasis } from "../Enums/MeasurementBasis";
import { SearchLogic } from "../Enums/SearchLogic";
import { StartsWithContains } from "../Enums/StartsWithContains";
import { SurveyLinesSurvey } from "../SurveyLinesSurvey";

  export default class SurveyLineSearchApiModel {
        // FLOOR BLOCK
        floorLogic?: StartsWithContains;
        floorSearch?: string;
        floorSpecifics?: boolean;
        floorHas?: FloorHasLogic;
        floorHasLogic?: SearchLogic;
        floorHasValue?: number;
        floorValueLessThan?: number;
    
        // SURVEY LINE BLOCK
        surveyLineLogic?: StartsWithContains;
        surveyLineSearch?: string;
        linkFloors?: boolean;
        surveySpecifics?: boolean;
        surveyHas?: FloorHasLogic;
        surveyLogic?: SearchLogic;
        surveyHasValue?: number;
        surveyHasValueLessThan?: number;
    
        // ADDITIONAL ITEMS BLOCK
        additionalItemsLogic?: StartsWithContains;
        additionalItemSearch?: string;
        additionalSpecifics?: boolean;
        additionalHas?: FloorHasLogic;
        additionalHasLogic?: SearchLogic;
        additionalHasValue?: number;
        additionalHasValueLessThan?: number;
    
        // ADJUSTMENT ITEMS BLOCK
        adjustmentsLogic?: StartsWithContains;
        adjustmentsSearch?: string;
        adjustmentsPercentage?: boolean;
        adjustmentsPercentageLogic?: SearchLogic;
        adjustmentsPercentageValue?: number;
        adjustmentsPercentageLessThan?: number;
    
        // OTHER
        measurementBasis?: MeasurementBasis;
        constructor(data: Partial<SurveyLinesSurvey>){
          
            this.floorSearch = (data.floorSearch &&data.floorSearch !='')? data.floorSearch :undefined ;
            this.floorLogic = (data.floorSearch &&data.floorSearch !='')? Number(data.floorLogic):undefined ;
            this.floorSpecifics = (data.floorSearch &&data.floorSearch !='')? data.floorSpecifics: undefined;
            this.floorHas= (data.floorSearch &&data.floorSearch !='' && data.floorSpecifics)? Number(data.floorHas):undefined;
            this.floorHasLogic=(data.floorSearch &&data.floorSearch !='' && data.floorSpecifics) ? Number(data.floorHasLogic):undefined;
            this.floorHasValue= (data.floorSearch &&data.floorSearch !='' && data.floorSpecifics) ? Number(data.floorHasValue):undefined;
            this.floorValueLessThan= (data.floorSearch &&data.floorSearch !='' && data.floorHasLogic == 3) ?Number(data.floorValueLessThan):undefined;
        
            // SURVEY LINE BLOCK
            this.surveyLineLogic = (data.surveyLineSearch &&data.surveyLineSearch !='')? Number(data.surveyLineLogic):undefined ;
            this.surveyLineSearch = (data.surveyLineSearch &&data.surveyLineSearch !='')? data.surveyLineSearch :undefined ;
            this.linkFloors = (data.surveyLineSearch &&data.surveyLineSearch !=''  && data.floorSearch &&data.floorSearch !='' )?  data.linkFloors: undefined;
            this.surveySpecifics = (data.surveyLineSearch &&data.surveyLineSearch !='')? data.surveySpecifics: undefined;
            this.surveyHas= (data.surveyLineSearch &&data.surveyLineSearch !='' && data.surveySpecifics)? Number(data.surveyHas):undefined;
            this.surveyLogic=(data.surveyLineSearch &&data.surveyLineSearch !='' && data.surveySpecifics) ? Number(data.surveyLogic):undefined;
            this.surveyHasValue= (data.surveyLineSearch &&data.surveyLineSearch !='' && data.surveySpecifics) ? Number(data.surveyHasValue):undefined;
            this.surveyHasValueLessThan= (data.surveyLineSearch &&data.surveyLineSearch !='' && Number(data.surveyLogic == 3)) ?Number(data.surveyHasValueLessThan):undefined;
        
            // ADDITIONAL ITEMS BLOCK
            this.additionalItemsLogic = (data.additionalItemSearch &&data.additionalItemSearch !='')? Number(data.additionalItemsLogic):undefined ;
            this.additionalItemSearch = (data.additionalItemSearch &&data.additionalItemSearch !='')? data.additionalItemSearch :undefined ;
            this.additionalSpecifics= (data.additionalItemSearch &&data.additionalItemSearch !='')? data.additionalSpecifics: undefined;
            this.additionalHas =  (data.additionalItemSearch &&data.additionalItemSearch !='' && data.additionalSpecifics)? Number(data.additionalHas):undefined;
            this.additionalHasLogic = (data.additionalItemSearch &&data.additionalItemSearch !='' && data.additionalSpecifics) ? Number(data.additionalHasLogic):undefined;
            this.additionalHasValue= (data.additionalItemSearch &&data.additionalItemSearch !='' && data.additionalSpecifics) ? Number(data.additionalHasValue):undefined;
            this.additionalHasValueLessThan= (data.additionalItemSearch &&data.additionalItemSearch !='' && Number(data.additionalHasLogic == 3)) ?Number(data.additionalHasValueLessThan):undefined;
        
            // ADJUSTMENT ITEMS BLOCK
            this.adjustmentsLogic = (data.adjustmentsSearch &&data.adjustmentsSearch !='')? Number(data.adjustmentsLogic):undefined ;
            this.adjustmentsSearch= (data.adjustmentsSearch &&data.adjustmentsSearch !='')? data.adjustmentsSearch :undefined ;
            this.adjustmentsPercentage= (data.adjustmentsSearch &&data.adjustmentsSearch !='')? data.adjustmentsPercentage :undefined ;
            this.adjustmentsPercentageLogic= (data.adjustmentsSearch &&data.adjustmentsSearch !='' && data.adjustmentsPercentage)? Number(data.adjustmentsPercentageLogic):undefined;
            this.adjustmentsPercentageValue= (data.adjustmentsSearch &&data.adjustmentsSearch !='' && data.adjustmentsPercentage) ? Number(data.adjustmentsPercentageValue):undefined;
            this.adjustmentsPercentageLessThan= (data.adjustmentsSearch &&data.adjustmentsSearch !='' && Number(data.adjustmentsPercentageLogic) == 3) ?Number(data.adjustmentsPercentageLessThan):undefined;
        
            // OTHER
            this.measurementBasis =  Number(data.measurementBasis);
        }
  }