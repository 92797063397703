import { DateSearchLogic } from "./Enums/DateSearchLogic";
import { SearchLogic } from "./Enums/SearchLogic";

export default class AuthorityScheduleAdv2 {
    rvSearch?: boolean;
    rvLogic?: SearchLogic;
    rvValue?: number;
    rvValueLessThan?: number;
    edSearch?: boolean;
    edLogic?: DateSearchLogic;
    edValue?: string;
    edValueLessThan?: string;
    ladSearch?: boolean;
    ladLogic?: DateSearchLogic;
    ladValue?: string;
    ladValueLessThan?: string;
    partDomestic?: boolean;
    partDomesticLogic?: boolean;
    
    constructor(data?: Partial<AuthorityScheduleAdv2>) {
        Object.assign(this, {
            rvSearch: false,
            rvLogic: 0,
            rvValue: 0,
            rvValueLessThan: 0,
            edSearch: false,
            edLogic: 0,
            edValue: undefined,
            edValueLessThan: undefined,
            ladSearch: false,
            ladLogic: 0,
            ladValue: undefined,
            ladValueLessThan: undefined,
            partDomestic: false,
            partDomesticLogic:false,
            ...data,
        });
    }

    serialize(): Partial<AuthorityScheduleAdv2> {
        return {
            rvSearch: this.rvSearch,
            rvLogic: this.rvLogic,
            rvValue: this.rvValue,
            rvValueLessThan: this.rvValueLessThan,
            edSearch: this.edSearch,
            edLogic: this.edLogic,
            edValue: this.edValue,
            edValueLessThan: this.edValueLessThan,
            ladSearch: this.ladSearch,
            ladLogic: this.ladLogic,
            ladValue: this.ladValue,
            ladValueLessThan: this.ladValueLessThan,
            partDomestic: this.partDomestic,
            partDomesticLogic:this.partDomesticLogic
        };
    }
}