import { useEffect } from "react";
import ListBuilderRow from "../../Models/ListBuilderRow";
import { DataGridPro, GridRowsProp, GridColDef, useGridApiRef, GridRowSelectionModel, GridColumnGroup, GridApiPro } from '@mui/x-data-grid-pro';
import { useSelector } from "react-redux";
import { resetListBuilderTableSort, selectResetListBuilderTableSort } from "../../Redux/ListBuilderSlicer";
import store from "../../Redux/ConfigureStore";
import ListBuilderRightClickMenu from "./TableContextMenus/ListBuilderRightClickMenu";
import IntegratedListResults from "../../Models/IntegratedListResult";

export default function ListBuilderTable(props){
    const {contextMenuPosition,handleCloseContextMenu,handleColumnWidthChange,handleMenuItemClick,columnGroupingModel,rows,rowSelectionModel,setRowSelectionModel,handleRowClick,setTableState,tableState,triggerSaveTableState,columns} = props
    
    const apiRef = useGridApiRef();
    const resetTableSort = useSelector(selectResetListBuilderTableSort); 
    useEffect(() => {
      if (tableState != null && apiRef.current) {
        apiRef.current.restoreState(tableState);
      }
      
    }, []);

    useEffect(() => {
      
      if (apiRef.current && triggerSaveTableState) {
        setTableState(apiRef.current.exportState());
      }
    }, [triggerSaveTableState]);
    useEffect(() => {
      if(resetTableSort){
        store.dispatch(resetListBuilderTableSort(false));
        apiRef?.current.setSortModel([]);
      }
    }, [resetTableSort])
    
    const onClipboardCopy = (data, event, { api }) => {
      const visibleColumns = apiRef.current.getVisibleColumns();
      
      const columnsAsString = visibleColumns.filter(col => col.field !== '__check__').map(col => col.headerName).join('\t');
      navigator.clipboard.writeText(columnsAsString + '\n' + data);
    }

    return(
        <>
        <DataGridPro
                  columns={columns}
                  rowHeight={22}
                  rows={rows}
                  apiRef={apiRef}
                  columnHeaderHeight={22}      
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                  }
                  onClipboardCopy={onClipboardCopy}
                  getRowId={(row: IntegratedListResults) => row.keY_FIELD!}
                  columnGroupingModel={columnGroupingModel}
                  onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                  pagination={false}
                  onRowClick={handleRowClick}
                  rowSelectionModel={rowSelectionModel}
                  onColumnWidthChange={handleColumnWidthChange}
                  autosizeOptions={{
                    columns: ['name', 'status', 'createdBy'],
                    includeOutliers: true,
                    includeHeaders: false,
                  }}
                  hideFooter
                />
            <ListBuilderRightClickMenu
            contextMenuPosition={contextMenuPosition}
            handleClose={handleCloseContextMenu}
            handleMenuItemClick={handleMenuItemClick}
          />
        </>
    )
}