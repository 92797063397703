import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectDisplayDetails, selectHeaderBarContext, selectListYear, selectTableContext, setTableContext } from "../Redux/SearchSlicer";
import IntegratedListTable from "./TableComponents/IntegratedListTable";
import SummaryValuationTable from "./TableComponents/SummaryValuationTable";
import AuthorityScheduleTable from "./TableComponents/AuthorityScheduleTable";
import ValuationTribunalTable from "./TableComponents/ValuationTribunalTable";
import IntegratedListSearchComponent from "./SearchComponents/IntegratedListSearchComponent";
import AuthorityScheduleSearchComponent from "./SearchComponents/AuthorityScheduleSearchComponent";
import ValuationTribunalSearchComponent from "./SearchComponents/ValuationTribunalSearchComponent";
import SummaryValuationSearchComponent from "./SearchComponents/SummaryValuationSearchComponent";
import SummaryValuationPopover from "./TableComponents/TablePopovers/SummaryValuationPopover";
import DetailsPopover from "./TableComponents/TablePopovers/DetailsPopover";
import HomeScreenComponent from "./SubComponents/HomeScreenComponent";

export default function RouteComponent(props) {
    const tableContextEvent = useSelector(selectTableContext);
    const [tableContext, setTableContext] = useState(0);
    const [sVMode, setSVMode] = useState(0);

    const [showTable, setShowTable] = useState(false);
    const [popOverUARN, setPopOverUARN] = useState();
    const [popoverKeyField, setPopOverKeyfield] = useState();
    const headerBarContext = useSelector(selectHeaderBarContext);
    const { searchCount,handleSearchClick } = props;
    const displayDetails = useSelector(selectDisplayDetails);
    const summaryListYear = useSelector(selectListYear);

    useEffect(() => {
        setTableContext(tableContextEvent);
    }, [tableContextEvent]);
    const handleUARNChange = (uarn,keyid = undefined) => {
        setPopOverUARN(uarn)
        setPopOverKeyfield(keyid);
    }  
    return (
        <>
            {headerBarContext ==0&&
            <div className="searchcontainer" onKeyDown={(e) => e.key === 'Enter' && handleSearchClick()}>
                {tableContext == 0 && <IntegratedListSearchComponent searchCount={searchCount}  />}
                {tableContext == 1 && <SummaryValuationSearchComponent searchCount={searchCount}  />}
                {tableContext == 2 && <AuthorityScheduleSearchComponent searchCount={searchCount} />}
                {tableContext == 3 && <ValuationTribunalSearchComponent searchCount={searchCount} />}
            </div>
            }
            <div className={headerBarContext == 0 ?'tablecontainer' :'tablecontainer full'}>
                {searchCount > 0 && <>
                    <div className={popOverUARN &&popOverUARN!='' && displayDetails?"tablediv":"tablediv full"}>
                        {tableContext == 0 && <IntegratedListTable searchCount={searchCount} handleUARNChange={handleUARNChange}/>}
                        {tableContext == 1 && <SummaryValuationTable searchCount={searchCount} handleUARNChange={handleUARNChange}/>}
                        {tableContext == 2 && <AuthorityScheduleTable searchCount={searchCount} handleUARNChange={handleUARNChange}/>}
                        {tableContext == 3 && <ValuationTribunalTable searchCount={searchCount} handleUARNChange={handleUARNChange}/>}
                    </div>
                    {popOverUARN&&popOverUARN!='' && displayDetails &&
                        <div className="tablepopoverdiv">
                            {tableContext != 1 && <DetailsPopover uarn={popOverUARN} listYear = {summaryListYear} svMode={sVMode} />}
                            {tableContext == 1 && <DetailsPopover uarn={popOverUARN} keyID = {popoverKeyField} listYear = {summaryListYear} routeTabContext ={tableContext}/>}
                            
                        </div>
                    }</>
                }
                {searchCount <1&&
                <>
                    <HomeScreenComponent/>
                </>
                }
            </div>
        </>
    )

}