
import {Regions} from "./Enums/Regions"
export default class AreaSearch{
    BACODE?: string;
    VOANNDRRVU?: string;
    VOOFFICELOCAL?: string;
    TOFFICE?: string;
    region?: Regions;
    constructor(data?: Partial<AreaSearch>) {
        Object.assign(this, {
            BACODE: '',
            VOANNDRRVU: '',
            VOOFFICELOCAL: '',
            TOFFICE: '',
            region: 0,
            ...data,
        });
    }

    serialize(): Partial<AreaSearch> {
        return {
            BACODE: this.BACODE,
            VOANNDRRVU: this.VOANNDRRVU,
            VOOFFICELOCAL: this.VOOFFICELOCAL,
            TOFFICE: this.TOFFICE,
            region: this.region,
        };
    }
}