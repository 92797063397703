import { useEffect, useState } from "react";
import BasicSearch from "../../Models/BasicSearch";
import store from "../../Redux/ConfigureStore";
import { setBasicSearch } from "../../Redux/SearchSlicer";
import { cleanup } from "@testing-library/react";
import { ClassNames } from "@emotion/react";

export default function BasicSearchComponent(props){
    const [searchData, setSearchData] = useState<BasicSearch | null>(null);
    const{searchCount} = props;
    useEffect(() => {

      setSearchData(new BasicSearch( store.getState().search.basicSearch));
      return () => {
          cleanup();
      };
  }, []);
  useEffect(() => {
    if(searchCount < 1){
      setSearchData(new BasicSearch( store.getState().search.basicSearch));
    }
    return () => {
        cleanup();
    };
}, [searchCount]);

    const handleChange = async(e) => {
        const { name, value } = e.target;
        const updatedSearchData = { ...searchData, [name]: value };
        setSearchData(new BasicSearch(updatedSearchData));
      };
      const handleDoubleClick = async(e) => {
        if(searchData){
        const { name } = e.target;
        var value = searchData[name+'logic'];
        value = value ==0 ? 1: 0 ;
        const updatedSearchData = { ...searchData, [name+'logic']: value };
        setSearchData(new BasicSearch(updatedSearchData));
        }
      };
      useEffect(() => {
        sendSearchData();
      }, [searchData]);

      const sendSearchData=()=>{
        if (searchData){
          store.dispatch(setBasicSearch(searchData.serialize()));

        }
      }
    return(
      <>{ searchData &&
        <div className="searchcomponentcategory">
          <div className="basicsearchinputgroup">
            <label htmlFor="firmname">Firm Name {searchData.firmnamelogic ==0?'Starts With':'Contains'}</label>
            <input
              className={"basicsearchinput" + (searchData.firmnamelogic == 0 ? "" : " contains")}
              type="text"
              id="firmname"
              name="firmname"
              value={searchData.firmname}
              onChange={handleChange}
              onDoubleClick={handleDoubleClick}
            />
          </div>
          <div className="basicsearchinputgroup">
            <label htmlFor="buildingName">Building Name {searchData.buildingNamelogic ==0?'Starts With':'Contains'}</label>
            <input
              className={"basicsearchinput" + (searchData.buildingNamelogic == 0 ? "" : " contains")}
              type="text"
              id="buildingName"
              name="buildingName"
              value={searchData.buildingName}
              onChange={handleChange}
              onDoubleClick={handleDoubleClick}
            />
            </div>
            <div className="basicsearchinputgroup">
            <label htmlFor="street">Street {searchData.streetlogic == 0?'Starts With':'Contains'}</label>
            <input
              className={"basicsearchinput" + (searchData.streetlogic == 0 ? "" : " contains")}
              type="text"
              id="street"
              name="street"
              value={searchData.street}
              onChange={handleChange}
              onDoubleClick={handleDoubleClick}
            />
            </div>
            <div className="basicsearchinputgroup">
            <label htmlFor="postdistrict">Postal District {searchData.postdistrictlogic ==0?'Starts With':'is'}</label>
            <input
              className={"basicsearchinput" + (searchData.postdistrictlogic == 0 ? "" : " contains")}
              type="text"
              id="postdistrict"
              name="postdistrict"
              value={searchData.postdistrict}
              onChange={handleChange}
              onDoubleClick={handleDoubleClick}
            />
            </div>
            <div className="basicsearchinputgroup">
            <label htmlFor="town">Town {searchData.townlogic ==0?'Starts With':'is'}</label>
            <input
              className={"basicsearchinput" + (searchData.townlogic == 0 ? "" : " contains")}
              type="text"
              id="town"
              name="town"
              value={searchData.town}
              onChange={handleChange}
              onDoubleClick={handleDoubleClick}
            />
            </div>
            <div className="basicsearchinputgroup">
            <label htmlFor="postcode">Postcode {searchData.postcodelogic ==0?'Starts With':'Equals'}</label>
            <input
              className={"basicsearchinput" + (searchData.postcodelogic == 0 ? "" : " contains")}
              type="text"
              id="postcode"
              name="postcode"
              value={searchData.postcode}
              onChange={handleChange}
              onDoubleClick={handleDoubleClick}
            />
            </div>
            <div className="basicsearchinputgroup">
            <label htmlFor="description">Description {searchData.descriptionlogic ==0?'Starts With':'Contains'}</label>
            <input
              className={"basicsearchinput" + (searchData.descriptionlogic == 0 ? "" : " contains")}
              type="text"
              id="description"
              name="description"
              value={searchData.description}
              onChange={handleChange}
              onDoubleClick={handleDoubleClick}
            />
            </div>
            <div className="basicsearchinputgroup">
            <label htmlFor="billingAuthorityReference">Billing Authority Reference Number</label>
            <input
              className="basicsearchinput"
              type="text"
              id="billingAuthorityReference"
              name="billingAuthorityReference"
              value={searchData.billingAuthorityReference}
              onChange={handleChange}
            />
            </div>
            <div className="basicsearchinputgroup">
            <label htmlFor="live2023AssessmentReference">Live 2023 Assessment Reference</label>
            <input
              className="basicsearchinput"
              type="text"
              id="live2023AssessmentReference"
              name="live2023AssessmentReference"
              value={searchData.live2023AssessmentReference}
              onChange={handleChange}
            />
            </div>
      </div>
      }
      </>
    )
}