import BasicCodeModel from "./BasicCodeModel";

export default class BasicCodeSearch {
    codeContext?:number;
    PSD: string[]=[];
    SCAT: string[]=[];
    codeSearch?:string;
    
    constructor(data?: Partial<BasicCodeSearch>) {
        Object.assign(this, {
    
        PSD: [],
        SCAT: [],
        codeContext:0,
        codeSearch:'',
        ...data,
        });
    }
    serialize(): Partial<BasicCodeSearch> {
        return {
            PSD: this.PSD,
            SCAT: this.SCAT,
            codeContext:this.codeContext,
            codeSearch:this.codeSearch
        }
    }
}