import { useSelector } from "react-redux";
import { selectBAListDropdown, selectLocalVTODropdown, selectVOANNDRRVUDropdown, selectVTODropdown, setLocalVTODropdown } from "../../Redux/PreloadedSlicer";
import { useEffect, useState } from "react";
import AreaSearch from "../../Models/AreaSearch";
import store from "../../Redux/ConfigureStore";
import { cleanup } from "@testing-library/react";
import { formatDateToString } from "../../Services/FormatService";
import Select from "react-select";
import BasicCodeModel from "../../Models/BasicCodeModel";
import { setAreaSearch } from "../../Redux/SearchSlicer";
import { FormControlLabel, Radio } from "@mui/material";
import { Regions } from "../../Models/Enums/Regions";
import { BorderAll, BorderVerticalRounded, Margin, Padding } from "@mui/icons-material";
import { IndicatorsContainer, valueContainerCSS } from "react-select/dist/declarations/src/components/containers";
import { indicatorSeparatorCSS } from "react-select/dist/declarations/src/components/indicators";

export default function AreaSearchComponent(props) {

    const localVTODropdown = useSelector(selectLocalVTODropdown);
    const VTODropdown = useSelector(selectVTODropdown);
    const VOANNDRRVUDropdown = useSelector(selectVOANNDRRVUDropdown);
    const baListDropdown = useSelector(selectBAListDropdown) as Array<BasicCodeModel>;
    const [baListDropdownValue, setBAListDropdownValue] = useState<BasicCodeModel|null>();
    const [VOANNDRRVUDropdownValue, setVOANNDRRVUDropdownValue] = useState<BasicCodeModel|null>();
    const [VTODropdownValue, setVTODropdownValue] = useState<BasicCodeModel|null>();
    const [localVTODropdownValue, SetLocalVTODropdownValue] = useState<BasicCodeModel|null>();
    const [searchData, setSearchData] = useState<AreaSearch | null>(null);
    const { searchCount } = props;

    useEffect(() => {
        setSearchData(new AreaSearch(store.getState().search.areaSearch))
    }, []);
    useEffect(() => {
        if (baListDropdown) {
            var bacode = store.getState().search.areaSearch.BACODE
            var value = baListDropdown.find((item) =>
                item.value == bacode
            )
            if (value) {
                setBAListDropdownValue(value)
            }
        }
    }, [baListDropdown]);
    useEffect(() => {
        if (VTODropdown) {
            var TOFFICE = store.getState().search.areaSearch.TOFFICE
            var value = VTODropdown.find((item) =>
                item.value == TOFFICE
            )
            if (value) {
                setVTODropdownValue(value)
            }
        }
    }, [VTODropdown]);
    useEffect(() => {
        if (localVTODropdown) {
            var officelocal = store.getState().search.areaSearch.VOOFFICELOCAL
            var value = localVTODropdown.find((item) =>
                item.value == officelocal
            )
            if (value) {
                SetLocalVTODropdownValue(value)
            }
        }
    }, [localVTODropdown]);

    useEffect(() => {
        if (VOANNDRRVUDropdown) {
            var voanndrrvu = store.getState().search.areaSearch.VOANNDRRVU
            var value = VOANNDRRVUDropdown.find((item) =>
                item.value == voanndrrvu
            )
            if (value) {
                setVOANNDRRVUDropdownValue(value)
            }
        }
    }, [VOANNDRRVUDropdown]);
    useEffect(() => {
        ReloadData()

    }, [searchCount]);
    const ReloadData=async()=>{
        if (searchCount <1) {
            var  area = new AreaSearch(store.getState().search.areaSearch)
            var balist = await baListDropdown.find(ele=>area.BACODE ==ele.value)
            var voa = await VOANNDRRVUDropdown.find(ele=>area.VOANNDRRVU ==ele.value)
            var vto = await VTODropdown.find(ele=>area.TOFFICE ==ele.value)
            var lvto = await localVTODropdown.find(ele=>area.VOOFFICELOCAL ==ele.value)
            setSearchData(area);
            setBAListDropdownValue(balist);
            setVOANNDRRVUDropdownValue(voa);
            SetLocalVTODropdownValue(lvto);
            setVTODropdownValue(vto);
        }
    }
    useEffect(() => {
    }, [searchData])
    const handleChange = async (e, name) => {
        var value = '';
        if(e!=null){
            value = e.value
        }
        const updatedSearchData = { ...searchData, [name]: value };
        await setSearchData(new AreaSearch(updatedSearchData));
        switch (name) {
            case "BACODE":
                setBAListDropdownValue(e);
                break;
            case "VOANNDRRVU":
                setVOANNDRRVUDropdownValue(e);
                break;


            case "TOFFICE":
                setVTODropdownValue(e);
                break;
            case "VOOFFICELOCAL":
                SetLocalVTODropdownValue(e);
                break;

        };
    }

    const handleRadioChange = (e) => {
        const { name, value } = e.target;
        if (searchData) {

                const updatedSearchData = { ...searchData, region: value };
                setSearchData(new AreaSearch(updatedSearchData));
        }
    }
    useEffect(() => {
        sendSearchData();
    }, [searchData]);

    const sendSearchData = () => {
        if (searchData) {
            store.dispatch(setAreaSearch(searchData.serialize()));

        }
    }
const selectStyling = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        border:state.isFocused ? 'none': state.isSelected  ?'none':'none',
        minHeight:'21px',
        height:'21px',
        borderRadius:'0px',
    }),
    indicatorsContainer:(baseStyles, state) => ({
        ...baseStyles,
        minHeight:'22px',
        height:'22px'
    }),
    indicatorSeparator:(baseStyles, state) => ({
        ...baseStyles,
        display:'none',
    }),
    valueContainer:(baseStyles,state) => ({
        ...baseStyles,
        padding:'0px 0px 0px 0px',
        minHeight:'22px',
        height:'22px',
        border:state.isFocused ? 'none': state.isSelected  ?'none':'none',
    }),
    clearIndicator: (baseStyles,state) => ({
        ...baseStyles,
        
        padding:state.isFocused ? 0: state.isSelected ? 0:state.menuIsOpen ? 0:0,
        }),
}
    return (
        <div className="searchcomponentcategory">
            <div className="verticallayout">
                <div className="areasearchdiv">
                    <div>Billing Authority is..</div>
                    <Select  name="BACODE" onChange={(e) => handleChange(e, "BACODE")} isClearable  id="dropdown" styles={selectStyling} className='authorityadv1select' isLoading={baListDropdown == undefined || baListDropdown.length == 0} value={baListDropdownValue ? baListDropdownValue : ''} isSearchable options={baListDropdown} />
                </div>
                <div className="areasearchdiv">
                    <div>VOA NNDR RVU is..</div>
                    <Select name="VOANNDRRVU" onChange={(e) => handleChange(e, "VOANNDRRVU")} isClearable  id="dropdown" className='authorityadv1select' styles={selectStyling} isLoading={VOANNDRRVUDropdown == undefined || VOANNDRRVUDropdown.length == 0} value={VOANNDRRVUDropdownValue ? VOANNDRRVUDropdownValue : ''} isSearchable options={VOANNDRRVUDropdown} />
                </div>
                <div className="areasearchdiv">
                    <div>Local VOA Office is..</div>
                    <Select name="VOOFFICELOCAL" onChange={(e) => handleChange(e, "VOOFFICELOCAL")} isClearable  id="dropdown" className='authorityadv1select' styles={selectStyling} isLoading={localVTODropdown == undefined || localVTODropdown.length == 0} value={localVTODropdownValue ? localVTODropdownValue : ''} isSearchable options={localVTODropdown} />
                </div>
                <div className="areasearchdiv">
                    <div>Valuation Tribunal Office is..</div>
                    <Select name="TOFFICE" onChange={(e) => handleChange(e, "TOFFICE")} id="dropdown" isClearable  className='authorityadv1select' styles={selectStyling} isLoading={VTODropdown == undefined || VTODropdown.length == 0} value={VTODropdownValue ? VTODropdownValue : ''} isSearchable options={VTODropdown} />
                </div>
            </div>
            <div className="regionsection">
                <div className="partdomesticlabel">
                    Region Selection
                </div>
                <div className="radiogroup regionselection">

                    <FormControlLabel value="best" control={
                        <Radio
                            checked={searchData?.region == 0}
                            onClick={handleRadioChange}
                            name='region'
                            value={0}
                            size="small"
                            inputProps={{ 'aria-label': 'All(England and Wales)' }}
                            sx={{ fontSize: '12px' }}

                        />}
                        label="All(England and Wales)" />

                    <FormControlLabel value="best" control={
                        <Radio
                            checked={searchData?.region == 1}
                            onClick={handleRadioChange}
                            name='region'
                            value={1}
                            size="small"
                            inputProps={{ 'aria-label': 'England Only (Inc City Billing Authority)' }}
                            sx={{ fontSize: '12px'}}
                        />}
                        label="England Only (Inc City Billing Authority)" />
                    <FormControlLabel value="best" control={
                        <Radio
                            checked={searchData?.region == 2}
                            onClick={handleRadioChange}
                            name='region'
                            value={2}
                            size="small"
                            inputProps={{ 'aria-label': 'England Only excl. City Billing Authority)' }}
                            sx={{ fontSize: '12px'}}
                        />}
                        label="England Only (excl. City Billing Authority)" />
                    <FormControlLabel value="best" control={
                        <Radio
                            checked={searchData?.region == 3}
                            onClick={handleRadioChange}
                            name='region'
                            value={3}
                            size="small"
                            inputProps={{ 'aria-label': 'Wales Only' }}

                            sx={{ fontSize: '12px' }}
                        />}
                        label="Wales Only" />
                </div>
            </div>
        </div>
    )
}