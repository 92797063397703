import { useEffect, useState } from "react";
import AuthorityScheduleAdv1 from "../../Models/AuthorityScheduleAdv1";
import { cleanup } from "@testing-library/react";
import store from "../../Redux/ConfigureStore";
import { setAuthorityScheduleAdv1Search } from "../../Redux/SearchSlicer";
import { CaseTypeLogic } from "../../Models/Enums/CaseTypeLogic";
import { CertificationLogic } from "../../Models/Enums/CertificationLogic";
import { ScheduleDateLogic } from "../../Models/Enums/ScheduleDateLogic";
import { ListTypeLogic } from "../../Models/Enums/ListTypeLogic";
import { useSelector } from "react-redux";
import { selectListDropDown, selectScheduleDatesDropDown } from "../../Redux/PreloadedSlicer";
import { formatDateToString } from "../../Services/FormatService"
import { Checkbox } from "@mui/material";

export default function AuthorityScheduleAdv1Component(props) {
    const [searchData, setSearchData] = useState<AuthorityScheduleAdv1 | null>(null);
    const { searchCount } = props;
    const listDropDown = useSelector(selectListDropDown);
    const dateDropDown = useSelector(selectScheduleDatesDropDown);

    useEffect(() => {

        setSearchData(new AuthorityScheduleAdv1(store.getState().search.authorityScheduleAdv1));
        return () => {
            cleanup();
        };
    }, []);
    useEffect(() => {
        if (searchCount < 1) {
            setSearchData(new AuthorityScheduleAdv1(store.getState().search.authorityScheduleAdv1));
        }
        return () => {
            cleanup();
        };
    }, [searchCount]);

    const handleChange = async (e) => {
        const { name, value } = e.target;
        const updatedSearchData = { ...searchData, [name]: value };
        setSearchData(new AuthorityScheduleAdv1(updatedSearchData));
    };
    const handleCheckBoxChange = (e) => {
        const { checked, name } = e.target;
        const updatedSearchData = { ...searchData, [name]: checked };
        setSearchData(new AuthorityScheduleAdv1(updatedSearchData));
    }
    const handleDoubleClick = async (e) => {
        if (searchData) {
            const { name } = e.target;
            var value = searchData[name + 'Logic'];
            value = value == 0 ? 1 : 0;
            const updatedSearchData = { ...searchData, [name + 'Logic']: value };
            setSearchData(new AuthorityScheduleAdv1(updatedSearchData));
        }
    };

    useEffect(() => {
        sendSearchData();
    }, [searchData]);

    const sendSearchData = () => {
        if (searchData) {
            store.dispatch(setAuthorityScheduleAdv1Search(searchData.serialize()));

        }
    }
    return (
        <>{searchData &&
            <div className="searchcomponentcategory">
                <div className="trendminicomponent">
                <div className="surveylineselectcontainer">
                    <div className="authorityadv1text">Show </div>
                    <select id="dropdown" className='authorityadv1select' name='scheduleDateLogic' value={searchData.scheduleDateLogic} onChange={handleChange}>
                        {Object.values(ScheduleDateLogic).filter(key => isNaN(Number(ScheduleDateLogic[key]))).map((value) => (
                            <option key={value} value={value}>
                                {ScheduleDateLogic[value]}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="surveylineselectcontainer">
                    <div className="authorityadv1text">Date </div>
                    {searchData.scheduleDateLogic != 2 &&
                        <select id="dropdown" className='authorityadv1select' name='scheduleDate' disabled={searchData.scheduleDateLogic != 1} value={searchData.scheduleDate ? searchData.scheduleDate : ''} onChange={handleChange}>
                            <option key={''} value={''}>
                                {''}
                            </option>
                            {dateDropDown.length != 0 && dateDropDown.map((value) => (
                                <option key={value} value={value}>
                                    {formatDateToString(new Date(value))}
                                </option>
                            ))}
                        </select>}
                    {searchData.scheduleDateLogic == 2 &&
                        <>
                            <select id="dropdown" className='authorityadv1selectbetween' name='scheduleFromDate' value={searchData.scheduleFromDate ? searchData.scheduleFromDate : ''} onChange={handleChange}>
                                <option key={''} value={''}>
                                    {''}
                                </option>
                                {dateDropDown.length != 0 && dateDropDown.map((value) => (
                                    <option key={value} value={value}>
                                        {formatDateToString(new Date(value))}
                                    </option>
                                ))}
                            </select>
                            <div className="adv1to">to</div>
                            <select id="dropdown" className='authorityadv1selectbetween' name='scheduleToDate' value={searchData.scheduleToDate ? searchData.scheduleToDate : ''} onChange={handleChange}>
                                <option key={''} value={''}>
                                    {''}
                                </option>
                                {dateDropDown.length != 0 && dateDropDown.map((value) => (
                                    <option key={value} value={value}>
                                        {formatDateToString(new Date(value))}
                                    </option>
                                ))}
                            </select>
                        </>}
                </div>
                <div className="surveylineselectcontainer">
                    <div className="authorityadv1text">From </div>
                    <select id="dropdown" className='authorityadv1select' name='listYear' value={searchData.listYear} onChange={handleChange}>
                        <option key={0} value={0}>
                            {'All Lists'}
                        </option>
                        {listDropDown.length != 0 && listDropDown.map((value) => (
                            <option key={value} value={value}>
                                {value} list only
                            </option>
                        ))}
                    </select>
                </div>
                <div className="surveylineselectcontainer">
                    <div className="authorityadv1text">Type </div>
                    <select id="dropdown" className='authorityadv1select' name='listTypeLogic' value={searchData.listTypeLogic} onChange={handleChange}>
                        {Object.values(ListTypeLogic).filter(key => isNaN(Number(ListTypeLogic[key]))).map((value) => (
                            <option key={value} value={value}>
                                {ListTypeLogic[value]}
                            </option>
                        ))}
                    </select>
                </div>
                </div>
                <div className="trendminicomponent">
                    <div className="trendminiheader">
                        <Checkbox color="secondary" sx={{ color: '#009FE3', p: 0 }} name='certification' checked={searchData.certification} onChange={handleCheckBoxChange} /> Certification
                    </div>
                    <div className="surveylineselectcontainer">
                        <div className="authorityadv1text">Show </div>
                        <select id="dropdown" className='authorityadv1select' name='listCertificationLogic' disabled={!searchData.certification} value={searchData.listCertificationLogic} onChange={handleChange}>
                            {Object.values(CertificationLogic).filter(key => isNaN(Number(CertificationLogic[key]))).map((value) => (
                                <option key={value} value={value}>
                                    {CertificationLogic[value]}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="trendminicomponent">
                    <label htmlFor="changeReason">Change Reason {searchData.changeReasonLogic == 0 ? 'Starts With' : 'Contains'}</label>
                    <input
                        className={"basicsearchinput" +(searchData.changeReasonLogic == 1?' contains':'')}
                        type="text"
                        id="changeReason"
                        name="changeReason"
                        value={searchData.changeReason}
                        onChange={handleChange}
                        onDoubleClick={handleDoubleClick}
                    /></div>
                <div className="trendminicomponent">
                    <label htmlFor="voaCaseReferenceNumber">VOA Case Reference Number is</label>
                    <input
                        className="basicsearchinput"
                        type="text"
                        id="voaCaseReferenceNumber"
                        name="voaCaseReferenceNumber"
                        value={searchData.voaCaseReferenceNumber}
                        onChange={handleChange}
                    /></div>
                <div className="advancedsurveytext">Case Type is </div>
                <select id="dropdown" className='schedulecasetype' name='listcaseTypeLogic' value={searchData.listcaseTypeLogic} onChange={handleChange}>
                    {Object.values(CaseTypeLogic).filter(key => isNaN(Number(CaseTypeLogic[key]))).map((value) => (
                        <option key={value} value={value}>
                            {CaseTypeLogic[value]}
                        </option>
                    ))}
                </select>

            </div>
        }
        </>
    )
}