import React, { useState, useEffect, Children, useRef } from 'react';
import { DataGridPro, GridRowsProp, GridColDef, GridRenderCellParams, GridColumnGroup, GridApiPro, GridEvents, useGridApiRef, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import IntegratedListResult from '../../Models/IntegratedListResult';
import IntegratedSearch, { validateIntegratedSearch } from '../../Models/SearchApiModels/IntegratedSearch';
import { formatCurrency, formatDateToString, FormatNumberWithCommas, FormatSettlementCode, stringifyList } from '../../Services/FormatService';
import FlagComponent from '../SubComponents/FlagComponent';
import store from '../../Redux/ConfigureStore';
import BasicSearchApiModel from '../../Models/SearchApiModels/BasicSearchApiModel';
import IntegratedListTrendsApiModel from '../../Models/SearchApiModels/IntegratedListTrendsApiModel';
import IntegratedListSettlementApiModel from '../../Models/SearchApiModels/IntegratedListSettlementApiModel';
import IntegratedListAdvancedApiModel from '../../Models/SearchApiModels/IntegratedListAdvancedApiModel';
import TableRightClickMenu from './TableContextMenus/TableRightClickMenu';
import { addUARNs, selectAddSelection, selectPrunedUARNs, setDispatchAddSelection } from '../../Redux/ListBuilderSlicer';
import { useSelector } from 'react-redux';
import { selectTriggerShowScheme, selectListYear, selectSelectedUARN, selectTriggerGOVUK, selectTriggerGoogleMaps, selectTriggerPrint, setHeaderBarContext, setSelectedUARN, setTriggerGOVUK, setTotal, setTriggerGetTotal, setCount, setTriggerGetCount, setTriggerGoogleMaps, setTriggerPrint, setTriggerShowScheme, setLoading, setDisplayDetails, setTriggerExportExcel, selectTriggerExportExcel, selectTriggerGetTotal, selectTriggerGetCount } from '../../Redux/SearchSlicer';
import HomeIcon from '@mui/icons-material/Home';
import BasicSearch from '../../Models/BasicSearch';
import { ReportModel } from '../../Models/ReportModel';
import SummaryValuationSearch from '../../Models/SearchApiModels/SummaryValuationSearch';
import { GetScheme } from '../../API/AnalyseAPIHelper';
import CloseIcon from '@mui/icons-material/Close';
import { Button, ClickAwayListener } from '@mui/material';
import ReportWindow from '../SubComponents/ReportWindow';
import HomeScreenComponent from '../SubComponents/HomeScreenComponent';
import { dataToFile } from '../../Services/ExcelExportService';
import DARReportModel from '../../Models/DARReportModel';



export default function IntegratedListTable(props) {
  const [integratedListData, setIntegratedListData] = useState<Array<IntegratedListResult>>([])
  const apiRef = useGridApiRef();
  var pageNo = useRef(0)
  const { searchCount, handleUARNChange } = props;
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [contextMenuPosition, setContextMenuPosition] = useState<{ mouseX: number | null; mouseY: number | null }>({ mouseX: null, mouseY: null });
  const [uarnSelectionModel, setUARNSelectionModel] = useState<string[]>([]);
  const triggerAddSelection = useSelector(selectAddSelection);
  const triggerGetTotal = useSelector(selectTriggerGetTotal);
  const triggerGetCount= useSelector(selectTriggerGetCount);
  const [storedQuery, setStoredQuery] = useState<IntegratedSearch>();
  const triggerGoogleMaps = useSelector(selectTriggerGoogleMaps);
  const triggerGOVUK = useSelector(selectTriggerGOVUK);
  const triggerSearchButton = useRef(false);
  const keepSelection = useRef(false);
  const selectedUARN = useSelector(selectSelectedUARN);
  const triggerPrint = useSelector(selectTriggerPrint);
  const [report, setReport] = useState();
  const summaryListYear = useSelector(selectListYear);
  const [showNoScheme, setShowNoScheme] = useState(false);
  const showScheme = useSelector(selectTriggerShowScheme);
  const [searchEmpty, setSearchEmpty] = useState(false);
  const triggerExport = useSelector(selectTriggerExportExcel);
  const[showNoValuation,setShowNoValuation] = useState(false);
  const [columnWidths, setColumnWidths] = useState({});

  useEffect(() => {
    pageNo.current = 0;
    var query = getIntegratedSearch();
    fetchData(query);
    triggerSearchButton.current = true;
    store.dispatch(setSelectedUARN(''))
  }, [searchCount]);
  
  const handleColumnResize = (params) => {
    setColumnWidths((prevWidths) => ({
      ...prevWidths,
      [params.colDef.field]: params.width,
    }));
  };

  useEffect(() => {
    if (triggerPrint[0]) {
      handlePrint(triggerPrint)

      store.dispatch(setTriggerPrint([0, triggerPrint[1]]))
    }
  }, [triggerPrint]);
  
  const handlePrint = async (props) => {
    var newreport
    store.dispatch(setLoading(true));
    try {
      switch (props[0]) {
        case 1:
          var query2 = new DARReportModel({ list: summaryListYear, uarn: stringifyList(uarnSelectionModel),highlightLive: store.getState().search.DARLiveHighlight  });
          newreport = await ApiHelper.GetDARReport(query2);
          store.dispatch(setLoading(false))
          break;
        case 2:
          var query1 = new ReportModel({ list: summaryListYear, uarn: stringifyList(uarnSelectionModel) });
          newreport = await ApiHelper.GetSMVReport(query1);
          store.dispatch(setLoading(false))
          break;
        case 3:
          var query3 = new IntegratedSearch();
          var basic = new BasicSearch();
          query3.basic = new BasicSearchApiModel(basic);
          query3.basic.uarn = stringifyList(uarnSelectionModel);
          newreport = await ApiHelper.GetIntegratedListReportSelected(query3);
          store.dispatch(setLoading(false))
          break
        case 4:
          newreport = await ApiHelper.GetIntegratedListReport(storedQuery!);
          store.dispatch(setLoading(false))
          break

        case 5:
          var query2 = new DARReportModel({ list: summaryListYear, uarn: stringifyList(uarnSelectionModel),highlightLive: store.getState().search.DARLiveHighlight });
          newreport = await ApiHelper.GetDARReport(query2);
          var query4 = new ReportModel({ list: summaryListYear, uarn: stringifyList(uarnSelectionModel) });
          newreport += '<div style="page-break-before: always;"></div>'
          newreport += await ApiHelper.GetSMVReport(query4);
          store.dispatch(setLoading(false))
          break
      }
    }
    catch (e) {
      console.error(e)

    }
    finally {
      store.dispatch(setLoading(false))
      if(newreport=='false'){
        setReport(undefined)
        setShowNoValuation(true)
      }
      else{
        setReport(newreport)
      }
    }
  }

  const openExternalLink = (url) => {
    try{
      ApiHelper.postLogThis("sending user to url: "+ url)
    }catch(error){
      console.error(error)
    }
    finally{
      window.open(url, '_blank', 'noopener,noreferrer');

    }
  };

  useEffect(() => {
    if (showScheme) {
      handleScheme()
    }
  }, [showScheme]);

  useEffect(() => {
    if (triggerGoogleMaps == true && selectedUARN) {
      handleGoogleMapSearch();
    }
    store.dispatch(setTriggerGoogleMaps(false));
  }, [triggerGoogleMaps]);
  const handleGoogleSearch = ()=>{
    var row = integratedListData.find(item =>
      item.uarn == selectedUARN
    )
    var mapquery = ''
    mapquery += row?.address ? row?.address + ',' : ''
    mapquery += row?.postcode;
    openExternalLink('https://www.google.co.uk/search?q=' + mapquery);
  }
  const handleGoogleMapSearch = (param = '') => {
    var row = integratedListData.find(item =>
      item.uarn == selectedUARN
    )
    var mapquery = ''
    switch (param) {
      case "address":
        mapquery += row?.address;
        break;
      case "postcode":
        mapquery += row?.postcode;
        break;

      case "addressandpostcode":
        mapquery += row?.address ? row?.address + ',' : ''
        mapquery += row?.postcode;
        break;

      default:
        mapquery += row?.postcode;
        break;
    }
    openExternalLink('https://www.google.co.uk/maps?q=' + mapquery);

  }
  useEffect(() => {
    if (triggerGOVUK == true && selectedUARN) {
      openExternalLink('https://www.tax.service.gov.uk/business-rates-find/valuations/' + selectedUARN)
    }
    store.dispatch(setTriggerGOVUK(false));
  }, [triggerGOVUK]);

  useEffect(() => {
    const selectedUARNs = rowSelectionModel.map(id => {
      return integratedListData.find(row => row.keY_FIELD === id)?.uarn;
    }).filter(Boolean) as string[];
    setUARNSelectionModel(selectedUARNs);
  }, [rowSelectionModel]);

  useEffect(() => {
    if (triggerAddSelection == true) {
      handleAddToListBuilder();
      store.dispatch(setDispatchAddSelection(false));
    }
  }, [triggerAddSelection]);
  useEffect(() => {
    if (triggerGetTotal == true) {
      handleGetTotal();
    }
  }, [triggerGetTotal]);

  useEffect(() => {
    if (triggerGetCount == true) {
      handleGetCount();
    }
  }, [triggerGetCount]);
  useEffect(() => {
    if (integratedListData.length > 0 && searchCount > 0) {
      if (integratedListData[0].uarn) {
        store.dispatch(setSelectedUARN(integratedListData[0].uarn))

      }
    }
  }, [integratedListData]);
  useEffect(() => {
    handleUARNChange(selectedUARN)
  }, [selectedUARN])
  const handleGetTotal = async () => {
    if (storedQuery) {
      store.dispatch(setLoading(true))
      try {
        var totalQuery = storedQuery
        totalQuery.totals = true
        var data = await ApiHelper.GetIntegratedListSum(totalQuery);
        storedQuery.totals = false
        if (data) {
          store.dispatch(setTotal(data[0].rateablE_VALUE_2023))

        }
        // if (store.getState().search.sqlDump) {
        //   var totalQuery = storedQuery
        //   storedQuery.sqldump = true;
        //   totalQuery.totals = true;
        //   var dump = await ApiHelper.GetIntegratedListSum(totalQuery);
        //   storedQuery.totals = false;
        //   storedQuery.sqldump = false;
        //   console.log(dump)
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        store.dispatch(setLoading(false))
      }
    }
  }


  const handleGetCount= async () => {
    if (storedQuery) {
      store.dispatch(setLoading(true))
      try {
        var totalQuery = storedQuery
        totalQuery.totals = true
        var data = await ApiHelper.GetIntegratedListCount(totalQuery);
        storedQuery.totals = false
        if (data) {
          store.dispatch(setCount(data[0].keY_FIELD))

        }
        // if (store.getState().search.sqlDump) {
        //   var totalQuery = storedQuery
        //   storedQuery.sqldump = true;
        //   totalQuery.totals = true;
        //   var dump = await ApiHelper.GetIntegratedListCount(totalQuery);
        //   storedQuery.totals = false;
        //   storedQuery.sqldump = false;
        //   console.log(dump)
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        store.dispatch(setLoading(false))
      }
    }
  }

  const handleRowsScrollEnd = () => {
    pageNo.current += 1;
    keepSelection.current = true;
    fetchData(storedQuery);
  }
  async function fetchData(query) {

    query.sortcolumn = "l.keY_FIELD";
    query.sortorder = 'asc';
    if (apiRef.current.state) {
      const { sortModel } = apiRef.current.state.sorting;
      if (sortModel.length != 0) {
        query.sortcolumn = sortModel[0]['field'];
        query.sortorder = sortModel[0]['sort'] ? sortModel[0]['sort'] : 'asc';
      }
    }
    query.pageno = pageNo.current;
    var errors = validateIntegratedSearch(query);

    if (errors.length == 0) {
      try {
        store.dispatch(setLoading(true))
        var data = await ApiHelper.GetIntegratedList(query);
        var dataarray = data ? data : [];
        if (dataarray.length > 0) {
          if (pageNo.current === 0) {
            setIntegratedListData(dataarray);
            store.dispatch(setTriggerGetTotal(false))
            store.dispatch(setTriggerGetCount(false))
            query.totals = false;
            setStoredQuery(query)
            if (triggerSearchButton.current) {
              store.dispatch(setHeaderBarContext(1));
            }

            if (!keepSelection.current) {
              store.dispatch(setSelectedUARN(dataarray[0].uarn ? dataarray[0].uarn : ''));
              setRowSelectionModel([dataarray[0].keY_FIELD!]);
              if (searchCount == 1) {
                store.dispatch(setDisplayDetails(true))
              }
            }
          } else {
            setIntegratedListData(prevData => [...prevData, ...dataarray]);
          }
        }
        else {
          if (query.pageno == 0) {
            setSearchEmpty(true);
          }
        }

        // if (store.getState().search.sqlDump) {
        //   query.sqldump = true;
        //   var dump = await ApiHelper.GetIntegratedListDump(query);
        //   console.log(dump)
        // }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      finally {
        store.dispatch(setLoading(false))
      }
    }
    triggerSearchButton.current = false;
    keepSelection.current = false;
  }
  const getIntegratedSearch = () => {
    var storeQueryData = store.getState().search;
    var query = new IntegratedSearch();
    query.basic = new BasicSearchApiModel(storeQueryData.basicSearch);
    var basicCodes = storeQueryData.basicCodeSearch;
    if (basicCodes.PSD?.length != 0 || basicCodes.SCAT?.length != 0 || basicCodes.codeSearch != '') {
      if (basicCodes.codeSearch) {
        if (basicCodes.codeContext == 0) {
          query.basic.PSD = [basicCodes.codeSearch]
        }
        else {
          query.basic.SCAT = [basicCodes.codeSearch]

        }
      }
      else {
        if (basicCodes.PSD?.length == 0) {
          query.basic.SCAT = basicCodes.SCAT;
        }
        if (basicCodes.SCAT?.length == 0) {
          query.basic.PSD = basicCodes.PSD;
        }
        if (basicCodes.PSD?.length != 0 && basicCodes.SCAT?.length != 0) {
          if (basicCodes.codeContext == 1) {
            query.basic.SCAT = basicCodes.SCAT;
          } else {
            query.basic.PSD = basicCodes.PSD;
          }
        }
      }

    }
    var areaSearch = storeQueryData.areaSearch;
    if (areaSearch.BACODE != '' && areaSearch.BACODE) {
      query.basic.BACODE = areaSearch.BACODE
    }
    if (areaSearch.TOFFICE != '' && areaSearch.TOFFICE) {
      query.basic.TOFFICE = areaSearch.TOFFICE
    }
    if (areaSearch.VOANNDRRVU != '' && areaSearch.VOANNDRRVU) {
      query.basic.VOANNDRRVU = areaSearch.VOANNDRRVU
    }
    if (areaSearch.VOOFFICELOCAL != '' && areaSearch.VOOFFICELOCAL) {
      query.basic.VOOFFICELOCAL = areaSearch.VOOFFICELOCAL
    }
    query.basic.region = Number(areaSearch.region)
    var integratedListTrends = storeQueryData.integratedListTrends;
    if (integratedListTrends.list1990 || integratedListTrends.list1995v1990 || integratedListTrends.list1995 || integratedListTrends.list2000v1995
      || integratedListTrends.list2000 || integratedListTrends.list2005v2000 || integratedListTrends.list2005 || integratedListTrends.list2010v2005
      || integratedListTrends.list2010 || integratedListTrends.list2017v2010 || integratedListTrends.list2017 || integratedListTrends.list2023v2017
      || integratedListTrends.list2023) {
      query.trends = new IntegratedListTrendsApiModel(integratedListTrends);

    }
    var settlement = storeQueryData.integratedSettlementSearch;
    if (settlement.searchSettlement && settlement.settlementType?.length != 0) {
      query.settlement = new IntegratedListSettlementApiModel(settlement)
    }
    var integratedListAdvancedSearch = storeQueryData.integratedListAdvancedSearch;
    if (integratedListAdvancedSearch.edSearch || integratedListAdvancedSearch.partDomesticSearch || integratedListAdvancedSearch.ladSearch || integratedListAdvancedSearch.rvSearch || integratedListAdvancedSearch.listStatusSearch) {
      
      query.advanced = new IntegratedListAdvancedApiModel(integratedListAdvancedSearch)
    }
    return query
  }
  const handleRowClick = (e) => {
    const { row } = e;
    if (row.uarn !== selectedUARN) {

      store.dispatch(setSelectedUARN(row.uarn));

    }
  }

  const handleRowOrderChange = (model) => {
    pageNo.current = 0
    fetchData(storedQuery);
    apiRef.current.scroll({ top: 0 });
  }
  const handleScheme = async () => {
    try {
      store.dispatch(setLoading(true))
      var response = await GetScheme(summaryListYear, selectedUARN)
      if (response && response.assessmenT_REFERENCE && response.schemE_REFERENCE && selectedUARN) {
        openExternalLink(`https://www.tax.service.gov.uk/business-rates-find/valuations/${response.assessmenT_REFERENCE}/schemes/${response.schemE_REFERENCE}?uarn=${selectedUARN}`);
      }
      else {
        setShowNoScheme(true);
      }
    }
    catch {
      setShowNoScheme(true);
    } finally {
      store.dispatch(setTriggerShowScheme(false));
      store.dispatch(setLoading(false))

    }
  }
  const handleContextMenu = (event, row) => {

    event.preventDefault();

    if (event.shiftKey) {
      console.log('Shift key is held down.');
    }
    else {
      if (event.ctrlKey) {
        setRowSelectionModel([...rowSelectionModel, row.keY_FIELD!]);
      }
      else {
        if (rowSelectionModel.findIndex(elem => row.keY_FIELD == elem) > -1) {
        }
        else {
          setRowSelectionModel([row.keY_FIELD]);
        }

      }
      if (row.uarn !== selectedUARN) {

        setSelectedUARN(row.uarn);

      }
    }

    if (contextMenuPosition.mouseX !== null && contextMenuPosition.mouseY !== null) {
      handleCloseContextMenu(() => {
        setContextMenuPosition({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
      });
    } else {
      setContextMenuPosition({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
    }
  };

  const handleCloseContextMenu = (callback) => {
    setContextMenuPosition({ mouseX: null, mouseY: null });
    if (callback) {
      setTimeout(callback, 0);
    }
  };
  useEffect(() => {
    if (triggerExport > 0) {
      handleExportExcel(triggerExport)
      store.dispatch(setTriggerExportExcel(0))
    }
  }, [triggerExport])

  const handleExportExcel = async (options) => {
    var excelData
    store.dispatch(setLoading(true))
    try {
      switch (options) {
        case 1:
          var query = new IntegratedSearch();
          var basic = new BasicSearch();
          query.basic = new BasicSearchApiModel(basic);
          query.basic.uarn = stringifyList(uarnSelectionModel);
          query.basic.region = undefined;
          query.report = true;
          excelData = await ApiHelper.GetIntegratedListExcel(query);
          break;
        case 2:
          var query = storedQuery!;
          query.report = true;
          excelData = await ApiHelper.GetIntegratedListExcel(query);
          break;
      }
    }
    catch (err) {
      console.error(err)
    }
    finally {
      storedQuery!.report = false;
      if (excelData.length) {
        excelData.forEach(item => {
          if(item.flaG_2023){
            item.effectivE_DATE_2023 = item.effectivE_DATE_2023? formatDateToString(item.effectivE_DATE_2023):null;
            item.lisT_ALTERATION_DATE_2023 =item.lisT_ALTERATION_DATE_2023?  formatDateToString(item.lisT_ALTERATION_DATE_2023):'COMPILED';
            item.rateablE_VALUE_2023 =item.rateablE_VALUE_2023?  item.rateablE_VALUE_2023:'DELETED';
            item.settlemenT_TYPE_2023 =item.settlemenT_TYPE_2023?  FormatSettlementCode(2023,item.settlemenT_TYPE_2023):null;
          }
          else{
            item.rateablE_VALUE_2023='<E>';
            item.effectivE_DATE_2023='<E>';
            item.lisT_ALTERATION_DATE_2023='<E>';
            item.compositE_2023='<E>';
            item.settlemenT_TYPE_2023='<E>';
            item.compileD_RATEABLE_VALUE_2023='<E>';
            item.lisT_PERCENTAGE_2023='<E>';
            item.percentagE_BETWEEN_2023_2017='<E>';
            item.drafT_2023_RATEABLE_VALUE='<E>';
          }
          if(item.flaG_2017){
            item.effectivE_DATE_2017 = item.effectivE_DATE_2017? formatDateToString(item.effectivE_DATE_2017):null;
            item.lisT_ALTERATION_DATE_2017 = item.lisT_ALTERATION_DATE_2017? formatDateToString(item.lisT_ALTERATION_DATE_2017):'COMPILED'; 
            item.rateablE_VALUE_2017 =item.rateablE_VALUE_2017?  item.rateablE_VALUE_2017:'DELETED';
            item.settlemenT_TYPE_2017 =item.settlemenT_TYPE_2017?  FormatSettlementCode(2017,item.settlemenT_TYPE_2017):null;
          }
          else{
            item.rateablE_VALUE_2017='<E>';
            item.effectivE_DATE_2017='<E>';
            item.lisT_ALTERATION_DATE_2017='<E>';
            item.compositE_2017='<E>';
            item.settlemenT_TYPE_2017='<E>';
            item.compileD_RATEABLE_VALUE_2017='<E>';
            item.lisT_PERCENTAGE_2017='<E>';
            item.percentagE_BETWEEN_2017_2010='<E>';
            item.drafT_2017_RATEABLE_VALUE='<E>';
          }
          if(item.flaG_2010){
            item.effectivE_DATE_2010 = item.effectivE_DATE_2010? formatDateToString(item.effectivE_DATE_2010):null;
            item.lisT_ALTERATION_DATE_2010 = item.lisT_ALTERATION_DATE_2010? formatDateToString(item.lisT_ALTERATION_DATE_2010):'COMPILED';
            item.rateablE_VALUE_2010 =item.rateablE_VALUE_2010? item.rateablE_VALUE_2010:'DELETED';
            item.settlemenT_TYPE_2010 =item.settlemenT_TYPE_2010?  FormatSettlementCode(2010,item.settlemenT_TYPE_2010):null;
          }
          else{
            item.rateablE_VALUE_2010='<E>';
            item.effectivE_DATE_2010='<E>';
            item.lisT_ALTERATION_DATE_2010='<E>';
            item.compositE_2010='<E>';
            item.settlemenT_TYPE_2010='<E>';
            item.compileD_RATEABLE_VALUE_2010='<E>';
            item.lisT_PERCENTAGE_2010='<E>';
            item.percentagE_BETWEEN_2010_2005='<E>';
            item.drafT_2010_RATEABLE_VALUE='<E>';
          }
          if(item.flaG_2005){
            item.effectivE_DATE_2005 = item.effectivE_DATE_2005? formatDateToString(item.effectivE_DATE_2005):null;
            item.lisT_ALTERATION_DATE_2005 = item.lisT_ALTERATION_DATE_2005? formatDateToString(item.lisT_ALTERATION_DATE_2005):'COMPILED';
            item.rateablE_VALUE_2005 =item.rateablE_VALUE_2005?  item.rateablE_VALUE_2005:'DELETED';
            item.settlemenT_TYPE_2005 =item.settlemenT_TYPE_2005?  FormatSettlementCode(2005,item.settlemenT_TYPE_2005):null;
           }
          else{
            item.rateablE_VALUE_2005='<E>';
            item.effectivE_DATE_2005='<E>';
            item.lisT_ALTERATION_DATE_2005='<E>';
            item.compositE_2005='<E>';
            item.settlemenT_TYPE_2005='<E>';
            item.compileD_RATEABLE_VALUE_2005='<E>';
            item.lisT_PERCENTAGE_2005='<E>';
            item.percentagE_BETWEEN_2005_2000='<E>';
          }
          if(item.flaG_2000){
            item.effectivE_DATE_2000 = item.effectivE_DATE_2000? formatDateToString(item.effectivE_DATE_2000):null;
            item.lisT_ALTERATION_DATE_2000 = item.lisT_ALTERATION_DATE_2000? formatDateToString(item.lisT_ALTERATION_DATE_2000):'COMPILED';
            item.rateablE_VALUE_2000 =item.rateablE_VALUE_2000?  item.rateablE_VALUE_2000:'DELETED';
            item.settlemenT_TYPE_2000 =item.settlemenT_TYPE_2000?  FormatSettlementCode(2000,item.settlemenT_TYPE_2000):null;
          }
          else{
            item.rateablE_VALUE_2000='<E>';
            item.effectivE_DATE_2000='<E>';
            item.lisT_ALTERATION_DATE_2000='<E>';
            item.compositE_2000='<E>';
            item.settlemenT_TYPE_2000='<E>';
            item.compileD_RATEABLE_VALUE_2000='<E>';
            item.lisT_PERCENTAGE_2000='<E>';
            item.percentagE_BETWEEN_2000_1995='<E>';
          }
          if(item.flaG_1995){
            item.effectivE_DATE_1995 = item.effectivE_DATE_1995? formatDateToString(item.effectivE_DATE_1995):null;
            item.lisT_ALTERATION_DATE_1995 = item.lisT_ALTERATION_DATE_1995? formatDateToString(item.lisT_ALTERATION_DATE_1995):'COMPILED';
            item.rateablE_VALUE_1995 =item.rateablE_VALUE_1995?  item.rateablE_VALUE_1995:'DELETED';
            item.settlemenT_TYPE_1995 =item.settlemenT_TYPE_1995?  FormatSettlementCode(1995,item.settlemenT_TYPE_1995):null;
          }
          else{
            item.rateablE_VALUE_1995='<E>';
            item.effectivE_DATE_1995='<E>';
            item.lisT_ALTERATION_DATE_1995='<E>';
            item.compositE_1995='<E>';
            item.settlemenT_TYPE_1995='<E>';
            item.compileD_RATEABLE_VALUE_1995='<E>';
            item.lisT_PERCENTAGE_1995='<E>';
            item.percentagE_BETWEEN_1995_1990='<E>';
          }
          if(item.flaG_1990){
            item.effectivE_DATE_1990 = item.effectivE_DATE_1990? formatDateToString(item.effectivE_DATE_1990):null;
            item.lisT_ALTERATION_DATE_1990 = item.lisT_ALTERATION_DATE_1990? formatDateToString(item.lisT_ALTERATION_DATE_1990):'COMPILED';
            item.rateablE_VALUE_1990 =item.rateablE_VALUE_1990? item.rateablE_VALUE_1990:'DELETED';
            item.settlemenT_TYPE_1990 =item.settlemenT_TYPE_1990?  FormatSettlementCode(1990,item.settlemenT_TYPE_1990):null;
          }
          else{
            item.rateablE_VALUE_1990='<E>';
            item.effectivE_DATE_1990='<E>';
            item.lisT_ALTERATION_DATE_1990='<E>';
            item.compositE_1990='<E>';
            item.settlemenT_TYPE_1990='<E>';
            item.compileD_RATEABLE_VALUE_1990='<E>';
            item.lisT_PERCENTAGE_1990='<E>';
          }
        });
        dataToFile(excelData, ExcelGroupingModel, ExcelColumnModel)
      }
    }
  }

  const onClipboardCopy = (data, event, { api }) => {
    const visibleColumns = apiRef.current.getVisibleColumns();
    
    const columnsAsString = visibleColumns.filter(col => col.field !== '__check__').map(col => col.headerName).join('\t');
    navigator.clipboard.writeText(columnsAsString + '\n' + data);
  }
  const handleMenuItemClick = (item, param = '') => {
    switch (item) {
      case "Add to 'ListBuilder'":
        handleAddToListBuilder();
        break;
      case "Show Scheme":
        handleScheme();
        break;
      case "Show Entry on GOV.UK":
        store.dispatch(setTriggerGOVUK(true));
        break;
      case "Quick Print DAR":
        handlePrint([1, 0])
        break;
      case "Show Valuation":
        handlePrint([2, 0])
        break;
      case "Quick Print DAR and Valuation":
        handlePrint([5, 0])
        break;
      case "Show in Google Maps":
        handleGoogleMapSearch(param);
        break;
      case "Search in Google":
        handleGoogleSearch();
        break;

      default:
        break;
    }
    handleCloseContextMenu(null);
  };

  const handleAddToListBuilder = () => {
    try{
      ApiHelper.postLogThis("Adding to List: "+ stringifyList(uarnSelectionModel))
    }catch(error){
      console.error(error)
    }
    finally{
      store.dispatch(addUARNs(uarnSelectionModel));
    }
  }

  
  const columns: GridColDef[] = [
    // {
    //   field: 'keY_FIELD',
    //   headerName: '',
    //   width: 10,
    //   minWidth: 10,
    //   disableColumnMenu: true,
    //   disableReorder: true,
    //   resizable: false,
    //   sortable: false,
    //   headerAlign: 'center',
    //   renderCell: (params: any) => (
    //     <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
    //       <>
    //         {params && rowSelectionModel.includes(params.row.keY_FIELD) &&
    //           <div className='rowindicator'>
    //           </div>
    //         }
    //       </>
    //     </div>
    //   ),
    // },
    {
      field: 'flaG_2023',
      headerName: '23',
      width: columnWidths['flaG_2023'] || 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {(params.row.rateablE_VALUE_2023 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_2023 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_2023 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2023 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_2023 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_2017',
      headerName: '17',
      width: columnWidths['flaG_2017'] || 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {(params.row.rateablE_VALUE_2017 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_2017 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_2017 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2017 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_2017 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_2010',
      headerName: '10',
      width: columnWidths['flaG_2010'] || 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {(params.row.rateablE_VALUE_2010 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_2010 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_2010 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2010 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_2010 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_2005',
      headerName: '05',
      width: columnWidths['flaG_2005'] || 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {(params.row.rateablE_VALUE_2005 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_2005 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_2005 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2005 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_2005 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_2000',
      headerName: '00',
      width: columnWidths['flaG_2000'] || 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {(params.row.rateablE_VALUE_2000 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_2000 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_2000 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2000 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_2000 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_1995',
      headerName: '95',
      width: columnWidths['flaG_1995'] || 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {(params.row.rateablE_VALUE_1995 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_1995 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_1995 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1995 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_1995 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_1990',
      headerName: '90',
      width: columnWidths['flaG_1990'] || 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {(params.row.rateablE_VALUE_1990 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_1990 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_1990 == null && !params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1990 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_1990 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'firm_name', headerName: 'FIRM NAME', width: columnWidths['firm_name'] || 78, minWidth: 78, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'address', headerName: 'ADDRESS', width: columnWidths['address'] || 458, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'postcode', headerName: 'POSTCODE', width: columnWidths['postcode'] || 80, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'bA_REFERENCE_NUMBER', headerName: 'BA REFERENCE', width: columnWidths['bA_REFERENCE_NUMBER'] || 104, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'assessmenT_REFERENCE_2023', headerName: 'ASSESSMENT REF (LIVE 2023)', width: columnWidths['assessmenT_REFERENCE_2023'] || 88, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && params.value != undefined && Number(params.value) != 0 && params.value != '0' ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'description', headerName: 'DESCRIPTION', width: columnWidths['description'] || 175, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'psD_CODE', headerName: 'PSD', width: columnWidths['psD_CODE'] || 34, minWidth: 34, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'scat', headerName: 'SCAT', width: columnWidths['scat'] || 40, minWidth: 40, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'rateablE_VALUE_2023', headerName: 'RATEABLE VALUE 2023', width: columnWidths['rateablE_VALUE_2023'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2023 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_2023 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_2023 == null && <span>&nbsp;</span>}
        </div>
        
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_2023 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_2023', headerName: 'EFFECTIVE DATE 2023', width: columnWidths['effectivE_DATE_2023'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row) => {
        if(row.rateablE_VALUE_2023 == null && row.flaG_2023 == null)  {return 'IN 2023';}
        return formatDateToString(value)
      },
    },
    {
      field: 'lisT_ALTERATION_DATE_2023', headerName: 'LIST ALTERATION DATE 2023', width: columnWidths['lisT_ALTERATION_DATE_2023'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_2023 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_2023 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row) => {
        if(row.rateablE_VALUE_2023  == null && row.flaG_2023 == null)  {return 'LIST';}
        return formatDateToString(value)
      },
    },
    {
      field: 'compositE_2023', headerName: 'COMPOSITE 2023', width: columnWidths['compositE_2023'] || 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}

        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_2023', headerName: 'SETTLEMENT TYPE 2023', width: columnWidths['settlemenT_TYPE_2023'] || 150, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(2023, params.value)}</span> : <span>&nbsp;</span>}

        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(row.lisT_YEAR,value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_2023', headerName: 'COMPILED RATEABLE VALUE 2023', width: columnWidths['compileD_RATEABLE_VALUE_2023'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_2023', headerName: 'LIST PERCENTAGE 2023', width: columnWidths['lisT_PERCENTAGE_2023'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_2023_2017', headerName: 'PERCENTAGE BETWEEN 2023 AND 2017', width: columnWidths['percentagE_BETWEEN_2023_2017'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'drafT_2023_RATEABLE_VALUE', headerName: 'DRAFT 2023 RATEABLE VALUE', width: columnWidths['drafT_2023_RATEABLE_VALUE'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div className={params.row.flaG_2023 == null ? 'emptycell right' : 'bluecell'} onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'rateablE_VALUE_2017', headerName: 'RATEABLE VALUE 2017', width: columnWidths['rateablE_VALUE_2017'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2017 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_2017 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_2017 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_2017 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_2017', headerName: 'EFFECTIVE DATE 2017', width: columnWidths['effectivE_DATE_2017'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div className={params.row.flaG_2017 == null ? 'emptycell' : 'bluecell'} onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2017 == null && row.flaG_2017 == null)  {return 'IN 2017';}
        return formatDateToString(value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_2017', headerName: 'LIST ALTERATION DATE 2017', width: columnWidths['lisT_ALTERATION_DATE_2017'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_2017 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_2017 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2017 == null && row.flaG_2017 == null)  {return 'LIST';}
        return formatDateToString(value)
      }
    },
    {
      field: 'compositE_2017', headerName: 'COMPOSITE 2017', width: columnWidths['compositE_2017'] || 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      )
    },
    {
      field: 'settlemenT_TYPE_2017', headerName: 'SETTLEMENT TYPE 2017', width: columnWidths['settlemenT_TYPE_2017'] || 150, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(2017, params.value)}</span> : <span>&nbsp;</span>}

        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(2017, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_2017', headerName: 'COMPILED RATEABLE VALUE 2017', width: columnWidths['compileD_RATEABLE_VALUE_2017'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_2017', headerName: 'LIST PERCENTAGE 2017', width: columnWidths['lisT_PERCENTAGE_2017'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_2017_2010', headerName: 'PERCENTAGE BETWEEN 2017 AND 2010', width: columnWidths['percentagE_BETWEEN_2017_2010'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'drafT_2017_RATEABLE_VALUE', headerName: 'DRAFT 2017 RATEABLE VALUE', width: columnWidths['drafT_2017_RATEABLE_VALUE'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2017 == null ? 'emptycell right' : 'bluecell'}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'rateablE_VALUE_2010', headerName: 'RATEABLE VALUE 2010', width: columnWidths['rateablE_VALUE_2010'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_2010 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_2010 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_2010 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_2010', headerName: 'EFFECTIVE DATE 2010', width: columnWidths['effectivE_DATE_2010'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell' : 'bluecell'}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2010 == null && row.flaG_2010 == null)  {return 'IN 2010';}
        return formatDateToString(value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_2010', headerName: 'LIST ALTERATION DATE 2010', width: columnWidths['lisT_ALTERATION_DATE_2010'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_2010 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_2010 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2010 == null && row.flaG_2010 == null)  {return 'LIST';}
        return formatDateToString(value)
      }
    },
    {
      field: 'compositE_2010', headerName: 'COMPOSITE 2010', width: columnWidths['compositE_2010'] || 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_2010', headerName: 'SETTLEMENT TYPE 2010', width: columnWidths['settlemenT_TYPE_2010'] || 20, minWidth: 20, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(2010, params.value)}</span> : <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(2010, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_2010', headerName: 'COMPILED RATEABLE VALUE 2010', width: columnWidths['compileD_RATEABLE_VALUE_2010'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? FormatNumberWithCommas(params.value) : <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_2010', headerName: 'LIST PERCENTAGE 2010', width: columnWidths['lisT_PERCENTAGE_2010'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_2010_2005', headerName: 'PERCENTAGE BETWEEN 2010 AND 2005', width: columnWidths['percentagE_BETWEEN_2010_2005'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'drafT_2010_RATEABLE_VALUE', headerName: 'DRAFT 2010 RATEABLE VALUE', width: columnWidths['drafT_2010_RATEABLE_VALUE'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell right' : 'bluecell'}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'rateablE_VALUE_2005', headerName: 'RATEABLE VALUE 2005', width: columnWidths['rateablE_VALUE_2005'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2005 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_2005 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_2005 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_2005 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_2005', headerName: 'EFFECTIVE DATE 2005', width: columnWidths['effectivE_DATE_2005'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2005 == null ? 'emptycell' : 'bluecell'}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2005 == null && row.flaG_2005 == null)  {return 'IN 2005';}
        return formatDateToString(value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_2005', headerName: 'LIST ALTERATION DATE 2005', width: columnWidths['lisT_ALTERATION_DATE_2005'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2005 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_2005 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_2005 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2005 == null && row.flaG_2005 == null)  {return 'LIST';}
        return formatDateToString(value)
      }
    },
    {
      field: 'compositE_2005', headerName: 'COMPOSITE 2005', width: columnWidths['compositE_2005'] || 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2005 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_2005', headerName: 'SETTLEMENT TYPE 2005', width: columnWidths['settlemenT_TYPE_2005'] || 20, minWidth: 20, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2005 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(2005, params.value)}</span> : <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(2005, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_2005', headerName: 'COMPILED RATEABLE VALUE 2005', width: columnWidths['compileD_RATEABLE_VALUE_2005'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2005 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas( value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_2005', headerName: 'LIST PERCENTAGE 2005', width: columnWidths['lisT_PERCENTAGE_2005'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2005 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_2005_2000', headerName: 'PERCENTAGE BETWEEN 2005 AND 2000', width: columnWidths['percentagE_BETWEEN_2005_2000'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2005 == null ? 'emptycell right' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'rateablE_VALUE_2000', headerName: 'RATEABLE VALUE 2000', width: columnWidths['rateablE_VALUE_2000'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2000 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_2000 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_2000 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_2000 != null)  {return 'DELETED';}
        return '\u200B';
      }
    },
    {
      field: 'effectivE_DATE_2000', headerName: 'EFFECTIVE DATE 2000', width: columnWidths['effectivE_DATE_2000'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2000 == null ? 'emptycell' : 'bluecell'}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return formatDateToString( value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_2000', headerName: 'LIST ALTERATION DATE 2000', width: columnWidths['lisT_ALTERATION_DATE_2000'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2000 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_2000 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_2000 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return formatDateToString( value)
      }
    },
    {
      field: 'compositE_2000', headerName: 'COMPOSITE 2000', width: columnWidths['compositE_2000'] || 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2000 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_2000', headerName: 'SETTLEMENT TYPE 2000', width: columnWidths['settlemenT_TYPE_2000'] || 20, minWidth: 20, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2000 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(2000, params.value)}</span> : <span>&nbsp;</span>}

        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(2000, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_2000', headerName: 'COMPILED RATEABLE VALUE 2000', width: columnWidths['compileD_RATEABLE_VALUE_2000'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2000 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas( value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_2000', headerName: 'LIST PERCENTAGE 2000', width: columnWidths['lisT_PERCENTAGE_2000'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2000 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_2000_1995', headerName: 'PERCENTAGE BETWEEN 2000 AND 1995', width: columnWidths['percentagE_BETWEEN_2000_1995'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2000 == null ? 'emptycell right' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'rateablE_VALUE_1995', headerName: 'RATEABLE VALUE 1995', width: columnWidths['rateablE_VALUE_1995'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1995 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_1995 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_1995 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_1995 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_1995', headerName: 'EFFECTIVE DATE 1995', width: columnWidths['effectivE_DATE_1995'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1995 == null ? 'emptycell' : 'bluecell'}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1995 == null && row.flaG_1995 == null)  {return 'IN 1995';}
        return formatDateToString( value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_1995', headerName: 'LIST ALTERATION DATE 1995', width: columnWidths['lisT_ALTERATION_DATE_1995'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1995 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_1995 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_1995 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1995 == null && row.flaG_1995 == null)  {return 'LIST';}
        return formatDateToString( value)
      }
    },
    {
      field: 'compositE_1995', headerName: 'COMPOSITE 1995', width: columnWidths['compositE_1995'] || 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1995 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_1995', headerName: 'SETTLEMENT TYPE 1995', width: columnWidths['settlemenT_TYPE_1995'] || 20, minWidth: 20, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1995 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(1995, params.value)}</span> : <span>&nbsp;</span>}

        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(1995, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_1995', headerName: 'COMPILED RATEABLE VALUE 1995', width: columnWidths['compileD_RATEABLE_VALUE_1995'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1995 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_1995', headerName: 'LIST PERCENTAGE 1995', width: columnWidths['lisT_PERCENTAGE_1995'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1995 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_1995_1990', headerName: 'PERCENTAGE BETWEEN 1995 AND 1990', width: columnWidths['percentagE_BETWEEN_1995_1990'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1995 == null ? 'emptycell right' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'rateablE_VALUE_1990', headerName: 'RATEABLE VALUE 1990', width: columnWidths['rateablE_VALUE_1990'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1990 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_1990 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_1990 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_1990 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_1990', headerName: 'EFFECTIVE DATE 1990', width: columnWidths['effectivE_DATE_1990'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1990 == null ? 'emptycell' : 'bluecell'}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1990 == null && row.flaG_1990 == null)  {return 'IN 1990';}
        return formatDateToString(value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_1990', headerName: 'LIST ALTERATION DATE 1990', width: columnWidths['lisT_ALTERATION_DATE_1990'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1990 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_1990 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_1990 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1990== null && row.flaG_1990 == null)  {return 'LIST';}
        return formatDateToString(value)
      }
    },
    {
      field: 'compositE_1990', headerName: 'COMPOSITE 1990', width: columnWidths['compositE_1990'] || 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1990 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_1990', headerName: 'SETTLEMENT TYPE 1990', width: columnWidths['settlemenT_TYPE_1990'] || 20, minWidth: 20, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1990 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(1990, params.value)}</span> : <span>&nbsp;</span>}

        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(1990, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_1990', headerName: 'COMPILED RATEABLE VALUE 1990', width: columnWidths['compileD_RATEABLE_VALUE_1990'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1990 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_1990', headerName: 'LIST PERCENTAGE 1990', width: columnWidths['lisT_PERCENTAGE_1990'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1990 == null ? 'emptycell right' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
  ];
  const columnGroupingModel: GridColumnGroup[] = [
    {
      groupId: 'STATUS/ADDRESS',
      children: [{ field: 'flaG_2023' },{ field: 'flaG_2017' },{ field: 'flaG_2010' },{ field: 'flaG_2005' },{ field: 'flaG_2000' },{ field: 'flaG_1995' }, { field: 'flaG_1990' },      { field: 'firm_name' }, { field: 'address' }, { field: 'postcode' }],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: 'HEREDITAMENT INFORMATION',
      children: [{ field: 'assessmenT_REFERENCE_2023' }, { field: 'description' }, { field: 'psD_CODE' }, { field: 'scat' }, { field: 'bA_REFERENCE_NUMBER' },]
      , headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '1990 LIST',
      children: [{ field: 'rateablE_VALUE_1990' }, { field: 'effectivE_DATE_1990' }, { field: 'lisT_ALTERATION_DATE_1990' }, { field: 'compositE_1990' }, { field: 'settlemenT_TYPE_1990' }, { field: 'compileD_RATEABLE_VALUE_1990' }, { field: 'lisT_PERCENTAGE_1990' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '1995 LIST',
      children: [{ field: 'rateablE_VALUE_1995' }, { field: 'effectivE_DATE_1995' }, { field: 'lisT_ALTERATION_DATE_1995' }, { field: 'compositE_1995' }, { field: 'settlemenT_TYPE_1995' }, { field: 'compileD_RATEABLE_VALUE_1995' }, { field: 'lisT_PERCENTAGE_1995' }, { field: 'percentagE_BETWEEN_1995_1990' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '2000 LIST',
      children: [{ field: 'rateablE_VALUE_2000' }, { field: 'effectivE_DATE_2000' }, { field: 'lisT_ALTERATION_DATE_2000' }, { field: 'compositE_2000' }, { field: 'settlemenT_TYPE_2000' }, { field: 'compileD_RATEABLE_VALUE_2000' }, { field: 'lisT_PERCENTAGE_2000' }, { field: 'percentagE_BETWEEN_2000_1995' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '2005 LIST',
      children: [{ field: 'rateablE_VALUE_2005' }, { field: 'effectivE_DATE_2005' }, { field: 'lisT_ALTERATION_DATE_2005' }, { field: 'compositE_2005' }, { field: 'settlemenT_TYPE_2005' }, { field: 'compileD_RATEABLE_VALUE_2005' }, { field: 'lisT_PERCENTAGE_2005' }, { field: 'percentagE_BETWEEN_2005_2000' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '2010 LIST',
      children: [{ field: 'rateablE_VALUE_2010' }, { field: 'effectivE_DATE_2010' }, { field: 'lisT_ALTERATION_DATE_2010' }, { field: 'compositE_2010' }, { field: 'settlemenT_TYPE_2010' }, { field: 'compileD_RATEABLE_VALUE_2010' }, { field: 'lisT_PERCENTAGE_2010' }, { field: 'percentagE_BETWEEN_2010_2005' }, { field: 'drafT_2010_RATEABLE_VALUE' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '2017 LIST',
      children: [{ field: 'rateablE_VALUE_2017' }, { field: 'effectivE_DATE_2017' }, { field: 'lisT_ALTERATION_DATE_2017' }, { field: 'compositE_2017' }, { field: 'settlemenT_TYPE_2017' }, { field: 'compileD_RATEABLE_VALUE_2017' }, { field: 'lisT_PERCENTAGE_2017' }, { field: 'percentagE_BETWEEN_2017_2010' }, { field: 'drafT_2017_RATEABLE_VALUE' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    }, {
      groupId: '2023 LIST',
      children: [{ field: 'rateablE_VALUE_2023' }, { field: 'effectivE_DATE_2023' }, { field: 'lisT_ALTERATION_DATE_2023' }, { field: 'compositE_2023' }, { field: 'settlemenT_TYPE_2023' }, { field: 'compileD_RATEABLE_VALUE_2023' }, { field: 'lisT_PERCENTAGE_2023' }, { field: 'percentagE_BETWEEN_2023_2017' }, { field: 'drafT_2023_RATEABLE_VALUE' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    }
  ]

  const ExcelGroupingModel = [
    {
      groupId: 'STATUS/ADDRESS',
      children: ['flaG_2023', 'flaG_2017', 'flaG_2010', 'flaG_2005', 'flaG_2000', 'flaG_1995', 'flaG_1990', 'firm_name', 'address', 'postcode']
    },
    {
      groupId: 'HEREDITAMENT INFORMATION',
      children: ['bA_REFERENCE_NUMBER', 'assessmenT_REFERENCE_2023', 'description', 'psD_CODE', 'scat']
    },
    {
      groupId: '2023 LIST',
      children: ['rateablE_VALUE_2023', 'effectivE_DATE_2023', 'lisT_ALTERATION_DATE_2023', 'compositE_2023', 'settlemenT_TYPE_2023', 'compileD_RATEABLE_VALUE_2023', 'lisT_PERCENTAGE_2023', 'percentagE_BETWEEN_2023_2017', 'drafT_2023_RATEABLE_VALUE']
    },
    {
      groupId: '2017 LIST',
      children: ['rateablE_VALUE_2017', 'effectivE_DATE_2017', 'lisT_ALTERATION_DATE_2017', 'compositE_2017', 'settlemenT_TYPE_2017', 'compileD_RATEABLE_VALUE_2017', 'lisT_PERCENTAGE_2017', 'percentagE_BETWEEN_2017_2010', 'drafT_2017_RATEABLE_VALUE']
    },
    {
      groupId: '2010 LIST',
      children: ['rateablE_VALUE_2010', 'effectivE_DATE_2010', 'lisT_ALTERATION_DATE_2010', 'compositE_2010', 'settlemenT_TYPE_2010', 'compileD_RATEABLE_VALUE_2010', 'lisT_PERCENTAGE_2010', 'percentagE_BETWEEN_2010_2005', 'drafT_2010_RATEABLE_VALUE']
    },
    {
      groupId: '2005 LIST',
      children: ['rateablE_VALUE_2005', 'effectivE_DATE_2005', 'lisT_ALTERATION_DATE_2005', 'compositE_2005', 'settlemenT_TYPE_2005', 'compileD_RATEABLE_VALUE_2005', 'lisT_PERCENTAGE_2005', 'percentagE_BETWEEN_2005_2000']
    },
    {
      groupId: '2000 LIST',
      children: ['rateablE_VALUE_2000', 'effectivE_DATE_2000', 'lisT_ALTERATION_DATE_2000', 'compositE_2000', 'settlemenT_TYPE_2000', 'compileD_RATEABLE_VALUE_2000', 'lisT_PERCENTAGE_2000', 'percentagE_BETWEEN_2000_1995']
    },
    {
      groupId: '1995 LIST',
      children: ['rateablE_VALUE_1995', 'effectivE_DATE_1995', 'lisT_ALTERATION_DATE_1995', 'compositE_1995', 'settlemenT_TYPE_1995', 'compileD_RATEABLE_VALUE_1995', 'lisT_PERCENTAGE_1995', 'percentagE_BETWEEN_1995_1990']
    },
    {
      groupId: '1990 LIST',
      children: ['rateablE_VALUE_1990', 'effectivE_DATE_1990', 'lisT_ALTERATION_DATE_1990', 'compositE_1990', 'settlemenT_TYPE_1990', 'compileD_RATEABLE_VALUE_1990', 'lisT_PERCENTAGE_1990']
    }
  ]

  const ExcelColumnModel = [
    { key: 'flaG_2023', header: '23', width: 3 },
    { key: 'flaG_2017', header: '17', width: 3 },
    { key: 'flaG_2010', header: '10', width: 3 },
    { key: 'flaG_2005', header: '05', width: 3 },
    { key: 'flaG_2000', header: '00', width: 3 },
    { key: 'flaG_1995', header: '95', width: 3 },
    { key: 'flaG_1990', header: '90', width: 3 },
    { key: 'firm_name', header: 'FIRM NAME', width: 12 },
    { key: 'address', header: 'ADDRESS', width: 46 },
    { key: 'postcode', header: 'POSTCODE', width: 12 },
    { key: 'bA_REFERENCE_NUMBER', header: 'BA REFERENCE', width: 14 },
    { key: 'assessmenT_REFERENCE_2023', header: 'ASSESSMENT REF (LIVE 2023)', width: 14 },
    { key: 'description', header: 'DESCRIPTION', width: 27 },
    { key: 'psD_CODE', header: 'PSD', width: 6 },
    { key: 'scat', header: 'SCAT', width: 6 },
    { key: 'rateablE_VALUE_2023', header: 'RATEABLE VALUE 2023', width: 14, style: { numFmt: '#,##0' } },
    { key: 'effectivE_DATE_2023', header: 'EFFECTIVE DATE 2023', width: 14 },
    { key: 'lisT_ALTERATION_DATE_2023', header: 'LIST ALTERATION DATE 2023', width: 14},
    { key: 'compositE_2023', header: 'COMPOSITE 2023', width: 14 },//
    { key: 'settlemenT_TYPE_2023', header: 'SETTLEMENT TYPE 2023', width: 14 },
    { key: 'compileD_RATEABLE_VALUE_2023', header: 'COMPILED RATEABLE VALUE 2023', width: 18, style: { numFmt: '#,##0' } },
    { key: 'lisT_PERCENTAGE_2023', header: 'LIST PERCENTAGE 2023', width: 14 },
    { key: 'percentagE_BETWEEN_2023_2017', header: 'PERCENTAGE BETWEEN 2023 AND 2017', width: 18 },
    { key: 'drafT_2023_RATEABLE_VALUE', header: 'DRAFT 2023 RATEABLE VALUE', width: 14, style: { numFmt: '#,##0' } },
    { key: 'rateablE_VALUE_2017', header: 'RATEABLE VALUE 2017', width: 14, style: { numFmt: '#,##0' } },
    { key: 'effectivE_DATE_2017', header: 'EFFECTIVE DATE 2017', width: 14 },
    { key: 'lisT_ALTERATION_DATE_2017', header: 'LIST ALTERATION DATE 2017', width: 14 },
    { key: 'compositE_2017', header: 'COMPOSITE 2017', width: 14 },
    { key: 'settlemenT_TYPE_2017', header: 'SETTLEMENT TYPE 2017', width: 14 },
    { key: 'compileD_RATEABLE_VALUE_2017', header: 'COMPILED RATEABLE VALUE 2017', width: 18 , style: { numFmt: '#,##0' }},
    { key: 'lisT_PERCENTAGE_2017', header: 'LIST  PERCENTAGE 2017', width: 14 },
    { key: 'percentagE_BETWEEN_2017_2010',header:  'PERCENTAGE BETWEEN 2017 AND 2010',width: 18,}, 
    { key: 'drafT_2017_RATEABLE_VALUE',header: 'DRAFT 2017 RATEABLE VALUE',width: 14, style: { numFmt: '#,##0' }},
    { key: 'rateablE_VALUE_2010', header:'RATEABLE VALUE 2010', width: 14 , style: { numFmt: '#,##0' }},
    { key: 'effectivE_DATE_2010', header:'EFFECTIVE DATE 2010', width: 14 },
    { key: 'lisT_ALTERATION_DATE_2010', header:'LIST ALTERATION DATE 2010', width: 14 },
    { key: 'compositE_2010', header:'COMPOSITE 2010', width: 14 },
    { key: 'settlemenT_TYPE_2010', header:'SETTLEMENT TYPE 2010', width: 14 },
    { key: 'compileD_RATEABLE_VALUE_2010', header:'COMPILED RATEABLE VALUE 2010', width: 18 , style: { numFmt: '#,##0' }},
    { key: 'lisT_PERCENTAGE_2010', header:'LIST PERCENTAGE 2010', width: 14 },
    { key: 'percentagE_BETWEEN_2010_2005',header:  'PERCENTAGE BETWEEN 2010 AND 2005',width: 18,},
    { key: 'drafT_2010_RATEABLE_VALUE',header: 'DRAFT 2010 RATEABLE VALUE',width: 14, style: { numFmt: '#,##0' }},
    { key: 'rateablE_VALUE_2005', header:'RATEABLE VALUE 2005', width: 14 , style: { numFmt: '#,##0' }},
    { key: 'effectivE_DATE_2005', header:'EFFECTIVE DATE 2005', width: 14 },
    { key: 'lisT_ALTERATION_DATE_2005', header:'LIST ALTERATION DATE 2005', width: 14 },
    { key: 'compositE_2005', header:'COMPOSITE 2005', width: 14 },
    { key: 'settlemenT_TYPE_2005', header:'SETTLEMENT TYPE 2005', width: 14 },
    { key: 'compileD_RATEABLE_VALUE_2005', header:'COMPILED RATEABLE VALUE 2005', width: 18 , style: { numFmt: '#,##0' }},
    { key: 'lisT_PERCENTAGE_2005', header:'LIST PERCENTAGE 2005', width: 14 },
    { key: 'percentagE_BETWEEN_2005_2000',header:  'PERCENTAGE BETWEEN 2005 AND 2000',width: 18,},
    { key: 'rateablE_VALUE_2000', header:'RATEABLE VALUE 2000', width: 14 , style: { numFmt: '#,##0' }},
    { key: 'effectivE_DATE_2000', header:'EFFECTIVE DATE 2000', width: 14},
    { key: 'lisT_ALTERATION_DATE_2000', header:'LIST ALTERATION DATE 2000', width: 14 },
    { key: 'compositE_2000', header:'COMPOSITE 2000', width: 14 },
    { key: 'settlemenT_TYPE_2000', header:'SETTLEMENT TYPE 2000', width: 14 },
    { key: 'compileD_RATEABLE_VALUE_2000', header:'COMPILED RATEABLE VALUE 2000', width: 18, style: { numFmt: '#,##0' } },
    { key: 'lisT_PERCENTAGE_2000', header:'LIST PERCENTAGE 2000', width: 14 },
    { key: 'percentagE_BETWEEN_2000_1995',header:  'PERCENTAGE BETWEEN 2000 AND 1995',width: 18,},
    { key: 'rateablE_VALUE_1995', header:'RATEABLE VALUE 1995', width: 14 , style: { numFmt: '#,##0' }},
    { key: 'effectivE_DATE_1995', header:'EFFECTIVE DATE 1995', width: 14 },
    { key: 'lisT_ALTERATION_DATE_1995', header:'LIST ALTERATION DATE 1995', width: 14 },
    { key: 'compositE_1995', header:'COMPOSITE 1995', width: 14 },
    { key: 'settlemenT_TYPE_1995', header:'SETTLEMENT TYPE 1995', width: 14 },
    { key: 'compileD_RATEABLE_VALUE_1995', header:'COMPILED RATEABLE VALUE 1995', width: 18, style: { numFmt: '#,##0' } },
    { key: 'lisT_PERCENTAGE_1995', header:'LIST PERCENTAGE 1995', width: 14 },
    { key: 'percentagE_BETWEEN_1995_1990', header:'PERCENTAGE BETWEEN 1995 AND 1990', width: 18 },
    { key: 'rateablE_VALUE_1990', header:'RATEABLE VALUE 1990', width: 14, style: { numFmt: '#,##0' } },
    { key: 'effectivE_DATE_1990', header:'EFFECTIVE DATE 1990', width: 14 },
    { key: 'lisT_ALTERATION_DATE_1990', header:'LIST ALTERATION DATE 1990', width: 14 },
    { key: 'compositE_1990', header:'COMPOSITE 1990', width: 14 },
    { key: 'settlemenT_TYPE_1990', header:'SETTLEMENT TYPE 1990', width: 14 },
    { key: 'compileD_RATEABLE_VALUE_1990', header:'COMPILED RATEABLE VALUE 1990', width: 18, style: { numFmt: '#,##0' } },
    { key: 'lisT_PERCENTAGE_1990', header:'LIST PERCENTAGE 1990', width: 14 },
  ]
  const rows = integratedListData;
  return (
    <div style={{ height: '100%', width: '100%' }}>
      {rows.length != 0 &&
        <>
          <DataGridPro
            columns={columns}
            rowHeight={22}
            rows={rows}
            apiRef={apiRef}
            onRowsScrollEnd={handleRowsScrollEnd}
            columnHeaderHeight={22}
            sortingMode="server"
            columnGroupingModel={columnGroupingModel}
            getRowId={(row: IntegratedListResult) => row.keY_FIELD!}
            onRowClick={handleRowClick}
            onClipboardCopy={onClipboardCopy}
            // ignoreValueFormatterDuringExport= {false}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            rowSelectionModel={rowSelectionModel}
            autosizeOptions={{
              columns: ['name', 'status', 'createdBy'],
              includeOutliers: true,
              includeHeaders: false,
            }}
            onSortModelChange={(model) => handleRowOrderChange(model)}
            onColumnWidthChange={handleColumnResize}

            hideFooter
          />
          <TableRightClickMenu
            contextMenuPosition={contextMenuPosition}
            handleClose={handleCloseContextMenu}
            handleMenuItemClick={handleMenuItemClick}
            table={0}
          />

        </>
      }
      {showNoScheme &&
        <ClickAwayListener onClickAway={(event) => (setShowNoScheme(false))}>
          <div className='noschemepopup'>
            <div className='closeme' onClick={() => setShowNoScheme(false)}>
              <CloseIcon />
            </div>
            <div className='popupheader'>Analyse</div>
            <div className='popupbody'>Valuation Scheme Not Available</div>
            <Button variant='outlined' className='popupbutton' onClick={() => setShowNoScheme(false)}>OK</Button>
          </div>
        </ClickAwayListener>
      }
      {
        searchEmpty &&
        <ClickAwayListener onClickAway={(event) => (setSearchEmpty(false))}>
          <div className='searchemptypopup'>
            <div className='closeme' onClick={() => setSearchEmpty(false)}>
              <CloseIcon />
            </div>
            <div className='popupheader'>Analyse</div>
            <div className='popupbody'>No Assessments found - Please refine your search</div>
            <Button variant='outlined' className='popupbutton' onClick={() => setSearchEmpty(false)}>OK</Button>
          </div>
        </ClickAwayListener>
      }
      { report &&
        <ReportWindow reportString={report} onClose={() => setReport(undefined)}></ReportWindow>
      }
      
      {showNoValuation &&
        <ClickAwayListener onClickAway={(event) => (setShowNoValuation(false))}>
          <div className='searchemptypopup'>
            <div className='closeme' onClick={() => setShowNoValuation(false)}>
              <CloseIcon />
            </div>
            <div className='popupheader'>Analyse</div>
            <div className='popupbody'>Valuation Not Available</div>
            <Button variant='outlined' className='popupbutton' onClick={() => setShowNoValuation(false)}>OK</Button>
          </div>
        </ClickAwayListener>
      }
      {rows.length == 0 &&
        <HomeScreenComponent />
      }

    </div>
  );


}