import React, { useEffect, useRef, useState } from 'react';
import { DataGridPro, GridRowsProp, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import { formatDateToString, FormatNumberWithCommas } from '../../Services/FormatService';
import DirectoryBA from '../../Models/SearchApiModels/DirectoryBAModel';
import DirectoryVoaUnits from '../../Models/SearchApiModels/DirectoryVOAUnitsModel';
import SurveySummary from '../../Models/SearchApiModels/SurveySummary';
import { useSelector } from 'react-redux';
import { selectListYear } from "../../Redux/SearchSlicer"



export default function DirectoryVoaUnitsTable(props) {
  const {uarn,data} = props



  return (
     
   <>
   {data.length !=0 ? (
   <div className='svSummaryTab' style={{height: '350px'}}>
    <div style={{ width: '50%', float: 'left' }}>
    SCHEME REFERENCE:
    <br></br> <br></br>
   
    MEASUREMENT BASIS::
    <br></br> <br></br>

    TOTAL AREA: 
    <br></br> <br></br>
    LIST ALTERATION DATE:
    <br></br> <br></br>
    IBR - CURRENT:
    <br></br> <br></br>
    IBR HISTORIC :
    <br></br> <br></br>
    IBR - DESCRIPTION:
    <br></br> <br></br>
    UAR - CURRENT:
    <br></br> <br></br>
    UAR HISTORIC :
    <br></br> <br></br>
    UAR %:
    <br></br> <br></br>
    {/* EFFECTIVE TO: {svSummaryData.totaL_AREA} */}
    POSTCODE:
    <br></br> <br></br>
    UAR VS IBR %: 
    </div>
   
    <div style={{ width: '50%', float: 'right' }}>
     {data.schemE_REFERENCE}
    <br></br> <br></br>
   
   {data.measuremenT_BASIS}
    <br></br> <br></br>

    {FormatNumberWithCommas(data.totaL_AREA!)}
    <br></br> <br></br>
    {formatDateToString(data.lisT_ALTERATION_DATE)}
    <br></br> <br></br>
    {data.ibR_A} 
    <br></br> <br></br>
    {data.ibR_B}
    <br></br> <br></br>
    {data.ibR_DESCRIPTION}
    <br></br> <br></br>
    {data.uaR_A}
    <br></br> <br></br>
    {data.uaR_B}
    <br></br> <br></br>
    {FormatNumberWithCommas(data.totaL_AREA!)}
    <br></br> <br></br>
    
    {data.uaR_IBR_PERCENTAGE}
    <br></br> <br></br>
    </div>
   
   </div>
    ):
    (<>
     {<p style={{marginLeft: '30px', marginTop: '30px'}}>No data available</p>}
   </>)
   }
   </>
   
  );
}