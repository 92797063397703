import { PayloadAction, createSlice } from "@reduxjs/toolkit";
interface ListBuilderState {
    listList: string[];
    savedList:string[];
    dispatchAddSelection:boolean;
    displayListBuilder:boolean;
    resetListBuilderTableSort:boolean;
    showListBuilderDetails:boolean;  
    triggerGOVUK:boolean,
    triggerGoogleMaps:boolean,
    listID:string,
    cid:string;
    sharable:number;
    changesMade:boolean,
    reloadList:boolean,
    summaryListYear:number,
    label:string;
    triggerShowScheme:boolean,
    prunedUARNs:number,
    triggerDeleteSelection:boolean,
    triggerExportExcel:number,
}
const initialState:ListBuilderState = {
    listList :[],
    dispatchAddSelection:false,
    displayListBuilder:false,
    resetListBuilderTableSort:false,
    showListBuilderDetails:false,
    triggerGOVUK:false,
    triggerGoogleMaps:false,
    listID:'',
    changesMade:false,
    reloadList:false,
    summaryListYear:2023,
    cid:'',
    sharable:0,
    label:'',
    triggerShowScheme:false,
    prunedUARNs:0,
    savedList:[],
    triggerDeleteSelection:false,
    triggerExportExcel:0,
}
const listBuilderSlice = createSlice({
    name: 'listBuilder',
    initialState,
    reducers: {
        addUARNs(state, action) {
            var tempArray = [...state.listList,...action.payload]
            var tempLength = tempArray.length;
            var uniqueSet = new Set(tempArray);
            const uniqueArr = Array.from(uniqueSet);
            const numberOfDuplicatesRemoved = tempLength - uniqueArr.length; 
            return{
                ...state,
                listList:uniqueArr,
                prunedUARNs:numberOfDuplicatesRemoved,
                changesMade:true,
            }
        },
        setUARNs(state,action){    
            var unsaved = true    
            if(action.payload.length ==0 && state.cid == ''){
                unsaved = false
            }
              return {
                ...state,
                showListBuilderDetails:action.payload.length ==0?false:state.showListBuilderDetails,
                listList: [...action.payload],
                changesMade:unsaved,
              };
            
        },
        setDispatchAddSelection(state,action){
            state.dispatchAddSelection = action.payload
        },
        displayListBuilder(state,action){
            state.displayListBuilder = action.payload
        },
        emptyListBuilderArray(state){
            return {
                ...state,
                listList: [],
                listID: '',
                cid:'',
                changesMade:false,
                reloadList:true,
                sharable: 0,
                showListBuilderDetails:false,
                label:'',
            };
        },
        resetListBuilderTableSort(state,action){
            state.resetListBuilderTableSort =  action.payload;
        },
        setShowListBuilderDetails(state,action){
            state.showListBuilderDetails = action.payload
        },
        setTriggerGoogleMaps(state,action){
          state.triggerGoogleMaps = action.payload
        },
        setTriggerGOVUK(state,action){
          state.triggerGOVUK = action.payload
        },
        setListFromApi(state,action){
            var array = action.payload.uarns.split(',')
            array.sort();
            return {
                ...state,
                listList: [...array],
                listID: action.payload.listID,
                changesMade:false,
                cid:action.payload.cid,
                sharable: action.payload.sharable,
                reloadList:true,
                label:action.payload.label,
                savedList: [...array],
            };
        },
        setReloadList(state,action){
            state.reloadList = action.payload

        },
        setChangesMade(state,action){
            state.changesMade = action.payload

        },
        setSummaryListYear(state,action){
            state.summaryListYear = action.payload

        },        
        setTriggerShowScheme(state,action){
            state.triggerShowScheme = action.payload

        },
        resetDuplicateUARN(state){
            state.prunedUARNs = 0;
        },
        resetListBuilder(state){
            return initialState
            
        },
        setTriggerDeleteSelection(state,action){
            state.triggerDeleteSelection = action.payload;
        },
        setTriggerExportExcel(state,action){
          state.triggerExportExcel = action.payload
        },
    }
});

export const {setTriggerExportExcel,resetDuplicateUARN,setTriggerDeleteSelection,resetListBuilder,setSummaryListYear,setTriggerShowScheme, setChangesMade,setReloadList, setListFromApi, addUARNs,setShowListBuilderDetails,setTriggerGoogleMaps,setTriggerGOVUK, setUARNs,setDispatchAddSelection,displayListBuilder,emptyListBuilderArray,resetListBuilderTableSort} = listBuilderSlice.actions;
export default listBuilderSlice.reducer;
export const selectListLength = state => state.listBuilder.listList.length;
export const selectAddSelection = state => state.listBuilder.dispatchAddSelection;
export const selectDisplayListBuilder = state => state.listBuilder.displayListBuilder;
export const selectListList = state => state.listBuilder.listList;
export const selectResetListBuilderTableSort = state => state.listBuilder.resetListBuilderTableSort;
export const selectShowListBuilderDetails = state => state.listBuilder.showListBuilderDetails;
export const selectTriggerGoogleMaps = state => state.listBuilder.triggerGoogleMaps;
export const selectTriggerGOVUK = state => state.listBuilder.triggerGOVUK;
export const selectHasUnsavedChanges = state=>state.listBuilder.changesMade;
export const selectSummaryListYear = state =>state.listBuilder.summaryListYear;
export const selectTriggerShowScheme = state =>state.listBuilder.triggerShowScheme;
export const selectPrunedUARNs= state=>state.listBuilder.prunedUARNs;
export const selectTriggerDeleteSelection=state => state.listBuilder.triggerDeleteSelection;
export const selectTriggerExportExcel = state=>state.listBuilder.triggerExportExcel;

