import { useState } from "react"
import BasicSearchComponent from "./BasicSearchComponent"
import TrendSearchComponent from "./TrendSearchComponent"
import IntegratedListAdvComponent from "./IntegratedListAdvComponent"
import BasicCodeComponent from "./BasicCodeComponent"
import AreaSearchComponent from "./AreaSearchComponent"
import IntegratedSettlementSearchComponent from "./IntegratedSettlementSearchComponent"

export default function IntegratedListSearchComponent(props){
    const [context, setContext] = useState(0)

    const{searchCount} = props;
    return(
        <>
        <div className="layoutrow searchtabbar">
            <div className={context==0?"searchcategoryheader selected":"searchcategoryheader"} onClick={()=>setContext(0)}>General</div>
            <div className={context==1?"searchcategoryheader selected":"searchcategoryheader"} onClick={()=>setContext(1)}>Area</div>
            <div className={context==2?"searchcategoryheader selected":"searchcategoryheader"} onClick={()=>setContext(2)}>Code</div>
            <div className={context==3?"searchcategoryheader selected":"searchcategoryheader"} onClick={()=>setContext(3)}>Advanced</div>
            <div className={context==4?"searchcategoryheader selected":"searchcategoryheader"} onClick={()=>setContext(4)}>Settlement</div>
            <div className={context==5?"searchcategoryheader selected specialtrendtabstyle":"searchcategoryheader specialtrendtabstyle"} onClick={()=>setContext(5)}>Trend</div>
        </div>    
        {context==0 &&
            <BasicSearchComponent searchCount = {searchCount}/>
        }
        {context==1 &&
            <AreaSearchComponent searchCount = {searchCount}/>
        }
        {context==2 &&
            <BasicCodeComponent searchCount={searchCount}/>
        }    
        
        {context==3 &&
            <IntegratedListAdvComponent searchCount = {searchCount}/>
        }

        
        {context==4 &&
            <IntegratedSettlementSearchComponent searchCount = {searchCount}/>
        }
        
        {context ==5 &&
            <TrendSearchComponent searchCount = {searchCount}/>
        }
    </>
    )
}