import { configureStore } from '@reduxjs/toolkit'
import rootReducer from './RootReducer';
import { getConfig } from '../API/APIHelper';

const persistedListBuilder = loadFromLocalStorage();

const store = configureStore({
    reducer: rootReducer,
    preloadedState:persistedListBuilder
  })

  function loadFromLocalStorage() {
    try {
      const serializedState = localStorage.getItem('listState');
      if (serializedState === null) return undefined; 
      var listBuilderState = { listBuilder: JSON.parse(serializedState) };
      return listBuilderState; 
    } catch (e) {
      console.error('Could not load list state', e);
      return undefined;
    }
  }  

  store.subscribe(() => {
    saveToLocalStorage(store.getState());
  });

  function saveToLocalStorage(state) {
    try {
      const serializedState = JSON.stringify(state.listBuilder); 
      localStorage.setItem('listState', serializedState);
    } catch (e) {
      console.error('Could not save auth state', e);
    }
  }
export default store;