import { format, parseISO } from "date-fns";
import AuthorityScheduleAdv1 from "../AuthorityScheduleAdv1";
import AuthorityScheduleAdv2 from "../AuthorityScheduleAdv2";
import { CaseTypeLogic } from "../Enums/CaseTypeLogic";
import { CertificationLogic } from "../Enums/CertificationLogic";
import { DateSearchLogic } from "../Enums/DateSearchLogic";
import { ListTypeLogic } from "../Enums/ListTypeLogic";
import { ScheduleDateLogic } from "../Enums/ScheduleDateLogic";
import { SearchLogic } from "../Enums/SearchLogic";
import { StartsWithContains } from "../Enums/StartsWithContains";
import { formatDateForApi } from "../../Services/FormatService";

export default class AuthorityScheduleAdv2ApiModel {
    rvSearch?: boolean;
    rvLogic?: SearchLogic;
    rvValue?: number;
    rvValueLessThan?: number;
    edSearch?: boolean;
    edLogic?: DateSearchLogic;
    edValue?: string;
    edValueLessThan?: string;
    ladSearch?: boolean;
    ladLogic?: DateSearchLogic;
    ladValue?: string;
    ladValueLessThan?: string;
    partDomestic?: boolean | null;

    constructor(data: Partial<AuthorityScheduleAdv2>) {
        this.rvSearch = data.rvSearch?data.rvSearch:undefined;
        this.rvLogic = data.rvSearch ? Number(data.rvLogic): undefined;
        this.rvValue = data.rvSearch?Number(data.rvValue):undefined;
        this.rvValueLessThan = data.rvSearch && data.rvLogic == Number(3)? Number(data.rvValueLessThan): undefined;
        this.partDomestic = data.partDomesticLogic? (data.partDomestic? true:false):null;
        this.edSearch = data.edSearch?data.edSearch:undefined;
        this.edLogic = data.edSearch ? Number(data.edLogic): undefined;
        this.edValue = (data.edSearch && data.edValue) ? formatDateForApi(data.edValue): undefined;
        this.edValueLessThan = (data.edSearch &&Number(data.edLogic)===4 &&data.edValueLessThan)? formatDateForApi(data.edValueLessThan) : undefined;
        this.ladSearch = data.ladSearch?data.ladSearch:undefined;
        this.ladLogic = data.ladSearch ?Number( data.ladLogic): undefined;
        this.ladValue = (data.ladSearch && data.ladValue) ?formatDateForApi(data.ladValue) : undefined;
        this.ladValueLessThan =(data.ladSearch &&Number(data.ladLogic)===4 &&data.ladValueLessThan) ? formatDateForApi(data.ladValueLessThan)  : undefined;
    }
}