export default class ValuationTribunalExcel{
    lisT_YEAR?: number;
    appeaL_STATUS?: string;
    firM_NAME?: string;
    address?: string;
    postcode?: string;
    bA_REFERENCE_NUMBER?: string;
    description?: string;
    psD_CODE?: string;
    composite?: string;
    rateablE_VALUE?: number;
    effectivE_DATE?: Date;
    agenT_NAME?: string;
    agenT_POSTCODE?: string;
    appeaL_NUMBER?: string;
    suB_PROGRAMME_ID?: string;
    starT_DATE?: Date;
    targeT_DATE?: Date;
    tribunaL_DATE?: Date;
    tribunaL_VENUE?: string;
    tribunaL_START_TIME?: string;
    landS_TRIBUNAL_INDICATOR?: string;
}