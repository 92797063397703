import React, { useEffect, useRef, useState } from 'react';
import { DataGridPro, GridRowsProp, GridColDef, useGridApiRef, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import AuthorityScheduleSearch, { validateAuthorityScheduleSearch } from '../../Models/SearchApiModels/AuthorityScheduleSearch';
import AuthorityScheduleResults from '../../Models/AuthorityScheduleResults';
import { cleanup } from '@testing-library/react';
import AuthorityScheduleAdv1 from '../../Models/AuthorityScheduleAdv1';
import store from '../../Redux/ConfigureStore';
import BasicSearchApiModel from '../../Models/SearchApiModels/BasicSearchApiModel';
import { formatDateToString, FormatNumberWithCommas } from '../../Services/FormatService';
import FlagComponent from '../SubComponents/FlagComponent';
import AuthorityScheduleAdv2ApiModel from '../../Models/SearchApiModels/AuthorityScheduleAdv2ApiModel';
import AuthorityScheduleAdv1ApiModel from '../../Models/SearchApiModels/AuthorityScheduleAdv1ApiModel';
import RvHistory from '../../Models/SearchApiModels/RvHistoryModel';
import RvHistoryGraph from '../RvHistoryGraph';
import SurveyAdjustments from '../../Models/SearchApiModels/SurveyAdjustments';
import surveLine from '../../Models/SearchApiModels/SurveyAdditionalItems';
import SurveyLine from '../../Models/SearchApiModels/SurveyLine';
import SVPieChart from '../SVPieChart';
import { selectListYear } from '../../Redux/SearchSlicer';
import { useSelector } from 'react-redux';
import { spacing } from 'react-select/dist/declarations/src/theme';


export default function SVSurveyLine(props) {
  const { uarn, data } = props

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [contextMenuPosition, setContextMenuPosition] = useState<{ mouseX: number | null; mouseY: number | null }>({ mouseX: null, mouseY: null });
  const [selectedRow, setSelectedRow] = useState(null);

  let currentId = useRef(0);
  const apiRef = useGridApiRef();
  const summaryListYear = useSelector(selectListYear);
  var pageNo = 0
  const getRowId = () => {
    return currentId.current++;
  };


  const [selectedUARN, setSelectedUARN] = useState<string>()

  useEffect(() => {
    setSelectedUARN(selectedUARN)
  }, [selectedUARN])


  const handleContextMenu = (event, row) => {

    event.preventDefault();

    if (event.shiftKey) {
      console.log('Shift key is held down.');
    }
    else {
      if (event.ctrlKey) {
        console.log('Control key is held down.');
      }
      else {
        if (rowSelectionModel.findIndex(elem => row.linE_ORDER == elem) > -1) {
        }
        else {
          setRowSelectionModel([row.linE_ORDER]);
          if (row.uarn !== selectedUARN) {

            setSelectedUARN(row.uarn);

          }
        }

      }
    }


  };

  const columns: GridColDef[] = [


    { field: 'floor', headerName: 'FLOOR', width: 70 },
    { field: 'description', headerName: 'DESCRIPTION', width: 85 },
    { field: 'area', headerName: 'AREA(m²)', width: 100, align: 'right' },
    {
      field: 'price', headerName: 'PRICE(£/m²)', width: 100, align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && FormatNumberWithCommas(params.value)}
        </div>
      ),
    },
    {
      field: 'totaL_VALUE', headerName: 'TOTAL VALUE(£)', width: 95, align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && FormatNumberWithCommas(params.value)}
        </div>
      ),
    },
    {
      field: 'uaR_A', headerName: 'UAR(£)', width: 85, align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && FormatNumberWithCommas(params.value)}

        </div>
      ),
    },
    {
      field: 'uaR_PRICE', headerName: 'PRICE(£/m²)', width: 105, align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && FormatNumberWithCommas(params.value)}
        </div>
      ),
    },
    {
      field: 'uaR_PRICE_PERC', headerName: 'UAR/Price(%)', width: 70, align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && FormatNumberWithCommas(params.value)}
        </div>
      ),
    },

  ]
  const rows = data || [];
 

  const TotalArea = rows.reduce((accumulator, item) => accumulator + item.area!, 0).toFixed(2);
  const TotalValue = rows.reduce((accumulator, item) => accumulator + item.totaL_VALUE!, 0).toFixed(0);

  return (

    <div style={{ height: '100%', width: '100%' }}>
  
        <div style={{ height: '90%', width: '51.5%', float: 'left', maxWidth: '720px' }}>
          {
            rows.length != 0 &&
            <DataGridPro rows={rows} columns={columns} rowHeight={22} columnHeaderHeight={22}

            disableColumnResize={true} 
            hideFooter={true}

            getRowId={(row: SurveyLine) => row.linE_ORDER!}

            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }

            apiRef={apiRef}

            autosizeOptions={{
              includeOutliers: false,
              includeHeaders: false,
            }}
          />
            
          }

{
         rows.length != 0 &&
        <div  className="container" style={{ height: '5%' }}>
        <div className='areaSum' >{TotalArea}</div>
        <div className="totalValueSum" >{FormatNumberWithCommas(TotalValue)}</div>
        </div>
          
}

        </div> 
          {data.length >0 &&
            <div style={{ height: '100%', width: '20%', float: 'right', marginRight: '120px' }}>
              <SVPieChart uarn={uarn} componentchanged={data}/>
            </div>
          
        
          }

      {rows.length == 0 &&
        <div style={{ marginLeft: '40px', marginTop: '40px' }}>
          No data to display
        </div>
      }



    </div>
  );
}