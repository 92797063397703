
import { SurveyAdvanced } from '../SurveyAdvanced';
import { SurveyLinesSurvey } from '../SurveyLinesSurvey';
import { ValidationError } from '../ValidationError';
import BasicSearchApiModel from './BasicSearchApiModel';
import { SurveyAdvancedApiModel } from './SurveyAdvancedApiModel';
import SurveyLineSearchApiModel from './SurveyLineSearchApiModel';

export default class SummaryValuationSearch {
    BasicSearch?: BasicSearchApiModel;
    SurveyAdvanced?: SurveyAdvancedApiModel;
    SurveyLinesSurvey?: SurveyLineSearchApiModel;
    pageno: number = 0;
    sortcolumn: string = "l.key_field";
    sortorder: string = "asc";
    ListYear?: number;
    Archive?: boolean;
    //sqldump?:boolean;
    totals:boolean=false;
    report:boolean = false;
}
export function validateSummaryValuationSearch(model: SummaryValuationSearch): ValidationError[] {
    let errors: ValidationError[] = [];
  
    if (model.BasicSearch) {
      errors = errors.concat(model.BasicSearch.validate().errors);
    }
    
  
    return errors;
  }
