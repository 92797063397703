
import { ValidationError } from "../ValidationError";
import { ValuationTribunalVTS } from "../ValuationTribunalVTS";
import BasicSearchApiModel from "./BasicSearchApiModel";
import ValuationTribunalVTSApiModel from "./ValuationTribunalVTSApiModel";

export default class ValuationTribunalSearch {
    basic?: BasicSearchApiModel;
    vts?: ValuationTribunalVTSApiModel;
    pageno: number = 0;
    sortcolumn: string = "l.key_field";
    sortorder: string = "asc";
    //sqldump?:boolean;
    totals:boolean=false;
    selectedItems?:boolean =false
    vtsItems?: string;
    report?:boolean = false;
}
export function validateValuationTribunalSearch(model: ValuationTribunalSearch): ValidationError[] {
    let errors: ValidationError[] = [];
  
    if (model.basic) {
      errors = errors.concat(model.basic.validate().errors);
    }
  
  
    return errors;
  }