import AuthorityScheduleAdv1 from "../AuthorityScheduleAdv1";
import AuthorityScheduleAdv2 from "../AuthorityScheduleAdv2";
import IntegratedSettlement from "../IntegratedSettlement";
import { ValidationError } from "../ValidationError";
import AuthorityScheduleAdv1ApiModel from "./AuthorityScheduleAdv1ApiModel";
import AuthorityScheduleAdv2ApiModel from "./AuthorityScheduleAdv2ApiModel";
import BasicSearchApiModel from "./BasicSearchApiModel";

export default class AuthorityScheduleSearch {
    basic?: BasicSearchApiModel;
    settlement?: IntegratedSettlement;
    advancedScheduleAd1?: AuthorityScheduleAdv1ApiModel;
    advancedScheduleAd2?: AuthorityScheduleAdv2ApiModel;
    pageno: number = 0;
    sortcolumn: string = "l.key_field";
    sortorder: string = "asc";
    //sqldump?:boolean;
    totals?:boolean=false;
    SelectedItems?:boolean =false;
    keyID?:string;
    report?: boolean = false;
}
export function validateAuthorityScheduleSearch(model: AuthorityScheduleSearch): ValidationError[] {
    let errors: ValidationError[] = [];
  
    if (model.basic) {
      errors = errors.concat(model.basic.validate().errors)
    }
    if(model.advancedScheduleAd1){
      errors = errors.concat(model.advancedScheduleAd1.validate().errors)
    }
  
    return errors;
}