import React, { useEffect, useRef, useState } from 'react';
import { DataGridPro, GridRowsProp, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import { formatDateToString } from '../../Services/FormatService';
import DirectoryBA from '../../Models/SearchApiModels/DirectoryBAModel';
import store from '../../Redux/ConfigureStore';
import { setLoading } from '../../Redux/SearchSlicer';



export default function DirectoryBATable(props) {
  const {uarn} = props

  const [directoryBAData, setDirectoryBAData] = useState<DirectoryBA>();
  const [localLoading, setLocalLoading] = useState(true);
  let currentId = useRef(0);
  const apiRef = useGridApiRef();
  var pageNo = 0
  const getRowId = () => {
    return currentId.current++;
  };
 
  const [selectedUARN,setSelectedUARN]=useState<string>()
  useEffect(() => {
    fetchData();
  }, [uarn]);

  useEffect(() => {
    setSelectedUARN(uarn);
    
  }, [directoryBAData]);
  useEffect(()=>{
    setSelectedUARN(selectedUARN)
  },[selectedUARN])

  
  async function fetchData() {
    setLocalLoading(true);
    store.dispatch(setLoading(true))
    try{
      var data;
    
        data = await ApiHelper.GetDirectory(uarn);
        var dataarray = data ? data : [];
     
            setDirectoryBAData(dataarray);
            setLocalLoading(false);

    }catch(e){
      console.log(e)
    }
    finally{

      store.dispatch(setLoading(false))
    }
            
  }

  if (localLoading) {
    return (
      <div className="loading">
        <p>Loading...</p>
      </div>
    );
  }
 
  return (
     
   <>
   {directoryBAData  ? (
   <div className='baTab'>
    <div style={{ height: '100%', width: '15%', float: 'left' }}>
    NAME: 
    <br></br> <br></br>
    TEL: 
     <br></br> <br></br>
   
    EMAIL:
     <br></br> <br></br>
    CONTACT: 
     <br></br> <br></br>
    ADDRESS: 
     <br></br> <br></br>
    : 
     <br></br> <br></br>
    : 
     <br></br> <br></br>
    : 
     <br></br> <br></br>
    : 
     <br></br> <br></br>
    POSTCODE:
     <br></br> <br></br>
    GLA:
     <br></br> <br></br>
    </div>

    <div style={{ height: '100%', width: '75%', float: 'right' }}>
    {/* NAME: {directoryBAData,BA_NAME}
     <br></br> <br></br> */}
    
    {directoryBAData.bA_NAME}
    <br></br> <br></br>
    {directoryBAData.bA_TEL}
     <br></br> <br></br>
   
    {directoryBAData.bA_EMAIL}
     <br></br> <br></br>
    {directoryBAData.bA_CONTACT}
     <br></br> <br></br>
    {directoryBAData.bA_ADD1}
     <br></br> <br></br>
    {directoryBAData.bA_ADD2}
     <br></br> <br></br>
    {directoryBAData.bA_ADD3}
     <br></br> <br></br>
    {directoryBAData.bA_ADD4}
     <br></br> <br></br>
    {directoryBAData.bA_ADD5}
     <br></br> <br></br>
    {directoryBAData.bA_POSTCODE}
     <br></br> <br></br>
    {directoryBAData.gla}
     <br></br> <br></br>
    </div>

   </div>
   ):
   (<>
    {directoryBAData !=undefined && <p>No data available</p>}
  </>)
   }


   </>
   
  );
}