import { validateBasicSearch } from "../../Services/ValidationService";
import BasicSearch from "../BasicSearch";
import { Regions } from "../Enums/Regions";
import { StartsWithContains } from "../Enums/StartsWithContains";
import { StartsWithEquals } from "../Enums/StartsWithEquals";

export default class BasicSearchApiModel {
    LIST?: number;
    firmname?: string;
    firmnamelogic?: StartsWithContains;
    buildingName?: string;
    buildingNamelogic?: StartsWithContains;
    street?: string;
    streetlogic?: StartsWithContains;
    postdistrict?: string;
    postdistrictlogic?: StartsWithContains;
    town?: string;
    townlogic?: StartsWithEquals;
    postcode?: string;
    postcodelogic?: StartsWithEquals;
    description?: string;
    descriptionlogic?: StartsWithContains;
    billingAuthorityReference?: string;
    live2023AssessmentReference?: string;
    BACODE?: string;
    VOANNDRRVU?: string;
    VOOFFICELOCAL?: string;
    TOFFICE?: string;
    PSD?: string[];
    SCAT?: string[];
    uarn?: string;
    wherefrom?: string;
    region?:number;
    constructor(data: Partial<BasicSearch>){
        
        this.firmname = data.firmname =='' ? undefined : data.firmname;
        this.firmnamelogic = data.firmname =='' ? undefined : data.firmnamelogic;
        this.buildingName = data.buildingName=='' ? undefined: data.buildingName ;
        this.buildingNamelogic = data.buildingName=='' ? undefined: data.buildingNamelogic ;
        this.street = data.street=='' ?undefined :  data.street;
        this.streetlogic = data.street=='' ?undefined :  data.streetlogic;
        this.postdistrict = data.postdistrict==''? undefined : data.postdistrict;
        this.postdistrictlogic = data.postdistrict==''? undefined : data.postdistrictlogic;
        this.town = data.town=='' ? undefined : data.town;
        this.townlogic = data.town=='' ? undefined : data.townlogic;
        this.postcode = data.postcode=='' ? undefined  :data.postcode ;
        this.postcodelogic = data.postcode=='' ? undefined  :data.postcodelogic ;
        this.description = data.description=='' ? undefined : data.description;
        this.descriptionlogic = data.description=='' ? undefined : data.descriptionlogic ;
        this.billingAuthorityReference= data.billingAuthorityReference== ''? undefined: data.billingAuthorityReference;
        this.live2023AssessmentReference= data.live2023AssessmentReference== ''? undefined: data.live2023AssessmentReference;
        this.BACODE= data.BACODE== ''? undefined: data.BACODE;
        this.VOANNDRRVU= data.VOANNDRRVU== ''? undefined: this.VOANNDRRVU;
        this.VOOFFICELOCAL= data.VOOFFICELOCAL== ''? undefined: data.VOOFFICELOCAL;
        this.TOFFICE= data.TOFFICE== ''? undefined: data.TOFFICE;
        this.uarn= data.uarn== ''? undefined: data.uarn;
        this.wherefrom= data.wherefrom== ''? undefined: data.wherefrom;
        this.region = Number(data.region)
    }
    validate() {
        const errors = validateBasicSearch(this);
        return {
          valid: errors.length === 0,
          errors
        };
      }
}