import { SearchLogic } from "./Enums/SearchLogic";
import { TrendLogic } from "./Enums/TrendLogic";
import { UARvsIBR } from "./Enums/UARvsIBR";

export class SurveyAdvanced {
    hasCarpark?: boolean;
    hasPlantMachinery?: boolean;
    totalAreaSearch?: boolean;
    totalAreaLogic?: SearchLogic;
    totalArea?: number;
    totalAreaLessThan?: number;
    ibrSearch?: boolean;
    ibrLogic?: TrendLogic;
    ibrValue?: number;
    uarTrendSearch?: boolean;
    uarTrendLogic?: TrendLogic;
    uarTrendValue?: number;
    uarVsIBRSearch?: UARvsIBR;
    uarSearch?: boolean;
    uarLogic?: SearchLogic;
    uarValue?: number;
    uarValueLessThan?: number;
    rvSearch?: boolean;
    rvLogic?: SearchLogic;
    rvValue?: number;
    rvValueLessThan?: number;
    schemeReference?: string;

    constructor(data?: Partial<SurveyAdvanced>) {
        Object.assign(this, {
            hasCarpark: false,
            hasPlantMachinery: false,
            totalAreaSearch: false,
            totalAreaLogic: 0,
            totalArea: 0,
            totalAreaLessThan: 0,
            ibrSearch: false,
            ibrLogic: 0,
            ibrValue: 0,
            uarTrendSearch: false,
            uarTrendLogic: 0,
            uarTrendValue: 0,
            uarVsIBRSearch: 0,
            uarSearch: false,
            uarLogic: 0,
            uarValue: 0,
            uarValueLessThan: 0,
            rvSearch: false,
            rvLogic: 0,
            rvValue: 0,
            rvValueLessThan: 0,
            schemeReference: '',
            ...data,
        });
    }

    serialize(): Partial<SurveyAdvanced> {
        return {
            hasCarpark: this.hasCarpark,
            hasPlantMachinery: this.hasPlantMachinery,
            totalAreaSearch: this.totalAreaSearch,
            totalAreaLogic: this.totalAreaLogic,
            totalArea: this.totalArea,
            totalAreaLessThan: this.totalAreaLessThan,
            ibrSearch: this.ibrSearch,
            ibrLogic: this.ibrLogic,
            ibrValue: this.ibrValue,
            uarTrendSearch: this.uarTrendSearch,
            uarTrendLogic: this.uarTrendLogic,
            uarTrendValue: this.uarTrendValue,
            uarVsIBRSearch: this.uarVsIBRSearch,
            uarSearch: this.uarSearch,
            uarLogic: this.uarLogic,
            uarValue: this.uarValue,
            uarValueLessThan: this.uarValueLessThan,
            rvSearch: this.rvSearch,
            rvLogic: this.rvLogic,
            rvValue: this.rvValue,
            rvValueLessThan: this.rvValueLessThan,
            schemeReference: this.schemeReference
        };
    }
}