import React, { useEffect, useRef, useState } from 'react';
import { DataGridPro, GridRowsProp, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../../API/AnalyseAPIHelper';
import { formatDateToString } from '../../Services/FormatService';
import DirectoryBA from '../../Models/SearchApiModels/DirectoryBAModel';
import DirectoryVoaUnits from '../../Models/SearchApiModels/DirectoryVOAUnitsModel';
import DirectoryVTS from '../../Models/SearchApiModels/DirectoryVTSModel';
import { setLoading } from '../../Redux/SearchSlicer';
import store from '../../Redux/ConfigureStore';



export default function DirectoryVTSTable(props) {
  const {uarn} = props

  const [directoryVTSData, setDirectoryVTSData] = useState<DirectoryVTS>();
  const [localLoading, setLocalLoading] = useState(true);
  let currentId = useRef(0);
  const apiRef = useGridApiRef();
  var pageNo = 0
  const getRowId = () => {
    return currentId.current++;
  };
 
  const [selectedUARN,setSelectedUARN]=useState<string>()
  useEffect(() => {
    fetchData();
  }, [uarn]);

  useEffect(() => {
    setSelectedUARN(uarn);
    
  }, [directoryVTSData]);
  useEffect(()=>{
    setSelectedUARN(selectedUARN)
  },[selectedUARN])

  
  async function fetchData() {
     
    store.dispatch(setLoading(true))
    setLocalLoading(true);
    try{
    var data;
     
    
    data = await ApiHelper.GetDirectory(uarn);
    var dataarray = data ? data : [];

    setDirectoryVTSData(dataarray);   
    setLocalLoading(false); } 
    catch(e){
      console.log(e)
    }
    finally{
      store.dispatch(setLoading(false))
    }
  }

  if (localLoading) {
    return (
      <div className="loading">
        <p>Loading...</p>
      </div>
    );
  }
 
  return (
     
   <>
   {directoryVTSData  ? (
   <div className='vtsTab'>
    <div style={{ height: '100%', width: '21%', float: 'left' }}>
    NAME:
    <br></br> <br></br>
   
   TEL: 
   <br></br> <br></br>

   ADMIN AREA: 
   <br></br> <br></br>

   EMAIL: 
    </div>
   
    <div style={{ height: '100%', width: '76%', float: 'right' }}>
    {directoryVTSData.t_NAME}
    <br></br> <br></br>
   
    {directoryVTSData.t_TEL}
    <br></br> <br></br>

    {directoryVTSData.t_ADMIN}
    <br></br> <br></br>

     {directoryVTSData.t_EMAIL}
    
    </div>
   </div>
    ):
    (<>
     {directoryVTSData !=undefined && <p>No data available</p>}
   </>)
   
   }
   </>
   
  );
}