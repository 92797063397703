import * as React from 'react';

import { LicenseInfo } from '@mui/x-license';
import AnalyseComponent from './Component/AnalyseComponent';
import './App.css';
import LoginComponent from './Component/LoginComponent';
import { HashRouter as Router, Route,Routes } from 'react-router-dom';
import  PrivateRoute from './Component/PrivateRoute';
import { useEffect, useState } from 'react';
import * as ApiHelper from './API/AnalyseAPIHelper';
import { getConfig, refreshTokens } from './API/APIHelper';
import { getAuthenticationCookies, getCookie } from './Services/CookieService';
import store from './Redux/ConfigureStore';
import { login, logout } from './Redux/AuthorisationSlicer';
import ForgotPasswordComponent from './Component/ForgotPasswordComponent';
import ResetPasswordComponent from './Component/ResetPasswordComponent';


export default function App() {
  const [waitingConfirmation,setConfirmation] =useState(false)
  useEffect(()=>{
    initialiseApp()

    
  },[]);
  async function initialiseApp(){
    let config = await getConfig();
    try{
      var tokens =getAuthenticationCookies();
      if(tokens.expiration && tokens.refreshToken && tokens.expiration){
        var newTokens = await refreshTokens(tokens);
        if(newTokens){
          newTokens.expiration =tokens.expiration
          store.dispatch(login(newTokens))
        }
      }
    }
    catch(e){
      console.error(e)
      store.dispatch(logout())
    }finally{
      setConfirmation(true)  
    }
    
  }
  return (<>
    {!waitingConfirmation&&<div>Loading</div>}
    {waitingConfirmation&&
      <Router>
        <Routes>
          <Route path="/login" Component=
          {LoginComponent} />
          <Route
            path="/"
            element={
                <PrivateRoute >
                  <AnalyseComponent />
                </PrivateRoute>
              
            }
          />
          <Route
            path="/forgot-password"
            Component=
            {ForgotPasswordComponent} />
          <Route
            path="/resettoken"
            Component=
            {ResetPasswordComponent} />
          <Route
            path="*"
            element={
                <div>ERROR Not Found</div>
              
            }
          />

      
        </Routes>
      </Router>}
      </>
  );
}
