import { useState } from "react"
import BasicSearchComponent from "./BasicSearchComponent"
import SurveyLineSearchComponent from "./SurveyLineSearchComponent"
import AdvancedSurveySearchComponent from "./AdvancedSurveySearchComponent"
import BasicCodeComponent from "./BasicCodeComponent"
import AreaSearchComponent from "./AreaSearchComponent"

export default function SummaryValuationSearchComponent(props){
    const [context, setContext] = useState(0)
    const{searchCount} = props;
    return(
        <>
        <div className="layoutrow searchtabbar">
            <div className={context==0?"searchcategoryheader selected":"searchcategoryheader"} onClick={()=>setContext(0)}>General</div>
            <div className={context==1?"searchcategoryheader selected":"searchcategoryheader"} onClick={()=>setContext(1)}>Area</div>
            <div className={context==2?"searchcategoryheader selected":"searchcategoryheader"} onClick={()=>setContext(2)}>Code</div>
            <div className={context==3?"searchcategoryheader selected":"searchcategoryheader"} onClick={()=>setContext(3)}>Survey</div>
            <div className={context==4?"searchcategoryheader selected":"searchcategoryheader"} onClick={()=>setContext(4)}>Advanced</div>
        </div>   
        {context==0 &&
            <BasicSearchComponent searchCount = {searchCount}/>
        }
        {context==1 &&
            <AreaSearchComponent searchCount = {searchCount}/>
        }
        {context==2 &&
            <BasicCodeComponent searchCount={searchCount}/>
        }
        
        {context==3 &&
            <SurveyLineSearchComponent searchCount = {searchCount}/>
        }
        
        {context==4 &&
            <AdvancedSurveySearchComponent searchCount = {searchCount}/>
        }


    </>
    )
}