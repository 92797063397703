import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { refreshTokens } from "../API/APIHelper";
import { setLoginCookies } from "../Services/CookieService";
import { useDispatch } from "react-redux";
const initialState = {
    authenticated:false,
}
const authorisationSlice = createSlice({
    name: 'authorisation',
    initialState,
    reducers: {
        login(state,action){
            setLoginCookies(action.payload);
            return{
                ...state,
                authenticated:true
            }
        },
        loginBypass(state){
            return{
                ...state,
                authenticated:true
            }
        },
        logout(state){
            document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
            document.cookie = `tokenExpires=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
            document.cookie = `refreshToken=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;`;
            return {
                ...state,
                authenticated:false
            };
        },
    }
});
export const { login,logout,loginBypass} = authorisationSlice.actions;
export default authorisationSlice.reducer;
export const authenticated = state=>state.authorisation.authenticated;