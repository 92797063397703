import { useEffect, useState } from "react"
import SVSurveyLine from "../SVSurveyLine"
import SVAdditional from "../SVAdditional"
import SVCarPark from "../SVCarPark"
import SVPlant from "../SVPlant"
import SVAdjustments from "../SVAdjustments"
import SVSummary from "../SVSummary"
import * as ApiHelper from '../../../API/AnalyseAPIHelper';
import SurveyAdjustments from "../../../Models/SearchApiModels/SurveyAdjustments"
import SummaryValuation from "../../../Models/SearchApiModels/SummaryValuation"
import SurveyPlant from "../../../Models/SearchApiModels/SurveyPlant"
import SurveyLine from "../../../Models/SearchApiModels/SurveyLine"
import SurveyAditionalItems from "../../../Models/SearchApiModels/SurveyAdditionalItems"
import SurveyCarPark from "../../../Models/SearchApiModels/SurveyCarPark"
import SurveySummary from "../../../Models/SearchApiModels/SurveySummary"
import { Padding } from "@mui/icons-material"
import { useSelector } from 'react-redux';
import { selectListYear, setLoading } from "../../../Redux/SearchSlicer"
import store from "../../../Redux/ConfigureStore"
import FlagComponent from "../../SubComponents/FlagComponent"


const tabs=[
    'Survey','Additional','Car Park','Plant','Adjustments', 'Summary'

]



export default function SummaryValuationPopover(props){
    
    const {uarn,keyID,listYear} = props
    const [surveyLine, setSurveyLine] = useState<SummaryValuation>();
    const [svLineData, setLineData] = useState<Array<SurveyLine>>();
    const [svAdditionalData, setAdditionalData] = useState<Array<SurveyAditionalItems>>();
    const [svCarParkData, setCarParkData] = useState<Array<SurveyCarPark>>();
    const [svPlantData, setSVPlantData] = useState<Array<SurveyPlant>>();
    const [svSummaryData, setSummaryData] = useState<Array<SurveySummary>>();
  
    const [svAdjustmentData, setAdjustmentData] = useState<Array<SurveyAdjustments>>();
    const [activeId, setActiveId] = useState(null);
 
    const [tabContext,setTabContext]=useState(0);

      const handleClick = (id) => {
        setActiveId(id);
      };

    useEffect(() => {
        fetchData();
      }, [uarn,keyID,listYear]);
    const handleTabClick=(index)=>{
        setTabContext(index);
    }

    async function fetchData() {
      
        var data;
        if(uarn && uarn!=''){
            store.dispatch(setLoading(true))
            data = await ApiHelper.GetSummaryValuationInfo(uarn, listYear,keyID?keyID:undefined,keyID?1:0);
            store.dispatch(setLoading(false))  
        }       
       
                setSurveyLine(data);    
                var adjustmentarray = data ? data.getSurveyAdjustments : [];
                var carParkarray = data ? data.getSurveyCarPark : [];
                var SurveyPlantarray = data ? data.getSurveyPlant : [];
                
                var additionalItemsarray = data ? data.getAdditionalItems : [];
                var linearray = data ? data.getSurveyLine : [];
                var summaryarray = data ? data.getSurveySummary : [];
                setSVPlantData(SurveyPlantarray);
                setAdjustmentData(adjustmentarray);
                setAdditionalData(additionalItemsarray);
                setCarParkData(carParkarray);
                setLineData(linearray);
                setSummaryData(summaryarray);
        
      }
    
      const line = svLineData || []; 
      const plant = svPlantData || []; 
      const adjustment = svAdjustmentData || []; 
      const additional = svAdditionalData || []; 
      const summary = svSummaryData || []; 
      const carpark = svCarParkData || []; 

    return(
    <>
   
    
    <div className="layoutrow summarypopover"  style={{ height: '100%', width: '100%' }}>
        <div className="summarypopovertabcontainer"  >
            <div key={0} id="div1" className={tabContext==0?"summarypopovertab selected": "summarypopovertab"} onClick={()=>setTabContext(0)} ><div className ='summaryflag'><FlagComponent value={line.length == 0 ? 'N':'Y'}/></div>{"Survey"} </div> 
            <div key={1} id="div2" className={tabContext==1?"summarypopovertab selected": "summarypopovertab"} onClick={()=>setTabContext(1)}> <div className ='summaryflag'><FlagComponent value={additional.length == 0 ? 'N':'Y'}/></div>{"Additional"}</div> 
            <div key={2} id="div3" className={tabContext==2?"summarypopovertab selected": "summarypopovertab"} onClick={()=>setTabContext(2)}><div className ='summaryflag'><FlagComponent value={carpark.length == 0 ? 'N':'Y'}/></div>{"Car Park"} </div> 
            <div key={3} id="div4" className={tabContext==3?"summarypopovertab selected": "summarypopovertab"} onClick={()=>setTabContext(3)}><div className ='summaryflag'><FlagComponent value={plant.length == 0 ? 'N':'Y'}/></div>{"Plant"} </div> 
            <div key={4} id="div5" className={tabContext==4?"summarypopovertab selected": "summarypopovertab"} onClick={()=>setTabContext(4)}><div className ='summaryflag'><FlagComponent value={adjustment.length == 0 ? 'N':'Y'}/></div>{"Adjustments"} </div> 
            <div key={5} id="div5" className={tabContext==5?"summarypopovertab selected": "summarypopovertab"} onClick={()=>setTabContext(5)}> <div className ='summaryflag'><FlagComponent value={summary.length == 0 ? 'N':'Y'}/></div>{"Summary"}</div> 
        </div>
        <div className="summarytabcontentcontainer" >
            {
                tabContext == 0&&
                <SVSurveyLine uarn = {uarn} data ={line}/>
            }

            {
                tabContext == 1&&
                <SVAdditional uarn = {uarn} data ={additional}/>
            }
            {
                tabContext == 2&&
                <SVCarPark uarn = {uarn} data ={carpark}/>
            }
                    {
                tabContext == 3&&
                <SVPlant uarn = {uarn} data ={plant}/>
            }
                    {
                tabContext == 4&&
                <SVAdjustments uarn = {uarn} data ={adjustment}/>
            }
                    {
                tabContext == 5&&
                <SVSummary uarn = {uarn} data ={summary}/>
            }
        </div>
    </div>
    </>)
    
}