import { useEffect, useState } from "react";
import store from "../../Redux/ConfigureStore";
import { displayListBuilder, selectListLength, setDispatchAddSelection } from "../../Redux/ListBuilderSlicer";
import { useSelector } from "react-redux";
import { selectDisplayDetails, selectListYear, selectSVIsLive, selectSelectedUARN, selectTableContext, selectTotal, selectCount,  setDisplayDetails, setSVIsLive, setSummaryListYear, setTriggerExportExcel, setTriggerGOVUK,  setTriggerGetCount,  setTriggerGetTotal,  setTriggerGoogleMaps, setTriggerShowScheme } from "../../Redux/SearchSlicer";
import { formatCurrency, formatCurrencywithoutDecimals, FormatNumberWithCommas } from "../../Services/FormatService";
import CloseIcon from '@mui/icons-material/Close';
import { ClickAwayListener } from "@mui/material";

enum AdditionalTables {
    'Detail' = 0,
    'List Builder' = 1,
    'Add to List Builder' = 2
}

enum CountRecords {
    'Count Records' = 0,
    'Total RV' = 1,
}


enum Export {
    'Map in Browser' = 0,
    'Show Entry GOV.UK' = 1,
    'Show Scheme' = 2,
    'Quick Excel Export All' = 4,
    'Quick Excel Export Selected' = 5
}
export default function ResultBarComponent(props) {
    const { } = props;
    const listBuilderCount = useSelector(selectListLength);
    const selectedUARN = useSelector(selectSelectedUARN);
    const displayDetails = useSelector(selectDisplayDetails);
    const total = useSelector(selectTotal);
    const count = useSelector(selectCount);
    const [showCount, setShowCount] = useState(false)
    const [showRV, setShowRV] = useState(false)
    const tableContext = useSelector(selectTableContext);
    const listYear = useSelector(selectListYear);
    const isLive = useSelector(selectSVIsLive);
    const [showVOAGuide,setShowVOAGuide] = useState(false);

    const handleAdditionalTableClick = (index: AdditionalTables) => {
        switch (index) {
            case 0: {
                if (selectedUARN && selectedUARN != '') {
                    store.dispatch(setDisplayDetails(!displayDetails))
                }
                break;
            }
            case 1: {
                store.dispatch(displayListBuilder(true));
                break;
            }
            case 2: {

                store.dispatch(setDispatchAddSelection(true));
                break;
            }
        }
    }
    const handleTotalClick = () => {
        store.dispatch(setTriggerGetTotal(true));
        setShowRV(true);


    }
    
    
    const handleCountClick = () => {
        store.dispatch(setTriggerGetCount(true));
        setShowCount(true)


        
    }
    const handleExportClick = (index: Export) => {
        switch (index) {
            case 0: {
                store.dispatch(setTriggerGoogleMaps(true));
                break;
            }
            case 1: {
                store.dispatch(setTriggerGOVUK(true));
                break;
            }
            case 2: {
                store.dispatch(setTriggerShowScheme(true))
                break;
            }
            case 4: {
                store.dispatch(setTriggerExportExcel(2))
                break;
            }
            case 5: {
                store.dispatch(setTriggerExportExcel(1))
                break;
            }
        }
    }
    return (
        <>
            <div className='layoutcolumn'>
                <div className='layoutrow'>
                    <div className={(selectedUARN ==null || selectedUARN =='') ?'navbarsearchsectionelement disabled' : displayDetails ? 'navbarsearchsectionelement selected' : 'navbarsearchsectionelement'} onClick={(e) => handleAdditionalTableClick(0)}>
                        <figure className="icon detailicon"></figure>
                        <div>Detail</div>
                    </div>
                    <div className='navbarsearchsectionelement' onClick={(e) => handleAdditionalTableClick(1)}>
                        {listBuilderCount > 0 &&
                            <div className="listbuildercount">
                                {listBuilderCount}
                            </div>}
                        <figure className="icon listbuildericon"></figure>
                        <div>List Builder</div>
                    </div>
                    <div className='navbarsearchsectionelement' onClick={(e) => handleAdditionalTableClick(2)}>
                        <figure className="icon addlistbuildericon"></figure>
                        <div>Add to List Builder</div>
                    </div>
                </div>
                <div className="navbartableselectiondescription">
                    <div>Search Functions</div>
                </div>
            </div>
            <div className="vhr">
            </div>
            <div className='layoutcolumn'>
                <div className='layoutrow'>
                    <div className='navbarsearchsectionelement' onClick={(e) => handleCountClick()}>
                        {showCount && count > 0 &&
                            <div className="totalscountsbar">{FormatNumberWithCommas(count)}</div>
                        }
                        <figure className="icon countrecordicon"></figure>
                        <div className="navbartableelementtext">Count Records</div>
                    </div>
                    <div className='navbarsearchsectionelement' onClick={(e) => handleTotalClick()}>
                        {showRV && total > 0 &&
                            <div className="totalsrvbar">{formatCurrencywithoutDecimals(total)}</div>
                        }
                        <figure className="icon totalrvicon"></figure>
                        <div className="navbartableelementtext">Total RV</div>
                    </div>
                </div>
                <div className="navbartableselectiondescription">
                    <div>Count Records</div>
                </div>
            </div>
            <div className="vhr">
            </div>
            <div className='layoutcolumn'>
                <div className='layoutrow'>
                    <div className='navbartableelement' onClick={(e) => handleExportClick(0)}>
                        <figure className="icon googlemapsicon"></figure>
                        <div className="navbartableelementtext">Google Maps</div>
                    </div>
                    <div className='navbartableelement' onClick={(e) => handleExportClick(1)}>
                        <figure className="icon showgovicon"></figure>
                        <div className="navbartableelementtext">Show Entry Gov.UK</div>
                    </div>
                    <div className='navbartableelement' onClick={(e) => handleExportClick(2)}>
                        <figure className="icon showschemeicon"></figure>
                        <div className="navbartableelementtext">Show Scheme</div>
                    </div>
                    <div className='navbartableelement' onClick={(e) => handleExportClick(4)}>
                        <figure className="icon showexcelexporticon"></figure>

                        <div className="navbartableelementtext">Quick Excel Export All</div>
                    </div>
                    <div className='navbartableelement wide' onClick={(e) => handleExportClick(5)}>
                        <figure className="icon showexcelexporticon"></figure>

                        <div className="navbartableelementtext">Quick Excel Export Selected</div>
                    </div>
                </div>
                <div className="navbartableselectiondescription">
                    <div>Export</div>
                </div>
            </div>
            <div className="vhr">
            </div>
            <div className="navbaryearsection">
                <div className="navbaryearrowsection">
                    {tableContext == 1 && <div className="archivesection">
                        <div className={`navbaryearelement ${isLive === true ? 'selected' : ''}`} onClick={() => store.dispatch(setSVIsLive(true))}>
                            <figure className="icon liveicon"></figure>
                            <div className="navbaryearelementtext">Live</div>
                        </div>
                        <div className={`navbaryearelement ${isLive === false ? 'selected' : ''}`} onClick={() => store.dispatch(setSVIsLive(false))}>
                            <figure className="icon archiveicon"></figure>
                            <div className="navbaryearelementtext">Archive</div>
                        </div>
                        <div className="vhr"></div>
                    </div>}
                    <div className="navbaryearselection">
                        <div className={`navbaryearelement ${listYear === 2023 ? 'selected' : ''}`} onClick={() => store.dispatch(setSummaryListYear(2023))}>
                            <figure className="icon list2023icon"></figure>
                            <div className="navbaryearelementtext">2023</div>
                        </div>
                        <div className={`navbaryearelement ${listYear === 2017 ? 'selected' : ''}`} onClick={() => store.dispatch(setSummaryListYear(2017))}>
                            <figure className="icon list2017icon"></figure>
                            <div className="navbaryearelementtext">2017</div>
                        </div>
                        <div className={`navbaryearelement ${listYear === 2010 ? 'selected' : ''}`} onClick={() => store.dispatch(setSummaryListYear(2010))}>
                            <figure className="icon list2010icon"></figure>
                            <div className="navbaryearelementtext">2010</div>
                        </div>
                        <div className={`navbaryearelement ${listYear === 2005 ? 'selected' : ''}`} onClick={() => store.dispatch(setSummaryListYear(2005))}>
                            <figure className="icon list2005icon"></figure>
                            <div className="navbaryearelementtext">2005</div>
                        </div>
                    </div>
                </div>
                <div className="navbartableselectiondescription">
                    <div>Summary Valuation List</div>
                </div>
            </div>
            <div className="vhr">
            </div>
            <div className="voaguide" onClick={()=>setShowVOAGuide(true)}>
                <figure className="icon gbicon"></figure>
                <div className="voaguidetext">VOA Guide</div>
            </div>
            {showVOAGuide && <>
        <div className='popoverbackground'></div>
            <ClickAwayListener onClickAway={(event) =>(setShowVOAGuide(false))}>
            <div className='popover'>
                
                <div className='closeme' onClick={()=>setShowVOAGuide(false)}>
                    <CloseIcon />
                </div>

                <iframe src="/NDR_Map.pdf" width="100%" height="100%"  />
            </div>
        </ClickAwayListener>
        </>}
        </>
    )
}