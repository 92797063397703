import React, { useState, useEffect, useRef, } from 'react';
import { GridColDef, GridRowSelectionModel, GridColumnGroup } from '@mui/x-data-grid-pro';
import * as ApiHelper from '../API/AnalyseAPIHelper';

import store from '../Redux/ConfigureStore';
import { formatDateToString, FormatNumberWithCommas, FormatSettlementCode, stringifyList } from '../Services/FormatService';
import FlagComponent from './SubComponents/FlagComponent';

import { useSelector } from 'react-redux';
import { displayListBuilder, resetDuplicateUARN, selectDisplayListBuilder, selectHasUnsavedChanges, selectListLength, selectListList, selectPrunedUARNs, selectShowListBuilderDetails, selectSummaryListYear, selectTriggerDeleteSelection, selectTriggerExportExcel, selectTriggerGOVUK, selectTriggerGoogleMaps, selectTriggerShowScheme, setReloadList, setSummaryListYear, setTriggerDeleteSelection, setTriggerExportExcel, setTriggerGOVUK, setTriggerGoogleMaps, setTriggerShowScheme, setUARNs } from '../Redux/ListBuilderSlicer';
import CloseIcon from '@mui/icons-material/Close';
import ListBuilderBarComponent from './HeaderBarComponents/ListBuilderBarComponent';
import DetailsPopover from './TableComponents/TablePopovers/DetailsPopover';
import ListBuilderRow from '../Models/ListBuilderRow';
import ListBuilderTable from './TableComponents/ListBuilderTable';
import HomeIcon from '@mui/icons-material/Home';
import IntegratedSearch from '../Models/SearchApiModels/IntegratedSearch';
import BasicSearch from '../Models/BasicSearch';
import BasicSearchApiModel from '../Models/SearchApiModels/BasicSearchApiModel';
import SummaryValuationSearch from '../Models/SearchApiModels/SummaryValuationSearch';
import { ReportModel } from '../Models/ReportModel';
import { getConfig, GetScheme } from '../API/AnalyseAPIHelper';
import { Button, ClickAwayListener } from '@mui/material';
import { setLoading } from '../Redux/SearchSlicer';
import ReportWindow from './SubComponents/ReportWindow';
import IntegratedListResults from '../Models/IntegratedListResult';
import { dataToFile } from '../Services/ExcelExportService';
import DARReportModel from '../Models/DARReportModel';


enum HeaderBarContext {
  'List Builder' = 1,
  Report = 2
}
enum Reports {
  'DAR' = 1,
  'Landscape Selected' = 3,
  'Landscape All' = 4,
  'Valuation' = 2,
  'Valuation Landscape' = 5,

}

export default function ListBuilderComponent(props) {
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const showListBuilder = useSelector(selectDisplayListBuilder);
  const uarnList = useSelector(selectListList) as Array<string>;
  const [resultsArray, setResultsArray] = useState<Array<IntegratedListResults>>([]);
  const showListBuilderDetails = useSelector(selectShowListBuilderDetails);
  const [selectedUARN, setSelectedUARN] = useState<string>();
  const [tableState, setTableState] = useState()
  const [closeListBuilder, setCloseListBuilder] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<{ mouseX: number | null; mouseY: number | null }>({ mouseX: null, mouseY: null });
  const triggerGoogleMaps = useSelector(selectTriggerGoogleMaps);
  const triggerGOVUK = useSelector(selectTriggerGOVUK);
  const listBuilderCount = useSelector(selectListLength);
  const [headerBarContext, setHeaderBarContext] = useState<HeaderBarContext>(1);
  const summaryListYear = useSelector(selectSummaryListYear);
  const [report,setReport] = useState();
  const [showNoScheme,setShowNoScheme]=useState(false);
  const showScheme = useSelector(selectTriggerShowScheme);
  const [uarnSelectionModel, setUARNSelectionModel] = useState<string[]>([]);
  const duplicateUARNs = useSelector(selectPrunedUARNs);
  const deleteRows = useSelector(selectTriggerDeleteSelection);
  const[showNoValuation,setShowNoValuation] = useState(false);
  const triggerExport = useSelector(selectTriggerExportExcel);
  const [columnWidths, setColumnWidths] = useState({});

  const handleColumnResize = (params) => {
    setColumnWidths((prevWidths) => ({
      ...prevWidths,
      [params.colDef.field]: params.width,
    }));
  };
  
  const handleDeleteFromListBuilder = async () => {
    store.dispatch(setLoading(true))
    const newListList = await uarnList.filter((item) => !uarnSelectionModel.includes(item)); 
    store.dispatch(setUARNs(newListList));
    store.dispatch(setLoading(false))    
  }

  const openExternalLink = (url) => {
    try{
      ApiHelper.postLogThis("sending user to url: "+ url)
    }catch(error){
      console.error(error)
    }
    finally{
    window.open(url, '_blank', 'noopener,noreferrer');
    }
  };
  useEffect(()=>{
    if (store.getState().listBuilder.listList.length>0){
      getPreloadedList()
    }
  },[])
  const getPreloadedList=async()=>{
      let config = await getConfig();
      handleGetListData();
  }  
  useEffect(() => {
    const selectedUARNs = rowSelectionModel.map(id => {
      return resultsArray.find(row => row.keY_FIELD === id)?.uarn;
    }).filter(Boolean) as string[];
    setUARNSelectionModel(selectedUARNs);
  }, [rowSelectionModel]);

  useEffect(()=>{
    if(showScheme){
       handleScheme()
    }
  },[showScheme]);

  useEffect(() => {
    if (triggerGoogleMaps == true && selectedUARN) {
      var row = resultsArray.find(item =>
        item.uarn == selectedUARN
      )
      if (row?.postcode) {
        var address = row.address ? row.address + ',' : ''
        openExternalLink('https://www.google.co.uk/maps?q=' + address + row.postcode);
      }

    }
    store.dispatch(setTriggerGoogleMaps(false));
  }, [triggerGoogleMaps]);

  useEffect(() => {
    if (triggerGOVUK == true && selectedUARN) {
      openExternalLink('https://www.tax.service.gov.uk/business-rates-find/valuations/' + selectedUARN)
    }
    store.dispatch(setTriggerGOVUK(false));
  }, [triggerGOVUK]);


  useEffect(() => {
    if(uarnList.length != 0){
      handleGetListData();
    }else{
      setResultsArray([])
      setSelectedUARN(undefined)
      setRowSelectionModel([])
    }
  }, [uarnList])



  const handleRowClick = (e) => {
    const { row } = e;
    if (row.uarn !== selectedUARN) {
      setSelectedUARN(row.uarn);

    }
  }
  const handleScheme = async()=>{
    try{        
      store.dispatch(setLoading(true))
      var response = await GetScheme(summaryListYear,selectedUARN)
      if(response && response.assessmenT_REFERENCE && response.schemE_REFERENCE && selectedUARN){
        openExternalLink(`https://www.tax.service.gov.uk/business-rates-find/valuations/${response.assessmenT_REFERENCE}/schemes/${response.schemE_REFERENCE}?uarn=${selectedUARN}`);
      }  
      else{
        setShowNoScheme(true);
      }
    }
    catch{
      setShowNoScheme(true);
    }
    finally{        
      store.dispatch(setLoading(false))
      store.dispatch(setTriggerShowScheme(false))

    }
  }

  const handleContextMenu = (event, row) => {
    event.preventDefault();

    if (event.shiftKey) {
      console.log('Shift key is held down.');
    }
    else {
      if (event.ctrlKey) {
        setRowSelectionModel([...rowSelectionModel,row.keY_FIELD!]);
      }
      else {
        if(rowSelectionModel.findIndex(elem=>row.keY_FIELD == elem) > -1){
        }
        else{
          setRowSelectionModel([row.keY_FIELD]);
        }
          
      }
      if (row.uarn !== selectedUARN) {

        setSelectedUARN(row.uarn);
  
      }
    }

    if (contextMenuPosition.mouseX !== null && contextMenuPosition.mouseY !== null) {
      handleCloseContextMenu(() => {
        //setSelectedRow(row);
        setContextMenuPosition({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
      });
    } else {
      //setSelectedRow(row);
      setContextMenuPosition({ mouseX: event.clientX - 2, mouseY: event.clientY - 4 });
    }
  };

  const handleCloseContextMenu = (callback) => {
    setContextMenuPosition({ mouseX: null, mouseY: null });
    if (callback) {
      setTimeout(callback, 0);
    }
  };
  useEffect(()=>{
    if(deleteRows == true){
      handleDeleteFromListBuilder();
      store.dispatch(setTriggerDeleteSelection(false))

    }
  },[deleteRows])
  const handleMenuItemClick = (item) => {
    switch (item) {
      case "Delete Assessment(s)":
        handleDeleteFromListBuilder();
        break;
        case "Show Scheme":
          handleScheme();
          break;
          case "Show Entry on GOV.UK":
            store.dispatch(setTriggerGOVUK(true));
            break;
          case "Quick Print DAR":
            handlePrint(1)
            break;
          case "Show Valuation":
            handlePrint(2)
            break;
      default:
        break;
    }
    handleCloseContextMenu(null);
  };

  const handleGetListData = async () => {
    try {
      var query = new IntegratedSearch();
      var basic = new BasicSearch()
      basic.uarn = stringifyList(uarnList)
      query.basic = new BasicSearchApiModel(basic);
      query.report = true
      var data = await ApiHelper.GetIntegratedList(query);
      data = data?data:[];
      setResultsArray(data);
      if(data.length>0){
        setSelectedUARN(data[0].uarn)
        setRowSelectionModel([data[0].keY_FIELD!])
      }
    }
    catch (ex) {
      console.log(ex)

    }
  }
  const handleSetTableState = (state) => {
    setTableState(state);
    setCloseListBuilder(false);
  }

  const handleClose = () => {
    setCloseListBuilder(true);
  }
  useEffect(() => {
    if (tableState != null) { store.dispatch(displayListBuilder(false)); }
  }, [tableState])

  const handlePrint = async (props) => {
    var newreport
    var uarnstring = ''
    try {
      store.dispatch(setLoading(true))
      if(props !=4){
      rowSelectionModel.forEach((item,index)=>{
        uarnstring += resultsArray.find(ele=> ele.keY_FIELD==item)?.uarn
        if(index < rowSelectionModel.length -1){
          uarnstring += ',';
        }
      })}
      switch (props) {
        case 1:
          var query1 = new DARReportModel({ list: summaryListYear, uarn: uarnstring,highlightLive: store.getState().search.DARLiveHighlight });
          newreport = await ApiHelper.GetDARReport(query1);
          break;
        case 2:
          var query5 = new ReportModel({ list: summaryListYear, uarn: stringifyList(uarnSelectionModel) });
          newreport = await ApiHelper.GetSMVReport(query5);

          break;
          case 5:
            var query2 = new SummaryValuationSearch();
            var basic = new BasicSearch();
            query2.BasicSearch = new BasicSearchApiModel(basic);
            query2.BasicSearch.uarn = uarnstring;
            query2.BasicSearch.region=undefined;
            query2.ListYear = summaryListYear;
            newreport = await ApiHelper.GetValuationListReportSelected(query2);
            break;
        case 3:
          var query3 = new IntegratedSearch();
          var basic = new BasicSearch();
          query3.basic = new BasicSearchApiModel(basic);
          query3.basic.uarn = uarnstring;
          query3.basic.region=undefined;
          newreport = await ApiHelper.GetIntegratedListReportSelected(query3);
          break
        case 4:
          var query3 = new IntegratedSearch();
          var basic = new BasicSearch();
          query3.basic = new BasicSearchApiModel(basic);
          query3.basic.uarn = stringifyList(uarnList);
          query3.basic.region=undefined;
          newreport = await ApiHelper.GetIntegratedListReport(query3);
          break
      }
    }
    catch (e) {
      console.error(e)
    }
    finally{
      store.dispatch(setLoading(false))
    }
    if(newreport=='false'){
      setReport(undefined)
      setShowNoValuation(true)
    }
    else{
      setReport(newreport)

    }
  }
  const handleExportExcel = async (options) => {
    var excelData
    store.dispatch(setLoading(true))
    try {
      switch (options) {
        case 1:
          var query = new IntegratedSearch();
          var basic = new BasicSearch();
          query.basic = new BasicSearchApiModel(basic);
          query.basic.uarn = stringifyList(uarnSelectionModel);
          query.basic.region = undefined;
          query.report = true;
          excelData = await ApiHelper.GetIntegratedListExcel(query);
          break;
        case 2:
          var query3 = new IntegratedSearch();
          var basic = new BasicSearch();
          query3.basic = new BasicSearchApiModel(basic);
          query3.basic.uarn = stringifyList(uarnList);
          query3.basic.region=undefined;
          excelData = await ApiHelper.GetIntegratedListExcel(query3);
          break
      }
    }
    catch (err) {
      console.error(err)
    }
    finally {
      if (excelData.length) {
        excelData.forEach(item => {
        if(item.flaG_2023){
          item.effectivE_DATE_2023 = item.effectivE_DATE_2023? formatDateToString(item.effectivE_DATE_2023):null;
          item.lisT_ALTERATION_DATE_2023 =item.lisT_ALTERATION_DATE_2023?  formatDateToString(item.lisT_ALTERATION_DATE_2023):'COMPILED';
          item.rateablE_VALUE_2023 =item.rateablE_VALUE_2023?  item.rateablE_VALUE_2023:'DELETED';
          item.settlemenT_TYPE_2023 =item.settlemenT_TYPE_2023?  FormatSettlementCode(2023,item.settlemenT_TYPE_2023):null;
        }
        else{
          item.rateablE_VALUE_2023='<E>';
          item.effectivE_DATE_2023='<E>';
          item.lisT_ALTERATION_DATE_2023='<E>';
          item.compositE_2023='<E>';
          item.settlemenT_TYPE_2023='<E>';
          item.compileD_RATEABLE_VALUE_2023='<E>';
          item.lisT_PERCENTAGE_2023='<E>';
          item.percentagE_BETWEEN_2023_2017='<E>';
          item.drafT_2023_RATEABLE_VALUE='<E>';
        }
        if(item.flaG_2017){
          item.effectivE_DATE_2017 = item.effectivE_DATE_2017? formatDateToString(item.effectivE_DATE_2017):null;
          item.lisT_ALTERATION_DATE_2017 = item.lisT_ALTERATION_DATE_2017? formatDateToString(item.lisT_ALTERATION_DATE_2017):'COMPILED'; 
          item.rateablE_VALUE_2017 =item.rateablE_VALUE_2017?  item.rateablE_VALUE_2017:'DELETED';
          item.settlemenT_TYPE_2017 =item.settlemenT_TYPE_2017?  FormatSettlementCode(2017,item.settlemenT_TYPE_2017):null;
        }
        else{
          item.rateablE_VALUE_2017='<E>';
          item.effectivE_DATE_2017='<E>';
          item.lisT_ALTERATION_DATE_2017='<E>';
          item.compositE_2017='<E>';
          item.settlemenT_TYPE_2017='<E>';
          item.compileD_RATEABLE_VALUE_2017='<E>';
          item.lisT_PERCENTAGE_2017='<E>';
          item.percentagE_BETWEEN_2017_2010='<E>';
          item.drafT_2017_RATEABLE_VALUE='<E>';
        }
        if(item.flaG_2010){
          item.effectivE_DATE_2010 = item.effectivE_DATE_2010? formatDateToString(item.effectivE_DATE_2010):null;
          item.lisT_ALTERATION_DATE_2010 = item.lisT_ALTERATION_DATE_2010? formatDateToString(item.lisT_ALTERATION_DATE_2010):'COMPILED';
          item.rateablE_VALUE_2010 =item.rateablE_VALUE_2010? item.rateablE_VALUE_2010:'DELETED';
          item.settlemenT_TYPE_2010 =item.settlemenT_TYPE_2010?  FormatSettlementCode(2010,item.settlemenT_TYPE_2010):null;
        }
        else{
          item.rateablE_VALUE_2010='<E>';
          item.effectivE_DATE_2010='<E>';
          item.lisT_ALTERATION_DATE_2010='<E>';
          item.compositE_2010='<E>';
          item.settlemenT_TYPE_2010='<E>';
          item.compileD_RATEABLE_VALUE_2010='<E>';
          item.lisT_PERCENTAGE_2010='<E>';
          item.percentagE_BETWEEN_2010_2005='<E>';
          item.drafT_2010_RATEABLE_VALUE='<E>';
        }
        if(item.flaG_2005){
          item.effectivE_DATE_2005 = item.effectivE_DATE_2005? formatDateToString(item.effectivE_DATE_2005):null;
          item.lisT_ALTERATION_DATE_2005 = item.lisT_ALTERATION_DATE_2005? formatDateToString(item.lisT_ALTERATION_DATE_2005):'COMPILED';
          item.rateablE_VALUE_2005 =item.rateablE_VALUE_2005?  item.rateablE_VALUE_2005:'DELETED';
          item.settlemenT_TYPE_2005 =item.settlemenT_TYPE_2005?  FormatSettlementCode(2005,item.settlemenT_TYPE_2005):null;
         }
        else{
          item.rateablE_VALUE_2005='<E>';
          item.effectivE_DATE_2005='<E>';
          item.lisT_ALTERATION_DATE_2005='<E>';
          item.compositE_2005='<E>';
          item.settlemenT_TYPE_2005='<E>';
          item.compileD_RATEABLE_VALUE_2005='<E>';
          item.lisT_PERCENTAGE_2005='<E>';
          item.percentagE_BETWEEN_2005_2000='<E>';
        }
        if(item.flaG_2000){
          item.effectivE_DATE_2000 = item.effectivE_DATE_2000? formatDateToString(item.effectivE_DATE_2000):null;
          item.lisT_ALTERATION_DATE_2000 = item.lisT_ALTERATION_DATE_2000? formatDateToString(item.lisT_ALTERATION_DATE_2000):'COMPILED';
          item.rateablE_VALUE_2000 =item.rateablE_VALUE_2000?  item.rateablE_VALUE_2000:'DELETED';
          item.settlemenT_TYPE_2000 =item.settlemenT_TYPE_2000?  FormatSettlementCode(2000,item.settlemenT_TYPE_2000):null;
        }
        else{
          item.rateablE_VALUE_2000='<E>';
          item.effectivE_DATE_2000='<E>';
          item.lisT_ALTERATION_DATE_2000='<E>';
          item.compositE_2000='<E>';
          item.settlemenT_TYPE_2000='<E>';
          item.compileD_RATEABLE_VALUE_2000='<E>';
          item.lisT_PERCENTAGE_2000='<E>';
          item.percentagE_BETWEEN_2000_1995='<E>';
        }
        if(item.flaG_1995){
          item.effectivE_DATE_1995 = item.effectivE_DATE_1995? formatDateToString(item.effectivE_DATE_1995):null;
          item.lisT_ALTERATION_DATE_1995 = item.lisT_ALTERATION_DATE_1995? formatDateToString(item.lisT_ALTERATION_DATE_1995):'COMPILED';
          item.rateablE_VALUE_1995 =item.rateablE_VALUE_1995?  item.rateablE_VALUE_1995:'DELETED';
          item.settlemenT_TYPE_1995 =item.settlemenT_TYPE_1995?  FormatSettlementCode(1995,item.settlemenT_TYPE_1995):null;
        }
        else{
          item.rateablE_VALUE_1995='<E>';
          item.effectivE_DATE_1995='<E>';
          item.lisT_ALTERATION_DATE_1995='<E>';
          item.compositE_1995='<E>';
          item.settlemenT_TYPE_1995='<E>';
          item.compileD_RATEABLE_VALUE_1995='<E>';
          item.lisT_PERCENTAGE_1995='<E>';
          item.percentagE_BETWEEN_1995_1990='<E>';
        }
        if(item.flaG_1990){
          item.effectivE_DATE_1990 = item.effectivE_DATE_1990? formatDateToString(item.effectivE_DATE_1990):null;
          item.lisT_ALTERATION_DATE_1990 = item.lisT_ALTERATION_DATE_1990? formatDateToString(item.lisT_ALTERATION_DATE_1990):'COMPILED';
          item.rateablE_VALUE_1990 =item.rateablE_VALUE_1990? item.rateablE_VALUE_1990:'DELETED';
          item.settlemenT_TYPE_1990 =item.settlemenT_TYPE_1990?  FormatSettlementCode(1990,item.settlemenT_TYPE_1990):null;
        }
        else{
          item.rateablE_VALUE_1990='<E>';
          item.effectivE_DATE_1990='<E>';
          item.lisT_ALTERATION_DATE_1990='<E>';
          item.compositE_1990='<E>';
          item.settlemenT_TYPE_1990='<E>';
          item.compileD_RATEABLE_VALUE_1990='<E>';
          item.lisT_PERCENTAGE_1990='<E>';
        }
      });
        dataToFile(excelData, ExcelGroupingModel, ExcelColumnModel)
      }
    }
  }
  useEffect(() => {
    if (triggerExport > 0) {
      handleExportExcel(triggerExport)
      store.dispatch(setTriggerExportExcel(0))
    }
  }, [triggerExport])

  const ExcelGroupingModel = [
    {
      groupId: 'STATUS/ADDRESS',
      children: ['flaG_2023', 'flaG_2017', 'flaG_2010', 'flaG_2005', 'flaG_2000', 'flaG_1995', 'flaG_1990', 'firm_name', 'address', 'postcode']
    },
    {
      groupId: 'HEREDITAMENT INFORMATION',
      children: ['bA_REFERENCE_NUMBER', 'assessmenT_REFERENCE_2023', 'description', 'psD_CODE', 'scat']
    },
    {
      groupId: '2023 LIST',
      children: ['rateablE_VALUE_2023', 'effectivE_DATE_2023', 'lisT_ALTERATION_DATE_2023', 'compositE_2023', 'settlemenT_TYPE_2023', 'compileD_RATEABLE_VALUE_2023', 'lisT_PERCENTAGE_2023', 'percentagE_BETWEEN_2023_2017', 'drafT_2023_RATEABLE_VALUE']
    },
    {
      groupId: '2017 LIST',
      children: ['rateablE_VALUE_2017', 'effectivE_DATE_2017', 'lisT_ALTERATION_DATE_2017', 'compositE_2017', 'settlemenT_TYPE_2017', 'compileD_RATEABLE_VALUE_2017', 'lisT_PERCENTAGE_2017', 'percentagE_BETWEEN_2017_2010', 'drafT_2017_RATEABLE_VALUE']
    },
    {
      groupId: '2010 LIST',
      children: ['rateablE_VALUE_2010', 'effectivE_DATE_2010', 'lisT_ALTERATION_DATE_2010', 'compositE_2010', 'settlemenT_TYPE_2010', 'compileD_RATEABLE_VALUE_2010', 'lisT_PERCENTAGE_2010', 'percentagE_BETWEEN_2010_2005', 'drafT_2010_RATEABLE_VALUE']
    },
    {
      groupId: '2005 LIST',
      children: ['rateablE_VALUE_2005', 'effectivE_DATE_2005', 'lisT_ALTERATION_DATE_2005', 'compositE_2005', 'settlemenT_TYPE_2005', 'compileD_RATEABLE_VALUE_2005', 'lisT_PERCENTAGE_2005', 'percentagE_BETWEEN_2005_2000']
    },
    {
      groupId: '2000 LIST',
      children: ['rateablE_VALUE_2000', 'effectivE_DATE_2000', 'lisT_ALTERATION_DATE_2000', 'compositE_2000', 'settlemenT_TYPE_2000', 'compileD_RATEABLE_VALUE_2000', 'lisT_PERCENTAGE_2000', 'percentagE_BETWEEN_2000_1995']
    },
    {
      groupId: '1995 LIST',
      children: ['rateablE_VALUE_1995', 'effectivE_DATE_1995', 'lisT_ALTERATION_DATE_1995', 'compositE_1995', 'settlemenT_TYPE_1995', 'compileD_RATEABLE_VALUE_1995', 'lisT_PERCENTAGE_1995', 'percentagE_BETWEEN_1995_1990']
    },
    {
      groupId: '1990 LIST',
      children: ['rateablE_VALUE_1990', 'effectivE_DATE_1990', 'lisT_ALTERATION_DATE_1990', 'compositE_1990', 'settlemenT_TYPE_1990', 'compileD_RATEABLE_VALUE_1990', 'lisT_PERCENTAGE_1990']
    }
  ]

  const ExcelColumnModel = [
    { key: 'flaG_2023', header: '23', width: 3 },
    { key: 'flaG_2017', header: '17', width: 3 },
    { key: 'flaG_2010', header: '10', width: 3 },
    { key: 'flaG_2005', header: '05', width: 3 },
    { key: 'flaG_2000', header: '00', width: 3 },
    { key: 'flaG_1995', header: '95', width: 3 },
    { key: 'flaG_1990', header: '90', width: 3 },
    { key: 'firm_name', header: 'FIRM NAME', width: 12 },
    { key: 'address', header: 'ADDRESS', width: 46 },
    { key: 'postcode', header: 'POSTCODE', width: 12 },
    { key: 'bA_REFERENCE_NUMBER', header: 'BA REFERENCE', width: 14 },
    { key: 'assessmenT_REFERENCE_2023', header: 'ASSESSMENT REF (LIVE 2023)', width: 14 },
    { key: 'description', header: 'DESCRIPTION', width: 27 },
    { key: 'psD_CODE', header: 'PSD', width: 6 },
    { key: 'scat', header: 'SCAT', width: 6 },
    { key: 'rateablE_VALUE_2023', header: 'RATEABLE VALUE 2023', width: 14, style: { numFmt: '#,##0' } },
    { key: 'effectivE_DATE_2023', header: 'EFFECTIVE DATE 2023', width: 14 },
    { key: 'lisT_ALTERATION_DATE_2023', header: 'LIST ALTERATION DATE 2023', width: 14},
    { key: 'compositE_2023', header: 'COMPOSITE 2023', width: 14 },//
    { key: 'settlemenT_TYPE_2023', header: 'SETTLEMENT TYPE 2023', width: 14 },
    { key: 'compileD_RATEABLE_VALUE_2023', header: 'COMPILED RATEABLE VALUE 2023', width: 18, style: { numFmt: '#,##0' } },
    { key: 'lisT_PERCENTAGE_2023', header: 'LIST PERCENTAGE 2023', width: 14 },
    { key: 'percentagE_BETWEEN_2023_2017', header: 'PERCENTAGE BETWEEN 2023 AND 2017', width: 18 },
    { key: 'drafT_2023_RATEABLE_VALUE', header: 'DRAFT 2023 RATEABLE VALUE', width: 14, style: { numFmt: '#,##0' } },
    { key: 'rateablE_VALUE_2017', header: 'RATEABLE VALUE 2017', width: 14, style: { numFmt: '#,##0' } },
    { key: 'effectivE_DATE_2017', header: 'EFFECTIVE DATE 2017', width: 14 },
    { key: 'lisT_ALTERATION_DATE_2017', header: 'LIST ALTERATION DATE 2017', width: 14 },
    { key: 'compositE_2017', header: 'COMPOSITE 2017', width: 14 },
    { key: 'settlemenT_TYPE_2017', header: 'SETTLEMENT TYPE 2017', width: 14 },
    { key: 'compileD_RATEABLE_VALUE_2017', header: 'COMPILED RATEABLE VALUE 2017', width: 18 , style: { numFmt: '#,##0' }},
    { key: 'lisT_PERCENTAGE_2017', header: 'LIST  PERCENTAGE 2017', width: 14 },
    { key: 'percentagE_BETWEEN_2017_2010',header:  'PERCENTAGE BETWEEN 2017 AND 2010',width: 18,}, 
    { key: 'drafT_2017_RATEABLE_VALUE',header: 'DRAFT 2017 RATEABLE VALUE',width: 14, style: { numFmt: '#,##0' }},
    { key: 'rateablE_VALUE_2010', header:'RATEABLE VALUE 2010', width: 14 , style: { numFmt: '#,##0' }},
    { key: 'effectivE_DATE_2010', header:'EFFECTIVE DATE 2010', width: 14 },
    { key: 'lisT_ALTERATION_DATE_2010', header:'LIST ALTERATION DATE 2010', width: 14 },
    { key: 'compositE_2010', header:'COMPOSITE 2010', width: 14 },
    { key: 'settlemenT_TYPE_2010', header:'SETTLEMENT TYPE 2010', width: 14 },
    { key: 'compileD_RATEABLE_VALUE_2010', header:'COMPILED RATEABLE VALUE 2010', width: 18 , style: { numFmt: '#,##0' }},
    { key: 'lisT_PERCENTAGE_2010', header:'LIST PERCENTAGE 2010', width: 14 },
    { key: 'percentagE_BETWEEN_2010_2005',header:  'PERCENTAGE BETWEEN 2010 AND 2005',width: 18,},
    { key: 'drafT_2010_RATEABLE_VALUE',header: 'DRAFT 2010 RATEABLE VALUE',width: 14, style: { numFmt: '#,##0' }},
    { key: 'rateablE_VALUE_2005', header:'RATEABLE VALUE 2005', width: 14 , style: { numFmt: '#,##0' }},
    { key: 'effectivE_DATE_2005', header:'EFFECTIVE DATE 2005', width: 14 },
    { key: 'lisT_ALTERATION_DATE_2005', header:'LIST ALTERATION DATE 2005', width: 14 },
    { key: 'compositE_2005', header:'COMPOSITE 2005', width: 14 },
    { key: 'settlemenT_TYPE_2005', header:'SETTLEMENT TYPE 2005', width: 14 },
    { key: 'compileD_RATEABLE_VALUE_2005', header:'COMPILED RATEABLE VALUE 2005', width: 18 , style: { numFmt: '#,##0' }},
    { key: 'lisT_PERCENTAGE_2005', header:'LIST PERCENTAGE 2005', width: 14 },
    { key: 'percentagE_BETWEEN_2005_2000',header:  'PERCENTAGE BETWEEN 2005 AND 2000',width: 18,},
    { key: 'rateablE_VALUE_2000', header:'RATEABLE VALUE 2000', width: 14 , style: { numFmt: '#,##0' }},
    { key: 'effectivE_DATE_2000', header:'EFFECTIVE DATE 2000', width: 14},
    { key: 'lisT_ALTERATION_DATE_2000', header:'LIST ALTERATION DATE 2000', width: 14 },
    { key: 'compositE_2000', header:'COMPOSITE 2000', width: 14 },
    { key: 'settlemenT_TYPE_2000', header:'SETTLEMENT TYPE 2000', width: 14 },
    { key: 'compileD_RATEABLE_VALUE_2000', header:'COMPILED RATEABLE VALUE 2000', width: 18, style: { numFmt: '#,##0' } },
    { key: 'lisT_PERCENTAGE_2000', header:'LIST PERCENTAGE 2000', width: 14 },
    { key: 'percentagE_BETWEEN_2000_1995',header:  'PERCENTAGE BETWEEN 2000 AND 1995',width: 18,},
    { key: 'rateablE_VALUE_1995', header:'RATEABLE VALUE 1995', width: 14 , style: { numFmt: '#,##0' }},
    { key: 'effectivE_DATE_1995', header:'EFFECTIVE DATE 1995', width: 14 },
    { key: 'lisT_ALTERATION_DATE_1995', header:'LIST ALTERATION DATE 1995', width: 14 },
    { key: 'compositE_1995', header:'COMPOSITE 1995', width: 14 },
    { key: 'settlemenT_TYPE_1995', header:'SETTLEMENT TYPE 1995', width: 14 },
    { key: 'compileD_RATEABLE_VALUE_1995', header:'COMPILED RATEABLE VALUE 1995', width: 18, style: { numFmt: '#,##0' } },
    { key: 'lisT_PERCENTAGE_1995', header:'LIST PERCENTAGE 1995', width: 14 },
    { key: 'percentagE_BETWEEN_1995_1990', header:'PERCENTAGE BETWEEN 1995 AND 1990', width: 18 },
    { key: 'rateablE_VALUE_1990', header:'RATEABLE VALUE 1990', width: 14, style: { numFmt: '#,##0' } },
    { key: 'effectivE_DATE_1990', header:'EFFECTIVE DATE 1990', width: 14 },
    { key: 'lisT_ALTERATION_DATE_1990', header:'LIST ALTERATION DATE 1990', width: 14 },
    { key: 'compositE_1990', header:'COMPOSITE 1990', width: 14 },
    { key: 'settlemenT_TYPE_1990', header:'SETTLEMENT TYPE 1990', width: 14 },
    { key: 'compileD_RATEABLE_VALUE_1990', header:'COMPILED RATEABLE VALUE 1990', width: 18, style: { numFmt: '#,##0' } },
    { key: 'lisT_PERCENTAGE_1990', header:'LIST PERCENTAGE 1990', width: 14 },
  ]
  const columns: GridColDef[] = [
    // {
    //   field: 'keY_FIELD',
    //   headerName: '',
    //   width: 10,
    //   minWidth: 10,
    //   disableColumnMenu: true,
    //   disableReorder: true,
    //   resizable: false,
    //   sortable: false,
    //   headerAlign: 'center',
    //   renderCell: (params: any) => (
    //     <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
    //       <>
    //         {params && rowSelectionModel.includes(params.row.keY_FIELD) &&
    //           <div className='rowindicator'>
    //           </div>
    //         }
    //       </>
    //     </div>
    //   ),
    // },
    {
      field: 'flaG_2023',
      headerName: '23',
      width: columnWidths['flaG_2023'] || 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {(params.row.rateablE_VALUE_2023 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_2023 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_2023 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2023 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_2023 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_2017',
      headerName: '17',
      width: columnWidths['flaG_2017'] || 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {(params.row.rateablE_VALUE_2017 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_2017 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_2017 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2017 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_2017 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_2010',
      headerName: '10',
      width: columnWidths['flaG_2010'] || 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {(params.row.rateablE_VALUE_2010 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_2010 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_2010 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2010 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_2010 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_2005',
      headerName: '05',
      width: columnWidths['flaG_2005'] || 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {(params.row.rateablE_VALUE_2005 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_2005 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_2005 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2005 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_2005 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_2000',
      headerName: '00',
      width: columnWidths['flaG_2000'] || 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {(params.row.rateablE_VALUE_2000 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_2000 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_2000 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2000 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_2000 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_1995',
      headerName: '95',
      width: columnWidths['flaG_1995'] || 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {(params.row.rateablE_VALUE_1995 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_1995 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_1995 == null && params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1995 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_1995 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'flaG_1990',
      headerName: '90',
      width: columnWidths['flaG_1990'] || 20,
      minWidth: 20,
      disableColumnMenu: true,
      headerAlign: 'center',
      headerClassName:'flagheader',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {(params.row.rateablE_VALUE_1990 != null && params.value != null) && <FlagComponent value={params.value} />}
          {(params.row.rateablE_VALUE_1990 == null && params.value != null) && <FlagComponent value={'N'} />}
          {(params.row.rateablE_VALUE_1990 == null && !params.value == null) && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1990 != null && value != null){return 'Y';} 
        if(row.rateablE_VALUE_1990 == null && value != null){return 'N';} 
        return '\u200B';
      }
    },
    {
      field: 'firm_name', headerName: 'FIRM NAME', width: columnWidths['firm_name'] || 78, minWidth: 78, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'address', headerName: 'ADDRESS', width: columnWidths['address'] || 458, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'postcode', headerName: 'POSTCODE', width: columnWidths['postcode'] || 80, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'bA_REFERENCE_NUMBER', headerName: 'BA REFERENCE', width: columnWidths['bA_REFERENCE_NUMBER'] || 104, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'assessmenT_REFERENCE_2023', headerName: 'ASSESSMENT REF (LIVE 2023)', width: columnWidths['assessmenT_REFERENCE_2023'] || 88, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && params.value != undefined && Number(params.value) != 0 && params.value != '0' ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'description', headerName: 'DESCRIPTION', width: columnWidths['description'] || 175, headerAlign: 'left',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'psD_CODE', headerName: 'PSD', width: columnWidths['psD_CODE'] || 34, minWidth: 34, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'scat', headerName: 'SCAT', width: columnWidths['scat'] || 40, minWidth: 40, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'rateablE_VALUE_2023', headerName: 'RATEABLE VALUE 2023', width: columnWidths['rateablE_VALUE_2023'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2023 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_2023 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_2023 == null && <span>&nbsp;</span>}
        </div>
        
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_2023 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_2023', headerName: 'EFFECTIVE DATE 2023', width: columnWidths['effectivE_DATE_2023'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row) => {
        if(row.rateablE_VALUE_2023 == null && row.flaG_2023 == null)  {return 'IN 2023';}
        return formatDateToString(value)
      },
    },
    {
      field: 'lisT_ALTERATION_DATE_2023', headerName: 'LIST ALTERATION DATE 2023', width: columnWidths['lisT_ALTERATION_DATE_2023'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_2023 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_2023 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row) => {
        if(row.rateablE_VALUE_2023  == null && row.flaG_2023 == null)  {return 'LIST';}
        return formatDateToString(value)
      },
    },
    {
      field: 'compositE_2023', headerName: 'COMPOSITE 2023', width: columnWidths['compositE_2023'] || 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}

        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_2023', headerName: 'SETTLEMENT TYPE 2023', width: columnWidths['settlemenT_TYPE_2023'] || 150, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(2023, params.value)}</span> : <span>&nbsp;</span>}

        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(row.lisT_YEAR,value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_2023', headerName: 'COMPILED RATEABLE VALUE 2023', width: columnWidths['compileD_RATEABLE_VALUE_2023'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_2023', headerName: 'LIST PERCENTAGE 2023', width: columnWidths['lisT_PERCENTAGE_2023'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_2023_2017', headerName: 'PERCENTAGE BETWEEN 2023 AND 2017', width: columnWidths['percentagE_BETWEEN_2023_2017'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2023 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'drafT_2023_RATEABLE_VALUE', headerName: 'DRAFT 2023 RATEABLE VALUE', width: columnWidths['drafT_2023_RATEABLE_VALUE'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div className={params.row.flaG_2023 == null ? 'emptycell right' : 'bluecell'} onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'rateablE_VALUE_2017', headerName: 'RATEABLE VALUE 2017', width: columnWidths['rateablE_VALUE_2017'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2017 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_2017 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_2017 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_2017 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_2017', headerName: 'EFFECTIVE DATE 2017', width: columnWidths['effectivE_DATE_2017'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div className={params.row.flaG_2017 == null ? 'emptycell' : 'bluecell'} onContextMenu={(event) => handleContextMenu(event, params.row)}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2017 == null && row.flaG_2017 == null)  {return 'IN 2017';}
        return formatDateToString(value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_2017', headerName: 'LIST ALTERATION DATE 2017', width: columnWidths['lisT_ALTERATION_DATE_2017'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_2017 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_2017 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2017 == null && row.flaG_2017 == null)  {return 'LIST';}
        return formatDateToString(value)
      }
    },
    {
      field: 'compositE_2017', headerName: 'COMPOSITE 2017', width: columnWidths['compositE_2017'] || 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      )
    },
    {
      field: 'settlemenT_TYPE_2017', headerName: 'SETTLEMENT TYPE 2017', width: columnWidths['settlemenT_TYPE_2017'] || 150, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(2017, params.value)}</span> : <span>&nbsp;</span>}

        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(2017, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_2017', headerName: 'COMPILED RATEABLE VALUE 2017', width: columnWidths['compileD_RATEABLE_VALUE_2017'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_2017', headerName: 'LIST PERCENTAGE 2017', width: columnWidths['lisT_PERCENTAGE_2017'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_2017_2010', headerName: 'PERCENTAGE BETWEEN 2017 AND 2010', width: columnWidths['percentagE_BETWEEN_2017_2010'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2017 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'drafT_2017_RATEABLE_VALUE', headerName: 'DRAFT 2017 RATEABLE VALUE', width: columnWidths['drafT_2017_RATEABLE_VALUE'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2017 == null ? 'emptycell right' : 'bluecell'}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'rateablE_VALUE_2010', headerName: 'RATEABLE VALUE 2010', width: columnWidths['rateablE_VALUE_2010'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_2010 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_2010 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_2010 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_2010', headerName: 'EFFECTIVE DATE 2010', width: columnWidths['effectivE_DATE_2010'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell' : 'bluecell'}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2010 == null && row.flaG_2010 == null)  {return 'IN 2010';}
        return formatDateToString(value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_2010', headerName: 'LIST ALTERATION DATE 2010', width: columnWidths['lisT_ALTERATION_DATE_2010'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_2010 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_2010 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2010 == null && row.flaG_2010 == null)  {return 'LIST';}
        return formatDateToString(value)
      }
    },
    {
      field: 'compositE_2010', headerName: 'COMPOSITE 2010', width: columnWidths['compositE_2010'] || 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_2010', headerName: 'SETTLEMENT TYPE 2010', width: columnWidths['settlemenT_TYPE_2010'] || 20, minWidth: 20, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(2010, params.value)}</span> : <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(2010, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_2010', headerName: 'COMPILED RATEABLE VALUE 2010', width: columnWidths['compileD_RATEABLE_VALUE_2010'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? FormatNumberWithCommas(params.value) : <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_2010', headerName: 'LIST PERCENTAGE 2010', width: columnWidths['lisT_PERCENTAGE_2010'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_2010_2005', headerName: 'PERCENTAGE BETWEEN 2010 AND 2005', width: columnWidths['percentagE_BETWEEN_2010_2005'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'drafT_2010_RATEABLE_VALUE', headerName: 'DRAFT 2010 RATEABLE VALUE', width: columnWidths['drafT_2010_RATEABLE_VALUE'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2010 == null ? 'emptycell right' : 'bluecell'}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'rateablE_VALUE_2005', headerName: 'RATEABLE VALUE 2005', width: columnWidths['rateablE_VALUE_2005'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2005 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_2005 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_2005 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_2005 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_2005', headerName: 'EFFECTIVE DATE 2005', width: columnWidths['effectivE_DATE_2005'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2005 == null ? 'emptycell' : 'bluecell'}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2005 == null && row.flaG_2005 == null)  {return 'IN 2005';}
        return formatDateToString(value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_2005', headerName: 'LIST ALTERATION DATE 2005', width: columnWidths['lisT_ALTERATION_DATE_2005'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2005 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_2005 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_2005 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_2005 == null && row.flaG_2005 == null)  {return 'LIST';}
        return formatDateToString(value)
      }
    },
    {
      field: 'compositE_2005', headerName: 'COMPOSITE 2005', width: columnWidths['compositE_2005'] || 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2005 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_2005', headerName: 'SETTLEMENT TYPE 2005', width: columnWidths['settlemenT_TYPE_2005'] || 20, minWidth: 20, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2005 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(2005, params.value)}</span> : <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(2005, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_2005', headerName: 'COMPILED RATEABLE VALUE 2005', width: columnWidths['compileD_RATEABLE_VALUE_2005'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2005 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas( value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_2005', headerName: 'LIST PERCENTAGE 2005', width: columnWidths['lisT_PERCENTAGE_2005'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2005 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_2005_2000', headerName: 'PERCENTAGE BETWEEN 2005 AND 2000', width: columnWidths['percentagE_BETWEEN_2005_2000'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2005 == null ? 'emptycell right' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'rateablE_VALUE_2000', headerName: 'RATEABLE VALUE 2000', width: columnWidths['rateablE_VALUE_2000'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2000 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_2000 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_2000 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_2000 != null)  {return 'DELETED';}
        return '\u200B';
      }
    },
    {
      field: 'effectivE_DATE_2000', headerName: 'EFFECTIVE DATE 2000', width: columnWidths['effectivE_DATE_2000'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2000 == null ? 'emptycell' : 'bluecell'}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return formatDateToString( value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_2000', headerName: 'LIST ALTERATION DATE 2000', width: columnWidths['lisT_ALTERATION_DATE_2000'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2000 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_2000 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_2000 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return formatDateToString( value)
      }
    },
    {
      field: 'compositE_2000', headerName: 'COMPOSITE 2000', width: columnWidths['compositE_2000'] || 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2000 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_2000', headerName: 'SETTLEMENT TYPE 2000', width: columnWidths['settlemenT_TYPE_2000'] || 20, minWidth: 20, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2000 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(2000, params.value)}</span> : <span>&nbsp;</span>}

        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(2000, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_2000', headerName: 'COMPILED RATEABLE VALUE 2000', width: columnWidths['compileD_RATEABLE_VALUE_2000'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2000 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas( value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_2000', headerName: 'LIST PERCENTAGE 2000', width: columnWidths['lisT_PERCENTAGE_2000'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2000 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_2000_1995', headerName: 'PERCENTAGE BETWEEN 2000 AND 1995', width: columnWidths['percentagE_BETWEEN_2000_1995'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_2000 == null ? 'emptycell right' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'rateablE_VALUE_1995', headerName: 'RATEABLE VALUE 1995', width: columnWidths['rateablE_VALUE_1995'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1995 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_1995 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_1995 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_1995 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_1995', headerName: 'EFFECTIVE DATE 1995', width: columnWidths['effectivE_DATE_1995'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1995 == null ? 'emptycell' : 'bluecell'}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1995 == null && row.flaG_1995 == null)  {return 'IN 1995';}
        return formatDateToString( value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_1995', headerName: 'LIST ALTERATION DATE 1995', width: columnWidths['lisT_ALTERATION_DATE_1995'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1995 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_1995 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_1995 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1995 == null && row.flaG_1995 == null)  {return 'LIST';}
        return formatDateToString( value)
      }
    },
    {
      field: 'compositE_1995', headerName: 'COMPOSITE 1995', width: columnWidths['compositE_1995'] || 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1995 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_1995', headerName: 'SETTLEMENT TYPE 1995', width: columnWidths['settlemenT_TYPE_1995'] || 20, minWidth: 20, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1995 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(1995, params.value)}</span> : <span>&nbsp;</span>}

        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(1995, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_1995', headerName: 'COMPILED RATEABLE VALUE 1995', width: columnWidths['compileD_RATEABLE_VALUE_1995'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1995 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_1995', headerName: 'LIST PERCENTAGE 1995', width: columnWidths['lisT_PERCENTAGE_1995'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1995 == null ? 'emptycell' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'percentagE_BETWEEN_1995_1990', headerName: 'PERCENTAGE BETWEEN 1995 AND 1990', width: columnWidths['percentagE_BETWEEN_1995_1990'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1995 == null ? 'emptycell right' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
    {
      field: 'rateablE_VALUE_1990', headerName: 'RATEABLE VALUE 1990', width: columnWidths['rateablE_VALUE_1990'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1990 == null ? 'emptycell left' : ''}>
          {params.value != null && FormatNumberWithCommas(params.value)}
          {params.value == null && params.row.flaG_1990 != null && <span>DELETED</span>}
          {params.value == null && params.row.flaG_1990 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(value != null){return FormatNumberWithCommas(value);}
        if(value == null && row.flaG_1990 != null)  {return 'DELETED';}
        return 'NOT';
      }
    },
    {
      field: 'effectivE_DATE_1990', headerName: 'EFFECTIVE DATE 1990', width: columnWidths['effectivE_DATE_1990'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1990 == null ? 'emptycell' : 'bluecell'}>
          {formatDateToString(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1990 == null && row.flaG_1990 == null)  {return 'IN 1990';}
        return formatDateToString(value)
      }
    },
    {
      field: 'lisT_ALTERATION_DATE_1990', headerName: 'LIST ALTERATION DATE 1990', width: columnWidths['lisT_ALTERATION_DATE_1990'] || 100, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1990 == null ? 'emptycell' : ''}>
          {params.value != null && formatDateToString(params.value)}
          {params.value == null && params.row.flaG_1990 != null && <span>COMPILED</span>}
          {params.value == null && params.row.flaG_1990 == null && <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        if(row.rateablE_VALUE_1990== null && row.flaG_1990 == null)  {return 'LIST';}
        return formatDateToString(value)
      }
    },
    {
      field: 'compositE_1990', headerName: 'COMPOSITE 1990', width: columnWidths['compositE_1990'] || 30, minWidth: 30, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1990 == null ? 'emptycell' : ''}>
          {params.value != 'C' && <span>&nbsp;</span>}
          {params.value == 'C' && <HomeIcon sx={{ height: 18, width: 18 }} color='secondary' />}
        </div>
      ),
    },
    {
      field: 'settlemenT_TYPE_1990', headerName: 'SETTLEMENT TYPE 1990', width: columnWidths['settlemenT_TYPE_1990'] || 20, minWidth: 20, headerAlign: 'left', align: 'center',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1990 == null ? 'emptycell' : ''}>
          {params.value != null || params.value != undefined ? <span>{FormatSettlementCode(1990, params.value)}</span> : <span>&nbsp;</span>}

        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatSettlementCode(1990, value)
      }
    },
    {
      field: 'compileD_RATEABLE_VALUE_1990', headerName: 'COMPILED RATEABLE VALUE 1990', width: columnWidths['compileD_RATEABLE_VALUE_1990'] || 100, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1990 == null ? 'emptycell' : ''}>
          {FormatNumberWithCommas(params.value) || <span>&nbsp;</span>}
        </div>
      ),
      valueFormatter:(value,row)=>{
        return FormatNumberWithCommas(value)
      }
    },
    {
      field: 'lisT_PERCENTAGE_1990', headerName: 'LIST PERCENTAGE 1990', width: columnWidths['lisT_PERCENTAGE_1990'] || 60, headerAlign: 'left', align: 'right',
      renderCell: (params: any) => (
        <div onContextMenu={(event) => handleContextMenu(event, params.row)} className={params.row.flaG_1990 == null ? 'emptycell right' : ''}>
          {params.value != null && params.value != undefined ? params.value : <span>&nbsp;</span>}
        </div>
      ),
    },
  ];

  const columnGroupingModel: GridColumnGroup[] = [
    {
      groupId: 'STATUS/ADDRESS',
      children: [{ field: 'flaG_1990' }, { field: 'flaG_1995' }, { field: 'flaG_2000' }, { field: 'flaG_2005' }, { field: 'flaG_2010' }, { field: 'flaG_2017' }, { field: 'flaG_2023' }, { field: 'firm_name' }, { field: 'address' }, { field: 'postcode' }],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: 'HEREDITAMENT INFORMATION',
      children: [{ field: 'assessmenT_REFERENCE_2023' }, { field: 'description' }, { field: 'psD_CODE' }, { field: 'scat' }, { field: 'bA_REFERENCE_NUMBER' },]
      , headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '1990 LIST',
      children: [{ field: 'rateablE_VALUE_1990' }, { field: 'effectivE_DATE_1990' }, { field: 'lisT_ALTERATION_DATE_1990' }, { field: 'compositE_1990' }, { field: 'settlemenT_TYPE_1990' }, { field: 'compileD_RATEABLE_VALUE_1990' }, { field: 'lisT_PERCENTAGE_1990' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '1995 LIST',
      children: [{ field: 'rateablE_VALUE_1995' }, { field: 'effectivE_DATE_1995' }, { field: 'lisT_ALTERATION_DATE_1995' }, { field: 'compositE_1995' }, { field: 'settlemenT_TYPE_1995' }, { field: 'compileD_RATEABLE_VALUE_1995' }, { field: 'lisT_PERCENTAGE_1995' },{ field: 'percentagE_BETWEEN_1995_1990' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '2000 LIST',
      children: [{ field: 'rateablE_VALUE_2000' }, { field: 'effectivE_DATE_2000' }, { field: 'lisT_ALTERATION_DATE_2000' }, { field: 'compositE_2000' }, { field: 'settlemenT_TYPE_2000' }, { field: 'compileD_RATEABLE_VALUE_2000' }, { field: 'lisT_PERCENTAGE_2000' },{ field: 'percentagE_BETWEEN_2000_1995' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '2005 LIST',
      children: [{ field: 'rateablE_VALUE_2005' }, { field: 'effectivE_DATE_2005' }, { field: 'lisT_ALTERATION_DATE_2005' }, { field: 'compositE_2005' }, { field: 'settlemenT_TYPE_2005' }, { field: 'compileD_RATEABLE_VALUE_2005' }, { field: 'lisT_PERCENTAGE_2005' },{ field: 'percentagE_BETWEEN_2005_2000' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '2010 LIST',
      children: [{ field: 'rateablE_VALUE_2010' }, { field: 'effectivE_DATE_2010' }, { field: 'lisT_ALTERATION_DATE_2010' }, { field: 'compositE_2010' }, { field: 'settlemenT_TYPE_2010' }, { field: 'compileD_RATEABLE_VALUE_2010' }, { field: 'lisT_PERCENTAGE_2010' },{ field: 'percentagE_BETWEEN_2010_2005' }, { field: 'drafT_2010_RATEABLE_VALUE' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    },
    {
      groupId: '2017 LIST',
      children: [{ field: 'rateablE_VALUE_2017' }, { field: 'effectivE_DATE_2017' }, { field: 'lisT_ALTERATION_DATE_2017' }, { field: 'compositE_2017' }, { field: 'settlemenT_TYPE_2017' }, { field: 'compileD_RATEABLE_VALUE_2017' }, { field: 'lisT_PERCENTAGE_2017' }, { field: 'percentagE_BETWEEN_2017_2010' }, { field: 'drafT_2017_RATEABLE_VALUE' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    }, {
      groupId: '2023 LIST',
      children: [{ field: 'rateablE_VALUE_2023' }, { field: 'effectivE_DATE_2023' }, { field: 'lisT_ALTERATION_DATE_2023' }, { field: 'compositE_2023' }, { field: 'settlemenT_TYPE_2023' }, { field: 'compileD_RATEABLE_VALUE_2023' }, { field: 'lisT_PERCENTAGE_2023' }, { field: 'percentagE_BETWEEN_2023_2017' }, { field: 'drafT_2023_RATEABLE_VALUE' },],
      headerClassName: 'columngroupheader'
      , headerAlign: 'center',
    }
  ]
  const rows = resultsArray ? resultsArray : [];
  return (
    <>
      {showListBuilder && <>
        <div className='popoverbackground'></div>
        <div className='popover'>
          <div className='popovercontainer'>
            <div className="layoutcolumn">
              <div className="headercontextbar layoutrow">
                {Object.values(HeaderBarContext).filter(key => isNaN(Number(HeaderBarContext[key]))).map((value) => (
                  (value != 2 || listBuilderCount > 0) &&
                  <div className={headerBarContext == value ? "headercontextoption selected" : "headercontextoption"} key={value} onClick={() => (setHeaderBarContext(Number(value)))}>
                    {HeaderBarContext[value]}
                  </div>

                ))}

              </div>
              <div className='closeme listbuilder'  onClick={handleClose}>
                <CloseIcon sx={{color:'white'}}/>
              </div>
              <div className='mainheaderbar'>

                {headerBarContext == 1 && <ListBuilderBarComponent />}
                {headerBarContext == 2 && <>
                  <div className='layoutcolumn'>
                    <div className='layoutrow'>
                      <div className='navbartableelement' onClick={(e) => handlePrint(1)}>
                      <figure className="icon reporticon"></figure>
                        <div className="navbartableelementtext">{Reports[1]}</div>
                      </div>
                      <div className='navbartableelement' onClick={(e) => handlePrint(3)}>
                      <figure className="icon reporticon"></figure>
                        <div className="navbartableelementtext">{Reports[3]}</div>
                      </div>
                      <div className='navbartableelement' onClick={(e) => handlePrint(4)}>
                      <figure className="icon reporticon"></figure>
                        <div className="navbartableelementtext">{Reports[4]}</div>
                      </div>
                      <div className="vhr">
                      </div>
                      <div className='navbartableelement' onClick={(e) => handlePrint(2)}>
                      <figure className="icon reporticon"></figure>
                        <div className="navbartableelementtext">{Reports[2]}</div>
                      </div>
                      <div className='navbartableelement' onClick={(e) => handlePrint(5)}>
                      <figure className="icon reporticon"></figure>
                        <div className="navbartableelementtext">{Reports[5]}</div>
                      </div>
                    </div>
                    <div className="navbartableselectiondescription">
                      <div>Report</div>
                    </div>
                  </div>
                  <div className="vhr">
                  </div>
                  <div className="layoutcolumn">
                    <div className="navbaryearselection">
                      <div className={`navbaryearelement ${summaryListYear === 2023 ? 'selected' : ''}`} onClick={() => store.dispatch(setSummaryListYear(2023))}>
                      <figure className="icon list2023icon"></figure>
                        <div className="navbaryearelementtext">2023</div>
                      </div>
                      <div className={`navbaryearelement ${summaryListYear === 2017 ? 'selected' : ''}`} onClick={() => store.dispatch(setSummaryListYear(2017))}>
                      <figure className="icon list2017icon"></figure>
                        <div className="navbaryearelementtext">2017</div>
                      </div>
                      <div className={`navbaryearelement ${summaryListYear === 2010 ? 'selected' : ''}`} onClick={() => store.dispatch(setSummaryListYear(2010))}>
                      <figure className="icon list2010icon"></figure>
                        <div className="navbaryearelementtext">2010</div>
                      </div>
                      <div className={`navbaryearelement ${summaryListYear === 2005 ? 'selected' : ''}`} onClick={() => store.dispatch(setSummaryListYear(2005))}>
                      <figure className="icon list2005icon"></figure>
                        <div className="navbaryearelementtext">2005</div>
                      </div>
                    </div>
                    <div className="navbartableselectiondescription">
                      <div>Summary Valuation List</div>
                    </div>
                  </div>
                  <div className="vhr">
                  </div>
                </>}
              </div>
              <div className='popovergridcontainer'>
                <div className={showListBuilderDetails ? "gridcontainer details listbuilder" : "gridcontainer listbuilder"} >
                  <ListBuilderTable columnGroupingModel={columnGroupingModel} columns={columns} rows={rows} rowSelectionModel={rowSelectionModel} setRowSelectionModel={setRowSelectionModel} handleRowClick={handleRowClick} setTableState={handleSetTableState} tableState={tableState}
                    triggerSaveTableState={closeListBuilder} contextMenuPosition={contextMenuPosition} handleCloseContextMenu={handleCloseContextMenu} handleMenuItemClick={handleMenuItemClick} handleColumnWidthChange={handleColumnResize}/>
                </div>
                {showListBuilderDetails && listBuilderCount &&
                  <div className="tablepopoverdiv">
                    <DetailsPopover uarn={selectedUARN}  listYear = {summaryListYear}/>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
          {report && 
          <ReportWindow reportString = {report} onClose={()=>setReport(undefined)}></ReportWindow>
          }
          {showNoValuation &&
            <ClickAwayListener onClickAway={(event) => (setShowNoValuation(false))}>
              <div className='searchemptypopup'>
                <div className='closeme' onClick={() => setShowNoValuation(false)}>
                  <CloseIcon />
                </div>
                <div className='popupheader'>Analyse</div>
                <div className='popupbody'>Valuation Not Available</div>
                <Button variant='outlined' className='popupbutton' onClick={() => setShowNoValuation(false)}>OK</Button>
              </div>
            </ClickAwayListener>
          }
      </>
      }
            {showNoScheme&&
        <ClickAwayListener onClickAway={(event) =>(setShowNoScheme(false))}>
          <div className='noschemepopup'>
          <div className='closeme' onClick={()=>setShowNoScheme(false)}>
            <CloseIcon />
          </div>
              <div className='popupheader'>Analyse</div>
              <div className='popupbody'>Valuation Scheme Not Available</div>
              <Button variant='outlined' className='popupbutton' onClick={()=>setShowNoScheme(false)}>OK</Button>
          </div>
        </ClickAwayListener>
          }
          {duplicateUARNs> 0 &&
            <ClickAwayListener onClickAway={(event) =>(store.dispatch(resetDuplicateUARN()))}>
            <div className='noschemepopup'>
            <div className='closeme' onClick={()=>store.dispatch(resetDuplicateUARN())}>
              <CloseIcon />
            </div>
                <div className='popupheader'>Analyse</div>
                <div className='popupbody'>{duplicateUARNs} duplicate rows were not added.</div>
                <Button variant='outlined' className='popupbutton' onClick={()=>store.dispatch(resetDuplicateUARN())}>OK</Button>
            </div>
          </ClickAwayListener>
          }
    </>
  );


}