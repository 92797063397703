import { SearchLogic } from "../Enums/SearchLogic";
import { TrendLogic } from "../Enums/TrendLogic";
import { UARvsIBR } from "../Enums/UARvsIBR";
import { SurveyAdvanced } from "../SurveyAdvanced";

export class SurveyAdvancedApiModel {
    hasCarpark?: boolean;
    hasPlantMachinery?: boolean;
    totalAreaSearch?: boolean;
    totalAreaLogic?: SearchLogic;
    totalArea?: number;
    totalAreaLessThan?: number;
    ibrSearch?: boolean;
    ibrLogic?: TrendLogic;
    ibrValue?: number;
    uarTrendSearch?: boolean;
    uarTrendLogic?: TrendLogic;
    uarTrendValue?: number;
    uarVsIBRSearch?: UARvsIBR;
    uarSearch?: boolean;
    uarLogic?: SearchLogic;
    uarValue?: number;
    uarValueLessThan?: number;
    rvSearch?: boolean;
    rvLogic?: SearchLogic;
    rvValue?: number;
    rvValueLessThan?: number;
    schemeReference?: string;

    constructor(data: Partial<SurveyAdvanced>) {
        this.hasCarpark = data.hasCarpark == true?data.hasCarpark : undefined;
        this.hasPlantMachinery = data.hasPlantMachinery == true?data.hasPlantMachinery: undefined;
        this.totalAreaSearch = data.totalAreaSearch == true?data.totalAreaSearch:undefined;
        this.totalAreaLogic = (data.totalAreaSearch) ?Number(data.totalAreaLogic) : undefined;
        this.totalArea = (data.totalAreaSearch) ? Number(data.totalArea) : undefined;
        this.totalAreaLessThan = (data.totalAreaSearch &&  Number(this.totalAreaLogic)== 3) ? Number(data.totalAreaLessThan) : undefined;
        this.ibrSearch = data.ibrSearch==true?data.ibrSearch: undefined;
        this.ibrLogic = (data.ibrSearch)?Number(data.ibrLogic) : undefined;
        this.ibrValue = (data.ibrSearch)?Number(data.ibrValue) : undefined;
        this.uarTrendSearch = (data.uarTrendSearch)?data.uarTrendSearch :undefined;
        this.uarTrendLogic = (data.uarTrendSearch)? Number(data.uarTrendLogic) : undefined;
        this.uarTrendValue = (data.uarTrendSearch && Number(data.uarTrendLogic))? Number(data.uarTrendValue) : undefined;
        this.uarVsIBRSearch = Number(data.uarVsIBRSearch) ==0? undefined : Number(data.uarVsIBRSearch);
        this.uarSearch = data.uarSearch == true ? data.uarSearch: undefined;
        this.uarLogic = (data.uarSearch) ? Number(data.uarLogic): undefined;
        this.uarValue = (data.uarSearch) ? Number(data.uarValue): undefined;
        this.uarValueLessThan = (data.uarSearch && Number(data.uarLogic) == 3) ? Number(data.uarValueLessThan): undefined;
        this.rvSearch = data.rvSearch== true? data.rvSearch: undefined;
        this.rvLogic = (data.rvSearch) ? Number(data.rvLogic): undefined;
        this.rvValue = (data.rvSearch) ? Number(data.rvValue): undefined;
        this.rvValueLessThan = (data.rvSearch && Number(data.rvLogic) == 3) ? Number(data.rvValueLessThan): undefined;
        this.schemeReference = (data.schemeReference &&data.schemeReference != '') ? data.schemeReference: undefined;
    }
}