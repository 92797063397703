import { useEffect, useState } from "react";
import store from "../../Redux/ConfigureStore";
import IntegratedListTrends from "../../Models/IntegratedListTrends";
import { cleanup } from "@testing-library/react";
import { setTrendSearch } from "../../Redux/SearchSlicer";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import { TrendLogic } from "../../Models/Enums/TrendLogic";

export default function TrendSearchComponent(props) {
  const [trendSearchData, setTrendSearchData] = useState<IntegratedListTrends | null>(null);
  useEffect(() => {
    setTrendSearchData(new IntegratedListTrends(store.getState().search.integratedListTrends));

  }, []);
  const handleChange = async (e) => {
    const { name } = e.target;
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    const updatedTrendSearchData = { ...trendSearchData, [name]: value };
    setTrendSearchData(new IntegratedListTrends(updatedTrendSearchData));
  };
  const{searchCount}= props;
  useEffect(()=>{
    if(searchCount<1){
      setTrendSearchData(new IntegratedListTrends(store.getState().search.integratedListTrends));
    }
  },[searchCount])
  useEffect(() => {
    sendSearchData();
  }, [trendSearchData]);
  const handleSelectChange = (e) => {
    const { value } = e.target;
    handleChange(e);
  }
  const handleCheckBoxChange = (e) => {
    const { checked } = e.target;
    handleChange(e);
  }
  const handleAmountChange = (e) => {
    const { value } = e.target;
    handleChange(e);
  }
  const handleBlur = (e) => {
    const { value, name } = e.target;
    if (value.trim() === '') {
      e.target.value = 0
      handleChange(e);
    }
  }
  const sendSearchData = () => {
    if(trendSearchData){
    store.dispatch(setTrendSearch(trendSearchData.serialize()));
  }
  }
  return (<>
      {trendSearchData &&
    <div className="searchcomponentcategory">
      <div className="trendminicomponent">
        <div className="trendminiheader layoutrow">
          <Checkbox name='list2023' checked={trendSearchData.list2023} sx={{ color: '#009FE3', p: 0 }} color="secondary" onChange={handleCheckBoxChange} />
          <div className="integratedlistlabel">2023 List</div>
        </div>
        <div className='trendminicontent'>
          <div className="text">Hereditaments that </div>
          <select id="dropdown" className='trendlogicinput' name='list2023Logic' disabled={!trendSearchData.list2023} value={trendSearchData.list2023Logic} onChange={handleSelectChange}>
            {Object.values(TrendLogic).filter(key => isNaN(Number(TrendLogic[key]))).map((value) => (
              <option key={value} value={value}>
                {TrendLogic[value]}
              </option>
            ))}
          </select>
          <input type='number' onBlur={handleBlur} className="trendamountinput" name='list2023Amount' value={trendSearchData.list2023Amount} disabled={trendSearchData.list2023 == true &&( Number(trendSearchData.list2023Logic) == 1 ||Number(trendSearchData.list2023Logic) == 4) ?false : true } onChange={handleAmountChange} />

        </div>
      </div>
      <div className="trendminicomponent">
        <div className="trendminiheader layoutrow">
          <Checkbox name='list2023v2017' checked={trendSearchData.list2023v2017} sx={{ color: '#009FE3', p: 0 }} color="secondary" onChange={handleCheckBoxChange} />
          <div className="integratedlistlabel">2023 VS 2017</div>
        </div>
        <div className='trendminicontent'>
          <div className="text">Hereditaments that </div>
          <select id="dropdown" className='trendlogicinput' name='list2023Logicv2017' disabled={!trendSearchData.list2023v2017} value={trendSearchData.list2023Logicv2017} onChange={handleSelectChange}>
            {Object.values(TrendLogic).filter(key => isNaN(Number(TrendLogic[key]))).map((value) => (
              <option key={value} value={value}>
                {TrendLogic[value]}
              </option>
            ))}
          </select>
          <input type='number' onBlur={handleBlur} className="trendamountinput" name='list2023Amountv2017' value={trendSearchData.list2023Amountv2017} disabled={trendSearchData.list2023v2017 == true &&( Number(trendSearchData.list2023Logicv2017) == 1 ||Number(trendSearchData.list2023Logicv2017) == 4) ?false : true } onChange={handleAmountChange} />

        </div>
      </div>
      <div className="trendminicomponent">
        <div className="trendminiheader layoutrow">
          <Checkbox name='list2017' checked={trendSearchData.list2017} sx={{ color: '#009FE3', p: 0 }} color="secondary" onChange={handleCheckBoxChange} />
          <div className="integratedlistlabel">2017 List</div>
        </div>
        <div className='trendminicontent'>
          <div className="text">Hereditaments that </div>
          <select id="dropdown" className='trendlogicinput' name='list2017Logic' disabled={!trendSearchData.list2017} value={trendSearchData.list2017Logic} onChange={handleSelectChange}>
            {Object.values(TrendLogic).filter(key => isNaN(Number(TrendLogic[key]))).map((value) => (
              <option key={value} value={value}>
                {TrendLogic[value]}
              </option>
            ))}
          </select>
          <input type='number' onBlur={handleBlur} className="trendamountinput" name='list2017Amount' value={trendSearchData.list2017Amount} disabled={trendSearchData.list2017 == true &&( Number(trendSearchData.list2017Logic) == 1 ||Number(trendSearchData.list2017Logic) == 4) ?false : true }onChange={handleAmountChange} />

        </div>
      </div>
      <div className="trendminicomponent">
        <div className="trendminiheader layoutrow">
          <Checkbox name='list2017v2010' checked={trendSearchData.list2017v2010} sx={{ color: '#009FE3', p: 0 }} color="secondary" onChange={handleCheckBoxChange} />
          <div className="integratedlistlabel">2017 VS 2010</div>
        </div>
        <div className='trendminicontent'>
          <div className="text">Hereditaments that </div>
          <select id="dropdown" className='trendlogicinput' name='list2017Logicv2010' disabled={!trendSearchData.list2017v2010} value={trendSearchData.list2017Logicv2010} onChange={handleSelectChange}>
            {Object.values(TrendLogic).filter(key => isNaN(Number(TrendLogic[key]))).map((value) => (
              <option key={value} value={value}>
                {TrendLogic[value]}
              </option>
            ))}
          </select>
          <input type='number' onBlur={handleBlur} className="trendamountinput" name='list2017Amountv2010' value={trendSearchData.list2017Amountv2010} disabled={trendSearchData.list2017v2010 == true &&( Number(trendSearchData.list2017Logicv2010) == 1 ||Number(trendSearchData.list2017Logicv2010) == 4) ?false : true } onChange={handleAmountChange} />

        </div>
      </div>
      <div className="trendminicomponent">
        <div className="trendminiheader layoutrow">
          <Checkbox name='list2010' checked={trendSearchData.list2010} sx={{ color: '#009FE3', p: 0 }} color="secondary" onChange={handleCheckBoxChange} />
          <div className="integratedlistlabel">2010 List</div>
        </div>
        <div className='trendminicontent'>
          <div className="text">Hereditaments that </div>
          <select id="dropdown" className='trendlogicinput' name='list2010Logic' disabled={!trendSearchData.list2010} value={trendSearchData.list2010Logic} onChange={handleSelectChange}>
            {Object.values(TrendLogic).filter(key => isNaN(Number(TrendLogic[key]))).map((value) => (
              <option key={value} value={value}>
                {TrendLogic[value]}
              </option>
            ))}
          </select>
          <input type='number' onBlur={handleBlur} className="trendamountinput" name='list2010Amount' value={trendSearchData.list2010Amount} disabled={trendSearchData.list2010 == true &&( Number(trendSearchData.list2010Logic) == 1 ||Number(trendSearchData.list2010Logic) == 4) ?false : true } onChange={handleAmountChange} />

        </div>
      </div>
      <div className="trendminicomponent">
        <div className="trendminiheader layoutrow">
          <Checkbox name='list2010v2005' checked={trendSearchData.list2010v2005} sx={{ color: '#009FE3', p: 0 }} color="secondary" onChange={handleCheckBoxChange} />
          <div className="integratedlistlabel">2010 VS 2005</div>
        </div>
        <div className='trendminicontent'>
          <div className="text">Hereditaments that </div>
          <select id="dropdown" className='trendlogicinput' name='list2010Logicv2005' disabled={!trendSearchData.list2010v2005} value={trendSearchData.list2010Logicv2005} onChange={handleSelectChange}>
            {Object.values(TrendLogic).filter(key => isNaN(Number(TrendLogic[key]))).map((value) => (
              <option key={value} value={value}>
                {TrendLogic[value]}
              </option>
            ))}
          </select>
          <input type='number' onBlur={handleBlur} className="trendamountinput" name='list2010Amountv2005' value={trendSearchData.list2010Amountv2005} disabled={trendSearchData.list2010v2005 == true &&( Number(trendSearchData.list2010Logicv2005) == 1 ||Number(trendSearchData.list2010Logicv2005) == 4) ?false : true } onChange={handleAmountChange} />

        </div>
      </div>
      <div className="trendminicomponent">
        <div className="trendminiheader layoutrow">
          <Checkbox name='list2005' checked={trendSearchData.list2005} sx={{ color: '#009FE3', p: 0 }} color="secondary" onChange={handleCheckBoxChange} />
          <div className="integratedlistlabel">2005 List</div>
        </div>
        <div className='trendminicontent'>
          <div className="text">Hereditaments that </div>
          <select id="dropdown" className='trendlogicinput' name='list2005Logic' disabled={!trendSearchData.list2005} value={trendSearchData.list2005Logic} onChange={handleSelectChange}>
            {Object.values(TrendLogic).filter(key => isNaN(Number(TrendLogic[key]))).map((value) => (
              <option key={value} value={value}>
                {TrendLogic[value]}
              </option>
            ))}
          </select>
          <input type='number' onBlur={handleBlur} className="trendamountinput" name='list2005Amount' value={trendSearchData.list2005Amount} disabled={trendSearchData.list2005 == true &&( Number(trendSearchData.list2005Logic) == 1 ||Number(trendSearchData.list2005Logic) == 4) ?false : true } onChange={handleAmountChange} />

        </div>
      </div>
      <div className="trendminicomponent">
        <div className="trendminiheader layoutrow">
          <Checkbox name='list2005v2000' checked={trendSearchData.list2005v2000} sx={{ color: '#009FE3', p: 0 }} color="secondary" onChange={handleCheckBoxChange} />
          <div className="integratedlistlabel">2005 VS 2000</div>
        </div>
        <div className='trendminicontent'>
          <div className="text">Hereditaments that </div>
          <select id="dropdown" className='trendlogicinput' name='list2005Logicv2000' disabled={!trendSearchData.list2005v2000} value={trendSearchData.list2005Logicv2000} onChange={handleSelectChange}>
            {Object.values(TrendLogic).filter(key => isNaN(Number(TrendLogic[key]))).map((value) => (
              <option key={value} value={value}>
                {TrendLogic[value]}
              </option>
            ))}
          </select>
          <input type='number' onBlur={handleBlur} className="trendamountinput" name='list2005Amountv2000' value={trendSearchData.list2005Amountv2000} disabled={trendSearchData.list2005v2000 == true &&( Number(trendSearchData.list2005Logicv2000) == 1 ||Number(trendSearchData.list2005Logicv2000) == 4) ?false : true } onChange={handleAmountChange} />

        </div>
      </div>
      <div className="trendminicomponent">
        <div className="trendminiheader layoutrow">
          <Checkbox name='list2000' checked={trendSearchData.list2000} sx={{ color: '#009FE3', p: 0 }} color="secondary" onChange={handleCheckBoxChange} />
          <div className="integratedlistlabel">2000 List</div>
        </div>
        <div className='trendminicontent'>
          <div className="text">Hereditaments that </div>
          <select id="dropdown" className='trendlogicinput' name='list2000Logic' disabled={!trendSearchData.list2000} value={trendSearchData.list2000Logic} onChange={handleSelectChange}>
            {Object.values(TrendLogic).filter(key => isNaN(Number(TrendLogic[key]))).map((value) => (
              <option key={value} value={value}>
                {TrendLogic[value]}
              </option>
            ))}
          </select>
          <input type='number' onBlur={handleBlur} className="trendamountinput" name='list2000Amount' value={trendSearchData.list2000Amount} disabled={trendSearchData.list2000 == true &&( Number(trendSearchData.list2000Logic) == 1 ||Number(trendSearchData.list2000Logic) == 4) ?false : true } onChange={handleAmountChange} />

        </div>
      </div>
      <div className="trendminicomponent">
        <div className="trendminiheader layoutrow">
          <Checkbox name='list2000v1995' checked={trendSearchData.list2000v1995} sx={{ color: '#009FE3', p: 0 }} color="secondary" onChange={handleCheckBoxChange} />
          <div className="integratedlistlabel">2000 VS 1995</div>
        </div>
        <div className='trendminicontent'>
          <div className="text">Hereditaments that </div>
          <select id="dropdown" className='trendlogicinput' name='list2000Logicv1995' disabled={!trendSearchData.list2000v1995} value={trendSearchData.list2000Logicv1995} onChange={handleSelectChange}>
            {Object.values(TrendLogic).filter(key => isNaN(Number(TrendLogic[key]))).map((value) => (
              <option key={value} value={value}>
                {TrendLogic[value]}
              </option>
            ))}
          </select>
          <input type='number' onBlur={handleBlur} className="trendamountinput" name='list2000Amountv1995' value={trendSearchData.list2000Amountv1995} disabled={trendSearchData.list2000v1995 == true &&( Number(trendSearchData.list2000Logicv1995) == 1 ||Number(trendSearchData.list2000Logicv1995) == 4) ?false : true } onChange={handleAmountChange} />

        </div>
      </div>
      <div className="trendminicomponent">
        <div className="trendminiheader layoutrow">
          <Checkbox name='list1995' checked={trendSearchData.list1995} sx={{ color: '#009FE3', p: 0 }} color="secondary" onChange={handleCheckBoxChange} />
          <div className="integratedlistlabel">1995 List</div>
        </div>
        <div className='trendminicontent'>
          <div className="text">Hereditaments that </div>
          <select id="dropdown" className='trendlogicinput' name='list1995Logic' disabled={!trendSearchData.list1995} value={trendSearchData.list1995Logic} onChange={handleSelectChange}>
            {Object.values(TrendLogic).filter(key => isNaN(Number(TrendLogic[key]))).map((value) => (
              <option key={value} value={value}>
                {TrendLogic[value]}
              </option>
            ))}
          </select>
          <input type='number' onBlur={handleBlur} className="trendamountinput" name='list1995Amount' value={trendSearchData.list1995Amount} disabled={trendSearchData.list1995 == true &&( Number(trendSearchData.list1995Logic) == 1 ||Number(trendSearchData.list1995Logic) == 4) ?false : true } onChange={handleAmountChange} />

        </div>
      </div>
      <div className="trendminicomponent">
        <div className="trendminiheader layoutrow">
          <Checkbox name='list1995v1990' checked={trendSearchData.list1995v1990} sx={{ color: '#009FE3', p: 0 }} color="secondary" onChange={handleCheckBoxChange} />
          <div className="integratedlistlabel">1995 VS 1990</div>
        </div>
        <div className='trendminicontent'>
          <div className="text">Hereditaments that </div>
          <select id="dropdown" className='trendlogicinput' name='list1995Logicv1990' disabled={!trendSearchData.list1995v1990} value={trendSearchData.list1995Logicv1990} onChange={handleSelectChange}>
            {Object.values(TrendLogic).filter(key => isNaN(Number(TrendLogic[key]))).map((value) => (
              <option key={value} value={value}>
                {TrendLogic[value]}
              </option>
            ))}
          </select>
          <input type='number' onBlur={handleBlur} className="trendamountinput" name='list1995Amountv1990' value={trendSearchData.list1995Amountv1990} disabled={trendSearchData.list1995v1990 == true &&( Number(trendSearchData.list1995Logicv1990) == 1 ||Number(trendSearchData.list1995Logicv1990) == 4) ?false : true } onChange={handleAmountChange} />

        </div>
      </div>
      <div className="trendminicomponent">
        <div className="trendminiheader layoutrow">
          <Checkbox name='list1990' checked={trendSearchData.list1990} sx={{ color: '#009FE3', p: 0 }} color="secondary" onChange={handleCheckBoxChange} />
          <div className="integratedlistlabel">1990 List</div>
        </div>
        <div className='trendminicontent'>
          <div className="text">Hereditaments that </div>
          <select id="dropdown" className='trendlogicinput' name='list1990Logic' disabled={!trendSearchData.list1990} value={trendSearchData.list1990Logic} onChange={handleSelectChange}>
            {Object.values(TrendLogic).filter(key => isNaN(Number(TrendLogic[key]))).map((value) => (
              <option key={value} value={value}>
                {TrendLogic[value]}
              </option>
            ))}
          </select>
          <input type='number' onBlur={handleBlur} className="trendamountinput" name='list1990Amount' value={trendSearchData.list1990Amount} disabled={trendSearchData.list1990 == true &&( Number(trendSearchData.list1990Logic) == 1 ||Number(trendSearchData.list1990Logic) == 4) ?false : true } onChange={handleAmountChange} />

        </div>
      </div>
      </div>
      }
      </>
  )
}