import { useSelector } from "react-redux";
import BasicSearch from "../Models/BasicSearch";
import { selectTableContext } from "../Redux/SearchSlicer";
import AuthorityScheduleAdv1 from "../Models/AuthorityScheduleAdv1";
import AuthorityScheduleAdv1ApiModel from "../Models/SearchApiModels/AuthorityScheduleAdv1ApiModel";
const validator = require('validator');
export function validateBasicSearch(data:Partial<BasicSearch>){
    const errors: { field: string; message: string; }[] = [];
    const hasAnyField = Object.keys(data).some(key => isRequired(data[key as keyof BasicSearch]));

    if (!hasAnyField) {
        errors.push({ field: 'basicSearch', message: 'At least one field is required' });
    }
      return errors;
    }
export function validateAuthorityScheduleAdv1ApiModel(data:Partial<AuthorityScheduleAdv1ApiModel>){
    const errors: { field: string; message: string; }[] = [];

    if((data.scheduleDateLogic==1 && !isRequired(data.scheduleDate))||(data.scheduleDateLogic ==2 && (!isRequired(data.scheduleFromDate) && !isRequired(data.scheduleToDate) ))){
      errors.push({ field: 'scheduleDate', message: 'One field is required' });
  }
    return errors;
  }

export function isRequired(object:any){
    if (object){        
        if(typeof object == 'string'){
            if (object.trim() ==''){
                return false
            }
        }
        return true
    }
    else{
        return false
    }
}
export function validatePasswords(password, repeatPassword) {
    const errors:string[] = [];
  
    if (!password || !repeatPassword) {
      errors.push('Both password fields are required.');
      return {
        isValid: false,
        errors,
      };
    }
  
    if (password !== repeatPassword) {
      errors.push("Passwords don't match.");
    }
  
    if (password.length < 8) {
      errors.push('Password must be at least 8 characters long.');
    }
  
    if (!/[A-Z]/.test(password)) {
      errors.push('Password must contain at least one uppercase letter.');
    }
  
    if (!/[a-z]/.test(password)) {
      errors.push('Password must contain at least one lowercase letter.');
    }
  
    if (!/\d/.test(password)) {
      errors.push('Password must contain at least one number.');
    }
  
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      errors.push('Password must contain at least one special character.');
    }
  
    return {
      isValid: errors.length === 0,
      errors,
    };
  }