import { formatDateForApi } from "../../Services/FormatService";
import { DateSearchLogic } from "../Enums/DateSearchLogic";
import { SearchLogic } from "../Enums/SearchLogic";
import IntegratedListAdvancedSearch from "../IntegratedListAdvancedSearch";

export default class IntegratedListAdvancedApiModel{
    rvSearch?: boolean;
    rvList?: number;
    rvLogic?: SearchLogic;
    rvValue?: number;
    rvValueLessThan?: number;

    edSearch?: boolean;
    edList?: number;
    edLogic?: DateSearchLogic;
    edValue?: string;
    edValueLessThan?: string;

    ladSearch?: boolean;
    ladList?: number;
    ladLogic?: DateSearchLogic;
    ladValue?: string;
    ladValueLessThan?: string;
    
    partDomestic?: boolean;
    partDomesticSearch?:boolean;
    partDomesticList?:number;
    listStatus?:boolean;
    listStatusSearch?:boolean;
    listStatusList?:number;

    constructor(data:Partial<IntegratedListAdvancedSearch>)
    {
        this.rvSearch = (data.rvSearch && data.rvValue!=null && data.rvValue !=undefined)?data.rvSearch:undefined;
        this.rvList = (data.rvSearch && data.rvValue!=null && data.rvValue !=undefined)? data.rvList:undefined;
        this.rvLogic =  (data.rvSearch && data.rvValue!=null && data.rvValue !=undefined)?Number(data.rvLogic):undefined;
        this.rvValue = (data.rvSearch && data.rvValue!=null && data.rvValue !=undefined)? data.rvValue:undefined;
        this.rvValueLessThan = (data.rvSearch&& Number(data.rvLogic) == 3)? data.rvValueLessThan:undefined;

        this.edSearch = (data.edSearch&& data.edValue)?data.edSearch:undefined;
        this.edList = (data.edSearch&& data.edValue)? data.edList:undefined;
        this.edLogic =  (data.edSearch&& data.edValue)?Number(data.edLogic):undefined;
        this.edValue = (data.edSearch&& data.edValue)? formatDateForApi(data.edValue):undefined;
        this.edValueLessThan = (data.edSearch&& Number(data.edLogic) == 4 && data.edValueLessThan)? formatDateForApi(data.edValueLessThan) :undefined;

        this.ladSearch = (data.ladSearch && data.ladValue)?data.ladSearch:undefined;
        this.ladList = (data.ladSearch && data.ladValue)? data.ladList:undefined;
        this.ladLogic =  (data.ladSearch && data.ladValue)?Number(data.ladLogic):undefined;
        this.ladValue = (data.ladSearch && data.ladValue)? formatDateForApi(data.ladValue):undefined;
        this.ladValueLessThan = (data.ladSearch&& Number(data.ladLogic) == 4 && data.ladValueLessThan)? formatDateForApi(data.ladValueLessThan):undefined;

        this.partDomestic = data.partDomesticSearch?data.partDomestic:undefined;
        this.partDomesticSearch = data.partDomesticSearch? data.partDomesticSearch:undefined;
        this.partDomesticList =  data.partDomesticSearch?Number(data.partDomesticList):undefined;

        this.listStatusSearch = data.listStatusSearch?data.listStatusSearch:undefined;
        this.listStatus = data.listStatusSearch? (data.listStatus == 'true' ? true:false):undefined;
        this.listStatusList =  data.listStatusSearch?Number(data.listStatusList):undefined;
    }
}