import { TrendLogic } from "../Enums/TrendLogic";
import IntegratedListTrends from "../IntegratedListTrends";

export default class IntegratedListTrendsApiModel {
    list2023?: boolean;
    list2023Logic?: TrendLogic;
    list2023Amount?: number;

    list2023v2017?:boolean;
    list2023Logicv2017?: TrendLogic;
    list2023Amountv2017?: number;

    list2017?: boolean;
    list2017Logic?: TrendLogic;
    list2017Amount?: number;

    list2017v2010?: boolean;
    list2017Logicv2010?: TrendLogic;
    list2017Amountv2010?: number;

    list2010?: boolean;
    list2010Logic?: TrendLogic;
    list2010Amount?: number;

    list2010v2005?: boolean;
    list2010Logicv2005?: TrendLogic;
    list2010Amountv2005?: number;

    list2005?: boolean;
    list2005Logic?: TrendLogic;
    list2005Amount?: number;

    list2005v2000?: boolean;
    list2005Logicv2000?: TrendLogic;
    list2005Amountv2000?: number;

    list2000?: boolean;
    list2000Logic?: TrendLogic;
    list2000Amount?: number;

    list2000v1995?: boolean;
    list2000Logicv1995?: TrendLogic;
    list2000Amountv1995?: number;

    list1995?: boolean;
    list1995Logic?: TrendLogic;
    list1995Amount?: number;

    list1995v1990?: boolean;
    list1995Logicv1990?: TrendLogic;
    list1995Amountv1990?: number;

    list1990?: boolean;
    list1990Logic?: TrendLogic;
    list1990Amount?: number;

    constructor(data: Partial<IntegratedListTrends>) {
            this.list2023 = data.list2023 ? data.list2023: undefined;
            this.list2023Logic = data.list2023 ?Number(data.list2023Logic): undefined;
            this.list2023Amount = (data.list2023 &&(Number(data.list2023Logic)==1 || Number(data.list2023Logic)==4))?data.list2023Amount: undefined
            this.list2023v2017 = data.list2023v2017 ? data.list2023v2017: undefined;
            this.list2023Logicv2017 =data.list2023v2017 ?  Number(data.list2023Logicv2017): undefined;
            this.list2023Amountv2017 =(data.list2023v2017&&(Number(data.list2023Logicv2017)==1 || Number(data.list2023Logicv2017)==4)) ?  data.list2023Amountv2017: undefined
            this.list2017 = data.list2017? data.list2017: undefined;
            this.list2017Logic = data.list2017? Number(data.list2017Logic): undefined;
            this.list2017Amount = (data.list2017&&(Number(data.list2017Logic)==1 || Number(data.list2017Logic)==4))? data.list2017Amount: undefined;
            this.list2017v2010 = data.list2017v2010? data.list2017v2010: undefined;
            this.list2017Logicv2010 = data.list2017v2010?Number(data.list2017Logicv2010): undefined;
            this.list2017Amountv2010 = (data.list2017v2010&&(Number(data.list2017Logicv2010)==1 || Number(data.list2017Logicv2010)==4))?data.list2017Amountv2010: undefined
            this.list2010 = data.list2010? data.list2010: undefined;
            this.list2010Logic = data.list2010? Number(data.list2010Logic): undefined;
            this.list2010Amount = (data.list2010&&(Number(data.list2010Logic)==1 || Number(data.list2010Logic)==4))? data.list2010Amount: undefined;
            this.list2010v2005 = data.list2010v2005? data.list2010v2005: undefined;
            this.list2010Logicv2005 = data.list2010v2005?Number(data.list2010Logicv2005): undefined
            this.list2010Amountv2005 = (data.list2010v2005&&(Number(data.list2010Logicv2005)==1 || Number(data.list2010Logicv2005)==4))?data.list2010Amountv2005: undefined
            this.list2005 = data.list2005? data.list2005: undefined;
            this.list2005Logic =  data.list2005? Number(data.list2005Logic): undefined
            this.list2005Amount =  (data.list2005&&(Number(data.list2005Logic)==1 || Number(data.list2005Logic)==4))? data.list2005Amount: undefined
            this.list2005v2000 = data.list2005v2000? data.list2005v2000: undefined;
            this.list2005Logicv2000 = data.list2005v2000?Number(data.list2005Logicv2000): undefined
            this.list2005Amountv2000 = (data.list2005v2000&&(Number(data.list2005Logicv2000)==1 || Number(data.list2005Logicv2000)==4))?data.list2005Amountv2000: undefined
            this.list2000 = data.list2000? data.list2000: undefined;
            this.list2000Logic = data.list2000?Number(data.list2000Logic): undefined
            this.list2000Amount = (data.list2000&&(Number(data.list2000Logic)==1 || Number(data.list2000Logic)==4))?data.list2000Amount: undefined
            this.list2000v1995 = data.list2000v1995? data.list2000v1995: undefined;
            this.list2000Logicv1995 = data.list2000v1995?Number(data.list2000Logicv1995): undefined
            this.list2000Amountv1995 = (data.list2000v1995&&(Number(data.list2000Logicv1995)==1 || Number(data.list2000Logicv1995)==4))?data.list2000Amountv1995: undefined
            this.list1995 = data.list1995? data.list1995: undefined;
            this.list1995Logic =  data.list1995?Number(data.list1995Logic): undefined
            this.list1995Amount =  (data.list1995&&(Number(data.list1995Logic)==1 || Number(data.list1995Logic)==4))?data.list1995Amount: undefined
            this.list1995v1990 = data.list1995v1990? data.list1995v1990: undefined;
            this.list1995Logicv1990 = data.list1995v1990?Number(data.list1995Logicv1990): undefined
            this.list1995Amountv1990 = (data.list1995v1990&&(Number(data.list1995Logicv1990)==1 || Number(data.list1995Logicv1990)==4))?data.list1995Amountv1990: undefined
            this.list1990 = data.list1990? data.list1990: undefined;
            this.list1990Logic = data.list1990?Number(data.list1990Logic): undefined
            this.list1990Amount = (data.list1990&&(Number(data.list1990Logic)==1 || Number(data.list1990Logic)==4))?data.list1990Amount: undefined
    }
}